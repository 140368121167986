import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import Loading from "../../components/FeedbackLoading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import subDays from "date-fns/subDays";
import moment from 'moment'
import XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faTable } from '@fortawesome/free-solid-svg-icons'
import "./style.css";
import api from "../../services/api";
import { limpaCache } from "../../common/cleanCache";


import { columns, formatNumbers, formatDifference, formatFinancialNumbers, formatPercentNumbers } from "./datatableColumns";

// Formatação da tabela, botão excel, coluna absoluta

const PricingQA = () => {
    const [dateToFilter, setDateToFilter] = useState(subDays(new Date(), 1));
    const [response, setResponse] = useState([])
    const [hashcode, setHashcode] = useState('')
    const [tablePending, setTablePending] = useState()
    const [historicResponse, setHistoricResponse] = useState([])
    const [responseIndex, setResponseIndex] = useState(0)

    const downloadCSV = (index) => {       
      var wb = XLSX.utils.book_new();
      var cols = ['ticker', 'isin', 'referenceDate', 'anbimaYield', 'anbimaPrice', 'fiaYield', 'fiaPrice', 'difference',
                   'differenceAbs', 'duration', 'convexity', 'vnaD0', 'puParD0', 'eventType', 'amount', 'validated']

      wb.Props = {
          Title: "Generated by PRICING",
          Subject: "Pricing QA",
          Author: "NetQuant",
          CreatedDate: new Date()
      };

      wb.SheetNames.push("Pricing QA");

      var pricingDataSheet = XLSX.utils.aoa_to_sheet([cols]);

      response[index].map(row => {
        XLSX.utils.sheet_add_aoa(pricingDataSheet, [
            [ row.ticker, 
              row.isin, 
              moment(row.referenceDate).format("DD/MM/YYYY"), 
              formatPercentNumbers(row.anbimaYield, 4), 
              formatFinancialNumbers(row.anbimaPrice, 6),
              formatPercentNumbers(row.fiaYield, 4),
              formatFinancialNumbers(row.fiaPrice, 6),
              formatNumbers(row.difference, 10),
              formatDifference(row.difference, 6),
              formatNumbers(row.duration, 6),
              formatNumbers(row.convexity, 2),
              formatFinancialNumbers(row.vnaD0, 6),
              formatFinancialNumbers(row.puParD0, 6),
              row.eventType,
              row.amount,
              String(row.validated)
            ],
              
        ], {origin:-1});

        return null
    })
      
      wb.Sheets["Pricing QA"] = pricingDataSheet;

      XLSX.writeFile(wb, `PricingQA_${historicResponse[index]}.xlsx`);
    }
    
    useEffect(() => {
      if (!hashcode) return
      const interval = setInterval(_ => {
          getDebenturesFromRedis()
      }, 30000);
      return _ => clearInterval(interval);
    }, [hashcode]);

    const isWeekday = (date) => {
      const day = date.getDay();
      return day !== 0 && day !== 6;
    };

    const postDebenturesOnRedis = async (generatedHashcode) => {
      var date = moment(dateToFilter).format("YYYY-MM-DD")

      try {
        await api.post('postPricingQA', {date, generatedHashcode});

        setTablePending(true)
      } catch (e) {
        console.log(e);
      }
    }


    const getDebenturesFromRedis = async () => {
      try {
          const { data }  = await api.post('getPricingQA', {hashcode});
                  
          if (data.result !== "No Data Found"){
            if (response.length >= 5){
              response.shift()
              historicResponse.shift()
              setResponse([...response, data.result])
              setHistoricResponse([...historicResponse, moment(dateToFilter).format("DD/MM/YYYY")])
            } else {
              setResponse([...response, data.result])
              setHistoricResponse([...historicResponse, moment(dateToFilter).format("DD/MM/YYYY")])
            }
            
            setTablePending(false)
            setHashcode('')
            setResponseIndex(response.length)
            limpaCache()
          }
      } catch (e) {
        console.log(e);
      }
    }

    const handleFilter = () => {
      limpaCache()
      var generatedHashcode = uuidv4()
      setHashcode(generatedHashcode)
      postDebenturesOnRedis(generatedHashcode)
    }

    const handleTableFilter = (index) => {
      setResponseIndex(index)
    }

    return (
        <div className="row pricingQA_menu">
          <div className="col-2">
            <table className="table table-striped pricingQA_table">
              <thead>
                <tr>
                  <th>Histórico de Datas</th>
                </tr>
              </thead>
              <tbody className="text-center">    
                {historicResponse ? historicResponse.map((date, index) => {
                  return (
                  <tr key={index}>
                    <td >{date} 
                      <button 
                        className="float-end ms-2"
                        onClick={() => downloadCSV(index)}
                        title="Baixar planilha"
                        disabled={tablePending}>
                          <FontAwesomeIcon icon={faFileCsv} size="2x"/>
                      </button>
                      <button 
                        className="float-end me-1"
                        onClick={() => handleTableFilter(index)}
                        title="Filtrar na tabela"
                        disabled={tablePending}>
                          <FontAwesomeIcon icon={faTable} size="2x"/>
                      </button>
                    </td>
                  </tr>
                  );
                }) : null}    
              </tbody>
            </table>
          </div>
          <div className="col-8">
              <div className="date-filter-input text-center">
                  <label>
                      <p className="fw-bolder">Calcular a partir do dia :</p>
                  </label>
                    
                  <div className="mb-1 mt-1">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        locale={ptBR}
                        selected={dateToFilter}
                        onChange={value => setDateToFilter(value)}
                        maxDate={subDays(new Date(), 1)}
                        filterDate={isWeekday}
                      />
                  </div>    
                  <div className="text-center">
                      <button 
                          className='btn btn-primary'
                          style={{marginBottom: '15px', marginTop: '15px'}}
                          onClick={() => handleFilter()}
                          >
                          Processar
                      </button>
                  </div> 
              </div>  
            </div>    
        { tablePending  ? <Loading/> : <DataTable
                columns={columns}
                data={response[responseIndex]}
                defaultSortFieldId={1}
                pagination
            />}
      </div>  
    );
};

export default PricingQA;