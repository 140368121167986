import React, { useState, useEffect, useMemo } from "react";

import api from "../../services/api";

import ModalDiary from "./components/Modal/Diary";
import ModalAgenda from "./components/Modal/Agenda";
import Loading from "../../components/Loading";
import notify from "../../common/toast";

import "./style.css";

const ShowAttributes = () => {
  const [debentures, setDebentures] = useState([]);
  const [searchTicker, setSearchTicker] = useState("");
  const [searchAttribute, setSearchAttribute] = useState("");
  const [checked, setChecked] = useState({
    tickers: [],
    attributes: ["B3 Code"],
  });
  const [selectedDebentures, setSelectedDebentures] = useState([]);
  const [showModalDiary, setShowModalDiary] = useState(false);
  const [diary, setDiary] = useState([]);
  const [agendaAnbima, setAgendaAnbima] = useState({
    tableHeader: [],
    data: [],
  });
  const [showModalAgenda, setShowModalAgenda] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDebentures = async () => {
      try {
        const { data } = await api.get("/getParcialDebenturesAttributes");
        setDebentures(data);
      } catch (e) {
        console.log(e);
      }
    };
    getDebentures().then();
  }, []);
  const getManyDebentures = async () => {
    try {
      setLoading(true);
      const { data } = await api.post("/getManyDebentures", {
        b3_codes: checked.tickers,
      });
      setSelectedDebentures(data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const debentureCodes = useMemo(
    () => debentures.map(debenture => debenture["B3 Code"].slice(-1)[0].value),
    [debentures]
  );

  const debentureAttributes = [
    "diary",
    "Company",
    "Serie",
    "Issue",
    "Status",
    "ISIN",
    "CVM Register Type",
    "CVM Register Date",
    "Issue Date",
    "Maturity Date",
    "Exit Reason",
    "Exit Date",
    "Yield Start Date",
    "Distribution Start Date",
    "Repactuation Date",
    "Collateral",
    "Class",
    "Quantity Issued",
    "Article 14",
    "Article 24",
    "Quantity in Market",
    "Quantity in Treasury",
    "Quantity Redeemed",
    "Quantity Canceled",
    "SND Converted Quantity",
    "SND Swapped Quantity",
    "Not SND Swapped Quantity",
    "Currency Unit 1",
    "Nominal Value",
    "Currency Unit 2",
    "Current Nominal Value",
    "Current Nominal Value Reference Date",
    "Index",
    "Type",
    "Calculation Criteria",
    "Price Index Reference Day",
    "Index Criteria",
    "Correct Every",
    "Index Multiplier",
    "Amortization - Rate",
    "Amortization - Every",
    "Amortization - Unit",
    "Amortization - Grace",
    "Amortization Type",
    "Interest New Criteria- Rate",
    "Interest New Criteria- Term",
    "Interest New Criteria- Every",
    "Interest New Criteria- Unit",
    "Interest New Criteria- Grace",
    "Interest New Criteria- Criteria",
    "Interest New Criteria- Type",
    "Participation - Rate",
    "Participation - Every",
    "Participation - Unit",
    "Participation - Grace",
    "Participation - Description",
    "Rating1",
    "Rating Agency 1",
    "Rating2",
    "Rating Agency 2",
    "Rating3",
    "Rating Agency 3",
    "Mandatory Bank",
    "Trustee",
    "Depositary Institution",
    "Lead Underwriter",
    "CNPJ",
    "Tax Free Debenture (Law 12_431)",
    "Standard Deed",
    "Early Redemption (call option)",
    "Early Redemption - Grace",
    "Early Redemption - Type 1",
    "Early Redemption - Premium 1",
    "Extraordinary Amortization - Grace",
    "Early Redemption - Type 2",
    "Early Redemption - Premium 2",
    "Events Agenda ANBIMA",
  ].sort();

  const handleToggle = (type, value) => {
    const currentIndex = checked[type].indexOf(value);
    const newChecked = { ...checked };

    currentIndex === -1
      ? setChecked(newChecked[type].push(value))
      : newChecked[type].splice(currentIndex, 1);

    setChecked(newChecked);
  };

  const showDebentureAttributes = async () => {
    const { tickers } = checked;
    if (tickers.length === 0) {
      return notify("Selecione pelo menos um ticker", "warning");
    }

    await getManyDebentures();
  };

  const showModal = (attributeName, attributeValue) => {
    if (attributeName === "diary") {
      setShowModalDiary(true);
      setDiary(attributeValue);
    }
    if (attributeName === "Events Agenda ANBIMA") {
      const [tableHeader, ...data] = attributeValue[0]["value"];
      const agenda = { tableHeader, data };
      setShowModalAgenda(true);
      setAgendaAnbima(agenda);
    }
  };

  return (
    <>
      <div className="container__debentures__search">
        <div className="box__search">
          <div className="box__ticker">
            <label style={{ fontWeight: "bold" }}>Ticker</label>
            <input
              type="text"
              onChange={e => setSearchTicker(e.target.value)}
              value={searchTicker}
              placeholder="Pesquise por um ticker"
            />
            <div className="search--scroll mb-2">
              {debentureCodes &&
                debentureCodes
                  .filter(ticker =>
                    ticker.toLowerCase().includes(searchTicker.toLowerCase())
                  )
                  .map(ticker => (
                    <div key={ticker} className="ticker__item">
                      <input
                        id={ticker}
                        type="checkbox"
                        onChange={() => handleToggle("tickers", ticker)}
                        checked={checked.tickers.includes(ticker)}
                        className="pointer"
                      />
                      <label
                        style={{ marginLeft: "10px" }}
                        htmlFor={ticker}
                        className="pointer"
                      >
                        {ticker}
                      </label>
                    </div>
                  ))}
            </div>
          </div>
          <div className="box__attribute">
            <label style={{ fontWeight: "bold" }}>Atributos</label>
            <input
              type="text"
              onChange={e => setSearchAttribute(e.target.value)}
              value={searchAttribute}
              placeholder="Pesquise por um atributo"
            />
            <div className="search--scroll mb-2" style={{ width: "330px" }}>
              {debentureAttributes
                .filter(attribute =>
                  attribute
                    .toLowerCase()
                    .includes(searchAttribute.toLowerCase())
                )
                .map(attribute => (
                  <div key={attribute} className="attribute__item">
                    <input
                      id={attribute}
                      type="checkbox"
                      onChange={() => handleToggle("attributes", attribute)}
                      checked={checked.attributes.includes(attribute)}
                      className="pointer"
                    />
                    <label
                      htmlFor={attribute}
                      style={{ marginLeft: "10px" }}
                      className="pointer"
                    >
                      {attribute.charAt(0).toUpperCase() + attribute.slice(1)}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="box__button">
        <button onClick={showDebentureAttributes} className="btn__attribute">
          Exibir atributos
        </button>
      </div>
      {loading && <Loading height="200" />}
      {selectedDebentures.length > 0 && !loading && (
        <table className="table">
          <thead>
            <tr>
              {checked["attributes"].map(attribute => (
                <th className="text-center" key={attribute}>
                  {attribute.charAt(0).toUpperCase() + attribute.slice(1)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {selectedDebentures.map((debenture, index) => (
              <tr key={index}>
                {checked["attributes"].map((attribute, indexAttribute) => (
                  <td className="text-center" key={indexAttribute}>
                    {attribute === "diary" ||
                    attribute === "Events Agenda ANBIMA" ? (
                      <button
                        onClick={() =>
                          showModal(attribute, debenture[attribute])
                        }
                        className="btn__diary"
                      >
                        Ver mais
                      </button>
                    ) : (
                      debenture[attribute][debenture[attribute].length - 1]
                        .value
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <ModalDiary
        modalIsOpen={showModalDiary}
        diary={diary}
        setModalIsOpen={setShowModalDiary}
      />
      <ModalAgenda
        agenda={agendaAnbima}
        setShowModalAgenda={setShowModalAgenda}
        showModalAgenda={showModalAgenda}
      />
    </>
  );
};

export default ShowAttributes;
