import React, {useState} from "react";
import api from "../../../services/api";

import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

import './styles.css';

const TableCashFlow = ({agendaId}) => {
    const [cashFlow, setCashFlow] = useState('')
    const [date, setDate] = useState(new Date().setDate(new Date().getDate() - 1))

    const generateCashFlow = async (agendaId) => {
      var formattedDate = moment(date).format("YYYY-MM-DD")
      var doc = document.getElementById('cashFlowTable')
  
      doc.classList.remove = 'toggle-cashflow-table hidden'
      doc.className = 'toggle-cashflow-table active'

      try {
        const { data }  = await api.post('calculeCashFlow', {formattedDate, agendaId});
              
        setCashFlow(JSON.parse(data.result));
      } catch (e) {
        console.log(e);
      }
    }

    const renderCashFlow = () => {
      return cashFlow && cashFlow.cashFlowEvents && cashFlow.cashFlowEvents.map((obj, index) => {
        return (
          <tr key={index}>
            <td>{moment(obj.date).format("DD/MM/YYYY")}</td>  
            <td>{obj.eventType}</td>
            <td>{obj.term}</td>  
            <td>{obj.rate}</td>
            <td>{obj.futureValue}</td>  
            <td>{obj.presentValue}</td>
          </tr>
          )
        })
    }

    return (   
    <> 
    <div className="col-12">
        <div className="text-center"> 
          <label>
              Data para gerar o fluxo de caixa:
          </label>
        </div>
        <div className="text-center">
          <DatePicker
              dateFormat="dd/MM/yyyy"
              locale={ptBR}
              selected={date}
              onChange={(date) => setDate(date)}
            />
        </div>
        <div className="text-center">
          <button 
            className='btn btn-primary'
            id='showTable'
            style={{marginBottom: '15px', marginTop: '15px'}}
            onClick={() => generateCashFlow(agendaId)}>
              Gerar Fluxo de Caixa
          </button>
        </div> 
    </div>
    <div id='cashFlowTable' className="toggle-cashflow-table hidden">
      <table className="table table-striped table-bordered mydatatable text-center " style={{margin: '15px'}}>
        <thead>
          <tr>
            <th>Data</th>
            <th>Tipo</th>
            <th>Dias úteis</th>
            <th>Taxa</th>
            <th>Valor Futuro</th>
            <th>Valor Presente</th>
          </tr>
        </thead>
        {cashFlow ?
          <tbody>
            {renderCashFlow()}
          </tbody>
          : null
        }
      </table> 
    </div>
    </>
    );
};

export default TableCashFlow;