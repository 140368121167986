import React, { useEffect, useState } from "react";

import api from "../../services/api";

import { useSelector } from "react-redux";

import swal from "sweetalert";

const EditUser = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const { user_id: id, user_name, user_email } = useSelector(
    (state) => state.userInfo
  );
  useEffect(() => {
    setName(user_name);
    setEmail(user_email);
  }, [user_email, user_name]);

  const editingUser = async () => {
    try {
      await api.patch(`/users/${id}`, {
        id,
        name,
        email,
      });
      await swal({
        text: "Usuário alterado com sucesso",
        icon: "success",
      });
    } catch (e) {
      await swal({
        text: "Não foi possivel editar as informações",
        icon: "warning",
      });
      console.log(e);
    }
  };
  return (
    <>
      <div className="login-form">
        <center>
          <h2 className="text-center">Editando usuário</h2>
        </center>
        <center>
          <p className="text-center">Editando usuário {name}</p>
        </center>

        <div className="form-group">
          <input
            type="text"
            name="name"
            placeholder="Nome"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="email"
            placeholder="Email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <button
            onClick={() => editingUser()}
            className="btn btn-primary btn-block"
          >
            Criar usuário
          </button>
        </div>
      </div>
    </>
  );
};

export default EditUser;
