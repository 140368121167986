import React from "react";
import DataTable from 'react-data-table-component';
import AnimatedNumber from 'react-animated-number';
import Card from 'react-bootstrap/Card'

import { formatDecimals } from "../../common/formatDecimals"
import { checkIfIsNaNorNone } from "../../common/checkIfIsNaNorNone"
import { customStyles } from "../../common/CallsTableModalColsStyles";

import "./style.css";


const CallsTableValidationModal = (props) => {
    const {selectedCalls, callsResult} = props
    const maturityDate = selectedCalls[0]?.maturity_date

    const callsResultFields = [
        'minBid', 'maxAsk', 'callsMid', 'callsSpread', 
    ]

    const callsTitleFields = [
        'min_limit', 'max_limit', 'min_spread_limit', 'max_spread_limit'
    ]

    const columns = [
        {
            name: 'Fonte',
            selector: call => call["source"],
        },
        {
            name: 'Compra Orig.',
            selector: call => checkIfIsNaNorNone(call["original_bid"]),
        },
        {
            name: 'Venda Orig.',
            selector: call => checkIfIsNaNorNone(call["original_ask"]),
        },
        {
            name: 'Compra',
            selector: call => checkIfIsNaNorNone(call["bid"]),
            conditionalCellStyles: [
                {
                    when: call => Number(call["bid"]) === Number(callsResult[maturityDate]["minBid"]),
                    style: {
                        color: 'rgba(224, 13, 94, 0.836)',
                        fontWeight: 'bold',
                    }
            }
        ],
        },
        {
            name: 'Venda',
            selector: call => checkIfIsNaNorNone(call["ask"]),
            conditionalCellStyles: [
                {
                    when: call => Number(call["ask"]) === Number(callsResult[maturityDate]["maxAsk"]),
                    style: {
                        color: 'rgb(3, 109, 180)',
                        fontWeight: 'bold',
                    }
            }
        ],
        },
        {
            name: 'Meio',
            selector: call => checkIfIsNaNorNone(call["mid"]),
            conditionalCellStyles: [
                {
                    when: call => Number(call["mid"]) < Number(call["min_limit"]) ||
                                  Number(call["mid"]) > Number(call["max_limit"]),
                    style: {
                        color: 'white',
                        backgroundColor: "red",
                        fontWeight: 'bold',
                    }
            },
        ],
        },
        {
            name: 'Spread',
            selector: call => checkIfIsNaNorNone(callsResult?.[call["maturity_date"]]?.['callsSpread']),
        },
        {
            name: 'Ultima',
            selector: call => checkIfIsNaNorNone(call["last"]),
        },
        {
            name: 'Hora',
            selector: call => call["time"],
            width: '10'
        }
    ]

    const translateFields = (field) => {
        switch (field) {
            case 'minBid':
                return 'Compra Mínima'
            case 'maxAsk':
                return 'Venda Máxima'
            case 'callsMid':
                return 'Mid'
            case 'callsSpread':
                return 'Spread'
            case 'spread':
                return 'Spread' 
            case 'min_limit':    
                return 'Limite mínimo' 
            case 'max_limit':    
                return 'Limite Máximo' 
            case 'min_spread_limit':    
                return 'Limite Min. Spread' 
            case 'max_spread_limit':    
                return 'Limite Max. Spread'              
            default:
                return field
        }
    }

    const renderIndicator = (field) => {
        switch (field) {
            case 'minBid':
            return(
                <>
                    <span 
                        className='indicator_box indicator_bid'>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </span> &nbsp;
                </>
            )
            case 'maxAsk':
            return(
                <>
                    <span 
                        className='indicator_box indicator_ask'>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </span> &nbsp;
                </>
            )
            default:
                return <></>
        }
    }

    const renderOutOfLimitMidValues = (field, calculatedMid, minLimit, maxLimit) => {
        if (field === "Mid" &&  (calculatedMid < minLimit || calculatedMid > maxLimit)) {
            return "fw-bold text-danger"
        } else {
            return "fw-bold"
        }
    }

    return (
        <Card>
            <Card.Header as="h6" className="text-center">
                <div id="calls-modal" className="col-md-12 d-flex justify-content-center">
                {callsTitleFields.map((field, index) => {
                    return (
                    <span key={index} className="me-4">
                        <span>{translateFields(field)}: &nbsp;</span> 
                        <AnimatedNumber 
                            className="fw-bold"
                            value={parseFloat(selectedCalls[0]?.[field])}
                            formatValue={n => formatDecimals(n)}
                            duration={1000}
                            style={{
                                transition: '0.8s ease-out',
                                fontSize: 15,
                                transitionProperty:
                                    'background-color, color, opacity'
                            }}
                        />
                    </span>
                    )
                })}
                </div>
            </Card.Header>
            <Card.Header>
            <div className="col-md-12 text-center">
                {callsResultFields.map((field, index) => {
                    return (
                    <span key={index} className="me-4">
                        {renderIndicator(field)}
                        <span>{translateFields(field)}: &nbsp;</span> 
                        { isFinite(callsResult?.[maturityDate]?.[field]) ? <AnimatedNumber 
                            className={renderOutOfLimitMidValues(
                                translateFields(field), 
                                callsResult?.[maturityDate]?.["callsMid"], 
                                selectedCalls[0]?.["min_limit"],
                                selectedCalls[0]?.["max_limit"]
                            )}
                            value={callsResult?.[maturityDate]?.[field]}
                            formatValue={n => formatDecimals(n)}
                            duration={1000}
                            stepPrecision= {4}
                            style={{
                                transition: '0.8s ease-out',
                                fontSize: 15,
                                transitionProperty:
                                    'background-color, color, opacity'
                            }}
                        /> : '-' }
                    </span>
                    )
                })}
            </div> 
            </Card.Header>
            <Card.Body id="DFA_table">
                <DataTable   
                    title={" "}
                    noDataComponent={"Nenhuma call selecionada para este vencimento"}
                    customStyles={customStyles}
                    columns={columns}
                    data={selectedCalls}
                />
            </Card.Body>
        </Card>
        );
};

export default CallsTableValidationModal;
