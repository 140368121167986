import React, {useState} from "react";
import Modal from "react-modal";
import api from "../../../../services/api";
import { AiOutlineCloseCircle } from "react-icons/ai";
import TableCashFlow from "../../../../components/Modal/ModalCashFlow";

Modal.setAppElement("#root");

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      width: "80%",
      height: "80%",
      right: "50%",
      bottom: "auto",
      marginRight: "0",
      transform: "translate(-50%, -50%)",
    },
  };

const ModalManualApprovals = ({userToken, modalIsOpen, setModalIsOpen, approvalLink, rejectionLink, changeProposal, agendaId, proposalSummary }) => {
  const [showRejection, setShowRejection] = useState(false)
  const [rejectionInput, setRejectionInput] = useState('')

  const renderChangeProposal = (proposals) => {
    return proposals && proposals.map((obj, index) => {
    return (
      index > 0 ? (
      <tr key={index}>
          <td>
              {obj.EVENT_DATE}
          </td>
          <td>
              {obj.ACTUAL_EVENT_DATE}
          </td>
          <td>
              {obj.EVENT}
          </td>
          <td>
              {obj.PERCENTUAL_RATE}
          </td>
          <td>
              {obj.STATUS}
          </td>           
          <td>
              {obj.AMOUNT}
          </td>      
      </tr> ) : null 
    )
    })
  }
  const sendRequest = async (urlRequest, userToken) =>{
    var url = urlRequest.slice(0, urlRequest.indexOf("?taskToken="));
    var taskToken = urlRequest.slice(urlRequest.indexOf("?taskToken=") + 11);

    var params = {
      url: url, 
      taskToken: taskToken, 
      userToken: userToken, 
      rejectionReason: rejectionInput
    }

    try {
      await api.post('sendManualApprovals', params);
    } catch (e) {
      console.log(e);
    }          
    
    setShowRejection(false)
    setRejectionInput('')
    setModalIsOpen(false)
  }

  const proposals = changeProposal.map(obj => ({ 
    "EVENT_DATE": obj[0], 
    "ACTUAL_EVENT_DATE": obj[1], 
    "EVENT": obj[2], 
    "PERCENTUAL_RATE": obj[3], 
    "STATUS": obj[4], 
    "AMOUNT": obj[5] 
  }))

  const renderRejectionModal = (rejectionLink, userToken) => { 
    return (
    showRejection ? 
      <div className="form-group">
        <label htmlFor="rejectionTextArea">Motivo da Rejeição (Obrigatório)</label>
        <textarea className="form-control" 
          id="rejectionTextArea" 
          value={rejectionInput} 
          onChange={(e) => setRejectionInput(e.target.value)} 
          rows="3">
        </textarea>
        <button 
          className="btn btn-primary" 
          style={{margin: '10px'}} 
          onClick={() => setShowRejection(!showRejection)}> 
          Cancelar
        </button>
        {rejectionInput ? 
        <button 
          className="btn btn-danger" 
          style={{margin: '10px'}} 
          onClick={() => sendRequest(rejectionLink, userToken)}> 
          Confirmar Rejeição
        </button> 
        :
        <button 
          className="btn btn-danger" 
          style={{margin: '10px'}} 
          disabled title="Preencha o campo de motivo de rejeição."> 
          Confirmar Rejeição
        </button>
        }
        
      </div>
     : null 
    )
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(true)}
        style={customStyles}
      >
        <div className="button-close-modal">
            <button onClick={() => setModalIsOpen(false)}>
                <AiOutlineCloseCircle
                  size={20}
                  className="icon" />
            </button>
        </div> 
        <div className="text-center" style={{margin: '15px'}} >
          <h5>Descrição da proposta de mundança :</h5>
          <textarea
            className="form-control" 
            readOnly
            id="proposalSummary"
            value={proposalSummary}
            rows="2"
            cols="2"> 
          </textarea>
        </div>     
          <table className="table table-striped table-bordered mydatatable text-center" style={{marginTop: '15px'}}>
            <thead>
                <tr>
                    <th>EVENT_DATE</th>
                    <th>ACTUAL_EVENT_DATE</th>
                    <th>EVENT</th>
                    <th>PERCENTURAL_RATE</th>
                    <th>STATUS</th>
                    <th>AMOUNT</th>
                </tr>
            </thead>
            <tbody>
                {renderChangeProposal(proposals)}
            </tbody>
          </table> 
          <TableCashFlow
            agendaId={agendaId}/>   
          <div className='text-center'>
            { !showRejection ?
              <>
                <button 
                  className="btn btn-success" 
                  style={{margin: '10px'}} 
                  onClick={() => sendRequest(approvalLink, userToken)}> 
                  Aprovar
                </button>
                <button 
                  className="btn btn-danger" 
                  style={{margin: '10px'}} 
                  onClick={() => setShowRejection(!showRejection)}> 
                  Rejeitar
                </button>
              </>
            : null}
            {renderRejectionModal(rejectionLink, userToken)}     
        </div>
      </Modal>
    </>
  );
};

export default ModalManualApprovals;