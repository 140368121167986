import React, { useState } from "react";

import "./style.css";
import DebenturesToRegisterList from "./components/DebenturesToRegisterList";
import SchedulesToRegisterList from "./components/SchedulesToRegisterList";
import CriCraToRegisterList from "./components/CriCraToRegisterList";
import CriCraSchedulesToRegisterList from "./components/CriCraSchedulesToRegisterList";

const DebenturesRegister = () => {
  const [changeComponent, setChangeComponent] = useState({
    registerDebentures: true,
    registerCriCra: false,
    registerSchedules: false,
    registerCriCraSchedules: false,
  });

  const handleComponent = (ComponentName) => {
    switch (ComponentName) {
      case "registerDebentures":
        setChangeComponent((prevState) => ({
          ...prevState,
          registerDebentures: true,
          registerCriCra: false,
          registerSchedules: false,
          registerCriCraSchedules: false,
        }));
        break;
      case "registerCriCra":
        setChangeComponent((prevState) => ({
          ...prevState,
          registerCriCra: true,
          registerDebentures: false,
          registerSchedules: false,
          registerCriCraSchedules: false,
        }));
        break;
      case "registerSchedules":
        setChangeComponent((prevState) => ({
          ...prevState,
          registerDebentures: false,
          registerCriCra: false,
          registerSchedules: true,
          registerCriCraSchedules: false,
        }));
        break;
      case "registerCriCraSchedules":
        setChangeComponent((prevState) => ({
          ...prevState,
          registerDebentures: false,
          registerCriCra: false,
          registerSchedules: false,
          registerCriCraSchedules: true,
        }));
        break;
      default:
        console.log(`Component error`);
        break;
    }
  };

  const renderComponent = () => {
    let {
      registerDebentures,
      registerCriCra,
      registerSchedules,
      registerCriCraSchedules,
    } = changeComponent;

    if (registerDebentures) {
      return <DebenturesToRegisterList />;
    } else if (registerCriCra) {
      return <CriCraToRegisterList />;
    } else if (registerSchedules) {
      return <SchedulesToRegisterList />;
    } else if (registerCriCraSchedules) {
      return <CriCraSchedulesToRegisterList />;
    } else {
      return null;
    }
  };

  return (
    <div>
      <div
        className="col-md-12 d-flex justify-content-center"
        style={{ marginTop: "20px" }}
      >
        <div className="btn-group" role="group" aria-label="...">
          <button
            type="button"
            className={
              changeComponent.registerDebentures
                ? "btn btn-primary active"
                : "btn btn-primary"
            }
            name="registerDebentures"
            onClick={(e) => handleComponent(e.target.name)}
          >
            Cadastro de Debêntures
          </button>
          <button
            type="button"
            className={
              changeComponent.registerCriCra
                ? "btn btn-primary active"
                : "btn btn-primary"
            }
            name="registerCriCra"
            onClick={(e) => handleComponent(e.target.name)}
          >
            Cadastro de CRI/CRA
          </button>
          <button
            type="button"
            className={
              changeComponent.registerSchedules
                ? "btn btn-primary active"
                : "btn btn-primary"
            }
            name="registerSchedules"
            onClick={(e) => handleComponent(e.target.name)}
          >
            Cadastro de Agenda
          </button>
          <button
            type="button"
            className={
              changeComponent.registerCriCraSchedules
                ? "btn btn-primary active"
                : "btn btn-primary"
            }
            name="registerCriCraSchedules"
            onClick={(e) => handleComponent(e.target.name)}
          >
            Cadastro de Agenda CRI/CRA
          </button>
        </div>
      </div>
      <hr />
      <>{renderComponent()}</>
    </div>
  );
};

export default DebenturesRegister;
