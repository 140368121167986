import React, {useState, useReducer} from "react";

import { faEdit, faSave, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import DebenturesInfoEditInput from "../DebenturesInfoEditInput";
import DebenturesModalSaveConfirm from "../DebenturesModalSaveConfirm";

import "./style.css";


const ListExpandedComponent = ({DebentureInfoRow, $isCustomDebentureFlag, reloadDataTable}) => {
    const [editMode, setEditMode] = useState($isCustomDebentureFlag ? true : false)
    const [modalShow, setModalShow] = useState(false)
    
    const [input, setInput] = useReducer( 
        (state, newState) => ({...state, ...newState}), 
            DebentureInfoRow
        )
    const [changedAttributes, setChangedAttributes] = useReducer( 
        (state, newState) => ({...state, ...newState}), 
            []
        )

    const handleEditInput = (id, rowToEdit, oldValue) => {
        var datesInputList = ['CVM Register Date', 'Amortization - Grace', 'Issue Date', 
        'Maturity Date' , 'Exit Date' , 'Yield Start Date', 
        'Interest New Criteria- Grace', 'Distribution Start Date', 
        'Current Nominal Value Reference Date']

        var floatInputs = ['Index Multiplier', 'Interest New Criteria- Rate', 'Interest New Criteria- Term', 'Nominal Value',
        'Current Nominal Value', 'Amortization - Rate', 'Amortization - Every', 'Quantity Issued', 'Quantity in Market',
        'Quantity in Treasury', 'Quantity Redeemed', 'Quantity Canceled', 'SND Converted Quantity', 'SND Swapped Quantity', 'Not SND Swapped Quantity',
        ]

        let copyDebentureInfo = {...input}
        
        if (id === "is_multiplicative_amortization" || id === "is_callable" || id === "has_price" || id === "were_redeemed" ){
            if (rowToEdit === "true"){
                copyDebentureInfo = {...copyDebentureInfo, [id]: true}
                setChangedAttributes({...changedAttributes, [id]: {new: rowToEdit, old: oldValue}})
            } else {
                copyDebentureInfo = {...copyDebentureInfo, [id]: false}
                setChangedAttributes({...changedAttributes, [id]: {new: rowToEdit, old: oldValue}})
            } 
        } else if (datesInputList.includes(id)){
            copyDebentureInfo = {...copyDebentureInfo,[id]: [{ version: 1, value: moment(rowToEdit, "DD/MM/YYYY").format('YYYY-MM-DD[T00:00:00.000Z]').toString()}]};
            setChangedAttributes({...changedAttributes, [id]: {new: moment(rowToEdit, "DD/MM/YYYY").format('YYYY-MM-DD[T00:00:00.000Z]'), old: oldValue}})
        } else if (floatInputs.includes(id)){
            copyDebentureInfo = {...copyDebentureInfo,[id]: [{ version: 1, value: parseFloat(rowToEdit)}]};
            setChangedAttributes({...changedAttributes, [id]: {new: rowToEdit, old: oldValue}})
        } else {
            copyDebentureInfo = {...copyDebentureInfo,[id]: [{ version: 1, value: rowToEdit }]};
            setChangedAttributes({...changedAttributes, [id]: {new: rowToEdit, old: oldValue}})
        }

        setInput(copyDebentureInfo)
    }

    const renderRegisterDebentureConfirmation = () => {
        if (editMode){
            setModalShow(true)
        }
    }

    const first_39_info = Object.keys(DebentureInfoRow).slice(0, 39).reduce((result, key) => {
        result[key] = DebentureInfoRow[key];
        return result;
    }, {});

    const last_39_info = Object.keys(DebentureInfoRow).slice(39).reduce((result, key) => {
        result[key] = DebentureInfoRow[key];

        return result;
    }, {});

    return (
        <>
        <div className="expandedDebentureRow_Container">
            <div className="card col-12">
                <div className="card-header deb_header">
                    <div className="row deb_buttons_menu">
                    {!editMode ?
                        <div className="d-grid gap-2 text-center col-2 mx-auto">
                            <button type="button" 
                                className="btn btn-primary" 
                                onClick={() => setEditMode(true)}>
                                <FontAwesomeIcon 
                                icon={faEdit} 
                                size="1x" 
                                className="me-1"/>
                                    Editar Atributos
                            </button>
                        </div>
                        :
                        <div className="d-grid gap-2 text-center d-md-block mx-auto">
                            <button type="button" 
                                className="btn btn-success w-25 me-2" 
                                onClick={() => renderRegisterDebentureConfirmation()}>
                                <FontAwesomeIcon 
                                icon={faSave} 
                                size="1x" 
                                className="me-1"/>
                                    Salvar
                            </button>

                            <button type="button" className="btn btn-danger ms-2 w-25"
                            onClick={() => setEditMode(false)}>
                            <FontAwesomeIcon 
                            icon={faAngleDoubleLeft} 
                            size="1x" 
                            className="me-1"/>
                                Cancelar
                            </button>
                        </div>
                    }
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-6">
                            {Object.keys(first_39_info).map(function(key, index) {
                                return(
                                    first_39_info[key][0] ?
                                    <span key={index}>
                                        {key}:
                                        <DebenturesInfoEditInput
                                            id={key}  
                                            rowToEdit={first_39_info[key][0].value}
                                            editMode={editMode}
                                            handleEditInput={handleEditInput}
                                            />
                                    </span>
                                : null
                                )
                            })}
                        </div>
                        <div className="col-6">
                            {Object.keys(last_39_info).map(function(key, index) {
                                return(
                                    last_39_info[key][0] ?
                                    <span key={index}>
                                        {key}:
                                        <DebenturesInfoEditInput
                                            id={key} 
                                            rowToEdit={last_39_info[key][0].value}
                                            editMode={editMode}
                                            handleEditInput={handleEditInput}
                                            />
                                    </span>
                                : null
                                )
                            })}
                            <span>
                                Multiplicative Amortization ? 
                                <DebenturesInfoEditInput
                                    id="is_multiplicative_amortization"
                                    rowToEdit={String(last_39_info.is_multiplicative_amortization)}
                                    editMode={editMode}
                                    handleEditInput={handleEditInput}/>
                            
                            </span>
                            <span>
                                Has Price ? 
                                <DebenturesInfoEditInput
                                    id="has_price"
                                    rowToEdit={String(last_39_info.has_price)}
                                    editMode={editMode}
                                    handleEditInput={handleEditInput}/>
                            
                            </span>
                            <span>
                                Callable ? 
                                <DebenturesInfoEditInput
                                    id="is_callable"
                                    rowToEdit={String(last_39_info.is_callable)}
                                    editMode={editMode}
                                    handleEditInput={handleEditInput}/>
                            
                            </span>
                            <span>
                                Were Redeemed ? 
                                <DebenturesInfoEditInput
                                    id="were_redeemed"
                                    rowToEdit={String(last_39_info.were_redeemed)}
                                    editMode={editMode}
                                    handleEditInput={handleEditInput}/>
                            
                            </span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <DebenturesModalSaveConfirm 
                show={modalShow}
                onHide={() => setModalShow(false)}
                changedAttributes={changedAttributes}
                debentureToRegister={input}
                isCustomDebentureFlag={$isCustomDebentureFlag ? 1 : 0}
                reloadDataTable={reloadDataTable}
            />
        </>
    );
};

export default ListExpandedComponent;