import React, { useCallback, useRef, useEffect } from "react";
import Modal from "react-modal";

import Input from "../../../../../components/Input/InputMask";
import notify from "../../../../../common/toast";

import { AiOutlineCloseCircle } from "react-icons/ai";

import { eventTypes } from "../../../../../common/eventTypes";
import { statusTypes } from "../../../../../common/statusTypes";

import "./style.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const EditAgendaEventFiAnalitycs = ({
  showModal,
  setShowModal,
  selectedEvent,
  setSelectedEvent,
  agendaValue,
  setAgendaValue,
  setValuesToAgenda,
}) => {
  const selectedEventRef = useRef(null);

  useEffect(() => {
    if (!!selectedEvent.length) {
      selectedEventRef.current = selectedEvent;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const edit = useCallback(() => {
    if (!selectedEvent[2]) {
      return notify("O campo evento não pode ficar vazio", "warning");
    }
    if (!selectedEvent[3]) {
      return notify("O campo taxa percentual não pode ficar vazio", "warning");
    }

    let copyAgendaValue = JSON.parse(JSON.stringify(agendaValue));
    const eventIndex = agendaValue.findIndex(
      value =>
        JSON.stringify(value.slice(0, 9)) ===
        JSON.stringify(selectedEventRef.current)
    );
    if (eventIndex === -1) return;
    copyAgendaValue[eventIndex] = [...selectedEvent, "wasEdited"];

    setAgendaValue(copyAgendaValue);
    setValuesToAgenda(copyAgendaValue);
    setShowModal(false);
    notify("OK.", "success");
  }, [
    agendaValue,
    setAgendaValue,
    selectedEvent,
    setValuesToAgenda,
    setShowModal,
  ]);

  const handleChange = useCallback(
    (eventValue, index) => {
      let value = eventValue;
      if (index === 6) {
        value = eventValue === "true" ? true : false;
      }
      const copySelectedAgenda = JSON.parse(JSON.stringify(selectedEvent));
      copySelectedAgenda[index] = value;
      setSelectedEvent(copySelectedAgenda);
    },
    [setSelectedEvent, selectedEvent]
  );

  return (
    <>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(true)}
        style={customStyles}
      >
        <div className="button-close-modal">
          <button onClick={() => setShowModal(false)}>
            <AiOutlineCloseCircle
              color="#7c00d3"
              size="38px"
              background="transparent"
            />
          </button>
        </div>
        <div className="container__form">
          <div className="box__form">
            <div className="group__form">
              <label className="form__label">Data do evento</label>
              <Input
                value={selectedEvent[0]}
                onChange={e => handleChange(e.target.value, 0)}
                className="field-lg"
              />
            </div>
            <div className="group__form">
              <label className="form__label">Data de liquidação</label>

              <Input
                value={selectedEvent[1]}
                onChange={e => handleChange(e.target.value, 1)}
                className="field-lg"
              />
            </div>
            <div className="group__form">
              <label className="form__label">Evento</label>
              <select
                value={selectedEvent[2]}
                className="form__select"
                onChange={e => handleChange(e.target.value, 2)}
              >
                <option value={null}>Selecione um evento</option>
                {eventTypes.map(event => (
                  <option key={event}>{event}</option>
                ))}
              </select>
            </div>
            <div className="group__form">
              <label className="form__label">Taxa / R$</label>
              <input
                value={selectedEvent[3]}
                onChange={e => handleChange(e.target.value, 3)}
                className="field-lg"
              />
            </div>
            <div className="group__form">
              <label className="form__label">Status</label>
              <select
                value={selectedEvent[4]}
                className="form__select"
                onChange={e => handleChange(e.target.value, 4)}
              >
                <option value="">Selecione uma opção</option>
                {statusTypes.map(status => (
                  <option key={status}>{status}</option>
                ))}
              </select>
            </div>
            <div className="group__form">
              <label className="form__label">Financeiro (R$)</label>
              <input
                value={selectedEvent[5]}
                onChange={e => handleChange(e.target.value, 5)}
                className="field-lg"
              />
            </div>
            <div className="group__form">
              <label className="form__label">Uso Interno</label>
              <select
                value={selectedEvent[6]}
                onChange={e => handleChange(e.target.value, 6)}
              >
                <option value="false">NÃO</option>
                <option value="true">SIM</option>
              </select>
            </div>
            <div className="group__form">
              <label className="form__label">
                Data da referência do cálculo
              </label>
              <Input
                value={selectedEvent[7]}
                onChange={e => handleChange(e.target.value, 7)}
                className="field-lg"
              />
            </div>
            <div className="group__form">
              <label className="form__label">
                Percentual do principal incorporado
              </label>
              <input
                value={selectedEvent[8]}
                onChange={e => handleChange(e.target.value, 8)}
                className="field-lg"
              />
            </div>
            <button
              onClick={edit}
              className="btn__modal--save--change btn--hover"
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(EditAgendaEventFiAnalitycs);
