import React from "react";
import Modal from "react-modal";

import { AiOutlineCloseCircle } from "react-icons/ai";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const ModalDiary = ({ modalIsOpen, setModalIsOpen, diary }) => {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(true)}
        style={customStyles}
      >
        <div className="content-modal">
          <div className="table-diary">
            <div className="button-close-modal">
              <button onClick={() => setModalIsOpen(false)}>
                <AiOutlineCloseCircle
                  color="#7c00d3"
                  size="38px"
                  background="transparent"
                />
              </button>
            </div>
            {diary ? (
              <table className="table table-striped table-bordered mydatatable">
                <thead>
                  <tr>
                    <th className="text-center">Data</th>
                    <th className="text-center">Conteúdo</th>
                  </tr>
                </thead>
                <tbody>
                  {diary.map((diary, index) => (
                    <tr key={index}>
                      <td>{diary.date}</td>
                      <td>{diary.content}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="nothing">
                <h3>Nada a ser exibido.</h3>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(ModalDiary);
