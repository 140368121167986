import React, { useState, useEffect } from "react";
import { getToken } from "../../auth/credentials";

import api from "../../services/api";

import "./style.css";
import ModalManualApprovals from "./Modal/ModalManualApprovals";


const ManualApprovals = () => {
    const userToken = getToken();

    const [termsSheet, setTermsSheet] = useState([]);
    const [agendaChangeProposal, setAgendaChangeProposal] = useState([])
    const [approvalLink, setApprovalLink] = useState('')
    const [rejectionLink, setRejectionLink] = useState('')
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const documents = await api.get("/getManualApprovals");

            setTermsSheet(documents.data)       
            return documents
          } catch (e) {
            console.log(e);
          }
        };
        fetchData()
    }, []);

    const renderTermsSheet = () => {
        return termsSheet && termsSheet.map((obj, index) => {
            return (
                <tr key={index}>
                    <td>
                        {obj.agenda_id}
                    </td>
                    <td>
                        {obj.change_proposal_author}
                    </td>
                    <td>
                        {obj.change_proposal_date}
                    </td>
                    <td>
                        {obj.checked ? 'Sim' : 'Não' }
                    </td>   
                    <td>
                        <button className='btn btn-primary' onClick={() => getChangeProposal(obj.agenda_change_proposal, obj.approval_link, obj.rejection_link) }>
                            Verificar
                        </button>
                    </td>              
                </tr>          
            )
        })
      }

    const getChangeProposal = (changeProposal, approvalLink, rejectionLink) => {
        setAgendaChangeProposal(changeProposal.value)
        setApprovalLink(approvalLink)
        setRejectionLink(rejectionLink)
        setModalIsOpen(true)
    }

  return (
    <>
        <div className="manualApprovals">
            <table className="table table-striped table-bordered mydatatable">
                <thead className='text-center'>
                    <tr>
                    <th>ID Agenda</th>
                    <th>Autor da proposta</th>
                    <th>Data da proposta</th>
                    <th>Checado</th>
                    <th>Mudanças</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {renderTermsSheet()}
                </tbody>
            </table>
        </div>
        
        <ModalManualApprovals
        userToken={userToken}
        changeProposal={agendaChangeProposal}
        approvalLink={approvalLink}
        rejectionLink={rejectionLink}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
    </>
  );
};

export default ManualApprovals;
