import React, { useState, useEffect } from "react";
import api from '../../services/api'
import { getCredentials } from "../../auth/credentials";

import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";

import { ReflexContainer,ReflexSplitter,ReflexElement} from 'react-reflex'
import CallsGroupContainer from "./components/CallsGroupContainer";
import CallsValidation from "./components/CallsValidation";
import SelectedCallsConfiguration from "./components/SelectedCallsConfiguration";
import Menu from "./components/Menu";
import Loading from "../../components/FeedbackLoading";
import Swal from "sweetalert2";


import "./style.css";
import 'react-reflex/styles.css'

const PricingDFA = () => {
    const [showResultsOrMenu, setShowResultsOrMenu] = useState({
        showMenu: true,
    })
    const [calls, setCalls] = useState('')
    const [loadingCalls, setLoadingCalls] = useState({
        firstStepLoading: false,
        secondStepLoading: false,
        thirdStepLoading: false,
        finalStepLoading: false,
    })
    const [selectedCallsConfigurationModal, setSelectedCallsConfigurationModal] = useState(false)
    const [callsCalcResult, setCallsCalcResult] = useState([])
    const [callsToValidate, setCallsToValidate] = useState([])
    const [callsContainerInfo, setCallsContainerInfo] = useState({
        instrumentType: '',
        dateToFilter: '',
        startTimeToFilter: '',
        endTimeToFilter: '',
    })

    const [bestCalls, setBestCalls] = useState([])
    const [maturityDatesToAnalyze, setMaturityDatesToAnalyze] = useState({
        totalMaturityDates: 0,
        analyzedCalls: 0
    }) 

    const [DFAResults, setDFAResults] = useState([])
    const [screenSize, setScreenSize] = useState(1)
    const [splitScreenSize, setSplitScreenSize] = useState({
        splitScreen: false,
        minSize: "450",
        maxSize: "1860"
    })

    useEffect(() => {
        var totalMaturityDates = Object.keys(calls).length
        var analyzedCalls = Object.keys(bestCalls).length
        
        setMaturityDatesToAnalyze({
            totalMaturityDates: totalMaturityDates,
            analyzedCalls: analyzedCalls
        })

        if ((analyzedCalls === totalMaturityDates) && totalMaturityDates > 0) {
            setTimeout(() => {
                get_gov_bond_calls_preview();
            }, 1000)   
        }
    }, [calls, bestCalls]);

    
    const generate_gov_bonds_call_intraday_data = async (instrumentType, dateToFilter, startTime, endTime) => {
        setCalls('')
        setBestCalls([])
        setDFAResults([])
        setScreenSize(1)
        setMaturityDatesToAnalyze({
            totalMaturityDates: 0,
            analyzedCalls: 0
        })

        setSplitScreenSize({
            "splitScreen": false,
            "minSize": "3600",
            "maxSize": "3600"
        })
        
        sessionStorage.clear();

        setLoadingCalls(prevState => ({
            ...prevState,
            firstStepLoading: true,
        }))
        var date = moment(dateToFilter).format("YYYY-MM-DD")
        
        setCallsContainerInfo({
            instrumentType: instrumentType,
            dateToFilter: date,
            startTimeToFilter: startTime,
            endTimeToFilter: endTime
        })
    
        try {
            const documents = await api.post('generate_gov_bonds_call_intraday_data', {instrumentType, date, startTime, endTime});

            if (documents.status === 200){
                var response_calls_json = documents.data.result

                let formattedArray = []

                if (response_calls_json) {
                    response_calls_json.forEach((calls) => {
                        const date = calls.maturity_date
                        if (formattedArray[date]) {
                        formattedArray[date].push(calls);
                        } else {
                        formattedArray[date] = [calls];
                        }
                    })
                }

                setCalls(formattedArray)

                setShowResultsOrMenu(prevState => ({
                    ...prevState,
                    showMenu: false,
                }))
            } else if (documents.data.result.statusCode === 500){
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao carregar calls',
                    text: `Sem dados para a data e horários selecionados.`,
                    showConfirmButton: true,
                  })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Limite de tempo atingido...',
                    text: `Tente novamente mais tarde`,
                    showConfirmButton: true,
                  })
            }
            
            setLoadingCalls(prevState => ({
                ...prevState,
                firstStepLoading: false,
            }))
        } catch (e) {
            setLoadingCalls(prevState => ({
                ...prevState,
                firstStepLoading: false,
            }))

            console.log(e);
        }
    }

    const get_gov_bond_calls_preview = async () => {
        setDFAResults([])
        setLoadingCalls(prevState => ({
            ...prevState,
            secondStepLoading: true,
        }))

        setSplitScreenSize(prevState => ({
            ...prevState,
            "splitScreen": true,
            "minSize": "450",
            "maxSize": "1860"
        }))

        try {
            var date = callsContainerInfo.dateToFilter
            var approved_calls = JSON.stringify(bestCalls)
            const documents = await api.post('get_gov_bond_calls_preview', {date, approved_calls});
            
            if (documents.data.result.statusCode === 200){
                var response_dfa_json = JSON.parse(documents.data.result.body)

                setCallsToValidate(response_dfa_json)
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao carregar os resultados',
                    text: `Tente novamente mais tarde`,
                    showConfirmButton: true,
                })
            }

            setLoadingCalls(prevState => ({
                ...prevState,
                secondStepLoading: false,
            }))
        } catch (e) {
            setLoadingCalls(prevState => ({
                ...prevState,
                secondStepLoading: false,
            }))

            console.log(e);
        }
    }

    const get_gov_bond_calls_after = async () => {
        setLoadingCalls(prevState => ({
            ...prevState,
            thirdStepLoading: true,
        }))

        try {
            var date = callsContainerInfo.dateToFilter
            var approved_calls = JSON.stringify(bestCalls)
            const documents = await api.post('get_gov_bond_calls_after', {date, approved_calls});
            
            if (documents.status === 200){
                var response_dfa_json = documents.data.result

                setDFAResults(response_dfa_json)

                Swal.fire({
                    icon: 'success',
                    title: `DFA realizado com sucesso.`,
                    text: `Verifique a aba "Mostrar Resultados" `,
                    showConfirmButton: true,
                })

                setLoadingCalls(prevState => ({
                    ...prevState,
                    thirdStepLoading: false,
                }))
            }         
        } catch (e) {
            setLoadingCalls(prevState => ({
                ...prevState,
                thirdStepLoading: false,
            }))

            Swal.fire({
                icon: 'error',
                title: 'Erro ao realizar o DFA',
                text: `${e}`,
                showConfirmButton: true,
              })
        }
    }

    const gov_bond_calls_final = async (isPreview) => {
        setLoadingCalls(prevState => ({
            ...prevState,
            finalStepLoading: true,
        }))

        const {user_id, user_name, user_email} = getCredentials()
        const {instrumentType, dateToFilter, startTimeToFilter, endTimeToFilter} =  callsContainerInfo

        var userInfo = {
            id: user_id,
            name: user_name,
            email: user_email,
        }
        var reference_date = dateToFilter
        var reference_start_date = new Date(moment(dateToFilter + ' ' + startTimeToFilter).format('YYYY-MM-DDTHH:mm:ss[.000Z]'));
        var reference_end_date = new Date(moment(dateToFilter + ' ' + endTimeToFilter).format('YYYY-MM-DDTHH:mm:ss[.000Z]'));
        var is_preview = isPreview
        var selected_calls = bestCalls
        var final_result = JSON.stringify(DFAResults)

        try {
            const documents = await api.post('gov_bond_calls_final', {
                userInfo, instrumentType, reference_date, reference_start_date, 
                reference_end_date, is_preview, selected_calls, final_result
            });

            if (documents.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: `Informações gravadas no banco de dados com sucesso`,
                    showConfirmButton: true,
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao gravar as informações',
                    showConfirmButton: true,
                  })
            }

            setLoadingCalls(prevState => ({
                ...prevState,
                finalStepLoading: false,
            }))
        } catch (e) {
            setLoadingCalls(prevState => ({
                ...prevState,
                finalStepLoading: false,
            }))

            Swal.fire({
                icon: 'error',
                title: 'Erro ao realizar o DFA',
                text: `${e}`,
                showConfirmButton: true,
              })
        }
    }

    const handleSelectedCalls = (maturityDate, selectedCallsByUser, callsResult) => {
        setCallsCalcResult({...callsCalcResult, [maturityDate]: callsResult})
        setBestCalls({...bestCalls, [maturityDate]: selectedCallsByUser})
    }

    const getSplitSizeValue = () => {
        var pane = document.querySelector('.left-panel-controller')

        setScreenSize(pane.style.flex)
    }

    const renderCallsInfoContainer = () => {
        return(
            <div className="container-sm text-center mt-4">
                <h4> 
                Grupo de calls do dia 
                <span className="dfa-values-color"> {moment.utc(callsContainerInfo.dateToFilter).format('DD/MM/YYYY')} </span>
                das  
                <span className="dfa-values-color"> {callsContainerInfo.startTimeToFilter} </span> 
                às  
                <span className="dfa-values-color"> {callsContainerInfo.endTimeToFilter} </span>
                </h4>
            </div>
        )
    }

    return ( 
    <ReflexContainer orientation="vertical">
        <ReflexElement  className="left-pane left-panel-controller" 
            minSize={splitScreenSize.splitScreen ? splitScreenSize.minSize : "3200"} 
            maxSize={splitScreenSize.splitScreen ? splitScreenSize.maxSize : "3200"}
            onStopResize={() => getSplitSizeValue()}>
            <SelectedCallsConfiguration
                calls={calls}
                show={selectedCallsConfigurationModal}
                onHide={() => setSelectedCallsConfigurationModal(false)}
            />         
            <div className="col-md-12 d-flex justify-content-center">
                <div className="row pricingDFA_menu">
                    <div className="d-grid gap-2 d-md-block card header mb-3 text-center">
                        <button 
                            className="btn dfa-button mx-5 my-1" 
                            type="button"
                            onClick={() => setShowResultsOrMenu(prevState => ({
                                ...prevState,
                                showMenu: !prevState.showMenu,
                            }))}> {showResultsOrMenu.showMenu ? "Fechar Menu" : "Abrir Menu"}
                        </button>
                    </div>
                    { showResultsOrMenu.showMenu && <Menu 
                        generate_gov_bonds_call_intraday_data={generate_gov_bonds_call_intraday_data}
                    /> }
                </div>
            </div>
            { loadingCalls.firstStepLoading ? <Loading/> : 
            calls &&
            <div className="container pricingDFA_container justify-content-center">
                <div className="pricingDFA_calls_group">
                    <nav id="selected_calls_options" className="navbar navbar-light bg-light">
                        <div className="container">
                            <button 
                                className="btn btn-warning"
                                onClick={() => setSelectedCallsConfigurationModal(true)}>
                                Configurações
                            </button>
                        </div>
                    </nav>
                    {renderCallsInfoContainer()}
                    {Object.keys(calls).sort((a,b) =>  new Date(a) - new Date(b))
                    .map(function(date, index) {
                        return(
                            <div key={index} className="container-sm">
                                <CallsGroupContainer
                                    calls={calls[date]}
                                    maturityDate={date}
                                    selectedCalls={handleSelectedCalls}
                                    maturityDatesToAnalyze={maturityDatesToAnalyze}
                                    screenSize={screenSize}
                                />
                            </div>
                        )})}
                </div>
            </div>
            }
        </ReflexElement>
        { splitScreenSize.splitScreen ?  <ReflexSplitter/> : null}
        <ReflexElement className="right-pane">
            <div className="pricingDFA_calls_group">
            {loadingCalls.secondStepLoading ? <Loading/> :
                <CallsValidation
                    date={callsContainerInfo.dateToFilter}
                    callsToValidate={callsToValidate}
                    selectedCalls={bestCalls}
                    callsResult={callsCalcResult}
                    get_gov_bond_calls_after={get_gov_bond_calls_after}
                    DFAResults={DFAResults}
                    isLoadingDFA={loadingCalls.thirdStepLoading}
                    isLoadingSave={loadingCalls.finalStepLoading}
                    saveOnDatabase={gov_bond_calls_final}
                />
            }
            </div>
        </ReflexElement>
    </ReflexContainer> 
    );
};

export default PricingDFA;