import React, { useEffect, useState } from "react"
import axios from "axios"
import { getToken } from "../../auth/credentials"
import DataTable from "react-data-table-component"
import ExpandendComponent from "../CallQA/ExpandedComponent"
import Loading from "../../components/Loading"
import { stringify } from "querystring"
import "./style.css"
import api from "../../services/api"
import DeleteIcon from "@mui/icons-material/Delete"
import IconButton from "@mui/material/IconButton"
import moment from "moment"
import Swal from "sweetalert2"
import notify from "../../common/toast"
import DatePicker from "react-datepicker"
import ptBR from "date-fns/locale/pt-BR"
import { useContext } from "react"
import TableDataContext from "./TableDataContext"

const CallQA = () => {
  //let data = moment.utc(new Date()).format("DD/MM/YYYY")
  let data = new Date().setDate(new Date().getDate())
  const [callToGet, setCallToGet] = useState({
    instrumentType: "NTN-B",
    source: "BGC",
    period: "morning",
    date: data,
  })
  const [sourceToFill, setSourceToFill] = useState()
  const [callInfo, setCallInfo] = useState({})
  const [renderTable, setRenderTable] = useState(false)
  const [formatToday, setFormatToday] = useState(false)
  const [indexOfRow, setIndexOfRow] = useState()

  const { tableRender, setTableRender } = useContext(TableDataContext)

  useEffect(() => {
    if (tableRender) {
      console.log("CHEGOU")
      getCall()
    } else {
      console.log("CHEGOU")
      getCall()
    }
  }, [tableRender, setTableRender])

  const getCall = async () => {
    try {
      const token = getToken()
      await api({
        method: "POST",
        url: "/callqa/calls",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          instrumentType: callToGet.instrumentType,
          source: callToGet.source,
          period: callToGet.period,
          date: moment.utc(callToGet.date).format("yyyy-MM-DD"),
        },
      }).then((res) => {
        setCallInfo(res.data.result)

        formatDate(res.data.result)
        setRenderTable(true)
      })
    } catch (e) {
      console.log(e)
    }
  }

  const formatDate = (value) => {
    value.forEach((v) => {
      v["maturityDate"] = moment.utc(v.maturityDate).format("DD/MM/YYYY")
      v["fileDate"] = moment.utc(v.callDate).format("HH:mm:ss")
      v["callDate"] = moment.utc(v.callDate).format("DD/MM/YYYY")
    })
  }

  async function getSources() {
    return api
      .get("/callqa/sources", {})
      .then((res) => {
        console.log(res.data.result)
        return res.data.result
      })
      .catch((err) => console.log("error", err))
  }

  useEffect(() => {
    getCall()
  }, [formatToday])

  useEffect(() => {
    console.log("Initializing")
    getSources().then((results) => {
      setSourceToFill(results)
    })
  }, [])

  const instrumentTypeOptions = ["LTN", "NTN-F", "NTN-B", "LFT"]
  const periodOptions = ["opening", "morning", "afternoon", "closing"]

  const staticSource = [
    "ANBIMA",
    "ANBIMA 12H",
    "ATIVA",
    "BGC",
    "RENASCENÇA",
    "XP",
  ]

  const customStyles = {
    rows: {
      style: {
        minHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        fontFamily: "Verdana, sans-serif",
        fontWeight: "bold",
        paddingLeft: "5px", // override the cell padding for head cells
        paddingRight: "5px",
      },
    },
    cells: {
      style: {
        fontSize: "12px",
        fontFamily: "Verdana, sans-serif",
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  }

  const centerOnRow = (debentureRow, event) => {
    let docToScroll = event.target.parentNode.id
    if (docToScroll !== null || docToScroll !== undefined) {
      let row = document.querySelector(`#${docToScroll}`)
      row.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  const columns = [
    {
      name: "instrumentType",
      selector: (row) => row.instrumentType,
      sortable: false,
    },

    {
      name: "maturityDate",
      selector: (row) => row.maturityDate,
      sortable: true,
    },

    {
      name: "bidYield",
      selector: (row) => row.bidYield,
      sortable: true,
    },

    {
      name: "askYield",
      selector: (row) => row.askYield,
      sortable: true,
    },

    {
      name: "last",
      selector: (row) => row.last,
      sortable: true,
    },

    {
      name: "source",
      selector: (row) => row.source,
      sortable: false,
    },

    {
      name: "period",
      selector: (row) => row.period,
      sortable: false,
    },

    {
      name: "isClosure",
      selector: (row) => row.isClosure,
      sortable: true,
    },

    {
      name: "callHour",
      selector: (row) => row.fileDate,
      sortable: true,
    },

    {
      name: "callDate",
      selector: (row) => row.callDate,
      sortable: true,
    },

    {
      cell: (row, index) => (
        <IconButton
          aria-label="delete"
          color="error"
          onClick={async (e) => {
            setIndexOfRow(index)
            let actualRow = document.getElementById(`row-${index}`)
            actualRow.style.backgroundColor = "red"
            let y = await deleteCallModal(row)
            if (!y) {
              let actualRowToRevert = document.getElementById(`row-${index}`)
              actualRowToRevert.style.backgroundColor = "transparent"
            }
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ]

  const deleteCallModal = async (row) => {
    let shouldCancel
    await Swal.fire({
      title: "Deletar Call",
      text: "Você tem certeza que deseja deletar essa call?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((result) => {
      shouldCancel = result.isDismissed
    })

    if (shouldCancel) {
      notify(`º Operação cancelada`, "warning")
      return false
    }

    deleteCall(row)
  }

  const deleteCall = async (row) => {
    try {
      const token = getToken()
      await api({
        method: "DELETE",
        url: "/callqa/delete_call",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          doc_id: row._id,
        },
      }).then((res) => {
        console.log(res)
        if (res.status === 200) {
          notify(`º Call Deletada com sucesso`, "success")
        } else {
          notify(`º Exclusão da Call falhou!`, "error")
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getCall()
  }, [DataTable])

  return (
    <div className="container">
      <div className="select-container">
        <h5>Instrumento: </h5>
        <select
          value={callToGet.instrumentType}
          onChange={(e) =>
            setCallToGet({ ...callToGet, instrumentType: e.target.value })
          }
        >
          <option value="">Selecione uma opção</option>
          {instrumentTypeOptions.map((option) => (
            <option key={option}>{option}</option>
          ))}
        </select>
      </div>
      <div className="select-container">
        <h5>Periodo: </h5>
        <select
          value={callToGet.period}
          onChange={(e) =>
            setCallToGet({ ...callToGet, period: e.target.value })
          }
        >
          <option value="">Selecione uma opção</option>
          {periodOptions.map((option) => (
            <option key={option}>{option}</option>
          ))}
        </select>
      </div>
      <div className="select-container">
        <h5>Fonte: </h5>
        {sourceToFill != undefined ? (
          <select
            onChange={(e) =>
              setCallToGet({ ...callToGet, source: e.target.value })
            }
          >
            <option value="">Selecione uma opção</option>
            {sourceToFill.map((option) => (
              <option key={option}>{option}</option>
            ))}
          </select>
        ) : (
          <select
            onChange={(e) =>
              setCallToGet({ ...callToGet, source: e.target.value })
            }
          >
            <option value="">Selecione uma opção</option>
            {staticSource.map((option) => (
              <option key={option}>{option}</option>
            ))}
          </select>
        )}
      </div>

      <div className="group__form">
        <label className="form__label">Data da call: </label>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          enablePersistence={true}
          locale={ptBR}
          selected={callToGet.date}
          onChange={(data) => {
            setCallToGet({ ...callToGet, date: data })
          }}
        />
      </div>

      <br></br>

      <button onClick={getCall} className="mt-2 btn btn-primary">
        Buscar
      </button>
      <hr />

      {renderTable && (
        <DataTable
          columns={columns}
          data={callInfo}
          expandableRows
          expandableRowsComponent={ExpandendComponent}
          customStyles={customStyles}
          noDataComponent={"Nenhuma Call Encontrada"}
          ndOnRowDoubleClicked
          onRowDoubleClicked={(row, event) => centerOnRow(row, event)}
          highlightOnHover
          progressComponent={<Loading />}
        />
      )}
    </div>
  )
}

export default CallQA
