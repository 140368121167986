import React from "react";
import { ClipLoader } from "react-spinners";

const Loading = ({ height = "75", loadText }) => {
  return (
    <>
    <div
      style={{
        display: "flex",
        marginTop: "100px",
        justifyContent: "center",
        alignItems: "center",
        height: `${height}px`,
      }}
    >
      <ClipLoader color="#7c00d3" size={40} />
        
    </div>
    <div className="row" 
        style={{
          fontSize: "14px"      }}>
          <div className="text-center">
            Calculando... 
          </div>
      </div>
      <div className="row"
        style={{
          fontSize: "11px"      }}
          >
        <div className="text-center">
            { loadText ? loadText : "(Gerando dados para análise...)" }
        </div>
    </div>
    </>
  );
};

export default Loading;
