import React from "react";

import { Router } from "react-router-dom";
import { history } from "./helpers/history";
import Header from "./components/Header";
import Routers from "./routes";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {TableDataProvider} from "./pages/CallQA/TableDataContext";
import "./global.css";

function App() {
  return (
    <div>
      <TableDataProvider>
      <Router history={history}>
        <ToastContainer autoClose={1500} />
        <Header />
        <Routers />
      </Router>
      </TableDataProvider>
    </div>
  );
}

export default App;
