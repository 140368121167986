import React from "react";

import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Loading = () => {
  return (
    <>
      <center className="mt-5">
        <h5>Carregando lista de usuários...</h5>
        <AiOutlineLoading3Quarters size="20" />
      </center>
    </>
  );
};
export default Loading;
