import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useLayoutEffect,
} from "react";

import api from "../../services/api";

import ModalEditAgendaEventFiAnalitycs from "./components/Modal/EditAgendaFiAnalitycs";
import TableAgendaFiAnalytics from "../../components/Table/TableAgendaFiAnalytics";
import SaveAgenda from "./components/Modal/SaveAgenda";
import TickerBox from "../../components/TickerBox";
import Loading from "../../components/Loading";
import { isAfter } from "date-fns";
import { transformStringDatePTBRToFormatMMDDYYYY } from "../../utils/date";
import Swal from "sweetalert2";
import Tabs from "../../components/Tabs";

import notify from "../../common/toast";

import "./style.css";

const Agenda = () => {
  const [debentures, setDebentures] = useState([]);
  const [agendaEvents, setAgendaEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [agendaId, setAgendaId] = useState("");
  const [showModalAgenda, setShowModalAgenda] = useState(false);
  const [showModalSaveAgenda, setShowModalSaveAgenda] = useState(false);
  const [formFilterEvents, setFormFilterEvents] = useState({
    initialDate: "",
    finalDate: "",
    typeEvent: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [ticker, setTicker] = useState("");

  useLayoutEffect(() => {
    const documents = document.getElementsByTagName("tr");
    agendaEvents.forEach((item, index) => {
      if (item.length === 10 && documents[index]) {
        documents[index].classList.add("rowPainted");
      }
    });
  }, [agendaEvents]);

  useEffect(() => {
    const getDebentures = async () => {
      try {
        const { data } = await api.get("/getParcialDebenturesAttributes");
        setDebentures(data);
      } catch (e) {
        console.log(e);
      }
    };
    getDebentures();
  }, []);

  const debenturesCodes = useMemo(
    () => debentures.map(debenture => debenture["B3 Code"].slice(-1)[0].value),
    [debentures]
  );

  const getObjectIdFromAgendaFiAnalytics = () => {
    const debenture = debentures.filter(
      debenture => debenture["B3 Code"].slice(-1)[0].value.trim() === ticker
    );
    if (!debenture.length) {
      return notify("Não existe nenhum evento para esse código", "warning");
    }
    const id = debenture[0]["Events Agenda FI Analytics"]["id"]["$oid"];
    setAgendaId(id);
    return id;
  };

  const saveAgendaEvent = async () => {
    try {
      if (!ticker) {
        return notify("O campo de busca precisa ser preenchido", "warning");
      }
      setIsLoading(true);

      const id = getObjectIdFromAgendaFiAnalytics();
      const { data } = await api.post("/agenda", { id });
      setAgendaEvents(data[0]["agenda"].slice(-1)[0]["value"]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const showEditModal = useCallback(
    event => {
      setShowModalAgenda(true);
      setSelectedEvent(event);
    },
    [setShowModalAgenda, setSelectedEvent]
  );

  const showSaveAgendaModal = () => {
    setShowModalSaveAgenda(true);
  };

  const deleteSingleEvent = async eventP => {
    let shouldCancel;

    await Swal.fire({
      title: "Deletar evento",
      text: "Você tem certeza que deseja deletar esse evento?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(result => {
      shouldCancel = result.isDismissed;
    });

    if (shouldCancel) return;

    const filteredEvents = agendaEvents.filter(
      event => JSON.stringify(event) !== JSON.stringify(eventP)
    );

    setAgendaEvents(filteredEvents);
    notify("Evento deletado", "success");
  };

  const filterAgenda = () => {
    const filteredAgendaValues = agendaEvents.slice(1).filter(event => {
      const { initialDate, finalDate, typeEvent } = formFilterEvents;
      if (!initialDate && !finalDate && !typeEvent) {
        return true;
      }
      if ((!initialDate || !finalDate) && typeEvent) {
        return event[2] === typeEvent;
      }
      if (initialDate && finalDate && !typeEvent) {
        return (
          isAfter(
            transformStringDatePTBRToFormatMMDDYYYY(event[0]),
            initialDate
          ) &&
          isAfter(finalDate, transformStringDatePTBRToFormatMMDDYYYY(event[0]))
        );
      }
      if (initialDate && finalDate && typeEvent) {
        return (
          isAfter(
            transformStringDatePTBRToFormatMMDDYYYY(event[0]),
            initialDate
          ) &&
          isAfter(
            finalDate,
            transformStringDatePTBRToFormatMMDDYYYY(event[0])
          ) &&
          typeEvent === event[2]
        );
      }
      return true;
    });
    return filteredAgendaValues;
  };

  return (
    <div className="container__agenda">
      <div className="container__search">
        <TickerBox
          debenturesCodes={debenturesCodes}
          ticker={ticker}
          setTicker={setTicker}
          isLoading={isLoading}
          getAgenda={saveAgendaEvent}
        />
      </div>
      {isLoading && <Loading />}
      {!isLoading && agendaEvents.length !== 0 && (
        <Tabs
          setAgendaValue={setAgendaEvents}
          agendaValue={agendaEvents}
          formFilterEvents={formFilterEvents}
          setFormFilterEvents={setFormFilterEvents}
        />
      )}

      {!isLoading && agendaEvents.length > 0 && (
        <>
          <div>
            <button onClick={showSaveAgendaModal} className="btn--save-changes">
              Salvar alterações
            </button>
          </div>
          <TableAgendaFiAnalytics
            agendaValue={agendaEvents}
            setAgendaValue={setAgendaEvents}
            deleteSingleEvent={deleteSingleEvent}
            showEditModal={showEditModal}
            filterAgenda={filterAgenda}
          />
        </>
      )}
      <ModalEditAgendaEventFiAnalitycs
        showModal={showModalAgenda}
        setShowModal={setShowModalAgenda}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        agendaEvents={agendaEvents}
        setAgendaEvents={setAgendaEvents}
      />
      <SaveAgenda
        showModalSaveAgenda={showModalSaveAgenda}
        setShowModalSaveAgenda={setShowModalSaveAgenda}
        agendaEvents={agendaEvents}
        agendaId={agendaId}
      />
    </div>
  );
};

export default Agenda;
