export function saveCvmInfos(
  url_file,
  company_name,
  document_date,
  affected_tickers
) {
  return dispatch => {
    dispatch({
      type: "SAVE_CVM_INFOS",
      url_file,
      company_name,
      document_date,
      affected_tickers,
    });
  };
}
