import { formatDecimals } from "./formatDecimals"

export const checkIfIsNaNorNone = (value) => {
    if (value === "nan" || value === "None" || !isFinite(value) 
    || value === undefined || value === ' - ' || value === null) {
        return "-"                     
    } else {
        return formatDecimals(value, 4)
    }
}

