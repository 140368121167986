import React, { useState, useEffect } from "react";

import "./style.css";
import api from "../../services/api";
import moment from "moment";
import Swal from "sweetalert2";

import { ReflexContainer,ReflexSplitter,ReflexElement} from 'react-reflex'
import Menu from "./components/Menu"
import InstrumentGroupContainer from "./components/InstrumentGroupContainer"
import Collapsible from 'react-collapsible';
import QaLineCharts from './components/QaLineCharts'
import Loading from "../../components/FeedbackLoading";

const PricingGovQA = () => {
    const instrumentTypeList = ["LTN", "NTF", "NTB", "LFT"] 
    const [tablePending, setTablePending] = useState()
    const [govBondsInfo, setGovBondsInfo] = useState({
        filteredDate: "",
        isPreview: "",
    })

    const [govBondQa, setGovBondsQa] = useState(
        {   
            "LTN": [],
            "NTF": [],
            "NTB": [],
            "LFT": [],
        }
    )

    const checkDateForPricingProcess = (date, checkIsPreview) => {
        var dateTime = date
        var triggerDate = new Date().setHours(14, 35, 0, 0)

        if (moment(dateTime).isSameOrAfter(moment(triggerDate))){
            Swal.fire({
                title: 'Deseja rodar o processo de "Pricing" ?',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                denyButtonText: `Não`,
              }).then((result) => {
                if (result.isConfirmed) {
                    setTablePending(true)
                    run_pricing_results(date, checkIsPreview)
                    .then(function () {
                        return domestic_curve_calculator(checkIsPreview);
                    })
                    .then(function () {
                        return gov_bonds_pricing_qa(date, checkIsPreview);
                    }).then(function () {
                        setTablePending(false);
                    })           
                } else if (result.isDenied) {
                    setTablePending(true)
                    gov_bonds_pricing_qa(date, checkIsPreview).then(function () {
                        setTablePending(false)
                    })
                }
              })
        } else {
             gov_bonds_pricing_qa(date, checkIsPreview)
        }

    }

    const run_pricing_results = async (date, isPreview) => {
        date = moment(date).format("YYYY-MM-DD")

        try {
            const documents = await api.post('run_pricing_process', {date, isPreview});
            
            var response = documents.data.result

            return response
        } catch (e) {
            console.log(e);
        }
    }

    const domestic_curve_calculator = async (isPreview) => {
        try {
            const documents = await api.post('domestic_curve_calculator', {isPreview});
            
            var response = documents.data.result

            return response
        } catch (e) {
            console.log(e);
        }
    }

    const gov_bonds_pricing_qa = async (date, checkIsPreview) => {
        setGovBondsInfo({
            filteredDate: moment(date).format("YYYY-MM-DD"),
            isPreview: checkIsPreview
        })

        for (var instrument in instrumentTypeList) {
            var date = moment(date).format("YYYY-MM-DD")
            instrument = instrumentTypeList[instrument]
            var isPreview = checkIsPreview

            try {
                const documents = await api.post('gov_bonds_pricing_qa', {instrument, date, isPreview});
                
                var response = documents.data.result

                if (!Array.isArray(documents.data.result)){
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro no processo.',
                        text: documents.data.result,
                        showConfirmButton: true,
                    })

                    setGovBondsQa(prevState => ({...prevState, [instrument]: []}))
                } else {
                    setGovBondsQa(prevState => ({...prevState, [instrument]: response}))
                }


            } catch (e) {
                console.log(e);
            }
        }
    }

    const gov_bonds_pricing_qa_save = async () => {
        var date = govBondsInfo.filteredDate
        var isPreview = govBondsInfo.isPreview

        if (govBondQa.LTN.length > 1 && govBondQa.NTF.length > 1 && govBondQa.NTB.length > 1) {
            Swal.fire({
                title: 'Deseja gerar o arquivo para markit ?',
                showDenyButton: true,
                confirmButtonText: 'Gerar',
                denyButtonText: `Cancelar`,
              }).then((result) => {
                if (result.isConfirmed) {
                    try {
                        (async () => {
                            const documents = await api.post('gov_bonds_pricing_qa_save', {date, isPreview});
                                        
                            var response = documents.data.result

                            Swal.fire('Gerado com sucesso!', '', 'success')
                        })()
                    } catch (e) {
                        Swal.fire('Erro ao gerar (Erro na API de save)', '', 'info')
                    }
                } else if (result.isDenied) {
                  Swal.fire('Nenhuma requisição feita', '', 'info')
                }
              })
        } else {
            Swal.fire('Sem dados para gerar ou uma das tabelas está sem informação', '', 'info')
        }
    }

    const renderChartByInstrument = (instrumentType) => {
        var ltnCharts = ["DI1xDI1minus1", "YTMandPREMIUM", "AnaliseRetorno"]
        var lftCharts = ["DI1xDI1minus1", "YTMandPREMIUM", "AnaliseRetorno"]
        var ntnCharts = ["DI1xDI1minus1", "YTMandPREMIUM", "AnaliseRetorno"]
        var ntbCharts = ["YTM", "BreakEvenInflation", "AnaliseRetorno"]

        if (instrumentType === "LTN") {
            return (
                ltnCharts.map(function(chartType, index) {
                    return(
                        <div className="pricingDFA_calls_group" key={index}>
                            <QaLineCharts 
                                title={chartType}
                                chartType={chartType}
                                dataToChart={govBondQa[instrumentType]}
                                instrumentType={instrumentType}
                            />
                        </div>
                    )
                })
            )
        }

        if (instrumentType === "NTF") {
            return (
                ntnCharts.map(function(chartType, index) {
                    return(
                        <div className="pricingDFA_calls_group" key={index}>
                            <QaLineCharts 
                                title={chartType}
                                chartType={chartType}
                                dataToChart={govBondQa[instrumentType]}
                                instrumentType={instrumentType}
                            />
                        </div>
                    )
                })
            )
        }

        if (instrumentType === "NTB") {
            return (
                ntbCharts.map(function(chartType, index) {
                    return(
                        <div className="pricingDFA_calls_group" key={index}>
                            <QaLineCharts 
                                title={chartType}
                                chartType={chartType}
                                dataToChart={govBondQa[instrumentType]}
                                instrumentType={instrumentType}
                            />
                        </div>
                    )
                })
            )
        }

        if (instrumentType === "LFT") {
            return (
                lftCharts.map(function(chartType, index) {
                    return(
                        <div className="pricingDFA_calls_group" key={index}>
                            <QaLineCharts 
                                title={chartType}
                                chartType={chartType}
                                dataToChart={govBondQa[instrumentType]}
                                instrumentType={instrumentType}
                            />
                        </div>
                    )
                })
            )
        }
    }

    const expandableButton = (instrumentType) => {
        return(
            <div className="row">
                <div className="btn-group">
                    <button type="button" className="btn expandable-qa-button dropdown-toggle">
                        Expandir gráficos de {instrumentType}
                    </button>
                </div>
            </div>
        )
    }

    return (
        <>
            {tablePending ? <Loading/> : 
            <ReflexContainer orientation="vertical">
                <ReflexElement  className="left-pane left-panel-controller">
                    <Menu 
                        gov_bonds_pricing_qa={checkDateForPricingProcess}
                    />
                    {instrumentTypeList.map(function(instrumentType, index) {
                        return (
                            <div key={index}>
                                <InstrumentGroupContainer 
                                    instrumentName={instrumentType}
                                    filteredDate={govBondsInfo.filteredDate}
                                    govBond={govBondQa[instrumentType]}/>
                            </div>
                        )
                    })}
                </ReflexElement>
                <ReflexSplitter/>
                <ReflexElement className="right-pane">
                    {instrumentTypeList.map(function(instrumentType, index) {
                        return (
                            <Collapsible trigger={expandableButton(instrumentType)} key={index}>
                            { renderChartByInstrument(instrumentType) }
                            </Collapsible>
                        )
                    })}

                <div className="d-grid gap-2 mt-4 pricingDFA_calls_group">
                    <button 
                        className="btn btn-danger" 
                        type="button"
                        onClick={() => gov_bonds_pricing_qa_save()}>
                            Gerar o arquivo para markit
                    </button>
                </div>
                </ReflexElement>
            </ReflexContainer> 
            }
            
            
        </> 
    );
};

export default PricingGovQA;