import React, { useEffect, useState, useMemo } from "react"
import DatePicker from "react-datepicker"
import ptBR from "date-fns/locale/pt-BR"
import api from "../../services/api"
import Loading from "../../components/Loading"
import { format } from "date-fns"
import Panel from "./components/Panel"
import { getPreviousBusinessDay, holidaysList } from "../../utils/holidays"

export default function DailyProcessMonitor() {
  const [currentProcessDate, setCurrentProcessDate] = useState(new Date())
  const [processData, setProcessData] = useState([])
  const [loading, setLoading] = useState(false)
  const [panelRendered, setPanelRendered] = useState(false)
  const formattedProcessDate = format(currentProcessDate, "yyyy-MM-dd")

  const fetchMonitorData = async () => {
    const previousBusinessDay = getPreviousBusinessDay(currentProcessDate)
    const formattedPreviousBusinessDay = format(previousBusinessDay, "yyyy-MM-dd")
    try {
      setLoading(true)
      const response = await api.get(
        `/getAllResults?reference_date=${formattedProcessDate}&previous_business_day=${formattedPreviousBusinessDay}`
      )

      setProcessData(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const isWeekday = (date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  const hasData = processData.length > 0 ? true : false

  useEffect(() => {
    if (hasData) {
      setPanelRendered(true)
    }
  }, [hasData])

  useEffect(() => {
    if (panelRendered) {
      const intervalId = setInterval(fetchMonitorData, 60000)

      return () => clearInterval(intervalId)
    }
  }, [panelRendered])

  return (
    <>
      <div
        className="
        container
        col-md-12
        justify-content-center
        mt-5
        d-flex
        shadow 
        p-3 
        mb-5 
        bg-body 
        rounded
      "
        style={{ width: "600px" }}
      >
        <div className="d-flex flex-column justify-content-between align-items-center">
          <h3 className="mt-1">Daily Process Monitor</h3>
          <label htmlFor="process-date">
            <b>Selecione a data:</b>
          </label>
          <DatePicker
            className="mt-2 p-2"
            dateFormat="dd/MM/yyyy"
            locale={ptBR}
            selected={currentProcessDate}
            onChange={(value) => setCurrentProcessDate(value)}
            filterDate={isWeekday}
            maxDate={new Date()}
            excludeDates={holidaysList}
          />
          <button
            className="btn mt-2"
            style={{ backgroundColor: "#7c00d3", color: "#fff" }}
            onClick={fetchMonitorData}
            disabled={loading}
          >
            Carregar dados
          </button>
        </div>
      </div>
      {loading && <Loading />}
      {hasData && <Panel processData={processData} />}
    </>
  )
}
