import React, {useState, useEffect, useMemo, useCallback} from "react";
import api from "../../../../services/api";

import { faEdit} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DataTable from 'react-data-table-component';
import Loading from "../../../../components/Loading";
import ScheduleEditModal from "../ScheduleEditModal";
import differenceBy from 'lodash/differenceBy';
import Swal from "sweetalert2";
import { normalizeEditedSchedule, eventsStatusDatesTranslate } from "../../../../common/debenturesRegisterEventAndStatusTypes";
import "./style.css";


const SchedulesToRegisterList = () => {
    //Agenda
    const [Schedule, setSchedule] = useState([])
    
    const [selectedTicker, setSelectedTicker] = useState('')

    //Filter
    const [schedulesToRegisterTickerList, setSchedulesToRegisterTickerList] = useState([])
    const [debentureScheduleTickerToEdit, setDebentureScheduleTickerToEdit] = useState('')
    const [debentureScheduleIdToEdit, setDebentureScheduleIdToEdit] = useState(0)
    const [selectedEventsToDelete, setSelectedEventsToDelete] = useState([])
    const [toggleCleared, setToggleCleared] = useState(false)
    const [isFocus, setIsFocus] = useState(false);
    const [pending, setPending] = useState(false)

    //Modal
    const [scheduleEditModal, setScheduleEditModal] = useState(false)
    const [scheduleRowAndIndex, setScheduleRowAndIndex] = useState({
        index: '',
        row: ''
    })

    const [reloadTable, setReloadTable] = useState(false)

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                fontSize: '15px',
                fontFamily: 'Verdana, sans-serif',
                fontWeight: 'bold',
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                fontFamily: 'Verdana, sans-serif',
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const ListColumns = [
        {
            name: "Editor",
            cell: (row, index) => 
            <div className="d-grid gap-2">
            <button className="btn btn-primary btn-sm" type="button"
                onClick={() => getRowAndIndexInfo(row, index)}>
                <FontAwesomeIcon icon={faEdit} size="1x" className="me-2"/>
                    Editar</button>
            </div>,
            grow: 0,
        },
        {
            id: 'Event Date' ,
            name: 'EVENT DATE',
            selector: row => eventsStatusDatesTranslate("Event Date", row[0]),
        },
        {
            id: 'Actual Event Date',
            name: 'ACTUAL EVENT DATE',
            selector: row => eventsStatusDatesTranslate("Actual Event Date" ,row[1]),
        },
        {
            id: 'Event',
            name: 'EVENT',
            selector: row => eventsStatusDatesTranslate("Event", row[2]),
            grow: 2,
        },
        {   
            id: 'Percentual Rate',
            name: '% RATE',
            selector: row => eventsStatusDatesTranslate("% RATE", row[3]),
        },
        {
            name: 'STATUS',
            selector: row => row[4],
            width: '350px',
        },
        {
            id: 'Amount',
            name: 'AMOUNT',
            selector: row => eventsStatusDatesTranslate("AMOUNT", row[5]),
        },
        {
            id: 'Internal Use',
            name: 'INTERNAL USE',
            selector: row => row[6],
        },
        {
            id: 'Calculus Reference',
            name: 'CALCULUS REF. DATE',
            selector: row => row[7],
        },
        {
            id: 'Percentage To Principal INCORPORATION',
            name: '% PRINC. INCORPORATION',
            selector: row => row[8],
        },     
    ];

    useEffect(() => {
        let componentMounted = true;
        const fetchData = async () => {
            setPending(true)
            
            const documents = await api.get("/getPendingB3SchedulesToRegister");
                
            if(componentMounted) {
                setSchedulesToRegisterTickerList(documents.data)  
            }

            setPending(false)      
        };

        fetchData()
        return () => {
            componentMounted = false;
        }
    }, [reloadTable]);

    const getFiAnalitycsScheduleById = async() => {
        const documents = await api.post("/getFiAnalyticsScheduleById", {id: debentureScheduleIdToEdit["$oid"]})

        documents.data[0].agenda[0].value.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a[0]) - new Date(b[0]);
        });
        
        setSchedule(documents.data[0].agenda[0].value)
        setSelectedTicker(debentureScheduleTickerToEdit)

        let row = document.getElementById('row-0');
        row.style.display = "none";
    }


    const tickeList = schedulesToRegisterTickerList.map((scheduleObj, ind) => { 
        return {ticker: scheduleObj["ticker"], tickerId: scheduleObj["_id"]}
    });

    const handleTickerSchedule = (ticker, tickerId) => {
        setDebentureScheduleTickerToEdit(ticker)
        setDebentureScheduleIdToEdit(tickerId)

        setIsFocus(false)
    }

    const handleTickerScheduleOnPress = (e) => {
        let firstTicker = []

        if (e.key === 'Enter') {
            tickeList && tickeList
            .filter((scheduleTicker) =>
                scheduleTicker.ticker.toLowerCase().includes(debentureScheduleTickerToEdit.toLowerCase())
            ).map((scheduleTicker) => (
                firstTicker.push(scheduleTicker)
            ))
            
            if (firstTicker.length > 0){
                setDebentureScheduleTickerToEdit(firstTicker[0].ticker)
                setDebentureScheduleIdToEdit(firstTicker[0].tickerId)
            }
            
            
            e.preventDefault()
            setIsFocus(false)
            document.getElementById("find-by-ticker").focus();
        }
    }

    const FilterComponent = useMemo(() => {
		return (
        <>
            <div className="col-md-8 d-flex justify-content-center">
                <div className="col-md-6 mb-3">
                    <div className="input-group">
                        <span className="input-group-text" id="find-by-ticker">Procurar por ticker</span>
                        <input
                            className="form-control"
                            id="search"
                            type="text"
                            autoComplete="off"
                            placeholder="Ticker"
                            onChange={(e) => setDebentureScheduleTickerToEdit(e.target.value)}
                            onFocus={() => setIsFocus(true)}
                            onKeyPress={(e) => handleTickerScheduleOnPress(e)}
                            value={debentureScheduleTickerToEdit}
                        />
                        <button 
                            className="btn btn-warning"
                            type="button" 
                            disabled={debentureScheduleTickerToEdit.length >= 6  ? false : true }
                            onClick={() => getFiAnalitycsScheduleById()}
                            >Filtrar
                        </button> 
                    </div>
                    { isFocus ?
                        <div className="scroll-show-tickers-attributes mb-2 mt-1">
                            {tickeList && tickeList
                                .filter((scheduleTicker) =>
                                scheduleTicker.ticker.toLowerCase().includes(debentureScheduleTickerToEdit.toLowerCase())
                                )
                                .map((scheduleTicker) => (
                                <div key={scheduleTicker.ticker}  
                                    id="ticker-select" 
                                    name={scheduleTicker.ticker} 
                                    onClick={() => handleTickerSchedule(scheduleTicker.ticker, scheduleTicker.tickerId)}>
                                    <span>
                                        {scheduleTicker.ticker} 
                                    </span>                                  
                                </div>
                                ))}
                        </div>
                        : 
                        null }
                </div>
            </div> 
            <div className="col-md-8 justify-content-center mt-2 text-center">
                <button disabled={!Schedule.length > 0} className="btn btn-success" onClick={() => saveDebentureAndScheduleOnProductionDB()}>Salvar Agenda Editada</button>
            </div>                 
            <div className="col-md-8 d-flex justify-content-center mt-3">
                <span className="fs-5 text fw-bold">
                    <hr/>
                    {selectedTicker}
                </span>
            </div> 
        </>
        )
	}, [Schedule, debentureScheduleTickerToEdit, isFocus, selectedTicker]); // eslint-disable-line react-hooks/exhaustive-deps

    const saveAgenda = (newSchedule) => {
        setSchedule(newSchedule)
    }

    const handleRowSelected = useCallback(state => {
        setSelectedEventsToDelete(state.selectedRows);
    }, []);

    const deleteScheduleEvent = useMemo(() => {
		const handleDelete = () => {
			setToggleCleared(!toggleCleared);
            setSchedule(differenceBy(Schedule, selectedEventsToDelete, [0, 1, 2]))
   	    };
           
        return (
            <button key="delete" className="btn btn-danger" onClick={handleDelete}>
    		    Deletar Eventos
    		</button>
    	);
    }, [Schedule, selectedEventsToDelete, toggleCleared]);

    const saveDebentureAndScheduleOnProductionDB = async () => {
        let normalizedAgenda = normalizeEditedSchedule(Schedule)
        
        try {
            var response = await api.post("/saveDebentureAndScheduleOnProductionDB", {agenda: normalizedAgenda, id: debentureScheduleIdToEdit})

            if (response.status === 200){
                Swal.fire({
                icon: 'success',
                title: 'Agenda cadastrada com sucesso!',
                text: `Agenda cadastrada ao banco de dados de produção.`,
                showConfirmButton: true,
              })
            }
        } catch (e) {
            if (e.response.status === 410) {
                Swal.fire({
                  icon: 'error',
                  title: 'Falha ao cadastrar debênture!',
                  text: `Aparentemente esta agenda já foi cadastrada no banco de dados por outro usuário. Caso esta agenda
                  continue nessa lista contate um administrador.`,
                  showConfirmButton: true,
                })
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Sistema fora do ar',
                  text: `Espero um dia essa mensagem nunca aparecer, se isso acontecer, é por que provavelmente algo está errado no back-end`,
                  showConfirmButton: true,
                })
              }
        }
        
        setPending(true)
        setSchedule([])
        setSelectedTicker('')
        setSchedulesToRegisterTickerList([])
        setDebentureScheduleTickerToEdit('')
        setDebentureScheduleIdToEdit(0)

        setReloadTable(!reloadTable)
    }

    const getRowAndIndexInfo = (row, index) => {
        setScheduleEditModal(true)

        setScheduleRowAndIndex({row: row, index: index})
    }

    const renderScheduleEditModal = () => { 
        return (scheduleEditModal ? 
            <ScheduleEditModal
                show={scheduleEditModal}
                onHide={() => setScheduleEditModal(false)} 
                rowSchedule={scheduleRowAndIndex.row}
                rowIndex={scheduleRowAndIndex.index}
                schedule={Schedule}
                saveAgenda={saveAgenda}
            />: null )
    }
    return (
        <>  
            {renderScheduleEditModal()}
           <DataTable
                title=" "
                fixedHeader
                customStyles={customStyles}
                columns={ListColumns} 
                data={Schedule}
                noDataComponent={"Nenhuma Agenda pendente encontrada para a debênture digitada..."}
                subHeader
                subHeaderComponent={FilterComponent}
                highlightOnHover    
                selectableRows
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleCleared}
                contextMessage={{singular: 'Evento', plural: 'Eventos', message: 'selecionado(s) para deletar'}}
                contextActions={deleteScheduleEvent}
                progressPending={pending}
			    progressComponent={<Loading />}  
            />
        </>
    );
};

export default SchedulesToRegisterList;
