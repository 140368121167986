import React, { useState, useEffect, useCallback } from "react";

import api from "../../services/api";

import { useDispatch } from "react-redux";

import { saveCvmInfos } from "../../actions/cvmInfosAction";

import ModalAddTicker from "./components/Modal/AddTicker";
import ModalDownloadCvmFile from "./components/Modal/DownloadFile";

import notify from "../../common/toast";

import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

import { AiOutlineCheckCircle } from "react-icons/ai";

import "./style.css";

const Cvm = () => {
  const [cvmInfos, setCvmInfos] = useState([]);
  const [date, setDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [showModalDownloadCvmFile, setShowModalDownloadCvmFile] =
    useState(false);

  const dispatch = useDispatch();

  const getCvmInfos = useCallback(
    async dateParam => {
      try {
        const date = dateFormated(dateParam).split("-");
        const { data } = await api.post("/cvmInfos", {
          document_date: `${date[2]}-${date[1]}-${date[0]}`,
        });
        if (data.length === 0) {
          setCvmInfos([]);
          return notify("Não existe nenhum registro para essa data", "info");
        }
        setCvmInfos(data);
      } catch (e) {
        console.log(e);
      }
    },
    [setCvmInfos]
  );

  useEffect(() => {
    const current_date = new Date();
    getCvmInfos(current_date);
  }, [getCvmInfos]);

  const dateFormated = dateParam => {
    const date = dateParam,
      day = date.getDate().toString(),
      dayF = day.length === 1 ? "0" + day : day,
      month = (date.getMonth() + 1).toString(),
      monthF = month.length === 1 ? "0" + month : month,
      yearF = date.getFullYear();
    return `${dayF}-${monthF}-${yearF}`;
  };

  const checkCvmDocument = async (
    document_date,
    company_name,
    document_type,
    document_index
  ) => {
    try {
      await api.post("/checkCvmDocument", {
        date: document_date.split("T")[0],
        company_name,
        document_type,
        index: document_index,
      });
      getCvmInfos(date);
    } catch (e) {
      console.log(e);
    }
  };

  const showEditModal = () => {
    setShowModal(true);
  };
  const showDownloadModal = () => {
    setShowModalDownloadCvmFile(true);
  };
  const dispatchCvmInfos = (url, company_name, date, affected_tickers) => {
    dispatch(saveCvmInfos(url, company_name, date, affected_tickers));
    showEditModal();
  };
  const saveFileUrl = url => {
    setFileUrl(url);
    showDownloadModal();
  };

  return (
    <>
      <div className="cvm">
        <DatePicker
          dateFormat="dd/MM/yyyy"
          locale={ptBR}
          selected={date}
          onChange={value => setDate(value)}
        />
        <button onClick={() => getCvmInfos(date)} className="btn__cvm">
          Buscar
        </button>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="text-center align">Empresa</th>
            <th className="text-center align">Tipo de documento</th>
            <th className="text-center align">Assunto</th>
            <th className="text-center align">Link do arquivo</th>
            <th className="text-center align">Checado</th>
            <th className="text-center align">Ações</th>
          </tr>
        </thead>
        <tbody>
          {cvmInfos?.map(item =>
            item["Data"]["ASSEMBLEIA_AGDEB"].map((info, index) => (
              <tr key={index}>
                <td className="align" data-label="EMPRESA">
                  {info[1]}
                </td>
                <td className="align" data-label="TIPO DE DOCUMENTO">
                  {info[2]} AGDEB
                </td>
                <td className="align" data-label="ASSUNTO">
                  {info[11]}
                </td>
                <td className="align" data-label="LINK DO ARQUIVO">
                  <button
                    className="btn__modal__download"
                    onClick={() => saveFileUrl(info[10])}
                  >
                    Baixar
                  </button>
                </td>
                <td className="align" data-label="CHECADO">
                  {!info[12] ? (
                    <button
                      className="btn__cvm__actions"
                      onClick={() =>
                        checkCvmDocument(
                          item["Date"],
                          info[1],
                          "ASSEMBLEIA_AGDEB",
                          index
                        )
                      }
                    >
                      Checar
                    </button>
                  ) : (
                    <AiOutlineCheckCircle size="40" color="green" />
                  )}
                </td>
                <td className="align" data-label="AÇÕES">
                  <button
                    className="btn__cvm__actions"
                    onClick={() =>
                      dispatchCvmInfos(
                        info[10],
                        info[1],
                        item["Date"],
                        item["Affected_tickers"]
                      )
                    }
                  >
                    Editar
                  </button>
                </td>
              </tr>
            ))
          )}
          {cvmInfos?.map(item =>
            item["Data"]["AVISO_AOS_DEBENTURISTAS"].map((info, index) => (
              <tr key={index}>
                <td className="align" data-label="EMPRESA">
                  {info[1]}
                </td>
                <td className="align" data-label="TIPO DE DOCUMENTO">
                  {info[2]}
                </td>
                <td className="align" data-label="ASSUNTO">
                  {info[11]}
                </td>
                <td className="align" data-label="LINK DO ARQUIVO">
                  <button
                    className="btn__modal__download"
                    onClick={() => saveFileUrl(info[10])}
                  >
                    Baixar
                  </button>
                </td>
                <td data-label="CHECADO" className="align">
                  {!info[12] ? (
                    <button
                      className="btn__cvm__actions align"
                      onClick={() =>
                        checkCvmDocument(
                          item["Date"],
                          info[1],
                          "AVISO_AOS_DEBENTURISTAS",
                          index
                        )
                      }
                    >
                      Checar
                    </button>
                  ) : (
                    <AiOutlineCheckCircle size="40" color="green" />
                  )}
                </td>
                <td className="align" data-label="AÇÕES">
                  <button
                    className="btn__cvm__actions align"
                    onClick={() =>
                      dispatchCvmInfos(
                        info[10],
                        info[1],
                        item["Date"],
                        item["Affected_tickers"]
                      )
                    }
                  >
                    Editar
                  </button>
                </td>
              </tr>
            ))
          )}
          {cvmInfos?.map(item =>
            item["Data"]["ESCRITURAS_E_ADITAMENTOS_DE_DEBENTURES"].map(
              (info, index) => (
                <tr key={index}>
                  <td className="align" data-label="EMPRESA">
                    {info[1]}
                  </td>
                  <td className="align" data-label="TIPO DE DOCUMENTO">
                    {info[2]}
                  </td>
                  <td className="align" data-label="ASSUNTO">
                    {info[11]}
                  </td>
                  <td className="align" data-label="LINK DO ARQUIVO">
                    <button
                      className="btn__modal__download"
                      onClick={() => saveFileUrl(info[10])}
                    >
                      Baixar
                    </button>
                  </td>

                  <td className="align" data-label="CHECADO">
                    {!info[12] ? (
                      <button
                        className="btn__cvm__actions align"
                        onClick={() =>
                          checkCvmDocument(
                            item["Date"],
                            info[1],
                            "ESCRITURAS_E_ADITAMENTOS_DE_DEBENTURES",
                            index
                          )
                        }
                      >
                        Checar
                      </button>
                    ) : (
                      <AiOutlineCheckCircle size="40" color="green" />
                    )}
                  </td>
                  <td className="align" data-label="AÇÕES">
                    <button
                      className="btn__cvm__actions align"
                      onClick={() =>
                        dispatchCvmInfos(
                          info[10],
                          info[1],
                          item["Date"],
                          item["Affected_tickers"]
                        )
                      }
                    >
                      Editar
                    </button>
                  </td>
                </tr>
              )
            )
          )}
        </tbody>
      </table>
      <ModalAddTicker showModal={showModal} setShowModal={setShowModal} />
      <ModalDownloadCvmFile
        showModalDownload={showModalDownloadCvmFile}
        setShowModalDownload={setShowModalDownloadCvmFile}
        fileUrl={fileUrl}
      />
    </>
  );
};

export default Cvm;
