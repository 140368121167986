import React, { useEffect, useCallback } from "react";

import { BsArrowDownShort } from "react-icons/bs";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { transformStringDatePTBRToFormatMMDDYYYY } from "../../../utils/date";

import "../../../global.css";

const TableAgendaFiAnalytics = ({
  agendaValue,
  showEditModal,
  setAgendaValue,
  deleteSingleEvent,
  filterAgenda,
}) => {
  const orderByDate = useCallback(() => {
    const orderAsc = agendaValue?.slice(1).sort((a, b) => {
      return (
        transformStringDatePTBRToFormatMMDDYYYY(a[0]).getTime() -
        transformStringDatePTBRToFormatMMDDYYYY(b[0]).getTime()
      );
    });
    setAgendaValue(value => [value[0], ...orderAsc]);
  }, [agendaValue, setAgendaValue]);

  useEffect(() => {
    orderByDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <table className="table margin-top-lg">
      <thead>
        <tr>
          <th onClick={orderByDate} style={{ cursor: "pointer" }}>
            Data do evento <BsArrowDownShort />
          </th>
          <th>Data de liquidação</th>
          <th>Evento</th>
          <th>Taxa / R$</th>
          <th>Status</th>
          <th>Financeiro (R$)</th>
          <th>Uso interno</th>
          <th>Data de referência do cálculo</th>
          <th>Percentual da primeira perna</th>
          <th>Foi editado?</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        {filterAgenda().map(
          (
            [
              event_date,
              actual_event_date,
              type_event,
              percentual_rate,
              status,
              amount,
              internal_use,
              calculus_reference_data,
              percentagem_to_principal_corporate,
              was_edited,
            ],
            index
          ) => (
            <tr key={index}>
              <td data-label="Data do evento">{event_date}</td>
              <td data-label="Data de liquidação">{actual_event_date}</td>
              <td data-label="Tipo de evento">{type_event}</td>
              <td data-label="Taxa / R$">{percentual_rate}</td>
              <td data-label="Status">{status}</td>
              <td data-label="Amount">{amount}</td>
              <td data-label="Uso interno">{internal_use ? "SIM" : "NÃO"}</td>
              <td data-label="Data de referência do cálculo">
                {calculus_reference_data}
              </td>
              <td data-label="Percentual do principal incorporado">
                {percentagem_to_principal_corporate}
              </td>
              <td data-label="Foi editado?" style={{ marginTop: "8px" }}>
                {was_edited ? (
                  <AiOutlineCheck color="green" size="20" />
                ) : (
                  <AiOutlineClose color="red" size="20" />
                )}
              </td>
              <td data-label="Ações">
                <button
                  onClick={() =>
                    showEditModal([
                      event_date,
                      actual_event_date,
                      type_event,
                      percentual_rate,
                      status,
                      amount,
                      internal_use,
                      calculus_reference_data,
                      percentagem_to_principal_corporate,
                    ])
                  }
                  className="btn--edit btn--hover"
                >
                  Editar
                </button>
                <button
                  className="btn--remove btn--hover"
                  onClick={() =>
                    deleteSingleEvent([
                      event_date,
                      actual_event_date,
                      type_event,
                      percentual_rate,
                      status,
                      amount,
                      internal_use,
                      calculus_reference_data,
                      percentagem_to_principal_corporate,
                    ])
                  }
                >
                  Deletar
                </button>
              </td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

export default React.memo(TableAgendaFiAnalytics);
