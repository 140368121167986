import React from "react"
import { Modal, Table, Button, CloseButton } from "react-bootstrap"

const DataModal = ({
  modalData,
  handleCloseModal,
  openModal,
  handleDownloadExcel,
}) => {
  const hasData = modalData?.processDataArray?.length > 0
  const manyFieldsArray = [
    "DI1_futures_adjustment",
    "DAP_futures_adjustment",
    "DOMESTIC_IPCA_CURVE",
    "DOMESTIC_PRE_CURVE",
    "IPCA_DEBENTURE_REFERENCE_NTNB",
    "IPCA_CRI_CRA_REFERENCE_NTNB",
  ]
  const modalSize =
    hasData && manyFieldsArray.includes(modalData.processName) ? "xl" : "lg"

  if (modalData.processName === "DI_futures_intraday") {
    return (
      <Modal
        size={modalSize}
        show={openModal}
        onHide={handleCloseModal}
        scrollable
      >
        <Modal.Header scrollable>
          <Modal.Title>{modalData.time || modalData.processName}</Modal.Title>
          <Button variant="success" onClick={handleDownloadExcel}>
            Download Excel
          </Button>
          <Button variant="danger" onClick={handleCloseModal}>
            x
          </Button>
        </Modal.Header>
        <Modal.Body scrollable>
          <Table>
            <thead>
              <tr>
                {modalData["dataKeys"]?.map((key) => {
                  return <th key={key}>{key}</th>
                })}
              </tr>
            </thead>
            <tbody>
              {hasData ? (
                modalData["processDataArray"]
                  ?.filter((item) =>
                    item["reference_date"]?.includes(modalData.time)
                  )
                  ?.map((filteredData) => {
                    return (
                      <tr key={filteredData["reference_date"]}>
                        {modalData["dataKeys"].map((key) => (
                          <td key={key}>{String(filteredData[key])}</td>
                        ))}
                      </tr>
                    )
                  })
              ) : (
                <tr>
                  <td colSpan={modalData?.dataKeys?.length || 1}>Sem dados</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  } else {
    return (
      <Modal
        size={modalSize}
        show={openModal}
        onHide={handleCloseModal}
        scrollable
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>{modalData.time || modalData.processName}</Modal.Title>
          <Button variant="success" onClick={handleDownloadExcel}>
            Download Excel
          </Button>
          <Button variant="danger" onClick={handleCloseModal}>
            x
          </Button>
        </Modal.Header>
        <Modal.Body scrollable>
          <Table>
            <thead>
              <tr>
                {modalData["dataKeys"]
                  ?.filter((key) => key !== "json")
                  ?.map((key) => {
                    return <th key={key}>{key}</th>
                  })}
              </tr>
            </thead>
            <tbody>
              {hasData ? (
                modalData["processDataArray"]?.map((data, index) => {
                  console.log(modalData)
                  return (
                    <tr key={`${data["reference_date"]}`}>
                      {modalData["dataKeys"]
                        ?.filter((key) => key !== "json")
                        ?.map((key) => {
                          return <td key={key}>{String(data[key])} </td>
                        })}
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={modalData?.dataKeys?.length || 1}>Sem dados</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default DataModal
