import React from "react";
import InputMask from "react-input-mask";

const Input = props => (
  <InputMask
    mask="99/99/9999"
    value={props.value}
    onChange={props.onChange}
    onBlur={props.onBlur}
    name={props.name}
    className={props.className}
  />
);

export default Input;
