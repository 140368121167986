import React from "react";
import { ClipLoader } from "react-spinners";

const Loading = ({ height = "500" }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: `${height}px`,
      }}
    >
      <ClipLoader color="#7c00d3" size={40} />
    </div>
  );
};

export default Loading;
