import React, {useState, useEffect} from "react";
import InputMask from 'react-input-mask';
import moment from 'moment'

const DebenturesInfoEditInput = ({id, rowToEdit, editMode, handleEditInput}) => {
    const [formControl, setFormControl] = useState('')
    useEffect(() => {
        setFormControl('')
    }, [editMode]);

    const onInputChange = (id, editedValue) => {    
        if (editedValue == rowToEdit || !editedValue){
            setFormControl('')
        } else {
            setFormControl('is-valid')
        }   
        
        handleEditInput(id, editedValue, rowToEdit)
    }

    const renderInputs = () => {
        switch (id) {
          case 'is_callable':
          case 'has_price':
          case 'were_redeemed':
          case 'is_multiplicative_amortization':
            return( 
            <p style={{fontWeight: 'bold'}}> 
                <select 
                    className={`form-control ${formControl}`}
                    name={id}
                    defaultValue={rowToEdit}
                    onChange={(e) => onInputChange(id, e.target.value)}
                    >
                        <option key="false">false</option>
                        <option key="true">true</option>
                </select>
            </p>
            )
          case 'CVM Register Date':
          case 'Amortization - Grace':
          case 'Issue Date':
          case 'Maturity Date': 
          case 'Exit Date': 
          case 'Yield Start Date':
          case 'Interest New Criteria- Grace':
          case 'Distribution Start Date':
          case 'Current Nominal Value Reference Date':
            return(
            <p style={{fontWeight: 'bold'}}> 
                <InputMask className={`form-control ${formControl}`}
                    name={id}
                    defaultValue={moment(rowToEdit).utc(false).format("DD/MM/YYYY")}
                    mask="99/99/9999"
                    autoComplete="off"
                    onChange={(e) => onInputChange(id, e.target.value)}/> 
            </p>
            )
         case 'Index Multiplier':
         case 'Interest New Criteria- Rate':
         case 'Interest New Criteria- Term':
         case 'Nominal Value':
         case 'Amortization - Rate':
         case 'Amortization - Every':
         case 'Quantity Issued':
         case 'Quantity in Market':
         case 'Quantity in Treasury':   
         case 'Quantity Redeemed':
         case 'Quantity Canceled':
         case 'SND Converted Quantity':
         case 'SND Swapped Quantity':
         case 'Not SND Swapped Quantity':
            return (
                <p style={{fontWeight: 'bold'}}> 
                    <input className={`form-control ${formControl}`}
                        type="number" 
                        step="0.01"
                        name={id}
                        placeholder={rowToEdit}
                        defaultValue={rowToEdit}
                        autoComplete="off"
                        onChange={(e) => onInputChange(id, e.target.value)}/> 
                </p>
                )
          default:
            return (
            <p style={{fontWeight: 'bold'}}> 
                <input className={`form-control ${formControl}`}
                    name={id}
                    placeholder={rowToEdit}
                    defaultValue={rowToEdit}
                    autoComplete="off"
                    onChange={(e) => onInputChange(id, e.target.value)}/> 
            </p>
            )
        }
      }
    
    return (
        <div className="col-md-4">
            {editMode ?
            renderInputs()
            :
            <p style={{fontWeight: 'bold'}}>
                {rowToEdit}
            </p>
            }
        </div>
    );
};

export default DebenturesInfoEditInput;
