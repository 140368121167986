const INITIAL_STATE = {
  user_id: "",
  user_name: "",
  user_email: "",
};

function userInfos(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "USERINFO":
      return {
        user_id: action.id,
        user_name: action.name,
        user_email: action.email,
      };
    default:
      return state;
  }
}

export default userInfos;
