import React, { useState } from "react";

import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import DatePicker from "react-datepicker";

import "./style.css";


const Menu = ({ getDebBondsQaData, debBondsFinalQaDataApi, emergencyButton, startStepFunction }) => {
    const [filters, setFilters] = useState({ date: new Date(), is_preview: "True" });
    
    const handleFilters = (name, value) => {
        setFilters(
            filter => ({
                ...filter,
                [name]: value,
            })
        );
    }
    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };
    
    return (
        <div className="col-md-12 d-flex justify-content-center">
            <div className="row pricingDFA_menu">
                { emergencyButton ? 
                <div className="col-12">
                    <button 
                        className='btn dfa-button float-end'
                        onClick={() => startStepFunction(moment(filters.date).format("YYYY-MM-DD"), filters.is_preview)}>
                        Re-processar Dados
                    </button>
                </div> : null
                }
                <div className="card col-12">
                    <div className="date-filter-input text-center">
                        <label>
                            <span className="fw-bolder">Selecione a data:</span>
                        </label>  
                        <div className="mb-3 mt-2">
                            <DatePicker
                                className="dfa-form-control"
                                dateFormat="dd/MM/yyyy"
                                locale={ptBR}
                                selected={filters.date}
                                name="date"
                                onChange={(value) => handleFilters("date", value)}
                                maxDate={new Date()}
                                filterDate={isWeekday}
                            />
                        </div>  
                        <label>
                            <span className="fw-bolder">Prévia ? </span>
                        </label>  
                        <div className="mb-3 mt-2">
                            <select 
                                className="dfa-form-control mx-auto"
                                id="instrument-select"
                                name="is_preview"
                                onChange={(e) => handleFilters(e.target.name, e.target.value)}
                                value={filters.is_preview}
                                >
                                 <option name="is_preview" value={"True"}>Sim</option>
                                 <option name="is_preview" value={"False"}>Não</option>
                            </select>
                        </div> 
                        <div className="btn-group">
                            <div className="text-center">
                                <button 
                                    className='btn btn-primary me-3'
                                    onClick={() => debBondsFinalQaDataApi(filters)}>
                                    Consultar Dados
                                </button>
                            </div> 
                            <div className="text-center">
                                <button 
                                    className='btn dfa-button'
                                    onClick={() => getDebBondsQaData(filters)}>
                                    Iniciar Processo
                                </button>
                            </div> 
                        </div>      
                    </div>
                </div>
            </div>
        </div>
        );
    };

export default Menu;