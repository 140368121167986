import { subDays } from "date-fns"

const holidaysStringList = [
  "1991-01-01",
  "1991-03-11",
  "1991-03-12",
  "1991-04-21",
  "1991-04-25",
  "1991-04-26",
  "1991-05-01",
  "1991-06-27",
  "1991-09-07",
  "1991-10-12",
  "1991-11-02",
  "1991-11-15",
  "1991-12-25",
  "1992-01-01",
  "1992-03-02",
  "1992-03-03",
  "1992-04-16",
  "1992-04-17",
  "1992-04-21",
  "1992-05-01",
  "1992-06-18",
  "1992-09-07",
  "1992-10-12",
  "1992-11-02",
  "1992-11-15",
  "1992-12-25",
  "1993-01-01",
  "1993-02-22",
  "1993-02-23",
  "1993-04-08",
  "1993-04-09",
  "1993-04-21",
  "1993-05-01",
  "1993-06-10",
  "1993-09-07",
  "1993-10-12",
  "1993-11-02",
  "1993-11-15",
  "1993-12-25",
  "1994-01-01",
  "1994-02-14",
  "1994-02-15",
  "1994-03-31",
  "1994-04-01",
  "1994-04-21",
  "1994-05-01",
  "1994-06-02",
  "1994-07-01",
  "1994-09-07",
  "1994-10-03",
  "1994-10-12",
  "1994-11-02",
  "1994-11-15",
  "1994-12-25",
  "1995-01-01",
  "1995-02-27",
  "1995-02-28",
  "1995-04-13",
  "1995-04-14",
  "1995-04-21",
  "1995-05-01",
  "1995-06-15",
  "1995-09-07",
  "1995-10-12",
  "1995-11-02",
  "1995-11-15",
  "1995-12-25",
  "1996-01-01",
  "1996-02-19",
  "1996-02-20",
  "1996-04-04",
  "1996-04-05",
  "1996-04-21",
  "1996-05-01",
  "1996-06-06",
  "1996-09-07",
  "1996-10-03",
  "1996-10-12",
  "1996-11-02",
  "1996-11-15",
  "1996-12-25",
  "1997-01-01",
  "1997-02-10",
  "1997-02-11",
  "1997-03-27",
  "1997-03-28",
  "1997-04-21",
  "1997-05-01",
  "1997-05-29",
  "1997-09-07",
  "1997-10-12",
  "1997-11-02",
  "1997-11-15",
  "1997-12-25",
  "1998-01-01",
  "1998-02-23",
  "1998-02-24",
  "1998-04-09",
  "1998-04-10",
  "1998-04-21",
  "1998-05-01",
  "1998-06-11",
  "1998-09-07",
  "1998-10-12",
  "1998-11-02",
  "1998-11-15",
  "1998-12-25",
  "1999-01-01",
  "1999-02-15",
  "1999-02-16",
  "1999-04-01",
  "1999-04-02",
  "1999-04-21",
  "1999-05-01",
  "1999-06-03",
  "1999-09-07",
  "1999-10-12",
  "1999-11-02",
  "1999-11-15",
  "1999-12-25",
  "2000-01-01",
  "2000-03-06",
  "2000-03-07",
  "2000-04-21",
  "2000-05-01",
  "2000-06-22",
  "2000-09-07",
  "2000-10-12",
  "2000-11-02",
  "2000-11-15",
  "2000-12-25",
  "2001-01-01",
  "2001-02-26",
  "2001-02-27",
  "2001-04-13",
  "2001-04-21",
  "2001-05-01",
  "2001-06-14",
  "2001-09-07",
  "2001-10-12",
  "2001-11-02",
  "2001-11-15",
  "2001-12-25",
  "2002-01-01",
  "2002-02-11",
  "2002-02-12",
  "2002-03-29",
  "2002-04-21",
  "2002-05-01",
  "2002-05-30",
  "2002-09-07",
  "2002-10-12",
  "2002-11-02",
  "2002-11-15",
  "2002-12-25",
  "2003-01-01",
  "2003-03-03",
  "2003-03-04",
  "2003-04-18",
  "2003-04-21",
  "2003-05-01",
  "2003-06-19",
  "2003-09-07",
  "2003-10-12",
  "2003-11-02",
  "2003-11-15",
  "2003-12-25",
  "2004-01-01",
  "2004-02-23",
  "2004-02-24",
  "2004-04-09",
  "2004-04-21",
  "2004-05-01",
  "2004-06-10",
  "2004-09-07",
  "2004-10-12",
  "2004-11-02",
  "2004-11-15",
  "2004-12-25",
  "2005-01-01",
  "2005-02-07",
  "2005-02-08",
  "2005-03-25",
  "2005-04-21",
  "2005-05-01",
  "2005-05-26",
  "2005-09-07",
  "2005-10-12",
  "2005-11-02",
  "2005-11-15",
  "2005-12-25",
  "2006-01-01",
  "2006-02-27",
  "2006-02-28",
  "2006-04-14",
  "2006-04-21",
  "2006-05-01",
  "2006-06-15",
  "2006-09-07",
  "2006-10-12",
  "2006-11-02",
  "2006-11-15",
  "2006-12-25",
  "2007-01-01",
  "2007-02-19",
  "2007-02-20",
  "2007-04-06",
  "2007-04-21",
  "2007-05-01",
  "2007-06-07",
  "2007-09-07",
  "2007-10-12",
  "2007-11-02",
  "2007-11-15",
  "2007-12-25",
  "2008-01-01",
  "2008-02-04",
  "2008-02-05",
  "2008-03-21",
  "2008-04-21",
  "2008-05-01",
  "2008-05-22",
  "2008-09-07",
  "2008-10-12",
  "2008-11-02",
  "2008-11-15",
  "2008-12-25",
  "2009-01-01",
  "2009-02-23",
  "2009-02-24",
  "2009-04-10",
  "2009-04-21",
  "2009-05-01",
  "2009-06-11",
  "2009-09-07",
  "2009-10-12",
  "2009-11-02",
  "2009-11-15",
  "2009-12-25",
  "2010-01-01",
  "2010-02-15",
  "2010-02-16",
  "2010-04-02",
  "2010-04-21",
  "2010-05-01",
  "2010-06-03",
  "2010-09-07",
  "2010-10-12",
  "2010-11-02",
  "2010-11-15",
  "2010-12-25",
  "2011-01-01",
  "2011-03-07",
  "2011-03-08",
  "2011-04-21",
  "2011-04-22",
  "2011-05-01",
  "2011-06-23",
  "2011-09-07",
  "2011-10-12",
  "2011-11-02",
  "2011-11-15",
  "2011-12-25",
  "2012-01-01",
  "2012-02-20",
  "2012-02-21",
  "2012-04-06",
  "2012-04-21",
  "2012-05-01",
  "2012-06-07",
  "2012-09-07",
  "2012-10-12",
  "2012-11-02",
  "2012-11-15",
  "2012-12-25",
  "2013-01-01",
  "2013-02-11",
  "2013-02-12",
  "2013-03-29",
  "2013-04-21",
  "2013-05-01",
  "2013-05-30",
  "2013-09-07",
  "2013-10-12",
  "2013-11-02",
  "2013-11-15",
  "2013-12-25",
  "2014-01-01",
  "2014-03-03",
  "2014-03-04",
  "2014-04-18",
  "2014-04-21",
  "2014-05-01",
  "2014-06-19",
  "2014-09-07",
  "2014-10-12",
  "2014-11-02",
  "2014-11-15",
  "2014-12-25",
  "2015-01-01",
  "2015-02-16",
  "2015-02-17",
  "2015-04-03",
  "2015-04-21",
  "2015-05-01",
  "2015-06-04",
  "2015-09-07",
  "2015-10-12",
  "2015-11-02",
  "2015-11-15",
  "2015-12-25",
  "2016-01-01",
  "2016-02-08",
  "2016-02-09",
  "2016-03-25",
  "2016-04-21",
  "2016-05-01",
  "2016-05-26",
  "2016-09-07",
  "2016-10-12",
  "2016-11-02",
  "2016-11-15",
  "2016-12-25",
  "2017-01-01",
  "2017-02-27",
  "2017-02-28",
  "2017-04-14",
  "2017-04-21",
  "2017-05-01",
  "2017-06-15",
  "2017-09-07",
  "2017-10-12",
  "2017-11-02",
  "2017-11-15",
  "2017-12-25",
  "2018-01-01",
  "2018-02-12",
  "2018-02-13",
  "2018-03-30",
  "2018-04-21",
  "2018-05-01",
  "2018-05-31",
  "2018-09-07",
  "2018-10-12",
  "2018-11-02",
  "2018-11-15",
  "2018-12-25",
  "2019-01-01",
  "2019-03-04",
  "2019-03-05",
  "2019-04-19",
  "2019-04-21",
  "2019-05-01",
  "2019-06-20",
  "2019-09-07",
  "2019-10-12",
  "2019-11-02",
  "2019-11-15",
  "2019-12-25",
  "2020-01-01",
  "2020-02-24",
  "2020-02-25",
  "2020-04-10",
  "2020-04-21",
  "2020-05-01",
  "2020-06-11",
  "2020-09-07",
  "2020-10-12",
  "2020-11-02",
  "2020-11-15",
  "2020-12-25",
  "2021-01-01",
  "2021-02-15",
  "2021-02-16",
  "2021-04-02",
  "2021-04-21",
  "2021-05-01",
  "2021-06-03",
  "2021-09-07",
  "2021-10-12",
  "2021-11-02",
  "2021-11-15",
  "2021-12-25",
  "2022-01-01",
  "2022-02-28",
  "2022-03-01",
  "2022-04-15",
  "2022-04-21",
  "2022-05-01",
  "2022-06-16",
  "2022-09-07",
  "2022-10-12",
  "2022-11-02",
  "2022-11-15",
  "2022-12-25",
  "2023-01-01",
  "2023-02-20",
  "2023-02-21",
  "2023-04-07",
  "2023-04-21",
  "2023-05-01",
  "2023-06-08",
  "2023-09-07",
  "2023-10-12",
  "2023-11-02",
  "2023-11-15",
  "2023-12-25",
  "2024-01-01",
  "2024-02-12",
  "2024-02-13",
  "2024-03-29",
  "2024-04-21",
  "2024-05-01",
  "2024-05-30",
  "2024-09-07",
  "2024-10-12",
  "2024-11-02",
  "2024-11-15",
  "2024-12-25",
  "2025-01-01",
  "2025-03-03",
  "2025-03-04",
  "2025-04-18",
  "2025-04-21",
  "2025-05-01",
  "2025-06-19",
  "2025-09-07",
  "2025-10-12",
  "2025-11-02",
  "2025-11-15",
  "2025-12-25",
  "2026-01-01",
  "2026-02-16",
  "2026-02-17",
  "2026-04-03",
  "2026-04-21",
  "2026-05-01",
  "2026-06-04",
  "2026-09-07",
  "2026-10-12",
  "2026-11-02",
  "2026-11-15",
  "2026-12-25",
  "2027-01-01",
  "2027-02-08",
  "2027-02-09",
  "2027-03-26",
  "2027-04-21",
  "2027-05-01",
  "2027-05-27",
  "2027-09-07",
  "2027-10-12",
  "2027-11-02",
  "2027-11-15",
  "2027-12-25",
  "2028-01-01",
  "2028-02-28",
  "2028-02-29",
  "2028-04-14",
  "2028-04-21",
  "2028-05-01",
  "2028-06-15",
  "2028-09-07",
  "2028-10-12",
  "2028-11-02",
  "2028-11-15",
  "2028-12-25",
  "2029-01-01",
  "2029-02-12",
  "2029-02-13",
  "2029-03-30",
  "2029-04-21",
  "2029-05-01",
  "2029-05-31",
  "2029-09-07",
  "2029-10-12",
  "2029-11-02",
  "2029-11-15",
  "2029-12-25",
  "2030-01-01",
  "2030-03-04",
  "2030-03-05",
  "2030-04-19",
  "2030-04-21",
  "2030-05-01",
  "2030-06-20",
  "2030-09-07",
  "2030-10-12",
  "2030-11-02",
  "2030-11-15",
  "2030-12-25",
  "2031-01-01",
  "2031-02-24",
  "2031-02-25",
  "2031-04-11",
  "2031-04-21",
  "2031-05-01",
  "2031-06-12",
  "2031-09-07",
  "2031-10-12",
  "2031-11-02",
  "2031-11-15",
  "2031-12-25",
  "2032-01-01",
  "2032-02-09",
  "2032-02-10",
  "2032-03-26",
  "2032-04-21",
  "2032-05-27",
  "2032-09-07",
  "2032-10-12",
  "2032-11-02",
  "2032-11-15",
  "2033-02-28",
  "2033-03-01",
  "2033-04-15",
  "2033-04-21",
  "2033-06-16",
  "2033-09-07",
  "2033-10-12",
  "2033-11-02",
  "2033-11-15",
  "2034-02-20",
  "2034-02-21",
  "2034-04-07",
  "2034-04-21",
  "2034-05-01",
  "2034-06-08",
  "2034-09-07",
  "2034-10-12",
  "2034-11-02",
  "2034-11-15",
  "2034-12-25",
  "2035-01-01",
  "2035-02-05",
  "2035-02-06",
  "2035-03-23",
  "2035-05-01",
  "2035-05-24",
  "2035-09-07",
  "2035-10-12",
  "2035-11-02",
  "2035-11-15",
  "2035-12-25",
  "2036-01-01",
  "2036-02-25",
  "2036-02-26",
  "2036-04-11",
  "2036-04-21",
  "2036-05-01",
  "2036-06-12",
  "2036-12-25",
  "2037-01-01",
  "2037-02-16",
  "2037-02-17",
  "2037-04-03",
  "2037-04-21",
  "2037-05-01",
  "2037-06-04",
  "2037-09-07",
  "2037-10-12",
  "2037-11-02",
  "2037-12-25",
  "2038-01-01",
  "2038-03-08",
  "2038-03-09",
  "2038-04-21",
  "2038-04-23",
  "2038-06-24",
  "2038-09-07",
  "2038-10-12",
  "2038-11-02",
  "2038-11-15",
  "2039-02-21",
  "2039-02-22",
  "2039-04-08",
  "2039-04-21",
  "2039-06-09",
  "2039-09-07",
  "2039-10-12",
  "2039-11-02",
  "2039-11-15",
  "2040-02-13",
  "2040-02-14",
  "2040-03-30",
  "2040-05-01",
  "2040-05-31",
  "2040-09-07",
  "2040-10-12",
  "2040-11-02",
  "2040-11-15",
  "2040-12-25",
  "2041-01-01",
  "2041-03-04",
  "2041-03-05",
  "2041-04-19",
  "2041-05-01",
  "2041-06-20",
  "2041-11-15",
  "2041-12-25",
  "2042-01-01",
  "2042-02-17",
  "2042-02-18",
  "2042-04-04",
  "2042-04-21",
  "2042-05-01",
  "2042-06-05",
  "2042-12-25",
  "2043-01-01",
  "2043-02-09",
  "2043-02-10",
  "2043-03-27",
  "2043-04-21",
  "2043-05-01",
  "2043-05-28",
  "2043-09-07",
  "2043-10-12",
  "2043-11-02",
  "2043-12-25",
  "2044-01-01",
  "2044-02-29",
  "2044-03-01",
  "2044-04-15",
  "2044-04-21",
  "2044-06-16",
  "2044-09-07",
  "2044-10-12",
  "2044-11-02",
  "2044-11-15",
  "2045-02-20",
  "2045-02-21",
  "2045-04-07",
  "2045-04-21",
  "2045-05-01",
  "2045-06-08",
  "2045-09-07",
  "2045-10-12",
  "2045-11-02",
  "2045-11-15",
  "2045-12-25",
  "2046-01-01",
  "2046-02-05",
  "2046-02-06",
  "2046-03-23",
  "2046-05-01",
  "2046-05-24",
  "2046-09-07",
  "2046-10-12",
  "2046-11-02",
  "2046-11-15",
  "2046-12-25",
  "2047-01-01",
  "2047-02-25",
  "2047-02-26",
  "2047-04-12",
  "2047-05-01",
  "2047-06-13",
  "2047-11-15",
  "2047-12-25",
  "2048-01-01",
  "2048-02-17",
  "2048-02-18",
  "2048-04-03",
  "2048-04-21",
  "2048-05-01",
  "2048-06-04",
  "2048-09-07",
  "2048-10-12",
  "2048-11-02",
  "2048-12-25",
  "2049-01-01",
  "2049-03-01",
  "2049-03-02",
  "2049-04-16",
  "2049-04-21",
  "2049-06-17",
  "2049-09-07",
  "2049-10-12",
  "2049-11-02",
  "2049-11-15",
  "2050-02-21",
  "2050-02-22",
  "2050-04-08",
  "2050-04-21",
  "2050-06-09",
  "2050-09-07",
  "2050-10-12",
  "2050-11-02",
  "2050-11-15",
  "2051-02-13",
  "2051-02-14",
  "2051-03-31",
  "2051-04-21",
  "2051-05-01",
  "2051-06-01",
  "2051-09-07",
  "2051-10-12",
  "2051-11-02",
  "2051-11-15",
  "2051-12-25",
  "2052-01-01",
  "2052-03-04",
  "2052-03-05",
  "2052-04-19",
  "2052-05-01",
  "2052-06-20",
  "2052-11-15",
  "2052-12-25",
  "2053-01-01",
  "2053-02-17",
  "2053-02-18",
  "2053-04-04",
  "2053-04-21",
  "2053-05-01",
  "2053-06-05",
  "2053-12-25",
  "2054-01-01",
  "2054-02-09",
  "2054-02-10",
  "2054-03-27",
  "2054-04-21",
  "2054-05-01",
  "2054-05-28",
  "2054-09-07",
  "2054-10-12",
  "2054-11-02",
  "2054-12-25",
  "2055-01-01",
  "2055-03-01",
  "2055-03-02",
  "2055-04-16",
  "2055-04-21",
  "2055-06-17",
  "2055-09-07",
  "2055-10-12",
  "2055-11-02",
  "2055-11-15",
  "2056-02-14",
  "2056-02-15",
  "2056-03-31",
  "2056-04-21",
  "2056-05-01",
  "2056-06-01",
  "2056-09-07",
  "2056-10-12",
  "2056-11-02",
  "2056-11-15",
  "2056-12-25",
  "2057-01-01",
  "2057-03-05",
  "2057-03-06",
  "2057-04-20",
  "2057-05-01",
  "2057-06-21",
  "2057-09-07",
  "2057-10-12",
  "2057-11-02",
  "2057-11-15",
  "2057-12-25",
  "2058-01-01",
  "2058-02-25",
  "2058-02-26",
  "2058-04-12",
  "2058-05-01",
  "2058-06-13",
  "2058-11-15",
  "2058-12-25",
  "2059-01-01",
  "2059-02-10",
  "2059-02-11",
  "2059-03-28",
  "2059-04-21",
  "2059-05-01",
  "2059-05-29",
  "2059-12-25",
  "2060-01-01",
  "2060-03-01",
  "2060-03-02",
  "2060-04-16",
  "2060-04-21",
  "2060-06-17",
  "2060-09-07",
  "2060-10-12",
  "2060-11-02",
  "2060-11-15",
  "2061-02-21",
  "2061-02-22",
  "2061-04-08",
  "2061-04-21",
  "2061-06-09",
  "2061-09-07",
  "2061-10-12",
  "2061-11-02",
  "2061-11-15",
  "2062-02-06",
  "2062-02-07",
  "2062-03-24",
  "2062-04-21",
  "2062-05-01",
  "2062-05-25",
  "2062-09-07",
  "2062-10-12",
  "2062-11-02",
  "2062-11-15",
  "2062-12-25",
  "2063-01-01",
  "2063-02-26",
  "2063-02-27",
  "2063-04-13",
  "2063-05-01",
  "2063-06-14",
  "2063-09-07",
  "2063-10-12",
  "2063-11-02",
  "2063-11-15",
  "2063-12-25",
  "2064-01-01",
  "2064-02-18",
  "2064-02-19",
  "2064-04-04",
  "2064-04-21",
  "2064-05-01",
  "2064-06-05",
  "2064-12-25",
  "2065-01-01",
  "2065-02-09",
  "2065-02-10",
  "2065-03-27",
  "2065-04-21",
  "2065-05-01",
  "2065-05-28",
  "2065-09-07",
  "2065-10-12",
  "2065-11-02",
  "2065-12-25",
  "2066-01-01",
  "2066-02-22",
  "2066-02-23",
  "2066-04-09",
  "2066-04-21",
  "2066-06-10",
  "2066-09-07",
  "2066-10-12",
  "2066-11-02",
  "2066-11-15",
  "2067-02-14",
  "2067-02-15",
  "2067-04-01",
  "2067-04-21",
  "2067-06-02",
  "2067-09-07",
  "2067-10-12",
  "2067-11-02",
  "2067-11-15",
  "2068-03-05",
  "2068-03-06",
  "2068-04-20",
  "2068-05-01",
  "2068-06-21",
  "2068-09-07",
  "2068-10-12",
  "2068-11-02",
  "2068-11-15",
  "2068-12-25",
  "2069-01-01",
  "2069-02-25",
  "2069-02-26",
  "2069-04-12",
  "2069-05-01",
  "2069-06-13",
  "2069-11-15",
  "2069-12-25",
  "2070-01-01",
  "2070-02-10",
  "2070-02-11",
  "2070-03-28",
  "2070-04-21",
  "2070-05-01",
  "2070-05-29",
  "2070-12-25",
  "2071-01-01",
  "2071-03-02",
  "2071-03-03",
  "2071-04-17",
  "2071-04-21",
  "2071-05-01",
  "2071-06-18",
  "2071-09-07",
  "2071-10-12",
  "2071-11-02",
  "2071-12-25",
  "2072-01-01",
  "2072-02-22",
  "2072-02-23",
  "2072-04-08",
  "2072-04-21",
  "2072-06-09",
  "2072-09-07",
  "2072-10-12",
  "2072-11-02",
  "2072-11-15",
  "2073-02-06",
  "2073-02-07",
  "2073-03-24",
  "2073-04-21",
  "2073-05-01",
  "2073-05-25",
  "2073-09-07",
  "2073-10-12",
  "2073-11-02",
  "2073-11-15",
  "2073-12-25",
  "2074-01-01",
  "2074-02-26",
  "2074-02-27",
  "2074-04-13",
  "2074-05-01",
  "2074-06-14",
  "2074-09-07",
  "2074-10-12",
  "2074-11-02",
  "2074-11-15",
  "2074-12-25",
  "2075-01-01",
  "2075-02-18",
  "2075-02-19",
  "2075-04-05",
  "2075-05-01",
  "2075-06-06",
  "2075-11-15",
  "2075-12-25",
  "2076-01-01",
  "2076-03-02",
  "2076-03-03",
  "2076-04-17",
  "2076-04-21",
  "2076-05-01",
  "2076-06-18",
  "2076-09-07",
  "2076-10-12",
  "2076-11-02",
  "2076-12-25",
  "2077-01-01",
  "2077-02-22",
  "2077-02-23",
  "2077-04-09",
  "2077-04-21",
  "2077-06-10",
  "2077-09-07",
  "2077-10-12",
  "2077-11-02",
  "2077-11-15",
  "2078-02-14",
  "2078-02-15",
  "2078-04-01",
  "2078-04-21",
  "2078-06-02",
  "2078-09-07",
  "2078-10-12",
  "2078-11-02",
  "2078-11-15",
  "2078-12-25",
]

export const holidaysList = holidaysStringList.map((date) => {
  const newDate = date.toLocaleString("pt-BR", { timeZone: "UTC" })
  return parseDate(newDate)
})

function parseDate(dateString) {
  const dateParts = dateString.split("-")
  const year = parseInt(dateParts[0])
  const month = parseInt(dateParts[1]) - 1
  const day = parseInt(dateParts[2])
  return new Date(year, month, day)
}

export const getNextBusinessDay = (date) => {
  let nextDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + 1)
  )

  while (
    nextDate.getUTCDay() === 0 ||
    nextDate.getUTCDay() === 6 ||
    holidaysList.some((holiday) => holiday.getTime() === nextDate.getTime())
  ) {
    nextDate = new Date(
      Date.UTC(
        nextDate.getUTCFullYear(),
        nextDate.getUTCMonth(),
        nextDate.getUTCDate() + 1
      )
    )
  }

  return nextDate
}

export const getPreviousBusinessDay = (date) => {
  const newDate = date.setHours(0, 0, 0, 0)
  let previousDate = subDays(newDate, 1)

  while (
    previousDate.getDay() === 0 ||
    previousDate.getDay() === 6 ||
    holidaysList.some((holiday) => holiday.getTime() === previousDate.getTime())
  ) {
    previousDate = subDays(previousDate, 1)
  }
  return previousDate
}
