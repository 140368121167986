import React, { useState, useEffect } from "react";
import api from '../../services/api'
import moment from 'moment'

import Form from "./components/Form"
import Panel from "./components/Panel"

const ProcessMonitor = () => {
    const [processList, setProcessList] = useState([]);
    const [autoUpdate, setAutoUpdate] = useState(false)

    const getProcessMonitorLastExecution = async (dateToFilter) => {
        var date = moment(dateToFilter).format("YYYY-MM-DD")

        try {
            await runProcessMonitor(date)

            const documents = await api.post('get_process_monitor_last_execution', {date});


            setProcessList(documents.data[0])
            setAutoUpdate(true)
        } catch (e) {
            console.log(e);
        }
    }

    const runProcessMonitor = async (dateToFilter) => {
        var date = moment(dateToFilter).format("YYYY-MM-DD")

        try {
           await api.post('runProcessMonitor', {date});
        } catch (e) {
            console.log(e);
        }
    }
    
    useEffect(() => {
        if (autoUpdate){
            const interval = setInterval(() => {
                getProcessMonitorLastExecution()
            }, 600000);

            return () => clearInterval(interval);
        } 
      }, [autoUpdate])

    return ( 
        <>
        <div className="col-md-12 d-flex justify-content-center">
            <div className="row pricingDFA_menu">
                <Form getProcessMonitorLastExecution={getProcessMonitorLastExecution}/>
            </div>
        </div>
        { processList?.date ?
            <Panel 
                processList={processList}/>
        : 
            null
        }
        </>
            
    );
};

export default ProcessMonitor;
