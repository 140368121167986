import { getToken } from "../auth/credentials";

export const useFetch = async (url, options) => {
  const response = await fetch(url, {
    ...options,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  const data = await response.json();
  return data;
};
