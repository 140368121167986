import { transformStringDatePTBRToFormatMMDDYYYY } from "./date";

export const sortAgendaEvents = events => {
  const [header, ...rest] = events;
  const sortEvents = rest.sort((a, b) => {
    return (
      transformStringDatePTBRToFormatMMDDYYYY(a[0]).getTime() -
      transformStringDatePTBRToFormatMMDDYYYY(b[0]).getTime()
    );
  });

  const orderedEvents = [header, ...sortEvents];

  return orderedEvents;
};
