import React, { useState, useEffect } from "react";
import "react-tooltip/dist/react-tooltip.css";
import api from "../../services/api";

import { Tooltip } from 'react-tooltip'
import InputMask from 'react-input-mask';
import Swal from "sweetalert2";

import "./style.css";

const ProcessMonitorRegister = () => {
    const [categoriesList, setCategoriesList] = useState([])
    const [datesFormatList, setDatesFormatList] = useState([])
    const [isFocus, setIsFocus] = useState({
        categoryFocus: false,
        dateFormatFocus: false,
    });
    const [formInput, setFormInput] = useState(
        {
            "process_name": "",
            "source": "",
            "category": "",
            "bucket": "",
            "bucket_file_prefix": "",
            "bucket_file_date": "",
            "date_format": "",
            "where_to_replace": "",
            "file_arrival": "",
            "deadline": "",
            "db_collection": "",
            "db_date_name": "",
            "is_closure": null
        }
    );

    useEffect(() => {
        const getCategories = async () => {
            try {
                const { data } = await api.get("/getProcessMonitorCategories");
              
                setCategoriesList(data);
            } catch (e) {
                console.log(e);
            }
          };

        const getDatesFormat = async () => {
            try {
                const { data } = await api.get("/getProcessMonitorDateFormatList");
            
                setDatesFormatList(data)
              } catch (e) {
                console.log(e);
              }
        }
          
          getCategories().then();
          getDatesFormat().then();
    }, []);


    const handleOnChange = (name, value) => {
        

        if (name === "source"){
            value = value.toUpperCase()
        }

        if (name === "is_closure"){
            switch (value) {
                case 'Sim':
                    value = true
                    break;
                case 'Não':
                    value = false
                    break;
                case 'Nulo':
                    value = null
                    break;
                default:
                    value = null
                    break;
            }
        }

        if (name === "where_to_replace"){
            var find = ["day", "DAY", "month", "MONTH", "STRMON", "year", "YEAR"];
            var replace = ["%-d", "%d", "%-m", "%m", "%b", "%y", "%Y"];
            var regex_value = value.replaceArray(find, replace);

            setFormInput(prevState => ({
                ...prevState,
                "date_format": regex_value,
            }))
        }

        setFormInput(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }

    const onSubmit = async () => {
        var newProcess = JSON.stringify(formInput)

        try {
            var response = await api.post('createProcessToMonitor', newProcess);

            console.log(response)

            if (response) {
                Swal.fire({
                  icon: 'success',
                  title: 'Processo Cadastrado com Sucesso',
                  showConfirmButton: true,
                })
            }

            setFormInput(prevState => ({
                ...prevState,
                "process_name": "",
                "source": "",
                "category": "",
                "bucket": "",
                "bucket_file_prefix": "",
                "bucket_file_date": "",
                "date_format": "",
                "where_to_replace": "",
                "file_arrival": "",
                "deadline": "",
                "db_collection": "",
                "db_date_name": "",
                "is_closure": null
            }))
        } catch (e) {
            console.log(e)
        }


    }

    String.prototype.replaceArray = function(find, replace) {
        var replaceString = this;
        var regex; 
        for (var i = 0; i < find.length; i++) {
          regex = new RegExp(find[i], "g");
          replaceString = replaceString.replace(regex, replace[i]);
        }
        return replaceString;
      };

    const inputList = ['process_name', 'source', 'category', 'bucket', 'bucket_file_prefix', 'bucket_file_date', 'where_to_replace',
                        'date_format', 'file_arrival', 'deadline', 'db_collection', 'db_date_name', 'is_closure']

    const translateInputs = (inputName) => {
        switch (inputName) {
            case 'process_name':
              return {
                  name:'Nome do Processo', 
                  placeholder: "Nome do processo (Livre)"
                };
            case 'source':
              return {
                  name: 'Fonte', 
                  placeholder: "Exatamente como no banco de dados (Mongo)"
                };
            case 'category':
                return {
                    name: 'Categoria', 
                    placeholder: "Exemplo: debenture_calls, trades, public_bonds"
                };
            case 'bucket':
                return {
                    name: 'Nome do Bucket (S3)', 
                    placeholder: "Exemplo: debentures-calls, negocios-b3, titulos-publicos"
                };
            case 'bucket_file_prefix':
                return {
                    name: 'Diretório onde o arquivo está (S3)', 
                    placeholder: "Exemplo: Anbima/YEAR/MONTH/"
                };
            case 'bucket_file_date':
                return {
                    name: 'Formato de data no nome do arquivo (S3)', 
                    placeholder: "Nome do arquivo + substituir data pelas expressões"
                };
            case 'date_format':
                return {
                    name: 'Formato da data em forma de Regex', 
                    placeholder: "O robozinho preenche."
                };
            case 'file_arrival':
                return {
                    name:'Horário de chegada do arquivo (Eventbridge)', 
                    placeholder: "", 
                    mask:  "99:99:99.000000", 
                    maskChar: "0",
                    permanents: [10, 11, 12, 13, 14 ,15],
                    alwaysShowMask: true
                };
            case 'deadline':
                return {
                    name: 'Horário final para arquivo ser considerado "MUITO ATRASADO"', 
                    placeholder: "",
                    mask: "99:99:99.000000", 
                    maskChar: "0",
                    permanents: [10, 11, 12, 13, 14 ,15],
                    alwaysShowMask: true
                };
            case 'where_to_replace':
                return {
                    name: 'Formato de data do arquivo que será substituída pelo algoritmo', 
                    placeholder: "Somente as expressões do campo 'Formato de data no nome do arquivo (S3)'"
                };
            case 'db_collection':
                return {
                    name: 'Nome da collection do arquivo (Mongo)', 
                    placeholder: "Olhar no mongo qual collection é guardada os dados do arquivo"
                };
            case 'db_date_name':
                return {
                    name: 'Nome da data do arquivo (Mongo)', 
                    placeholder: "Olhar no mongo qual nome do campo de data"
                };
            case 'is_closure':
                return {
                    name: 'é Fechamento?', 
                    placeholder: "Sim ou Não"
                };
            default:
                return null
          }
    }

    const showToolTip = (inputName) => {
        switch (inputName) {
            case 'process_name':
                return (
                    <p>
                        Nome do processo
                        <p>
                        Exemplos: <span className="red-highlight-text">Call de Debênture da Anbima
                        </span>, &nbsp; 
                        <span className="red-highlight-text">
                            Call de Debênture da Renascença
                        </span>, etc. 
                        </p>
                    </p>
                )
            case 'source':
                return (
                    <p>
                        Fonte
                        <p>
                        Exemplos: <span className="red-highlight-text">
                            ANBIMA
                        </span>, &nbsp; 
                        <span className="red-highlight-text">
                            RENASCENÇA
                        </span>, etc. 
                        </p>
                    </p>
                )
            case 'category':
                return (
                    <p>
                        Categoria
                        <p>
                        Exemplos: <span className="red-highlight-text">
                            debentures_calls
                        </span>, &nbsp; 
                        <span className="red-highlight-text">
                            trades
                        </span>, etc. 
                        </p>

                        Não é restrito, use o mesmo nome de categoria para outros processos da mesma categoria.
                    </p>
                )
            case 'bucket':
                return (
                    <p>
                            Bucket
                            <p>
                            Exemplos: <span className="red-highlight-text">
                                debentures-calls
                            </span>, &nbsp; 
                            <span className="red-highlight-text">
                                negocios-b3
                            </span>, &nbsp;
                            <span className="red-highlight-text">
                                titulos-publicos
                            </span>, etc. 
                            </p>
                            Diferente do campo Categoria, por padrão os nomes composto do bucket são separado por "-" em vez de "_"
                    </p>
                )
            case 'bucket_file_prefix':
                return (
                    <p>
                            Diretório onde o arquivo está (S3)
                            <p>
                            Exemplos: <span className="red-highlight-text">
                                Anbima/YEAR/MONTH/
                            </span>, &nbsp; 
                            <span className="red-highlight-text">
                                Renascença/YEAR/month/
                            </span>, &nbsp;
                            <span className="red-highlight-text">
                                XP/YEAR/MONTH/CALL/Primeira Call/
                            </span>, etc. 
                            </p>
                            O algoritmo substitui algumas expressões de acordo com o que foi parametrizado na tela de relatórios, segue a lista abaixo.
                            Lista de Expressões: <p>
                                <span className="red-highlight-text">
                                    YEAR
                                </span>
                                &nbsp; Será substituído pelo ANO que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 4</span> dígitos.
                            </p>
                            <p>
                                <span className="red-highlight-text">
                                    MONTH
                                </span>
                                &nbsp; Será substituído pelo MÊS que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 2</span> dígitos.
                            </p>
                            <p>
                                <span className="red-highlight-text">
                                    month
                                </span>
                                &nbsp; Será substituído pelo MÊS que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 1</span> dígito.
                            </p>

                            Atentar-se a quantidade de digitos do MÊS no bucket do arquivo!
                    </p>
                )
            case 'bucket_file_date':
                return (
                    <p>
                            Formato de data no nome do arquivo (S3)
                            <p>
                            Exemplos: <br/>
                            <span className="red-highlight-text">
                                dYEARSTRMONDAY
                            </span> <br/> 
                            <span className="red-highlight-text">
                                Renascença DTVM: Call Debêntures DAY_MONTH_YEAR_15:00
                            </span> <br/>
                            <span className="red-highlight-text">
                                Debenture-CALL-YEAR-MONTH-DAY_10h
                            </span>. 
                            </p>
                            

                            Mesma regra do "Diretório onde o arquivo está (S3)", porém no NOME DO ARQUIVO.
                            <br/>
                            Lista de Expressões: 
                            <p>
                                <span className="red-highlight-text">
                                    year
                                </span>
                                &nbsp; Será substituído pelo ANO que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 2</span> dígitos.
                            </p>
                            <p>
                                <span className="red-highlight-text">
                                    YEAR
                                </span>
                                &nbsp; Será substituído pelo ANO que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 4</span> dígitos.
                            </p>
                            <p>
                                <span className="red-highlight-text">
                                    MONTH
                                </span>
                                &nbsp; Será substituído pelo MÊS que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 2</span> dígitos.
                            </p>
                            <p>
                                <span className="red-highlight-text">
                                    STRMON
                                </span>
                                &nbsp; Será substituído pelo MÊS que foi inputado na tela de relatórios no formato do 
                                <span className="yellow-highlight-text"> Nome do mês (jan, fev, mar...)</span>.
                            </p>
                            <p>
                                <span className="red-highlight-text">
                                    month
                                </span>
                                &nbsp; Será substituído pelo MÊS que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 1</span> dígito.
                            </p>
                            <p>
                                <span className="red-highlight-text">
                                    day
                                </span>
                                &nbsp; Será substituído pelo DIA que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 1</span> dígito.
                            </p>
                            <p>
                                <span className="red-highlight-text">
                                    DAY
                                </span>
                                &nbsp; Será substituído pelo DIA que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 2</span> dígitos.
                            </p>
                    </p>
                )
            case 'date_format':
                return 'Formato da data em forma de Regex'
            case 'file_arrival':
                return (
                    <p> Horário de chegada do arquivo (Eventbridge)
                        <p>
                            No formato: <span className="red-highlight-text"> HH:mm:ss.SSSSSS </span>
                        </p>
                    </p>
                );
            case 'deadline':
                return (
                    <p> Horário final para arquivo ser considerado "MUITO ATRASADO"
                        <p>
                            No formato: <span className="red-highlight-text"> HH:mm:ss.SSSSSS </span>
                        </p>
                    </p>
                );
            case 'where_to_replace':
                return ( 
                <p>Formato de data do arquivo que será substituída pelo algoritmo. <br/>
                    Dica: Copia e cola do Formato de data no nome do arquivo (S3). <br/>
                    Lista de Expressões: 
                            <p>
                                <span className="red-highlight-text">
                                    year
                                </span>
                                &nbsp; Será substituído pelo ANO que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 2</span> dígitos.
                            </p>
                            <p>
                                <span className="red-highlight-text">
                                    YEAR
                                </span>
                                &nbsp; Será substituído pelo ANO que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 4</span> dígitos.
                            </p>
                            <p>
                                <span className="red-highlight-text">
                                    MONTH
                                </span>
                                &nbsp; Será substituído pelo MÊS que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 2</span> dígitos.
                            </p>
                            <p>
                                <span className="red-highlight-text">
                                    STRMON
                                </span>
                                &nbsp; Será substituído pelo MÊS que foi inputado na tela de relatórios no formato do 
                                <span className="yellow-highlight-text"> Nome do mês (jan, fev, mar...)</span>.
                            </p>
                            <p>
                                <span className="red-highlight-text">
                                    month
                                </span>
                                &nbsp; Será substituído pelo MÊS que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 1</span> dígito.
                            </p>
                            <p>
                                <span className="red-highlight-text">
                                    day
                                </span>
                                &nbsp; Será substituído pelo DIA que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 1</span> dígito.
                            </p>
                            <p>
                                <span className="red-highlight-text">
                                    DAY
                                </span>
                                &nbsp; Será substituído pelo DIA que foi inputado na tela de relatórios no formato de 
                                <span className="yellow-highlight-text"> 2</span> dígitos.
                            </p>
                    </p>
                )
            case 'db_collection':
                return ( 
                    <p> Nome da collection do arquivo (Mongo) <br/>
                        Exemplos: <span className="red-highlight-text">
                                debentures_calls
                            </span>, &nbsp; 
                            <span className="red-highlight-text">
                                trades
                            </span>, &nbsp;
                            <span className="red-highlight-text">
                                governament_bond_calls
                            </span>, etc.
                    </p>
                )
            case 'db_date_name':
                return ( 
                    <p> Nome da data do arquivo (Mongo) <br/>
                        Exemplos: <span className="red-highlight-text">
                                callDate
                            </span>, &nbsp; 
                            <span className="red-highlight-text">
                                tradeDateTime
                            </span>, &nbsp;
                            <span className="red-highlight-text">
                                fileDate
                            </span>, etc.
                    </p>
                )
            case 'is_closure':
                return 'é Fechamento?';
            default:
                return null
        }
    }

    const renderInput = (inputId, InputObjects) => {
        switch (inputId) {
            case 'category':
                return (
                    <>
                        <InputMask 
                            type="text" 
                            className="form-control" 
                            id={inputId} 
                            placeholder={InputObjects.placeholder}
                            mask={InputObjects.mask}
                            maskChar={InputObjects.maskChar}
                            permanents={InputObjects.permanents}
                            alwaysShowMask={InputObjects.alwaysShowMask}
                            onFocus={() => setIsFocus(prevState => ({
                                ...prevState,
                                "categoryFocus": true,
                            }))}
                            onChange={(e) => handleOnChange(inputId, e.target.value)}
                            value={formInput[inputId]}
                        /> 
                        { isFocus.categoryFocus ?
                        <div className="scroll-show-tickers-attributes mb-2 mt-1">
                                {categoriesList && categoriesList
                                    .filter((categoryToSelect) =>
                                        categoryToSelect.toLowerCase().includes(formInput['category'].toLowerCase())
                                    )
                                    .map((categoryToSelect) => (
                                    <div key={categoryToSelect}  
                                        id="category-select" 
                                        name={categoryToSelect}
                                        onClick={() => {handleOnChange(inputId, categoryToSelect); setIsFocus(prevState => ({
                                            ...prevState,
                                            "categoryFocus": false,
                                        }))}}>
                                        <span>
                                            {categoryToSelect} 
                                        </span>                                  
                                    </div>
                                ))}
                        </div> : 
                        null }
                    </>
                )
            case 'where_to_replace':
                return (
                    <>
                        <InputMask 
                            type="text" 
                            className="form-control" 
                            id={inputId} 
                            placeholder={InputObjects.placeholder}
                            mask={InputObjects.mask}
                            maskChar={InputObjects.maskChar}
                            permanents={InputObjects.permanents}
                            alwaysShowMask={InputObjects.alwaysShowMask}
                            onFocus={() => setIsFocus(prevState => ({
                                ...prevState,
                                "dateFormatFocus": true,
                            }))}
                            onChange={(e) => handleOnChange(inputId, e.target.value)}
                            value={formInput[inputId]}
                        /> 
                        { isFocus.dateFormatFocus ?
                            <div className="scroll-show-tickers-attributes mb-2 mt-1">
                                    {datesFormatList && datesFormatList
                                        .filter((dateFormatToSelect) =>
                                            dateFormatToSelect.includes(formInput['where_to_replace'])
                                        )
                                        .map((dateFormatToSelect) => (
                                        <div key={dateFormatToSelect}  
                                            id="category-select" 
                                            name={dateFormatToSelect}
                                            onClick={() => {handleOnChange(inputId, dateFormatToSelect); setIsFocus(prevState => ({
                                                ...prevState,
                                                "dateFormatFocus": false,
                                            }))}}>
                                            <span>
                                                {dateFormatToSelect} 
                                            </span>                                  
                                        </div>
                                    ))}
                            </div> : 
                            null }
                    </>
                )
            case 'is_closure':
                return (
                    <>  
                        <select className="form-select" defaultValue={formInput[inputId]} onChange={(e) => handleOnChange(inputId, e.target.value)}>
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                            <option value="Nulo">Nulo</option>
                        </select> 
                    </>
                )
            case 'date_format':
                return (
                    <InputMask 
                        type="text" 
                        className="form-control" 
                        id={inputId} 
                        placeholder={InputObjects.placeholder}
                        mask={InputObjects.mask}
                        maskChar={InputObjects.maskChar}
                        permanents={InputObjects.permanents}
                        alwaysShowMask={InputObjects.alwaysShowMask}
                        onChange={(e) => handleOnChange(inputId, e.target.value)}
                        value={formInput[inputId]}
                        disabled={true}
                    />
                    )
            default:
                return (
                    <InputMask 
                        type="text" 
                        className="form-control" 
                        id={inputId} 
                        placeholder={InputObjects.placeholder}
                        mask={InputObjects.mask}
                        maskChar={InputObjects.maskChar}
                        permanents={InputObjects.permanents}
                        alwaysShowMask={InputObjects.alwaysShowMask}
                        onChange={(e) => handleOnChange(inputId, e.target.value)}
                        value={formInput[inputId]}
                    />
                )
          }
    }

    return ( 
        <>
            <div className="mt-5 container-xxl d-flex align-items-center justify-content-center">
            <div className="process-register-container ">
            {inputList.map(function(inputName, index) {
                    return(
                        <div key={index}>
                            <div className="mb-2">
                                <label 
                                    className="form-label">
                                        {translateInputs(inputName).name}
                                </label>
                                {renderInput(inputName, translateInputs(inputName))}
                            </div>
                            
                            <Tooltip 
                                anchorId={inputName} 
                                place="top"
                                content={showToolTip(inputName)}
                            />
                        </div>
                    )})} 
                </div>
            </div>
            <div className="text-center mt-4">
                <button 
                type="button" 
                className="btn btn-success"
                onClick={() => onSubmit()}>
                    Cadastrar Processo
                </button>
            </div>
        </>
    )
};

export default ProcessMonitorRegister;