import React from "react";
import DataTable from 'react-data-table-component';
import CallsTableValidationModal from "../../PricingDFA/components/CallsTableValidation";
import DFALineChart from "../../PricingDFA/components/DFALineChart";
import Card from 'react-bootstrap/Card'
import moment from 'moment'
import { checkIfIsNaNorNone } from "../../PricingDFA/common/checkIfIsNaNorNone";

import { customStyles } from "../../PricingDFA/common/CallsValidationColsStyles";
import "./style.css";


const CallsVisualization = ({userInfo, selectedCalls, finalResults, callsResult, isPreview}) => {
    const columnsDFAResult = [
        {
            name: 'Vencimento',
            selector: call => moment(call?.["maturity_date"]).format("DD/MM/YYYY"),
        },
        {
            name: 'Taxa D-1',
            selector: call => checkIfIsNaNorNone(call?.["previous_mid"]),
        },
        {
            name: 'Meio',
            selector: call => checkIfIsNaNorNone(call?.["mid"]),
            conditionalCellStyles: [
                {
                    when: call => (Number(call?.["mid"]) < Number(call?.["min_limit"]) ||
                                  Number(call?.["mid"]) > Number(call?.["max_limit"])) && call?.["mid"] != null,
                    style: {
                        color: 'white',
                        backgroundColor: "red",
                        fontWeight: 'bold',
                    }
                },
        ],
        },
        {
            name: 'Spread',
            selector: call => checkIfIsNaNorNone(callsResult?.[call["maturity_date"]]?.['callsSpread']),
            conditionalCellStyles: [
                {
                    when: call => Number(callsResult?.[call["maturity_date"]]?.['callsSpread']) < 0,
                    style: {
                        color: 'red',
                        fontWeight: 'bold',
                    }
                },
            ],
        },
        {
            name: 'Limite Inferior',
            selector: call => checkIfIsNaNorNone(call?.["min_limit"]),
        },
        {
            name: 'Limite Superior',
            selector: call => checkIfIsNaNorNone(call?.["max_limit"]),
        },
    ]

    const rowsWithEstimatedMidValues = [
        {
            when: call => call["is_estimated"] === true ,
            style: {
                color: 'white',
                backgroundColor: "#04AA6D",
                fontWeight: 'bold',
            }
        },
    ];

    const SelectedCallsByMaturity = (maturityDateCall) => {
        const {data} = maturityDateCall;

        return(
        <div className="expandedDebentureRow_Container">
            <div className="col-12 pricingDFA_calls_group">
                {selectedCalls.hasOwnProperty(data.maturity_date) ?
                    <CallsTableValidationModal
                        selectedCalls={selectedCalls[data.maturity_date]}
                        callsResult={callsResult}
                    />
                : <span 
                    className="text-center"> 
                    Aparentemente essa data de vencimento não possui calls na primeira etapa...  
                </span> }
            </div>
        </div>
        )
    }

    const checkIfisPreview = () => {
        return (
            <div>
            {isPreview ?
                <span className="fw-bold">
                    Prévia: &nbsp;  
                    <span className="text-success fw-bold"> 
                        Sim 
                    </span>
                </span> 
                : 
                <span className="fw-bold">
                    Prévia: &nbsp; 
                    <span className="text-danger fw-bold"> 
                        Não
                    </span>
                </span> 
            }
            </div>
        )
    }

    return (
        <Card>
            <Card.Header className="text-center fs-5">
                <p>Salvo por: <span className="fw-bold text-success">
                    {userInfo.user_info.email}
                    </span>
                </p> 
                <p>No dia:
                    <span className="fw-bold text-success">
                    &nbsp; {moment(userInfo.saved_at).format('DD/MM/YYYY')} &nbsp;
                    </span>
                    às
                    <span className="fw-bold text-success">
                    &nbsp; {moment(userInfo.saved_at).utc(false).format('HH:mm:ss')} &nbsp;
                    </span>
                </p>
            </Card.Header>
            <Card.Body>
                <DataTable   
                    title={" Resultados das Estimativas (DFA)"}
                    customStyles={customStyles}
                    columns={columnsDFAResult}
                    data={finalResults}
                    highlightOnHover
                    expandableRows
                    expandableRowsComponent={SelectedCallsByMaturity}
                    conditionalRowStyles={rowsWithEstimatedMidValues}
                    subHeader={true}
                    subHeaderComponent={checkIfisPreview()}
                />
            </Card.Body>
            <Card.Body>
                <DFALineChart
                    title={"Resultados + Estimados"}
                    dataToChart={finalResults}
                    showResults={true}/>
            </Card.Body>
        </Card>
    );
};

export default CallsVisualization;