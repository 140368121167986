import React, { useState } from "react";
import moment, { utc } from 'moment'

import Modal from 'react-bootstrap/Modal'
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

import "./style.css";


const ProcessDetails = (props) => {
    const { processList } = props 

    const columns = [
        {
            name: 'Fonte',
            selector: process => process["source"],
            compact: true,
        },
        {
            name: 'Nome do Processo',
            selector: process => process["process_name"],
            compact: true,
        },
        {
            name: 'Carregado no S3 ?',
            selector: process => process["is_document_on_S3"] ? 
                <FontAwesomeIcon icon={faCheck} style={{color: "#57A83D"}}/> : 
                <FontAwesomeIcon icon={faTimes} style={{color: "#FF0000"}}/>,
            compact: true,
            center: true,
        },
        {
            name: 'Carregado no Banco de Dados ?',
            selector: process => process["is_document_readed"] ? 
                <FontAwesomeIcon icon={faCheck} style={{color: "#57A83D"}}/> : 
                <FontAwesomeIcon icon={faTimes} style={{color: "#FF0000"}}/>,
            center: true,
        },
        {
            name: 'Previsão Mínima',
            selector: process => moment(process["file_min_arrival"]).utc(false).format("HH:mm:ss"),
            sortable: true,
        },
        {
            name: 'Previsão Máxima',
            selector: process => moment(process["file_max_arrival"]).utc(false).format("HH:mm:ss"),
            sortable: true, 
        }
    ]


    const highlightLateFiles = [
        {   
            when: process => process["is_document_on_S3"] !== true && 
            moment().isAfter(moment(process["file_min_arrival"]).add(3, 'hours')),
            style: {
                color: 'white',
                backgroundColor: "#E5C510",
                fontWeight: 'bold',
            }
        },
        {   
            when: process => process["is_document_on_S3"] !== true && 
            moment().isAfter(moment(process["file_max_arrival"]).add(3, 'hours')),
            style: {
                color: 'white',
                backgroundColor: "#FF1F00",
                fontWeight: 'bold',
            }
        },
    ];

    return (
        <div className="container-md">
            <Modal
            show={props.show}
            onHide={props.onHide}
            centered="true"
            dialogClassName="dfa-modal-xl"
            keyboard={ false }
            >
            <Modal.Header>
                Detalhes 
            </Modal.Header>
            <Modal.Header>
                <div id="calls-modal" className="col-md-11 d-flex justify-content-center">
                    <span 
                        className='row-color-indicator indicator-late me-2'>
                        &nbsp;&nbsp;&nbsp;&nbsp; 
                    </span> 
                    <span> Atrasado</span>
                    <span 
                        className='ms-5 row-color-indicator indicator-very-late me-2'>
                        &nbsp;&nbsp;&nbsp;&nbsp; 
                    </span> 
                    <span> Muito Atrasado</span>
                </div>
            </Modal.Header>
            <Modal.Body id="DFA_table">
                <DataTable   
                    columns={columns}
                    data={processList}
                    defaultSortFieldId={5}
                    conditionalRowStyles={highlightLateFiles}
                />
            </Modal.Body>
        </Modal>
        </div>
        );
    };

export default ProcessDetails;