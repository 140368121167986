import React, { useState } from "react";

const TickerBox = ({
  debenturesCodes,
  ticker,
  setTicker,
  getAgenda,
  isLoading,
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const updateSearchCode = b3_code => {
    setTicker(b3_code);
  };

  return (
    <div className="box__ticker">
      <label>
        <strong>Ticker</strong>
      </label>
      <input
        type="text"
        onChange={e => setTicker(e.target.value.toUpperCase())}
        value={ticker}
        placeholder="Pesquise por um ticker"
        onFocus={() => setIsFocus(true)}
        onBlur={() => setTimeout(() => setIsFocus(false), 200)}
      />
      <button
        className="search__btn btn--hover margin-top-sm"
        onClick={getAgenda}
        disabled={isLoading}
      >
        Exibir agenda
      </button>
      <div className="mb-2 mt-1 box__scroll">
        {isFocus &&
          debenturesCodes
            .filter(code => code.toLowerCase().includes(ticker.toLowerCase()))
            .map(code => (
              <div
                className="scroll__item"
                key={code}
                onClick={() => updateSearchCode(code)}
              >
                <span>{code}</span>
              </div>
            ))}
      </div>
    </div>
  );
};

export default React.memo(TickerBox);
