import React from "react"
import { NavLink } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { limpaCache } from "../../common/cleanCache"
import { logoutAction } from "../../actions/authAction"
import Dropdown from "react-bootstrap/Dropdown"

import "./styles.css"

function NavBar() {
  const dispatch = useDispatch()

  const { user_admin, user_role } = useSelector(
    (state) => state.auth.credentials
  )

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <NavLink to="/" className="navbar-brand" onClick={() => limpaCache()}>
          FIA Internal
        </NavLink>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ">
            <li className="nav-item">
              <NavLink
                to="/password"
                className="nav-link"
                activeClassName="current"
              >
                Alterar senha
              </NavLink>
            </li>
            <Dropdown>
              <Dropdown.Toggle className="pricing-dropdown">
                Monitoramento de Processos
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <NavLink
                  to="/process_monitor_register"
                  className="nav-link"
                  activeClassName="current"
                >
                  Cadastrar Processo
                </NavLink>
                <NavLink
                  to="/process_monitor"
                  className="nav-link"
                  activeClassName="current"
                >
                  Ver Relatórios
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
            {user_role === "master" ? (
              <li>
                <NavLink
                  to="/manage_users"
                  className="nav-link"
                  activeClassName="current"
                >
                  Gerenciar Usuários
                </NavLink>
              </li>
            ) : null}

            {user_admin ? (
              <li>
                <NavLink
                  to="/netquant_user"
                  className="nav-link"
                  activeClassName="current"
                >
                  Usuário NetQuant
                </NavLink>
              </li>
            ) : null}

            {user_role === "master" ? (
              <li>
                <NavLink
                  to="/user_master"
                  className="nav-link"
                  activeClassName="current"
                >
                  Criar usuário master
                </NavLink>
              </li>
            ) : null}
            <li>
              <NavLink
                to="/newsletter-dashboard"
                className="nav-link"
                activeClassName="current"
              >
                Newsletter Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/comparative_yields"
                className="nav-link"
                activeClassName="current"
              >
                Taxas Comparativas
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/fia_reports"
                className="nav-link"
                activeClassName="current"
              >
                Fia Reports
              </NavLink>
            </li>
            <Dropdown>
              <Dropdown.Toggle className="pricing-dropdown">
                Editar atributos
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <NavLink
                  to="/debenture_attributes"
                  className="nav-link"
                  activeClassName="current"
                >
                  Debêntures
                </NavLink>
                <NavLink
                  to="/cri_cra_attributes"
                  className="nav-link"
                  activeClassName="current"
                >
                  CRI/CRA
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
            <li>
              <NavLink
                to="/show_attributes"
                className="nav-link"
                activeClassName="current"
              >
                Atributos
              </NavLink>
            </li>
            <Dropdown>
              <Dropdown.Toggle className="pricing-dropdown">
                Editar agenda
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <NavLink
                  to="/edit_agenda_fi_analytics"
                  className="nav-link"
                  activeClassName="current"
                >
                  Editar agenda Debentures
                </NavLink>
                <NavLink
                  to="/edit_cri_cra_agenda"
                  className="nav-link"
                  activeClassName="current"
                >
                  Editar agenda CRI/CRA
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>

            <li>
              <NavLink
                to="/pricing_QA"
                className="nav-link"
                activeClassName="current"
              >
                Pricing Deb QA
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/cri_cra_qa"
                className="nav-link"
                activeClassName="current"
              >
                CRI CRA QA
              </NavLink>
            </li>
            <Dropdown>
              <Dropdown.Toggle className="pricing-dropdown">
                Pricing Gov
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <NavLink
                  to="/pricing_DFA"
                  className="nav-link"
                  activeClassName="current"
                >
                  DFA
                </NavLink>
                <NavLink
                  to="/pricing_gov_QA"
                  className="nav-link"
                  activeClassName="current"
                >
                  QA
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
            <li>
              <NavLink
                to="/query_DFA"
                className="nav-link"
                activeClassName="current"
              >
                Consulta DFA
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/cadastro_debentures"
                className="nav-link"
                activeClassName="current"
              >
                Cad. de Debentures
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/call_qa"
                className="nav-link"
                activeClassName="current"
              >
                CallQA
              </NavLink>
            </li>
            <Dropdown>
              <Dropdown.Toggle className="pricing-dropdown">
                Deb. Bonds
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <NavLink
                  to="/debentures_and_trades_preview"
                  className="nav-link"
                  activeClassName="current"
                >
                  DFA
                </NavLink>
                <NavLink
                  to="/deb_bonds_qa"
                  className="nav-link"
                  activeClassName="current"
                >
                  QA
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
            {/* <li>
              <NavLink
                to="/debentures_and_trades_preview"
                className="nav-link"
                activeClassName="current"
              >
                Deb. & Trades Preview
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/cri_cra_dfa"
                className="nav-link"
                activeClassName="current"
              >
                CRI CRA DFA
              </NavLink>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => dispatch(logoutAction())}
              >
                Sair
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default NavBar
