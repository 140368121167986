import { getToken } from "../auth/credentials";
import api from "../services/api";

export const limpaCache = async () => {
    const userToken = getToken();
  
    if(userToken){
      const hasToken = true;
      try {
        await api.get('cleanCacheCriCraQA',{hasToken}).then(
          console.log('Cache limpo com sucesso!')
        )
      } catch(erro) {
        console.log(erro);
        console.log('Falha ao Limpar Cache, verifique o console');
      }
    } 
  }

export const limpaCache2 = async () => {
    const userToken = getToken();
  
    if(userToken){
      const hasToken = true;
      try {
        await api.get('clean_internal_elastic_cache',{hasToken}).then(
          console.log('Cache limpo com sucesso!')
        )
      } catch(erro) {
        console.log(erro);
        console.log('Falha ao Limpar Cache, verifique o console');
      }
    } 
  }