export const debenturesInfoTemplate = {
  deeds: [],
  changes: [],
  "B3 Code": [{ version: 1, value: "" }],
  Company: [{ version: 1, value: "" }],
  Serie: [{ version: 1, value: "" }],
  Issue: [{ version: 1, value: "" }],
  Status: [{ version: 1, value: "" }],
  ISIN: [{ version: 1, value: "" }],
  "CVM Register Type": [{ version: 1, value: "" }],
  "CVM Register Date": [{ version: 1, value: "dd/mm/aaaa" }],
  "Issue Date": [{ version: 1, value: "dd/mm/aaaa" }],
  "Maturity Date": [{ version: 1, value: "dd/mm/aaaa" }],
  "Exit Reason": [{ version: 1, value: null }],
  "Exit Date": [{ version: 1, value: "dd/mm/aaaa" }],
  "Yield Start Date": [{ version: 1, value: "dd/mm/aaaa" }],
  "Distribution Start Date": [{ version: 1, value: "dd/mm/aaaa" }],
  "Repactuation Date": [{ version: 1, value: "-" }],
  Collateral: [{ version: 1, value: "" }],
  Class: [{ version: 1, value: "" }],
  "Quantity Issued": [{ version: 1, value: "" }],
  "Article 14": [{ version: 1, value: null }],
  "Article 24": [{ version: 1, value: null }],
  "Quantity in Market": [{ version: 1, value: "" }],
  "Quantity in Treasury": [{ version: 1, value: "" }],
  "Quantity Redeemed": [{ version: 1, value: "" }],
  "Quantity Canceled": [{ version: 1, value: null }],
  "SND Converted Quantity": [{ version: 1, value: null }],
  "SND Swapped Quantity": [{ version: 1, value: null }],
  "Not SND Swapped Quantity": [{ version: 1, value: "" }],
  "Currency Unit 1": [{ version: 1, value: "R$" }],
  "Nominal Value": [{ version: 1, value: "" }],
  "Currency Unit 2": [{ version: 1, value: "R$" }],
  "Current Nominal Value": [{ version: 1, value: "" }],
  "Current Nominal Value Reference Date": [{ version: 1, value: "dd/mm/aaaa" }],
  Index: [{ version: 1, value: "" }],
  Type: [{ version: 1, value: null }],
  "Calculation Criteria": [{ version: 1, value: "" }],
  "Price Index Reference Day": [{ version: 1, value: null }],
  "Index Criteria": [{ version: 1, value: "-" }],
  "Correct Every": [{ version: 1, value: null }],
  "Index Multiplier": [{ version: 1, value: "" }],
  "Amortization - Rate": [{ version: 1, value: "-" }],
  "Amortization - Every": [{ version: 1, value: "-" }],
  "Amortization - Unit": [{ version: 1, value: null }],
  "Amortization - Grace": [{ version: 1, value: "-" }],
  "Amortization Type": [{ version: 1, value: "-" }],
  "Interest New Criteria- Rate": [{ version: 1, value: null }],
  "Interest New Criteria- Term": [{ version: 1, value: null }],
  "Interest New Criteria- Every": [{ version: 1, value: "" }],
  "Interest New Criteria- Unit": [{ version: 1, value: "" }],
  "Interest New Criteria- Grace": [{ version: 1, value: "dd/mm/aaaa" }],
  "Interest New Criteria- Criteria": [{ version: 1, value: "-" }],
  "Interest New Criteria- Type": [{ version: 1, value: "-" }],
  "Participation - Rate": [{ version: 1, value: "" }],
  "Participation - Every": [{ version: 1, value: null }],
  "Participation - Unit": [{ version: 1, value: "-" }],
  "Participation - Grace": [{ version: 1, value: null }],
  "Participation - Description ": [{ version: 1, value: null }],
  "Mandatory Bank": [{ version: 1, value: "" }],
  Trustee: [{ version: 1, value: "" }],
  "Depositary Institution": [{ version: 1, value: "" }],
  "Lead Underwriter": [{ version: 1, value: "" }],
  CNPJ: [{ version: 1, value: "" }],
  "Tax Free Debenture (Law 12_431)": [{ version: 1, value: "" }],
  "Standard Deed": [{ version: 1, value: null }],
  "Early Redemption (call option)": [{ version: 1, value: "" }],
  "Early Redemption - Grace": [{ version: 1, value: null }],
  "Early Redemption - Type 1": [{ version: 1, value: null }],
  "Early Redemption - Premium 1": [{ version: 1, value: null }],
  "Extraordinary Amortization - Grace": [{ version: 1, value: null }],
  "Early Redemption - Type 2": [{ version: 1, value: null }],
  "Early Redemption - Premium 2": [{ version: 1, value: null }],
  is_multiplicative_amortization: false,
  register_checked: false,
}

export const criCraInfoTemplate = {
  "B3 Code": [{ version: 1, value: "" }],
  Company: [{ version: 1, value: "" }],
  Serie: [{ version: 1, value: "" }],
  Issue: [{ version: 1, value: "" }],
  "Issue Date": [{ version: 1, value: "dd/mm/aaaa" }],
  "Maturity Date": [{ version: 1, value: "dd/mm/aaaa" }],
  Index: [{ version: 1, value: "" }],
  "Index Multiplier": [{ version: 1, value: "" }],
  "Interest New Criteria- Rate": [{ version: 1, value: "" }],
  "Yield Start Date": [{ version: 1, value: "dd/mm/aaaa" }],
  Lag: [{ version: 1, value: "" }],
  ISIN: [{ version: 1, value: "" }],
  "Interest New Criteria- Term": [{ version: 1, value: "" }],
  "Quantity Issued": [{ version: 1, value: "" }],
  "Nominal Value": [{ version: 1, value: "" }],
  Concentration: [{ version: 1, value: "" }],
  Debtor: [{ version: 1, value: "" }],
  "Debtor CNPJ": [{ version: 1, value: "" }],
  instrumentType: "",
  "Tax Free (Law 12_431)": [{ version: 1, value: "" }],
  "Early Redemption (call option)": [{ version: 1, value: "" }],
  Trustee: [{ version: 1, value: "" }],
  "Index For Maturities Before The 15th": [{ version: 1, value: "" }],
  "Annual Update Month": [{ version: 1, value: "" }],
  "Curve Correction Final Period Indicator": [{ version: 1, value: "" }],
  "Price Index Reference Day": [{ version: 1, value: "" }],
  has_price: false,
  is_callable: false,
  is_validated: false,
  is_multiplicative_amortization: false,
  were_redeemed: false,
  register_checked: false,
  Inflation_offset_in_months: 0,
  DI1_offset_in_days: 0,
}
