import React, { useState } from "react";

import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

import "./style.css";


const Menu = ({generate_gov_bonds_call_intraday_data}) => {
    const [dateToFilter, setDateToFilter] = useState(new Date());
    const [instrumentType, setInstrumentType] = useState('LTN')
    const [startTimeToFilter, setStartTimeToFilter] = useState('09:00')
    const [endTimeToFilter, setEndTimeToFilter] = useState('17:00')

    const instrumentTypeList = ['LTN', 'LFT', 'NTF', 'NTB']

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    return (
        <div className="card col-12">
            <div className="date-filter-input text-center">
                <label>
                    <span className="fw-bolder">Selecione a data:</span>
                </label>  
                <div className="mb-3 mt-2">
                    <DatePicker
                        className="dfa-form-control"
                        dateFormat="dd/MM/yyyy"
                        locale={ptBR}
                        selected={dateToFilter}
                        onChange={value => setDateToFilter(value)}
                        maxDate={new Date()}
                        filterDate={isWeekday}
                    />
                </div> 
                <label>
                    <span className="fw-bolder">Selecione o tipo de instrumento: </span>
                </label>  
                <div className="mb-3 mt-2">
                    <select 
                        className="dfa-form-control mx-auto"
                        id="instrument-select"
                        onChange={(e) => setInstrumentType(e.target.value)}
                        value={instrumentType}
                        >
                        {instrumentTypeList.map(function(type) {
                        return(
                            <option key={type}>{type}</option>
                        )})}
                    </select>
                </div>   
                <label>
                    <span className="fw-bolder">Selecione o horário inicial e o horário final:</span>
                </label> 
                <div className="mb-3 mt-2">
                    <span className="fw-bolder me-2 ms-2">
                        Inicial:
                    </span>
                    <TimePicker 
                        onChange={value => setStartTimeToFilter(value)} 
                        value={startTimeToFilter}
                        hourPlaceholder="HH"
                        minutePlaceholder="mm"
                    />
                    <span className="fw-bolder me-2 ms-2">
                        Final:
                    </span>
                    <TimePicker 
                        onChange={value => setEndTimeToFilter(value)} 
                        value={endTimeToFilter}
                        hourPlaceholder="HH"
                        minutePlaceholder="mm"
                    />
                </div>
                <div className="text-center">
                    <button 
                        className='btn dfa-button'
                        id='dfa-button'
                        onClick={() => generate_gov_bonds_call_intraday_data(instrumentType, dateToFilter, startTimeToFilter, endTimeToFilter)}
                    >
                    Carregar Calls
                    </button>
                </div> 
            </div>
        </div>
        );
    };

export default Menu;