import React, {useState,useEffect    } from "react";
import axios from "axios";
import moment from "moment";
import { getToken } from "../../../auth/credentials";
import "./style.css"
import api from "../../../services/api";
import notify from "../../../common/toast";
import {useContext} from 'react';
import TableDataContext from "../TableDataContext";


const ExpandendComponent = ({data,formatToday}) => {
    const [toChange,setToChange] = useState()
    const [render,setRender] = useState(false)
    const [valueChange, setValueChange] = useState();
    const [valor,setValor] = useState(" ")

    var today = new Date();
    var date = moment(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()).format("YYYY-MM-DD")
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    //const timeFormatado = time.split(':').map(e => `0${e}`.slice(-2)).join(':')
    const {tableRender,setTableRender} = useContext(TableDataContext);
    var testeRender = tableRender

    
  

    const optionsToChange = [
        'bidYield',
        'askYield',
        'last',
        'instrumentType',
        'isClosure',
        'maturityDate',
        'source',
        'period',
        'callDate',
       
    ]  



    const checkType = (e) => {
        if(checkNumber(e)) {
         if(e.toString().includes(',')){
         let convert = e.toString().replace(',','.')
         setValueChange(Number(convert))
         setValor(Number(convert))
         
         } else {
         setValueChange(Number(e))
         setValor(Number(e))
         }
        }

        else if(checkDate(e)) {
        console.log('date formatting..')
        }

        else if(checkIfString(e)){
        console.log("string included")
        }

        else {
        setValueChange(Boolean(e))
        setValor(Boolean(e))
        }
        

    }


    const checkNumber = () => {
        if(toChange === 'askYield' || toChange === 'bidYield' || toChange === 'last') {
        return true;
    }
    else {
        return false;
    }

}

    

    const checkDate = (e) => {
        if(toChange == 'callDate' || toChange == 'maturityDate' || toChange == 'fileDate') {
            setValueChange(moment.utc(e,"DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm"))
            setValor(moment.utc(e,"DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm"))
            return true;
        }
        else {
            return false;
        }
       
        
    }


    const checkIfString = (e) => {
        if(toChange == 'instrumentType' || toChange == 'source' || toChange == 'period') {
         setValueChange(e)
         setValor(e)
        }
    }

   
  


    const CustomPlaceholder = (name) => {
        let placeholderContent;
        switch(name) {
            case "instrumentType": 
                placeholderContent = data.instrumentType
                return placeholderContent;
            case "bidYield":
                if (data.bidYield == null) {
                    placeholderContent = ''
                    return placeholderContent
                } else { 
                    placeholderContent = data.bidYield
                    return placeholderContent
                }
                return placeholderContent;
            case "askYield":
                if (data.askYield == null) {
                    placeholderContent = ''
                    return placeholderContent
                } else {
                    placeholderContent = data.askYield
                    return placeholderContent;
                }
            case "last":
                if(data.last == null) {
                placeholderContent = ''  
                return placeholderContent  
                } else {
                    placeholderContent = data.last
                    return placeholderContent
                }
            case "isClosure":
                placeholderContent = String(data.isClosure)
                return placeholderContent;
            case "maturityDate":
                placeholderContent = data.maturityDate
                return placeholderContent;
            case "source":
                placeholderContent = data.source
                return placeholderContent;
            case "period":
                placeholderContent = data.period
                return placeholderContent;
            case "fileDate":
                placeholderContent = data.fileDate
                return placeholderContent;
            case "callDate":
                placeholderContent = data.callDate + ` ${data.fileDate}`
                return placeholderContent;
            case "other":
                placeholderContent = " ";
                return placeholderContent;
                
            default: console.log(" ");
        }
    }

   


const changeData = async () => {
    
    if(valueChange == undefined || valueChange == '') {
    alert('preencha o campo!')
    return;
    }

    if(toChange == 'askYield' || toChange == 'bidYield' || toChange == 'last') {
        if(!isNumeric(valueChange)) {
         alert(`Somente números para o atributo ${toChange}`)
         return;
        }
    }

   
   api.post("/callqa/update_call",{

            doc_id: data._id,
            what: {
                name:toChange,
                new_value:valueChange
            }
            
        
       
    }).then(
        res => {
             console.log(res)
             if(res.status === 200) {
                notify(
                    `º Call Editada com sucesso`,
                    "success"
                  );
                  setTableRender(!testeRender);
                  

             }
             else {
                notify(
                    `º Falha na edição da call`,
                    "error"
                  );
             }
        }
    )
   
} 






function isNumeric(value) {
   if(isNaN(value)) {
    return false;
   }
   else {
    return true;
   }
  }


return (
    <div className="container">
       <div className="form-group">
            <h6>O que alterar: </h6>
                <select  onChange={(e) => {
                 setToChange(e.target.value)
                 setValor(e.target.value)
                 CustomPlaceholder(valor)
                
                  
                } 
                }
                 >
                    <option value="">Selecione uma opção</option>
                    {optionsToChange.map((option) => (
                        <option key={option} onClick={(e) => {
                            
                        }}>{option}</option>
                        ))}
            </select>
            <label htmlFor="valueToChange">
            <input
             id="valueToChange"
             className="input-expanded"
             style={{marginLeft: "5.5px"}}
             onChange={(e) => checkType(e.target.value)}
             value={CustomPlaceholder(valor)}
             >
            </input>
            </label>



 

            <button 
            className="btn btn-success"
            style={{marginLeft: "15px"}}
            onClick={changeData}
            >
              Enviar
            </button>


           
           
            </div>





            
           
            
    </div>
)
}





export default ExpandendComponent;