import React, { useState, useEffect, useCallback, useRef } from "react";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import { eventTypes } from "../../common/eventTypes";

import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

import notify from "../../common/toast";
import {
  transformStringDatePTBRToFormatMMDDYYYY,
  transformDateMMDDYYYYToStringDatePTBR,
} from "../../utils/date";

import { sortAgendaEvents } from "../../utils/orderEvents";

import "./style.css";

const FormAddEvent = ({ agendaEvents, setAgendaEvents, setValuesToAgenda }) => {
  const [formAddEvent, setFormAddEvent] = useState({
    event_date: "",
    event: "",
    percentual_rate: "",
    internal_use: false,
    calculus_reference_data: "",
    percentagem_to_principal_corporate: "",
  });
  const handleChange = e => {
    setFormAddEvent({ ...formAddEvent, [e.target.name]: e.target.value });
  };



  const addEvent = () => {
    const {
      event_date,
      event,
      percentual_rate,
      internal_use,
      calculus_reference_data,
      percentagem_to_principal_corporate,
    } = formAddEvent;

    if (!event_date) {
      return notify("Preencha a data", "warning");
    }

    if (!event) {
      return notify("O tipo de evento não pode ser vazio.", "warning");
    }

    const new_events = [
      ...agendaEvents,
      [
        transformDateMMDDYYYYToStringDatePTBR(event_date),
        transformDateMMDDYYYYToStringDatePTBR(event_date),
        event,
        percentual_rate.replace(".", ","),
        "",
        "",
        internal_use === "true" ? true : false,
        transformDateMMDDYYYYToStringDatePTBR(calculus_reference_data) || "",
        percentagem_to_principal_corporate || "",
      ],
    ];
    const orderedEvents = sortAgendaEvents(new_events);
    setAgendaEvents(orderedEvents);
    setValuesToAgenda(orderedEvents);

    notify("Evento adicionado com sucesso", "success");
  };

  const [percentualToShow, setPercentualToShow] = useState('Percentual do principal incorporado');

  const handleEventType = e =>{
    if (e.target.value == 'COUPON_PARTIALLY_DEFERRED_PARTIALLY_INCORPORATED_BR_CORP'){
      setPercentualToShow('Percentual do juros diferido')
    }else{
      setPercentualToShow('Percentual do principal incorporado')
    }

  }

  return (
    <>
      <div className="container__form">
        <div className="box__form">
          <div className="group__form">
            <label className="form__label">Data do evento</label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              locale={ptBR}
              selected={formAddEvent.event_date}
              onChange={value =>
                setFormAddEvent({
                  ...formAddEvent,
                  event_date: value,
                })
              }
              className="field-lg"
            />
          </div>

          <div className="group__form">
            <label className="form__label">Tipo de evento</label>
            <select
              value={formAddEvent.event}
              onChange={e => {handleChange(e); handleEventType(e)}}
              className="form__select"
              name="event"
            >
              <option value={null}>Selecione um evento</option>
              {eventTypes.map(event => (
                <option key={event} value={event}>
                  {event}
                </option>
              ))}
            </select>
          </div>
          <div className="group__form">
            <label className="form__label">Taxa / R$</label>
            <input
              type="text"
              name="percentual_rate"
              value={formAddEvent.percentual_rate}
              onChange={handleChange}
              className="field-lg"
            />
          </div>
          <div className="group__form">
            <label className="form__label">Uso interno</label>
            <select
              name="internal_use"
              value={formAddEvent.internal_use}
              onChange={handleChange}
            >
              <option value={false}>NÃO</option>
              <option value={true}>SIM</option>
            </select>
          </div>
          <div className="group__form">
            <label className="form__label">Data de referência do cálculo</label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              locale={ptBR}
              selected={formAddEvent.calculus_reference_data}
              onChange={value =>
                setFormAddEvent({
                  ...formAddEvent,
                  calculus_reference_data: value,
                })
              }
              className="field-lg"
            />
          </div>
          <div className="group__form">
            <label className="form__label">
              {percentualToShow}
            </label>
            <input
              type="text"
              name="percentagem_to_principal_corporate"
              value={formAddEvent.percentagem_to_principal_corporate}
              onChange={handleChange}
              className="field-lg"
            />
          </div>
          <button onClick={addEvent} className="btn__events btn--hover">
            Adicionar
          </button>
        </div>
      </div>
    </>
  );
};



const Tabs = ({
  setAgendaValue,
  agendaValue,
  formFilterEvents,
  setFormFilterEvents,
  setValuesToAgenda,
}) => {
  const [toggleState, setToggleState] = useState(1);
  const [showTabs, setShowTabs] = useState(true);
  const [formAddEvents, setFormAddEvents] = useState({
    initialDate: "",
    finalDate: "",
    event: "",
    frequency: 0,
    percentualRate: 0,
    internal_use: false,
    calculus_reference_data: "",
    percentagem_to_principal_corporate: "",
  });
  const [frequencies, setFrequencies] = useState([]);
  const [formDeleteEvents, setFormDeleteEvents] = useState({
    initialDate: "",
    finalDate: "",
    typeEvent: "",
  });

  const monthsRef = useRef(null);
  useEffect(() => {
    const { initialDate, finalDate } = formAddEvents;

    const months = getMonthsDiff(initialDate, finalDate);
    monthsRef.current = months;
    let possibleFrequencies = [];
    for (let x = 1; x <= months; x++) {
      if (months % x === 0) {
        possibleFrequencies.push(x);
      }
    }
    setFrequencies(possibleFrequencies);
  }, [formAddEvents]);

  const getMonthsDiff = (d1, d2) => {
    if (!(d1 instanceof Date) || !(d2 instanceof Date)) {
      return;
    }

    let initial_date = d1;
    let end_date = d2;
    let months = (end_date.getFullYear() - initial_date.getFullYear()) * 12;
    months -= initial_date.getMonth();
    months += end_date.getMonth();
    return months <= 0 ? 0 : months;
  };

  const toggleTab = index => {
    setToggleState(index);
  };

  const handleChange = event => {
    setFormAddEvents({
      ...formAddEvents,
      [event.target.name]: event.target.value,
    });
  };

  const clearFilter = () => {
    const { initialDate, finalDate, typeEvent } = formFilterEvents;
    if (!initialDate && !finalDate && !typeEvent) {
      return notify("Não existe nenhum filtro selecionado", "warning");
    }
    setFormFilterEvents({ initialDate: "", finalDate: "", typeEvent: "" });
    notify("Os filtros foram removidos", "success");
  };

  const generateEventPreDetermined = useCallback(() => {
    const {
      initialDate,
      finalDate,
      event,
      frequency,
      percentualRate,
      internal_use,
      calculus_reference_data,
      percentagem_to_principal_corporate,
    } = formAddEvents;

    if (!frequency) {
      return notify("Preencha a frequência dos eventos", "warning");
    }
    if (!event) {
      return notify("Preencha o tipo de evento", "warning");
    }
    if (+percentualRate === 0) {
      return notify("Preencha a taxa percentual com valores", "warning");
    }

    let initial_date = initialDate;

    let final_date = finalDate;

    if (initial_date.getTime() > final_date.getTime()) {
      return notify(
        "A data inicial não pode ser maior que a data final",
        "warning"
      );
    }

    if (frequency > +monthsRef.current) {
      return notify("Preenche um valor válido", "warning");
    }

    const totalEvents = +monthsRef.current / +frequency;
    const day_initial_date = initial_date.getDate();
    const month_initial_date = initial_date.getMonth();
    const year_inital_date = initial_date.getFullYear();

    let new_events = [];
    for (let x = 0; x <= totalEvents; x++) {
      let event_date;
      if (x === 0) {
        event_date = new Date(
          year_inital_date,
          month_initial_date,
          day_initial_date
        );

        const eventDate =
          transformDateMMDDYYYYToStringDatePTBR(event_date) || "";

        new_events.push([
          eventDate,
          eventDate,
          event,
          percentualRate.replace(".", ","),
          "",
          "",
          internal_use === "true" ? true : false,
          transformDateMMDDYYYYToStringDatePTBR(calculus_reference_data) || "",
          percentagem_to_principal_corporate || "",
        ]);
      } else if (x !== 0) {
        event_date = new Date(
          year_inital_date,
          month_initial_date + +frequency * x,
          day_initial_date
        );

        const eventDate =
          transformDateMMDDYYYYToStringDatePTBR(event_date) || "";
        new_events.push([
          eventDate,
          eventDate,
          event,
          percentualRate.replace(".", ","),
          "",
          "",
          internal_use === "true" ? true : false,
          transformDateMMDDYYYYToStringDatePTBR(calculus_reference_data) || "",
          percentagem_to_principal_corporate || "",
        ]);
      }
    }
    const orderedEvents = sortAgendaEvents([...agendaValue, ...new_events]);
    setAgendaValue(orderedEvents);
    setValuesToAgenda(orderedEvents);
    notify("Eventos adicionados com sucesso", "success");
  }, [setValuesToAgenda, agendaValue, setAgendaValue, formAddEvents]);

  const deleteManyEvents = () => {
    const { initialDate, finalDate, typeEvent } = formDeleteEvents;

    if (!initialDate && !finalDate && !typeEvent) {
      return notify(
        "Não foi possível deletar. Selecione pelo menos uma opção",
        "warning"
      );
    }
    if (finalDate && !initialDate && !typeEvent) {
      return notify(
        "Não é possivel filtrar somente com a data final. Preencha a data inicial",
        "warning"
      );
    }

    const filteredEvents = agendaValue.filter(event => {
      const eventDateIsGreaterThanInitialDate =
        transformStringDatePTBRToFormatMMDDYYYY(event[0]).getTime() >=
        initialDate.getTime();
      const eventDateIsLessThanFinalDate =
        transformStringDatePTBRToFormatMMDDYYYY(event[0]).getTime() <=
        finalDate.getTime();
      const typeEventIsEqualToTypeEventFromParam = event[2] === typeEvent;

      if (initialDate && finalDate && typeEvent) {
        return !(
          eventDateIsGreaterThanInitialDate &&
          eventDateIsLessThanFinalDate &&
          typeEventIsEqualToTypeEventFromParam
        );
      } else if (initialDate && finalDate) {
        return !(
          eventDateIsGreaterThanInitialDate && eventDateIsLessThanFinalDate
        );
      } else if (typeEvent && initialDate) {
        return !(eventDateIsGreaterThanInitialDate && event[2] === typeEvent);
      } else if (initialDate) {
        return !eventDateIsGreaterThanInitialDate;
      } else if (typeEvent) {
        return !typeEventIsEqualToTypeEventFromParam;
      }
      return true;
    });

    const eventsLength = agendaValue.length;
    const filteredEventsLength = filteredEvents.length;
    if (filteredEventsLength === eventsLength) {
      return notify(
        "Não existe nenhum evento a ser deletado com o atual parâmetro",
        "warning"
      );
    }

    setAgendaValue(filteredEvents);
    setValuesToAgenda(filteredEvents);
    notify("Eventos deletados com sucesso", "success");
  };

  const [percentualToShow, setPercentualToShow] = useState('Percentual do principal incorporado');

  const handleEventType = e =>{
    if (e.target.value == 'COUPON_PARTIALLY_DEFERRED_PARTIALLY_INCORPORATED_BR_CORP'){
      setPercentualToShow('Percentual do juros diferido')
    }else{
      setPercentualToShow('Percentual do principal incorporado')
    }

  }

  return (
    <div className="container__tabs">
      <div className="bloc__tabs">
        <button
          className={
            toggleState === 1
              ? "tab__button tab--active btn--hover"
              : "tab__button btn--hover"
          }
          onClick={() => toggleTab(1)}
        >
          Filtrar
        </button>
        <button
          className={
            toggleState === 2
              ? "tab__button tab--active btn--hover"
              : "tab__button btn--hover"
          }
          onClick={() => toggleTab(2)}
        >
          Adicionar um evento
        </button>
        <button
          className={
            toggleState === 3
              ? "tab__button tab--active btn--hover"
              : "tab__button btn--hover"
          }
          onClick={() => toggleTab(3)}
        >
          Adicionar vários eventos
        </button>
        <button
          className={
            toggleState === 4
              ? "tab__button tab--active btn--hover"
              : "tab__button btn--hover"
          }
          onClick={() => toggleTab(4)}
        >
          Deletar por data e tipo
        </button>

        <button onClick={() => setShowTabs(!showTabs)} className="tab__arrow">
          {showTabs ? (
            <IoIosArrowDown color="#fff" />
          ) : (
            <IoIosArrowUp color="#fff" />
          )}
        </button>
      </div>
      {showTabs && (
        <div className="content__tabs">
          <div
            className={
              toggleState === 1
                ? "content__form content__form--active"
                : "content__form "
            }
          >
            <div className="container__form">
              <div>
                <label className="form__label">Data inicial</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  locale={ptBR}
                  selected={formFilterEvents.initialDate}
                  onChange={value =>
                    setFormFilterEvents({
                      ...formFilterEvents,
                      initialDate: value,
                    })
                  }
                  className="field-lg"
                />
                <label className="form__label">Data final</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  locale={ptBR}
                  selected={formFilterEvents.finalDate}
                  onChange={value =>
                    setFormFilterEvents({
                      ...formFilterEvents,
                      finalDate: value,
                    })
                  }
                  className="field-lg"
                />
                <label className="form__label">Tipo de evento</label>
                <select
                  name="typeEvent"
                  className="form__select"
                  value={formFilterEvents.typeEvent}
                  onChange={e =>
                    setFormFilterEvents({
                      ...formFilterEvents,
                      typeEvent: e.target.value,
                    })
                  }
                >
                  <option value="">Selecione um evento</option>
                  {eventTypes.map(event => (
                    <option key={event} value={event}>
                      {event}
                    </option>
                  ))}
                </select>
                <button className="btn--clear--filter" onClick={clearFilter}>
                  Limpar filtros
                </button>
              </div>
            </div>
          </div>
          <div
            className={
              toggleState === 2
                ? "content__form  content__form--active"
                : "content__form"
            }
          >
            <FormAddEvent
              setAgendaEvents={setAgendaValue}
              agendaEvents={agendaValue}
              setValuesToAgenda={setValuesToAgenda}
            />
          </div>
          <div
            className={
              toggleState === 3
                ? "content__form content__form--active"
                : "content__form"
            }
          >
            <div className="container__form">
              <div className="box__form">
                <div className="group__form">
                  <label className="form__label">Data inicial</label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale={ptBR}
                    selected={formAddEvents.initialDate}
                    onChange={value =>
                      setFormAddEvents({
                        ...formAddEvents,
                        initialDate: value,
                      })
                    }
                    className="field-lg"
                  />
                </div>
                <div className="group__form">
                  <label className="form__label">Data final</label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale={ptBR}
                    selected={formAddEvents.finalDate}
                    onChange={value =>
                      setFormAddEvents({
                        ...formAddEvents,
                        finalDate: value,
                      })
                    }
                    className="field-lg"
                  />
                </div>
                <div className="group__form">
                  <label className="form__label">Frequência</label>
                  <select
                    name="frequency"
                    onChange={handleChange}
                    className="form__select"
                    disabled={!frequencies.length}
                  >
                    <option value="">Selecione a frequência</option>
                    {frequencies.map(frequency => (
                      <option
                        key={frequency}
                        value={frequency}
                      >{`${frequency} ${
                        frequency === 1 ? "mês" : "meses"
                      }`}</option>
                    ))}
                  </select>
                </div>
                <div className="group__form">
                  <label className="form__label">Taxa / R$</label>
                  <input
                    name="percentualRate"
                    value={formAddEvents.percentualRate}
                    onChange={handleChange}
                    type="number"
                    className="field-lg"
                  />
                </div>
                <div className="group__form">
                  <label className="form__label">Tipo de evento</label>
                  <select
                    className="form__select"
                    name="event"
                    value={formAddEvents.event}
                    onChange={e => {handleChange(e); handleEventType(e)}}
                  >
                    <option value={null}>Selecione um evento</option>
                    {eventTypes.map(event => (
                      <option key={event} value={event}>
                        {event}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="group__form">
                  <label className="form__label">Uso interno</label>
                  <select
                    name="internal_use"
                    value={formAddEvents.internal_use}
                    onChange={handleChange}
                  >
                    <option value={false}>NÃO</option>
                    <option value={true}>SIM</option>
                  </select>
                </div>
                <div className="group__form">
                  <label className="form__label">
                    Data de referência do cálculo
                  </label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale={ptBR}
                    selected={formAddEvents.calculus_reference_data}
                    onChange={value =>
                      setFormAddEvents({
                        ...formAddEvents,
                        calculus_reference_data: value,
                      })
                    }
                    className="field-lg"
                  />
                </div>
                <div className="group__form">
                  <label className="form__label">
                  {percentualToShow}
                  </label>
                  <input
                    name="percentagem_to_principal_corporate"
                    value={formAddEvents.percentagem_to_principal_corporate}
                    onChange={handleChange}
                    type="number"
                    className="field-lg"
                  />
                </div>
                <button
                  className="btn__events btn--hover"
                  onClick={generateEventPreDetermined}
                >
                  Gerar eventos
                </button>
              </div>
            </div>
          </div>
          <div
            className={
              toggleState === 4
                ? "content__form  content__form--active"
                : "content__form"
            }
          >
            <div className="container__form">
              <div>
                <div>
                  <label className="form__label">Data inicial</label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale={ptBR}
                    selected={formDeleteEvents.initialDate}
                    onChange={value =>
                      setFormDeleteEvents({
                        ...formDeleteEvents,
                        initialDate: value,
                      })
                    }
                    className="field-lg"
                  />
                </div>
                <div>
                  <label className="form__label">Data final</label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale={ptBR}
                    selected={formDeleteEvents.finalDate}
                    onChange={value =>
                      setFormDeleteEvents({
                        ...formDeleteEvents,
                        finalDate: value,
                      })
                    }
                    className="field-lg"
                  />
                </div>
                <div>
                  <label className="form__label">Tipo de evento</label>
                  <select
                    name="typeEvent"
                    className="form__select"
                    value={formDeleteEvents.typeEvent}
                    onChange={e =>
                      setFormDeleteEvents({
                        ...formDeleteEvents,
                        typeEvent: e.target.value,
                      })
                    }
                  >
                    <option value={null}>Selecione um evento</option>
                    {eventTypes.map(event => (
                      <option key={event} value={event}>
                        {event}
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  onClick={deleteManyEvents}
                  className="btn--delete btn--hover"
                >
                  Deletar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Tabs);
