import React, { useMemo, useState } from "react"
import { Card, Accordion, Button, Badge, Modal, Table } from "react-bootstrap"
import ColorBadge from "../Badge"
import { startOfToday } from "date-fns"
import AccordionCard from "../AccordionCard"
import DataModal from "../DataModal"
import XLSX from "xlsx"
import notify from "../../../../common/toast"

const DI_futures_intraday_schedule = [
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:01",
]

const bacen_selic_target_schedule = ["08:00"]

const future_adjusment_schedule = [
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "22:40",
  "22:55",
]

// const anbima_credit_spread_schedule = [
//   "08:00",
//   "08:30",
//   "09:00",
//   "09:30",
//   "10:00",
//   "10:30",
//   "11:00",
//   "11:30",
// ]

const anbima_credit_spread_schedule = ["19:30"]

const Panel = ({ processData = [] }) => {
  const [openModal, setOpenModal] = useState(false)
  const [modalData, setModalData] = useState({})

  const getCurrentTime = () => {
    const now = new Date()
    const hours = now.getHours()
    const minutes = String(now.getMinutes()).padStart(2, "0")

    return `${hours}:${minutes}`
  }

  const getAllTimeStampsFromProcessData = () => {
    const diFutureData = processData.filter((item) => {
      if (item.index_id === "DI_futures_intraday") {
        return item.process_data
      }
    })

    const timeStampsList = []

    for (const item of diFutureData[0]["process_data"]) {
      const slicedDate = item.reference_date.slice(11, 16)
      if (!timeStampsList.includes(slicedDate)) {
        timeStampsList.push(slicedDate)
      }
    }
    return timeStampsList
  }

  const handleIntradayCurvesStatus = (timestamp) => {
    const allExecutionTimeToNow = getAllTimeStampsFromProcessData()

    const currentTime = getCurrentTime()

    if (allExecutionTimeToNow.includes(timestamp)) {
      return "success"
    } else if (currentTime > timestamp) {
      return "danger"
    } else {
      return "secondary"
    }
  }

  const handleBacenSelicStatus = (timestamp, bacenDataLength) => {
    const currentTime = getCurrentTime()

    if (currentTime >= timestamp && bacenDataLength > 0) {
      return "success"
    } else if (currentTime < timestamp) {
      return "secondary"
    } else {
      return "danger"
    }
  }

  const handleAnbimaCreditStatus = (timestamp, dataLength) => {
    const currentTime = getCurrentTime()

    if (currentTime > timestamp && dataLength > 32) {
      return "success"
    } else if (currentTime < timestamp) {
      return "secondary"
    } else {
      return "danger"
    }
  }

  const handleAdjustmentPriceStatus = (timestamp, dataLength) => {
    const currentTime = getCurrentTime()

    if (currentTime > timestamp && dataLength > 0) {
      return "success"
    } else if (currentTime < timestamp) {
      return "secondary"
    } else {
      return "danger"
    }
  }

  const handleStatusColor = (timestamp, index_id, dataLength) => {
    if (index_id === "DI_futures_intraday") {
      return handleIntradayCurvesStatus(timestamp)
    } else if (index_id === "BACEN_SELIC_TARGET") {
      return handleBacenSelicStatus(timestamp, dataLength)
    } else if (index_id === "ANBIMA_CREDIT_SPREADS") {
      return handleAnbimaCreditStatus(timestamp, dataLength)
    } else {
      return handleAdjustmentPriceStatus(timestamp, dataLength)
    }
  }

  const handleSummaryColor = (indexId, dataLength) => {
    const currentTime = getCurrentTime()
    const diFutureIntradays = "09:30"
    const bacenSelicTarget = "08:00"
    const anbimaCreditSpread = "11:35"
    const diOneFuturesAdjustments = "17:35"
    const dapFuturesAdjusments = "19:30"

    let currentTimestamp = ""

    switch (indexId) {
      case "DI_futures_intraday":
        currentTimestamp = diFutureIntradays
        break
      case "BACEN_SELIC_TARGET":
        currentTimestamp = bacenSelicTarget
        break
      case "ANBIMA_CREDIT_SPREADS":
        currentTimestamp = anbimaCreditSpread
        break
      case "DI1_futures_adjustment":
        currentTimestamp = diOneFuturesAdjustments
        break
      default:
        currentTimestamp = dapFuturesAdjusments
        break
    }

    if (dataLength > 0) {
      return "success"
    } else if (currentTime > currentTimestamp && dataLength > 0) {
      return "success"
    } else if (currentTimestamp > currentTime) {
      return "secondary"
    } else {
      return "danger"
    }
  }

  const handleOpenValueModal = (time, processDataObject) => {
    setOpenModal(!openModal)
    const dataKeys =
      processDataObject["process_data"].length > 0
        ? Object.keys(processDataObject["process_data"]?.[0])
        : []
    const processDataArray = processDataObject["process_data"]
    const processName = processDataObject["index_id"]

    setModalData({ time, processDataArray, dataKeys, processName })
  }

  const handleCloseModal = () => {
    setOpenModal(!openModal)
    setModalData({
      time: "",
      processDataArray: [],
      dataKeys: [],
      processName: "",
    })
  }

  const handleDownloadExcel = () => {
    const modalDataArray = modalData["processDataArray"]
    if (modalDataArray.length > 0) {
      const slicedDate = modalDataArray[0]["reference_date"].slice(0, 10)
      const modifiedData = modalDataArray.map((item) => {
        const modifiedItem = { ...item }
        Object.keys(modifiedItem).forEach((key) => {
          if (typeof modifiedItem[key] === "boolean") {
            modifiedItem[key] = modifiedItem[key].toString()
          }
        })
        return modifiedItem
      })

      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet(modifiedData)

      XLSX.utils.book_append_sheet(workbook, worksheet, "Dados")
      const fileName = `${modalData.processName}_${slicedDate}.xlsx`
      XLSX.writeFile(workbook, fileName)
      console.log("Arquivo Excel gerado com sucesso!")
    } else {
      return notify("Não foi possível criar o arquivo", "error")
    }
  }

  const handleTimeExecutionArrays = (indexId) => {
    if (indexId === "DI_futures_intraday") {
      return DI_futures_intraday_schedule
    } else if (indexId === "BACEN_SELIC_TARGET") {
      return bacen_selic_target_schedule
    } else if (indexId === "ANBIMA_CREDIT_SPREADS") {
      return anbima_credit_spread_schedule
    } else {
      return future_adjusment_schedule
    }
  }

  return (
    <div
      className="
        container
        col-md-12
        mt-5
        shadow 
        p-3 
        mb-5 
        bg-body 
        rounded
        w-50
        "
    >
      <Accordion defaultActiveKey="0">
        {processData.map((data, index) => {
          const executionTimeArray = handleTimeExecutionArrays(data.index_id)
          return (
            <AccordionCard
              key={data.index_id}
              index={index}
              data={data}
              handleStatusColor={handleStatusColor}
              handleSummaryColor={handleSummaryColor}
              executionTimeArray={executionTimeArray}
              handleOpenValueModal={handleOpenValueModal}
            />
          )
        })}
      </Accordion>
      {openModal && (
        <DataModal
          modalData={modalData}
          handleCloseModal={handleCloseModal}
          openModal={openModal}
          handleDownloadExcel={handleDownloadExcel}
        />
      )}
    </div>
  )
}

export default Panel
