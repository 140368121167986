export const resetForm = (formId) => {
  document.getElementById(formId).reset();
};

export const generateRandonAndUniquePassoword = () => {
  const password =
    Date.now().toString(36) + Math.random().toString(36).slice(5, 10);

  return password;
};
