import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

// Pintar Bid median e trade yield permitir editar tbm

const CellInputEditor = ({ ticker, cellDefaultValue, changeCriCraQaData, inputName }) => {
  const [inputs, setInputs] = useState("")

  useEffect(() => {
    setInputs(cellDefaultValue)
  }, [cellDefaultValue])

  const handleChange = (event) => {
    setInputs(event.target.value)
    changeCriCraQaData(ticker, event.target.value, inputName)
  }
  
  return (
    <InputGroup>
        <Form.Control
          aria-label="Editor"
          aria-describedby="cellEdit"
          size="sm" 
          type= {inputName === "reliability_score" ? "number" : "text"} 
          placeholder={cellDefaultValue}
          step="5"
          value={inputs}
          onChange={(event) => handleChange(event)}
        />
    </InputGroup>
  );
};

export default CellInputEditor;