import React, { useState } from "react";

import api from "../../../../../services/api";

import Modal from "react-modal";

import { AiOutlineCloseCircle } from "react-icons/ai";

import notify from "../../../../../common/toast";

import { useSelector } from "react-redux";

import "./style.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
const EditCVM = ({ showModal, setShowModal }) => {
  const [ticker, setTicker] = useState("");

  const { company_name, document_date, affected_tickers } = useSelector(
    state => state.cvmInfos
  );

  const handleChange = event => {
    setTicker(event.target.value);
  };

  const addTicker = async () => {
    const uniqueTickers = [...new Set([...affected_tickers, ticker])];
    try {
      const date = document_date.split("T")[0];
      await api.post("/addTicker", {
        company_name,
        document_date: date,
        affected_tickers: uniqueTickers,
      });
      notify("Ticker adicionado com sucesso", "success");
    } catch (e) {
      notify("Erro ao adicionar o ticker", "warning");
      console.log(e);
    }
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(true)}
        style={customStyles}
      >
        <div>
          <div>
            <div className="button-close-modal">
              <button onClick={() => setShowModal(false)}>
                <AiOutlineCloseCircle
                  color="#7c00d3"
                  size="38px"
                  background="transparent"
                />
              </button>
            </div>
            <div className="box__add__ticker">
              <label>
                <strong>Adicionar ticker</strong>
              </label>
              <input type="text" onChange={handleChange} value={ticker} />
              <button onClick={addTicker} className="btn__add__ticker">
                Adicionar
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(EditCVM);
