import React from "react";

const CreateUserButton = ({ disabled, ...props }) => {
  return disabled ? (
    <button disabled={true} {...props}>
      Carregando....
    </button>
  ) : (
    <button disabled={false} {...props}>
      Criar Usuário
    </button>
  );
};

export default CreateUserButton;
