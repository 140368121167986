import React, { useState } from "react";
import moment from 'moment'
import ProcessDetails from "../ProcessDetails";

import "./style.css";

const CardsCategories = ({title, categorie, lastExecution}) => {
    const [showDetails, setShowDetails] = useState(false)

    const formatCategorieName = (title) => {
        if (title === "debentures_calls") {
            return "Calls de Debêntures"
        } else {
            return title
        }
    }

    const getProcessPercentInfo = (isDocumentOnS3, isDocumentReaded) => {
        if (isDocumentOnS3 && isDocumentReaded) {
            return (
                <span className="badge bg-success rounded-pill">
                    100%
                </span>
            )
        } else if ((isDocumentOnS3 && !isDocumentReaded) || (!isDocumentOnS3 && isDocumentReaded)) {
            return (
                <span className="badge bg-warning rounded-pill">
                    50%
                </span>
            )
        } else {
            return (
                <span className="badge bg-secondary rounded-pill">
                    0%
                </span>
            )
        }
    }

    return (
        <div className="col">
            <div className="card">
                <div className="card-body" id="card-categorie">
                    <h5 className="card-title">{formatCategorieName(title)}</h5>
                    <p className="card-text">
                        Lista de processos: 
                    </p>
                    <ul className="list-group">
                        {categorie?.map(function(processInfo, index) {
                        return(
                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                {processInfo["process_name"]}
                                {getProcessPercentInfo(processInfo["is_document_on_S3"], processInfo["is_document_readed"])}
                            </li>
                        )})} 
                        
                    </ul>
                </div>
                <div className="btn btn-primary"
                    onClick={() => setShowDetails(true)}>
                    Detalhes
                </div>
                <div className="card-footer">
                    <small className="text-muted">Ultima atualização feita às {moment(lastExecution).utc(false).format("HH:mm:ss")}</small>
                </div>
            </div>
            <ProcessDetails
                processList={categorie}
                show={showDetails}
                onHide={() => setShowDetails(false)}
            />
        </div>
    
        );
    };

export default CardsCategories;
