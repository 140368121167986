import React, { useState, useReducer } from "react"

import {
  faEdit,
  faSave,
  faAngleDoubleLeft,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { criCraInfoTemplate } from "../../../../common/debenturesInfoTemplate"
import CriCraInfoEditInput from "../CriCraInfoEditInput"
import CriCraModalSaveConfirm from "../CriCraModalSaveConfirm"

import "./style.css"

const CriCraListExpandedComponent = ({
  criCraInfoRow,
  $isCustomCriCraFlag,
  reloadDataTable,
}) => {
  const [editMode, setEditMode] = useState($isCustomCriCraFlag ? true : false)
  const [modalShow, setModalShow] = useState(false)

  const [input, setInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    criCraInfoRow
  )
  const [changedAttributes, setChangedAttributes] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    []
  )

  const simpleInputs = [
    "instrumentType",
    "is_multiplicative_amortization",
    "has_price",
    "is_callable",
    "were_redeemed",
    "is_callable",
    "Inflation_offset_in_months",
    "DI1_offset_in_days",
  ]

  const handleEditInput = (id, rowToEdit, oldValue) => {
    var datesInputList = ["Issue Date", "Maturity Date", "Yield Start Date"]

    var floatInputs = [
      "Index Multiplier",
      "Interest New Criteria- Rate",
      "Interest New Criteria- Term",
      "Nominal Value",
      "Quantity Issued",
      "Price Index Reference Day",
    ]

    const intInputs = ["DI1_offset_in_days", "Inflation_offset_in_months"]

    let copyCriCraInfo = { ...input }

    if (
      id === "is_multiplicative_amortization" ||
      id === "is_callable" ||
      id === "has_price" ||
      id === "were_redeemed"
    ) {
      if (rowToEdit === "true") {
        copyCriCraInfo = { ...copyCriCraInfo, [id]: true }
        setChangedAttributes({
          ...changedAttributes,
          [id]: { new: rowToEdit, old: oldValue },
        })
      } else {
        copyCriCraInfo = { ...copyCriCraInfo, [id]: false }
        setChangedAttributes({
          ...changedAttributes,
          [id]: { new: rowToEdit, old: oldValue },
        })
      }
    } else if (datesInputList.includes(id)) {
      copyCriCraInfo = {
        ...copyCriCraInfo,
        [id]: [
          {
            version: 1,
            value: moment(rowToEdit, "DD/MM/YYYY")
              .format("YYYY-MM-DD[T00:00:00.000Z]")
              .toString(),
          },
        ],
      }
      setChangedAttributes({
        ...changedAttributes,
        [id]: {
          new: moment(rowToEdit, "DD/MM/YYYY").format(
            "YYYY-MM-DD[T00:00:00.000Z]"
          ),
          old: oldValue,
        },
      })
    } else if (floatInputs.includes(id)) {
      copyCriCraInfo = {
        ...copyCriCraInfo,
        [id]: [{ version: 1, value: parseFloat(rowToEdit) }],
      }
      setChangedAttributes({
        ...changedAttributes,
        [id]: { new: rowToEdit, old: oldValue },
      })
    } else if (id === "instrumentType") {
      copyCriCraInfo = {
        ...copyCriCraInfo,
        [id]: rowToEdit,
      }
      setChangedAttributes({
        ...changedAttributes,
        [id]: { new: rowToEdit, old: oldValue },
      })
    } else if (intInputs.includes(id)) {
      copyCriCraInfo = {
        ...copyCriCraInfo,
        [id]: Number(rowToEdit),
      }
      setChangedAttributes({
        ...changedAttributes,
        [id]: { new: rowToEdit, old: oldValue },
      })
    } else if (id === "Tax Free (Law 12_431)") {
      if (String(rowToEdit) === "true" || String(rowToEdit) === "S") {
        copyCriCraInfo = {
          ...copyCriCraInfo,
          [id]: { version: 1, value: "S" },
        }
        setChangedAttributes({
          ...changedAttributes,
          [id]: { new: "S", old: oldValue },
        })
      } else {
        copyCriCraInfo = {
          ...copyCriCraInfo,
          [id]: { version: 1, value: "N" },
        }
        setChangedAttributes({
          ...changedAttributes,
          [id]: { new: "N", old: oldValue },
        })
      }
    } else if (id === "Early Redemption (call option)") {
      if (String(rowToEdit).trim() === "true") {
        copyCriCraInfo = {
          ...copyCriCraInfo,
          [id]: { version: 1, value: "S" },
        }
        setChangedAttributes({
          ...changedAttributes,
          [id]: { new: "S", old: oldValue },
        })
      } else {
        copyCriCraInfo = {
          ...copyCriCraInfo,
          [id]: { version: 1, value: "N" },
        }
        setChangedAttributes({
          ...changedAttributes,
          [id]: { new: "N", old: oldValue },
        })
      }
    } else {
      copyCriCraInfo = {
        ...copyCriCraInfo,
        [id]: [{ version: 1, value: rowToEdit }],
      }
      setChangedAttributes({
        ...changedAttributes,
        [id]: { new: rowToEdit, old: oldValue },
      })
    }

    setInput(copyCriCraInfo)
  }

  const renderRegisterCriCraConfirmation = () => {
    if (editMode) {
      setModalShow(true)
    }
  }

  const reducedObject = Object.keys(criCraInfoRow).reduce((result, key) => {
    result[key] = criCraInfoRow[key]
    return result
  }, {})

  return (
    <>
      <div className="expandedDebentureRow_Container">
        <div className="card col-12">
          <div className="card-header deb_header">
            <div className="row deb_buttons_menu">
              {!editMode ? (
                <div className="d-grid gap-2 text-center col-2 mx-auto">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setEditMode(true)}
                  >
                    <FontAwesomeIcon icon={faEdit} size="1x" className="me-1" />
                    Editar Atributos
                  </button>
                </div>
              ) : (
                <div className="d-grid gap-2 text-center d-md-block mx-auto">
                  <button
                    type="button"
                    className="btn btn-success w-25 me-2"
                    onClick={() => renderRegisterCriCraConfirmation()}
                  >
                    <FontAwesomeIcon icon={faSave} size="1x" className="me-1" />
                    Salvar
                  </button>

                  <button
                    type="button"
                    className="btn btn-danger ms-2 w-25"
                    onClick={() => setEditMode(false)}
                  >
                    <FontAwesomeIcon
                      icon={faAngleDoubleLeft}
                      size="1x"
                      className="me-1"
                    />
                    Cancelar
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="card-body">
            <div className="row row-cols-2">
              {Object.keys(criCraInfoTemplate).map((key, index) => {
                if (
                  key === "_id" ||
                  key === "changes" ||
                  key === "has_agenda"
                ) {
                  return false
                }

                if (reducedObject.hasOwnProperty(key)) {
                  if (simpleInputs.includes(key)) {
                    return (
                      <div className="col" key={key.toString()}>
                        <span key={key}>
                          {key}
                          <CriCraInfoEditInput
                            id={key}
                            rowToEdit={String(reducedObject[key]) ?? ""}
                            editMode={editMode}
                            handleEditInput={handleEditInput}
                          />
                        </span>
                      </div>
                    )
                  } else {
                    return reducedObject[key] ? (
                      <div className="col" key={key.toString()}>
                        <span>
                          {key.toString()}:
                          <CriCraInfoEditInput
                            id={key.toString()}
                            rowToEdit={
                              reducedObject[key]?.[0]?.value === "boolean"
                                ? String(reducedObject[key]?.[0]?.value) ?? ""
                                : reducedObject[key]?.[0]?.value ?? ""
                            }
                            editMode={editMode}
                            handleEditInput={handleEditInput}
                          />
                        </span>
                      </div>
                    ) : null
                  }
                } else {
                  return (
                    <div className="col" key={key.toString()}>
                      <span>
                        {key.toString()}:
                        <CriCraInfoEditInput
                          id={key.toString()}
                          rowToEdit={
                            reducedObject[key]?.[0]?.value === "boolean"
                              ? String(reducedObject[key]?.[0]?.value) ?? ""
                              : reducedObject[key]?.[0]?.value ?? ""
                          }
                          editMode={editMode}
                          handleEditInput={handleEditInput}
                        />
                      </span>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>
      </div>
      <CriCraModalSaveConfirm
        show={modalShow}
        onHide={() => setModalShow(false)}
        changedAttributes={changedAttributes}
        criCraToRegister={input}
        isCustomCriCraFlag={$isCustomCriCraFlag ? 1 : 0}
        reloadDataTable={reloadDataTable}
      />
    </>
  )
}

export default CriCraListExpandedComponent
