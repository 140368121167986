import React, { useState } from "react"
import DatePicker from "react-datepicker"
import ptBR from "date-fns/locale/pt-BR"
import { FiDownload } from "react-icons/fi"
import notify from "../../common/toast"
import api from "../../services/api"

export default function ComparativeRates() {
  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [fileLink, setFileLink] = useState("")

  const formatDate = (date) => {
    return Intl.DateTimeFormat("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "America/Sao_Paulo",
    }).format(date)
  }

  async function handleFetchFileLink(e) {
    e.preventDefault()

    try {
      setLoading(true)

      const { data } = await api.post("/yields", {
        initial_date: formatDate(initialDate),
        final_date: formatDate(finalDate),
      })

      setFileLink(data)
    } catch (error) {
      notify("Houve um erro ao buscar o arquivo", "error")
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <main className="container d-flex justify-content-center flex-column align-items-center p-4">
      <div className="mt-4 shadow p-4 d-flex flex-column gap-4 align-items-center">
        <h1 className="fs-2">Taxas Comparativas</h1>
        <form
          onSubmit={handleFetchFileLink}
          className="d-flex flex-column gap-4"
        >
          <div className="d-flex flex-column gap-2">
            <label htmlFor="startDate">Data Inicial</label>
            <DatePicker
              className="p-1"
              dateFormat="dd/MM/yyyy"
              locale={ptBR}
              id="initialDate"
              selected={initialDate}
              onChange={(value) => setInitialDate(value)}
              maxDate={new Date()}
            />
          </div>

          <div className="d-flex flex-column gap-2">
            <label htmlFor="finalDate">Data Final</label>
            <DatePicker
              className="p-1"
              dateFormat="dd/MM/yyyy"
              locale={ptBR}
              selected={finalDate}
              id="finalDate"
              onChange={(value) => setFinalDate(value)}
              maxDate={new Date()}
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary p-1"
            disabled={loading}
          >
            Buscar
          </button>
        </form>

        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : fileLink?.url ? (
          <a
            href={fileLink.url}
            target="_blank"
            className="text-decoration-none btn btn-success d-flex align-items-center gap-1"
          >
            Baixar arquivo <FiDownload />
          </a>
        ) : null}
      </div>
    </main>
  )
}
