import React, { useState, useEffect, useMemo, useCallback } from "react"
import api from "../../../../services/api"

import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import DataTable from "react-data-table-component"
import Loading from "../../../../components/Loading"
import CriCraScheduleEditModal from "../CriCraScheduleEditModal"
import differenceBy from "lodash/differenceBy"
import Swal from "sweetalert2"
import {
  normalizeEditedSchedule,
  eventsStatusDatesTranslate,
} from "../../../../common/debenturesRegisterEventAndStatusTypes"
import "./style.css"

const customStyles = {
  rows: {
    style: {
      minHeight: "50px", // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: "15px",
      fontFamily: "Verdana, sans-serif",
      fontWeight: "bold",
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      fontSize: "14px",
      fontFamily: "Verdana, sans-serif",
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
}

const CriCraSchedulesToRegisterList = () => {
  //Agenda
  const [schedule, setSchedule] = useState([])

  const [selectedTicker, setSelectedTicker] = useState("")

  const [tickerScheduleToBeRegisteredManually, setTickerScheduleToBeRegisteredManually] =
    useState("")

  //Filter
  const [schedulesToRegisterTickerList, setSchedulesToRegisterTickerList] = useState([])
  const [criCraScheduleTickerToEdit, setCriCraScheduleTickerToEdit] = useState("")
  const [criCraScheduleIdToEdit, setCriCraScheduleIdToEdit] = useState(0)
  const [selectedEventsToDelete, setSelectedEventsToDelete] = useState([])
  const [toggleCleared, setToggleCleared] = useState(false)
  const [isFocus, setIsFocus] = useState(false)
  const [pending, setPending] = useState(false)

  //Modal
  const [scheduleEditModal, setScheduleEditModal] = useState(false)
  const [scheduleRowAndIndex, setScheduleRowAndIndex] = useState({
    index: "",
    row: "",
  })

  const [reloadTable, setReloadTable] = useState(false)

  const ListColumns = [
    {
      name: "Editor",
      cell: (row, index) => (
        <div className="d-grid gap-2">
          <button
            className="btn btn-primary btn-sm"
            type="button"
            onClick={() => getRowAndIndexInfo(row, index)}
          >
            <FontAwesomeIcon
              icon={faEdit}
              size="1x"
              className="me-2"
            />
            Editar
          </button>
        </div>
      ),
      grow: 0,
    },
    {
      id: "Event Date",
      name: "EVENT DATE",
      selector: (row) => eventsStatusDatesTranslate("Event Date", row[0]),
    },
    {
      id: "Actual Event Date",
      name: "ACTUAL EVENT DATE",
      selector: (row) => eventsStatusDatesTranslate("Actual Event Date", row[1]),
    },
    {
      id: "Event",
      name: "EVENT",
      selector: (row) => eventsStatusDatesTranslate("Event", row[2]),
      grow: 2,
    },
    {
      id: "Percentual Rate",
      name: "% RATE",
      selector: (row) => eventsStatusDatesTranslate("% RATE", row[3]),
    },
    {
      name: "STATUS",
      selector: (row) => row[4],
      width: "350px",
    },
    {
      id: "Amount",
      name: "AMOUNT",
      selector: (row) => eventsStatusDatesTranslate("AMOUNT", row[5]),
    },
    {
      id: "Internal Use",
      name: "INTERNAL USE",
      selector: (row) => row[6],
    },
    {
      id: "Calculus Reference",
      name: "CALCULUS REF. DATE",
      selector: (row) => row[7],
    },
    {
      id: "Percentage To Principal INCORPORATION",
      name: "% PRINC. INCORPORATION",
      selector: (row) => row[8],
    },
  ]

  useEffect(() => {
    let componentMounted = true
    const fetchData = async () => {
      setPending(true)

      const documents = await api.get("/getPendingCriCraSchedulesToRegister")

      if (componentMounted) {
        setSchedulesToRegisterTickerList(documents.data)
      }

      setPending(false)
    }

    fetchData()
    return () => {
      componentMounted = false
    }
  }, [reloadTable])

  const getCriCraScheduleById = async () => {
    try {
      setPending(true)
      const documents = await api.post("/getCriCraScheduleById", {
        id: criCraScheduleIdToEdit["$oid"],
      })

      documents.data[0].agenda[0].value.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a[0]) - new Date(b[0])
      })

      setSchedule(documents.data[0].agenda[0].value)
      setSelectedTicker(criCraScheduleTickerToEdit)
    } catch (error) {
      console.log(error)
    } finally {
      setPending(false)
      let row = document.getElementById("row-0")
      row.style.display = "none"
    }
  }

  const tickeList = schedulesToRegisterTickerList.map((scheduleObj, ind) => {
    return { ticker: scheduleObj["ticker"], tickerId: scheduleObj["_id"] }
  })

  const handleTickerSchedule = (ticker, tickerId) => {
    setCriCraScheduleTickerToEdit(ticker)
    setCriCraScheduleIdToEdit(tickerId)

    setIsFocus(false)
  }

  const handleTickerScheduleOnPress = (e) => {
    let firstTicker = []

    if (e.key === "Enter") {
      tickeList &&
        tickeList
          .filter((scheduleTicker) =>
            scheduleTicker.ticker
              .toLowerCase()
              .includes(criCraScheduleTickerToEdit.toLowerCase())
          )
          .map((scheduleTicker) => firstTicker.push(scheduleTicker))

      if (firstTicker.length > 0) {
        setCriCraScheduleTickerToEdit(firstTicker[0].ticker)
        setCriCraScheduleIdToEdit(firstTicker[0].tickerId)
      }

      e.preventDefault()
      setIsFocus(false)
      document.getElementById("find-by-ticker").focus()
    }
  }

  const createEmptySchedule = async () => {
    const trimmedTicker = tickerScheduleToBeRegisteredManually.toUpperCase().trim()

    const isCraAndHasElevenDigits =
      trimmedTicker.startsWith("CRA") && trimmedTicker.length == 11

    const hasTenDigits = trimmedTicker.length === 10

    // console.log(isCraAndHasElevenDigits);
    if (isCraAndHasElevenDigits || hasTenDigits) {
      const usrResponse = await Swal.fire({
        icon: "question",
        title: "Desejar criar agenda vazia?",
        text: `Deseja criar agenda vazia para o ticker ${trimmedTicker}?.`,
        showConfirmButton: true,
        showCancelButton: true,
      })

      if (usrResponse.isConfirmed) {
        try {
          const response = await api.post("/registerEmptyCriCraSchedule", {
            ticker: trimmedTicker,
          })

          if (response.status === 201) {
            Swal.fire({
              icon: "success",
              title: "Agenda cadastrada com sucesso!",
              text: `Agenda cadastrada ao banco de dados de produção.`,
              showConfirmButton: true,
            })
          }
        } catch (e) {
          if (e.response.status === 410) {
            Swal.fire({
              icon: "error",
              title: "Falha ao cadastrar nova agenda!",
              text: `Aparentemente esta agenda já foi cadastrada no banco de dados por outro usuário. Caso esta agenda
                      continue nessa lista contate um administrador.`,
              showConfirmButton: true,
            })
          } else if (e.response.status === 409) {
            Swal.fire({
              icon: "error",
              title: "Falha ao cadastrar nova agenda!",
              text: `Já existe agenda para este documento`,
              showConfirmButton: true,
            })
          } else {
            Swal.fire({
              icon: "error",
              title: "Sistema fora do ar",
              text: `Espero um dia essa mensagem nunca aparecer, se isso acontecer, é por que provavelmente algo está errado no back-end`,
              showConfirmButton: true,
            })
          }
        }
      }
    }
  }

  const FilterComponent = useMemo(() => {
    return (
      <>
        <div className="col-md-8 d-flex justify-content-center">
          <div className="col-md-6 mb-3">
            <div className="input-group">
              <span
                className="input-group-text"
                id="find-by-ticker"
              >
                Procurar por ticker
              </span>
              <input
                className="form-control"
                id="search"
                type="text"
                autoComplete="off"
                placeholder="Ticker"
                onChange={(e) => setCriCraScheduleTickerToEdit(e.target.value)}
                onFocus={() => setIsFocus(true)}
                onKeyPress={(e) => handleTickerScheduleOnPress(e)}
                value={criCraScheduleTickerToEdit}
              />
              <button
                className="btn btn-warning"
                type="button"
                disabled={
                  criCraScheduleTickerToEdit.length >= 10 &&
                  criCraScheduleTickerToEdit.length <= 11
                    ? false
                    : true
                }
                onClick={() => getCriCraScheduleById()}
              >
                Filtrar
              </button>
            </div>
            <div className="input-group">
              <span
                className="input-group-text"
                id="find-by-ticker"
              >
                Cadastrar por ticker
              </span>
              <input
                className="form-control"
                id="register"
                type="text"
                autoComplete="off"
                placeholder="Ticker"
                onChange={(e) => setTickerScheduleToBeRegisteredManually(e.target.value)}
                value={tickerScheduleToBeRegisteredManually}
              />
              <button
                className="btn btn-warning"
                type="button"
                disabled={
                  tickerScheduleToBeRegisteredManually.length >= 10 &&
                  tickerScheduleToBeRegisteredManually.length <= 11
                    ? false
                    : true
                }
                onClick={createEmptySchedule}
              >
                Criar agenda vazia
              </button>
            </div>
            {isFocus ? (
              <div className="scroll-show-tickers-attributes mb-2 mt-1">
                {tickeList &&
                  tickeList
                    .filter((scheduleTicker) =>
                      scheduleTicker.ticker
                        .toLowerCase()
                        .includes(criCraScheduleTickerToEdit.toLowerCase())
                    )
                    .map((scheduleTicker) => (
                      <div
                        key={scheduleTicker.ticker}
                        id="ticker-select"
                        name={scheduleTicker.ticker}
                        onClick={() =>
                          handleTickerSchedule(
                            scheduleTicker.ticker.trim(),
                            scheduleTicker.tickerId
                          )
                        }
                      >
                        <span>{scheduleTicker.ticker}</span>
                      </div>
                    ))}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-8 justify-content-center mt-2 text-center">
          <button
            disabled={!schedule.length > 0}
            className="btn btn-success"
            onClick={() => saveCriCraAndScheduleOnProductionDB()}
          >
            Salvar Agenda Editada
          </button>
        </div>
        <div className="col-md-8 d-flex justify-content-center mt-3">
          <span className="fs-5 text fw-bold">
            <hr />
            {selectedTicker}
          </span>
        </div>
      </>
    )
  }, [
    schedule,
    criCraScheduleTickerToEdit,
    isFocus,
    selectedTicker,
    tickerScheduleToBeRegisteredManually,
    reloadTable,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  const saveAgenda = (newSchedule) => {
    setSchedule(newSchedule)
  }

  const handleRowSelected = useCallback((state) => {
    setSelectedEventsToDelete(state.selectedRows)
  }, [])

  const deleteScheduleEvent = useMemo(() => {
    const handleDelete = () => {
      setToggleCleared(!toggleCleared)
      setSchedule(differenceBy(schedule, selectedEventsToDelete, [0, 1, 2]))
    }

    return (
      <button
        key="delete"
        className="btn btn-danger"
        onClick={handleDelete}
      >
        Deletar Eventos
      </button>
    )
  }, [schedule, selectedEventsToDelete, toggleCleared])

  const saveCriCraAndScheduleOnProductionDB = async () => {
    let normalizedAgenda = normalizeEditedSchedule(schedule)

    const usrResponse = await Swal.fire({
      icon: "question",
      title: "Salvar dados na agenda",
      text: `Deseja salvar a agenda editada?.`,
      showConfirmButton: true,
      showCancelButton: true,
    })

    if (usrResponse.isConfirmed) {
      try {
        const response = await api.post("/saveCriCraAndScheduleOnProductionDB", {
          agenda: normalizedAgenda,
          id: criCraScheduleIdToEdit,
        })

        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Agenda cadastrada com sucesso!",
            text: `Agenda cadastrada ao banco de dados de produção.`,
            showConfirmButton: true,
          })
        }
      } catch (e) {
        if (e.response.status === 410) {
          Swal.fire({
            icon: "error",
            title: "Falha ao cadastrar CRI/CRA!",
            text: `Aparentemente esta agenda já foi cadastrada no banco de dados por outro usuário. Caso esta agenda
                    continue nessa lista contate um administrador.`,
            showConfirmButton: true,
          })
        } else {
          Swal.fire({
            icon: "error",
            title: "Sistema fora do ar",
            text: `Espero um dia essa mensagem nunca aparecer, se isso acontecer, é por que provavelmente algo está errado no back-end`,
            showConfirmButton: true,
          })
        }
      } finally {
        setPending(true)
        setSchedule([])
        setSelectedTicker("")
        setSchedulesToRegisterTickerList([])
        setCriCraScheduleTickerToEdit("")
        setCriCraScheduleIdToEdit(0)

        setReloadTable(!reloadTable)
      }
    }
  }

  const getRowAndIndexInfo = (row, index) => {
    setScheduleEditModal(true)

    setScheduleRowAndIndex({ row: row, index: index })
  }

  const renderScheduleEditModal = () => {
    return scheduleEditModal ? (
      <CriCraScheduleEditModal
        show={scheduleEditModal}
        onHide={() => setScheduleEditModal(false)}
        rowSchedule={scheduleRowAndIndex.row}
        rowIndex={scheduleRowAndIndex.index}
        schedule={schedule}
        saveAgenda={saveAgenda}
      />
    ) : null
  }

  return (
    <>
      {renderScheduleEditModal()}
      <DataTable
        title=" "
        fixedHeader
        customStyles={customStyles}
        columns={ListColumns}
        data={schedule}
        noDataComponent={"Nenhuma Agenda pendente encontrada para o CRI/CRA digitada..."}
        subHeader
        subHeaderComponent={FilterComponent}
        highlightOnHover
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        contextMessage={{
          singular: "Evento",
          plural: "Eventos",
          message: "selecionado(s) para deletar",
        }}
        contextActions={deleteScheduleEvent}
        progressPending={pending}
        progressComponent={<Loading />}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
        paginationPerPage={100}
      />
    </>
  )
}

export default CriCraSchedulesToRegisterList
