import React from "react"
import { Card, Accordion, Button, Badge } from "react-bootstrap"
import ColorBadge from "../Badge"

const AccordionCard = ({
  index,
  data,
  handleStatusColor,
  handleSummaryColor,
  executionTimeArray,
  handleOpenValueModal,
}) => {
  if (
    data.index_id === "DI_futures_intraday" ||
    data.index_id === "BACEN_SELIC_TARGET"
  ) {
    return (
      <Card key={data.index_id}>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="text"
            eventKey={String(index)}
            className="d-flex justify-content-between"
          >
            <span>
              <ColorBadge
                variant={handleSummaryColor(
                  data.index_id,
                  data.process_data.length
                )}
              />
              {data.index_id}
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={String(index)}>
          <Card.Body className="d-flex justify-content-between flex-wrap">
            {executionTimeArray.map((time) => {
              return (
                <Button
                  key={time}
                  variant={handleStatusColor(
                    time,
                    data.index_id,
                    data.process_data.length
                  )}
                  onClick={() => handleOpenValueModal(time, data)}
                  className="m-2 w-25"
                  size="sm"
                >
                  {time}
                </Button>
              )
            })}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  } else if (
    data.index_id === "IPCA_DEBENTURE_REFERENCE_NTNB" ||
    data.index_id === "IPCA_CRI_CRA_REFERENCE_NTNB"
  ) {
    return (
      <Card key={data.index_id}>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="text"
            eventKey={String(index)}
            className="d-flex justify-content-between"
          >
            <span>
              <ColorBadge
                variant={handleSummaryColor(
                  data.index_id,
                  data.process_data.length
                )}
              />
              {data.index_id === "IPCA_DEBENTURE_REFERENCE_NTNB"
                ? "DEBÊNTURES - NTNB Referência"
                : "CRI/CRAs - NTNB Referência"}{" "}
              - {data.process_data.length}
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={String(index)}>
          <Card.Body className="d-flex justify-content-between flex-wrap">
            <Button
              variant={handleSummaryColor(
                data.index_id,
                data.process_data.length
              )}
              onClick={() => handleOpenValueModal("", data)}
              className="m-2 w-25"
              size="sm"
            >
              Mostrar dados
            </Button>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  } else {
    return (
      <Card key={data.index_id}>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="text"
            eventKey={String(index)}
            className="d-flex justify-content-between"
          >
            <span>
              <ColorBadge
                variant={handleSummaryColor(
                  data.index_id,
                  data.process_data.length
                )}
              />
              {data.index_id}
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={String(index)}>
          <Card.Body className="d-flex justify-content-between flex-wrap">
            <Button
              variant={handleSummaryColor(
                data.index_id,
                data.process_data.length
              )}
              onClick={() => handleOpenValueModal("", data)}
              className="m-2 w-25"
              size="sm"
            >
              Mostrar dados
            </Button>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }
}

export default AccordionCard
