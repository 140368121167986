import React, { useCallback, useEffect, useState } from "react"
import { AiOutlineReload } from "react-icons/ai"
import DatePicker from "react-datepicker"
import ptBR from "date-fns/locale/pt-BR"
import notify from "../../common/toast"

import api from "../../services/api"
import Loading from "../../components/Loading"
import { holidaysList } from "../../utils/holidays"

export default function NewsletterDashboard() {
  const [registeredUsers, setRegisteredUsers] = useState([])
  const [filesList, setFilesList] = useState({})
  const [filesInput, setFilesInput] = useState([])
  const [dateInput, setDateInput] = useState(new Date())
  const [emailInput, setEmailInput] = useState("")
  const [subscribeLoading, setSubscribeLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [attachmentLoading, setAttachmentLoading] = useState(false)
  const [filesLoading, setFilesLoading] = useState(false)

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target

    if (checked) {
      setFilesInput((prevFilesInput) => [...prevFilesInput, value])
    } else {
      setFilesInput((prevFilesInput) =>
        prevFilesInput.filter((file) => file !== value)
      )
    }
  }

  const isWeekday = (date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  const subscribeUser = async (e) => {
    e.preventDefault()

    if (emailInput.length < 7) {
      return notify("Digite um e-mail válido", "error")
    }

    try {
      setSubscribeLoading(true)

      const response = await api.post("newsletter/users/", {
        email: emailInput,
      })

      notify("Usuário cadastrado com sucesso", "success")
      setEmailInput("")
    } catch (err) {
      console.log(err)
      notify("Houve um erro ao cadastrar o usuário", "error")
    } finally {
      setSubscribeLoading(false)
    }
  }

  const formatDate = (date) => {
    return Intl.DateTimeFormat("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "America/Sao_Paulo",
    }).format(date)
  }

  const getRegisteredUsers = async (signal) => {
    try {
      setLoading(true)

      const { data } = await api.get("newsletter/users", {
        signal: signal,
      })

      setRegisteredUsers(data)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const ac = new AbortController()
    getRegisteredUsers(ac.signal)

    return () => {
      ac.abort()
    }
  }, [])

  const handleUpdateDate = (value) => {
    setDateInput(value)
  }

  const getFilesList = async () => {
    const ac = new AbortController()
    setFilesInput([])
    try {
      setFilesLoading(true)
      const { data } = await api.get(
        `newsletter/files?date=${formatDate(dateInput)}`,
        {
          signal: ac.signal,
        }
      )

      setFilesList(data)
    } catch (err) {
      console.log(err)
    } finally {
      setFilesLoading(false)
    }
  }

  const sendNewsletter = async (e) => {
    e.preventDefault()

    if (filesInput.length > 0) {
      try {
        setAttachmentLoading(true)
        const respose = await api.post("newsletter", {
          files: filesInput,
        })

        notify("Arquivos enviados com sucesso", "success")
      } catch (err) {
        console.log(err)
        notify("Não foi possível enviar os arquivos", "error")
      } finally {
        setAttachmentLoading(false)
      }
    }
  }

  return (
    <main
      className="container-fluid p-4 d-flex justify-content-center flex-column align-items-center"
      style={{
        height: "fit-content",
      }}
    >
      <div className="d-flex justify-content-center mb-4">
        <h2>Newsletter Dashboard</h2>
      </div>

      <div
        className="container-fluid d-flex shadow p-4"
        style={{
          width: "fit-content",
        }}
      >
        <div
          className="border-end"
          style={{
            minWidth: "400px",
          }}
        >
          <section>
            <h3 className="text-center">Envio FIA-Report</h3>
            <form
              action="post"
              onSubmit={sendNewsletter}
              className="d-flex flex-column align-items-center gap-4 p-4"
            >
              <div className="d-flex gap-2 justify-content-between align-items-center">
                <DatePicker
                  className="p-1"
                  dateFormat="dd/MM/yyyy"
                  locale={ptBR}
                  selected={dateInput}
                  onChange={(value) => handleUpdateDate(value)}
                  filterDate={isWeekday}
                  maxDate={new Date()}
                  excludeDates={holidaysList}
                />
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={getFilesList}
                >
                  {filesLoading ? (
                    <div
                      className="spinner-border text-light"
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Buscar"
                  )}
                </button>
              </div>

              <div className="w-100 ">
                {Object.keys(filesList).length ? (
                  <div className="d-flex flex-column gap-4">
                    <div className="d-flex flex-column">
                      <span>Deb:</span>
                      {filesList.deb.length ? (
                        filesList.deb.map((deb) => (
                          <div
                            className="d-flex gap-2 justify-content-between"
                            style={{
                              marginLeft: "1rem",
                            }}
                            key={deb}
                          >
                            <label htmlFor={deb} className="form-check-label">
                              {deb}
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              value={deb}
                              name={deb}
                              id={deb}
                              onChange={handleCheckboxChange}
                            />
                          </div>
                        ))
                      ) : (
                        <div
                          className="d-flex gap-2 justify-content-between"
                          style={{
                            marginLeft: "1rem",
                          }}
                        >
                          <span>Não há dados</span>
                        </div>
                      )}
                    </div>

                    <div className="d-flex flex-column">
                      <span>Gov:</span>
                      {filesList.gov.length ? (
                        filesList.gov.map((gov) => (
                          <div
                            className="d-flex gap-2 justify-content-between"
                            style={{
                              marginLeft: "1rem",
                            }}
                            key={gov}
                          >
                            <label htmlFor={gov} className="form-check-label">
                              {gov}
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              value={gov}
                              name={gov}
                              id={gov}
                              onChange={handleCheckboxChange}
                            />
                          </div>
                        ))
                      ) : (
                        <div
                          className="d-flex gap-2 justify-content-between"
                          style={{
                            marginLeft: "1rem",
                          }}
                        >
                          <span>Não há dados</span>
                        </div>
                      )}
                    </div>

                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={filesInput.length < 1 || attachmentLoading}
                    >
                      Enviar arquivos selecionados
                    </button>
                  </div>
                ) : null}
              </div>
            </form>
          </section>
        </div>

        <div>
          <section>
            <h3 className="text-center">Cadastro FIA-Report</h3>
            <form
              action="post"
              onSubmit={(e) => subscribeUser(e)}
              className="d-flex flex-column align-items-center gap-4 p-4 border-bottom"
            >
              <div className="">
                <label htmlFor="email" className="form-label">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  value={emailInput}
                  onChange={(e) => setEmailInput(e.target.value)}
                />
              </div>

              <button
                type="submit"
                className="btn btn-primary"
                disabled={subscribeLoading}
              >
                Cadastrar e-mail
              </button>
            </form>
          </section>

          <section className="mt-4">
            <h3 className="text-center">Usuários ativos</h3>
            <div
              className="p-4 d-flex flex-column gap-2 align-items-center h-100"
              style={
                {
                  // height: "31.25rem",
                }
              }
            >
              <div>
                <button type="button" onClick={getRegisteredUsers}>
                  <AiOutlineReload />
                </button>
              </div>

              <div className="d-flex justify-content-center h-100 w-100">
                {loading ? (
                  <div className="align-self-center">
                    <Loading height="25" />
                  </div>
                ) : (
                  <ul className="list-group">
                    {registeredUsers.map((user) => (
                      <li
                        className="list-group-item d-flex justify-content-between gap-2"
                        key={user.email}
                      >
                        <span>{user.email}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  )
}
