import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import api from "../../services/api";

import { loginAction } from "../../actions/authAction";
import { setToken } from "../../auth/credentials";
import "./styles.css";

function SignIn() {
  const [loginInput, setLoginInput] = useState([
    { email: "", password: "", error: "" },
  ]);
  const error = useSelector((state) => state.auth.error);
  const dispatch = useDispatch();

  const handleSignIn = (e) => {
    e.preventDefault();
    dispatch(loginAction(loginInput));
  };

  const login = async () => {
    try {
      const signin = await api.post("/authenticate", {
        email: loginInput.email,
        password: loginInput.password,
      });
      setToken(signin.data.token);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="login-form">
      <form onSubmit={handleSignIn}>
        <h2 className="text-center">Log in</h2>
        <div className="form-group">
          <input
            type="email"
            placeholder="Endereço de e-mail"
            className="form-control"
            required
            onChange={(e) =>
              setLoginInput({ ...loginInput, email: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Senha"
            className="form-control"
            required
            onChange={(e) =>
              setLoginInput({ ...loginInput, password: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <button
            onClick={() => login()}
            className="btn btn-primary btn-block"
            type="submit"
          >
            Entrar
          </button>
        </div>
        <hr />
        <center>{error}</center>
        <center>
          <Link to="/forgot_password"> Esqueceu sua senha? </Link>
        </center>
      </form>
    </div>
  );
}
export default SignIn;
