import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal'

import { faSave, faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const QaChangesConfirm = (props) => {
  const { show, criCraQaData, confirmCriCraQaData, criCraPricingFinalSteps, isFinalDataAnalysis } = props
  const [qaDataToSave, setQaDataToSave] = useState([])

  useEffect(() => {
    var modfiedQaData = criCraQaData.filter(objeto => objeto.hasOwnProperty('new_mid_m2m') || objeto.hasOwnProperty('new_reliability_score'));
    var qaDataToSaveArr = []
    modfiedQaData.forEach(obj => {
      if (parseFloat(obj.new_mid_m2m).toFixed(4) !== parseFloat(obj.mid_m2m).toFixed(4) || 
      parseInt(obj.new_reliability_score) !== parseInt(obj.reliability_score)) {
        qaDataToSaveArr.push(obj)
      }
    });

    setQaDataToSave(qaDataToSaveArr)
  }, [criCraQaData, show])

  return (
    <>
      <Modal
          show={props.show}
          onHide={props.onHide}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Confirmação de alterações
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className="text-center"> Valores Alterados :</h4>
              <div className="row">
                <div className="col-md-4 text-center">
                  {qaDataToSave.map(function(attr, index) {
                    return(
                      <span key={index}>
                        {attr.new_mid_m2m ?
                          <p>
                            {attr.ticker}: <span className="text-danger fw-bold">{parseFloat(attr.mid_m2m).toFixed(4)}</span>
                          </p>
                        : null}
                        {attr.new_reliability_score ?
                          <p>
                            {attr.ticker}: <span className="text-danger fw-bold">{attr.reliability_score}</span>
                          </p>
                        : null}
                      </span> 
                    )
                  })}
                  </div>
                  <div className="col-md-4 d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon 
                      icon={faLongArrowAltRight} 
                      size="6x" />
                        
                  </div>
                  <div className="col-md-4 text-center">
                  {qaDataToSave.map(function(attr, index){ 
                    return(
                      <span key={index}>
                        {attr.new_mid_m2m ?
                          <p>
                            {attr.ticker}: <span className="text-danger fw-bold">{parseFloat(attr.new_mid_m2m).toFixed(4)}</span>
                          </p>
                        : null}
                        {attr.new_reliability_score ?
                          <p>
                            {attr.ticker}: <span className="text-success fw-bold">{attr.new_reliability_score}</span>
                          </p>
                        : null}
                      </span>
                    )
                  })}
                  </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <div className="d-grid gap-2 text-center d-md-block mx-auto">
              { isFinalDataAnalysis ? 
              <button 
                  className="btn btn-success me-2"
                  onClick={() => criCraPricingFinalSteps()}>    
                  <FontAwesomeIcon 
                  icon={faSave} 
                  size="1x" />
                    Confirmar Alterações e Finalizar
              </button> 
              :
              <button 
                  className="btn btn-success me-2"
                  onClick={() => confirmCriCraQaData()}>    
                  <FontAwesomeIcon 
                  icon={faSave} 
                  size="1x" />
                    Confirmar Alterações e Calcular
              </button> 
              }
                
              <button 
                className="btn btn-danger ms-2 "
                onClick={props.onHide}>Close
              </button>                
            </div>
            </Modal.Footer>
        </Modal>
      </>
      );
};

export default QaChangesConfirm;
