import React, { useState } from "react";
import { useSelector } from "react-redux";

import api from "../../services/api";
import { resetForm } from "../../common";
import "./styles.css";

function ChangePassword() {
  const id = useSelector((state) => state.auth.credentials.user_id);
  const [passwordsInput, setPasswordsInput] = useState([
    { currentPassword: "", password: "", password_confirmation: "", msg: "" },
  ]);

  const ChangePassword = () => {
    const { currentPassword, password } = passwordsInput;
    api
      .put(`/users/${id}`, { currentPassword, password })
      .then((resp) => {
        setPasswordsInput({ ...passwordsInput, msg: resp.data.success });
        resetForm("changePwForm");
      })
      .catch((e) => {
        setPasswordsInput({ ...passwordsInput, msg: e.response.data.error });
      });
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    if (passwordsInput.password !== passwordsInput.password_confirmation) {
      setPasswordsInput({
        ...passwordsInput,
        msg: "Nova senha e confirmação não batem!",
      });
    } else if (passwordsInput.password.length < 6) {
      setPasswordsInput({
        ...passwordsInput,
        msg: "Sua senha precisar ter, no mínimo, 6 digitos!",
      });
    } else {
      ChangePassword();
    }
  };

  return (
    <div className="login-form">
      <form id="changePwForm" onSubmit={handleChangePassword}>
        <h2 className="text-center">Password</h2>
        <div className="form-group">
          <input
            type="password"
            placeholder="Senha atual"
            className="form-control"
            required
            autoComplete="off"
            onChange={(e) =>
              setPasswordsInput({
                ...passwordsInput,
                currentPassword: e.target.value,
              })
            }
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Nova Senha"
            className="form-control"
            required
            autoComplete="off"
            onChange={(e) =>
              setPasswordsInput({ ...passwordsInput, password: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Confirmação da nova senha"
            className="form-control"
            required
            autoComplete="off"
            onChange={(e) =>
              setPasswordsInput({
                ...passwordsInput,
                password_confirmation: e.target.value,
              })
            }
          />
        </div>
        <div className="form-group">
          <button className="btn btn-primary btn-block" type="submit">
            Alterar
          </button>
        </div>
        <hr />
        <center>{passwordsInput.msg}</center>
      </form>
    </div>
  );
}

export default ChangePassword;
