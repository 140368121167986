import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

import "./style.css";


const Menu = ({gov_bonds_pricing_qa}) => {
    const [dateToFilter, setDateToFilter] = useState(new Date());

    const [checkIsPreview, setCheckIsPreview] = useState(false)

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    return (
        <>
        <div className="col-md-12 d-flex justify-content-center">
            <div className="row pricingDFA_menu">
                <div className="d-grid gap-2 d-md-block card header mb-3 text-center">
                    <div className="date-filter-input text-center">
                        <label>
                            <span className="fw-bolder">Selecione a data:</span>
                        </label>  
                        <div className="mb-3 mt-2">
                            <DatePicker
                                className="dfa-form-control"
                                dateFormat="dd/MM/yyyy"
                                locale={ptBR}
                                selected={dateToFilter}
                                onChange={value => setDateToFilter(value)}
                                maxDate={new Date()}
                                filterDate={isWeekday}
                            />
                        </div> 
                        
                        <div className="form-check form-switch d-flex justify-content-center">
                            <input className="form-check-input" type="checkbox" onChange={
                                () => setCheckIsPreview(!checkIsPreview)
                            }/>
                            <label className="form-check-label ml-2">&nbsp; Prévia ?</label>
                        </div> 

                        <div className="text-center mt-3">
                            <button 
                                className='btn dfa-button'
                                id='dfa-button'
                                onClick={() => gov_bonds_pricing_qa(dateToFilter, checkIsPreview)}
                            >
                            Carregar Pricing Gov QA
                            </button>
                        </div> 
                    </div>     
                </div>
            </div>
        </div>
        </>
        );
    };

export default Menu;