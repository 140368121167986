import React from "react";

import Card from 'react-bootstrap/Card'
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import InstrumentGovBondsTable from "../InstrumentGovBondsTable";

import "./style.css";


const InstrumentGroupContainer = ({instrumentName, filteredDate, govBond}) => {
    const instrumentRename = (instrumentName) => {
        if (instrumentName === "LTN"){
            return "LTN"
        } else if (instrumentName === "LFT") {
            return "LFT"
        } else if (instrumentName === "NTF") {
            return "NTN-F"
        } else {
            return "NTN-B"
        }
    }

    return (
        <div className="container pricingDFA_container justify-content-center">
                <div className="pricingDFA_calls_group">
                    <Card className="mt-4 mb-4">
                        <Card.Header as="h5" className="text-center">
                            <span className="fw-bold">
                                Tipo de instrumento: 
                                <span className="dfa-values-color">
                                    &nbsp; {instrumentRename(instrumentName)}
                                </span>
                            </span>
                        </Card.Header>
                        <Card.Body>
                                <InstrumentGovBondsTable
                                    instrumentType={instrumentName}
                                    tableData={govBond}
                                />
                        </Card.Body>
                        <Card.Footer className="text-center">
                            <span className="fw-bold">
                                Data Referência: 
                                <span className="dfa-values-color">
                                    &nbsp; {moment(filteredDate).format("DD/MM/YYYY")}
                                </span>
                            </span>
                        </Card.Footer>
                    </Card>
                </div>
        </div>
    )
}

export default InstrumentGroupContainer;