import axios from "axios"

import { getToken, removeToken } from "../auth/credentials"

const api = axios.create({
  baseURL: "https://internal21.fi-analytics.com.br/api/v1",
  // baseURL: "http://localhost:3001/api/v1",
  headers: { "Content-Type": "application/json" },
})

api.interceptors.request.use((request) => {
  const token = getToken()
  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }
  return request
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // Unauthorized: token has expired, etc.
    if (401 === error.response.status) {
      removeToken()
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  }
)

export default api
