import React, { useState, useEffect } from "react";
import moment from 'moment'
import api from "../../../../services/api";

import CardsCategories from "../CardsCategories";

const Panel = ({processList}) => {
    const [categoriesList, setCategoriesList] = useState([])
    const { date, last_monitor_execution} = processList

    useEffect(() => {
        const getCategories = async () => {
            try {
                const { data } = await api.get("/getProcessMonitorCategories");
              
                setCategoriesList(data);
            } catch (e) {
                console.log(e);
            }
          };
          
          getCategories().then();
    }, []);

    return (
        <div className="container-md">
            <div className="card">
                <div className="card-body">
                    <h5> Relatório de monitoramento do dia:  
                        &nbsp;
                        {moment(date).utc(false).format("DD/MM/YYYY")} 
                    </h5>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-md-3 g-4 mt-2">
                {categoriesList.map(function(categorie, index) {
                    return(
                        <div key={index}>
                            <CardsCategories
                                title={categorie}
                                lastExecution={last_monitor_execution}
                                categorie={processList[categorie]}
                            />
                        </div>
                    )})}   
            </div>
        </div>
        );
    };

export default Panel;