import React, { useState } from "react";

import axios from "axios";

import Modal from "react-modal";

import { AiOutlineCloseCircle } from "react-icons/ai";
import notify from "../../../../../common/toast";

import { getToken } from "../../../../../auth/credentials";

import "./style.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const SaveAgenda = ({
  showModalSaveAgenda,
  setShowModalSaveAgenda,
  agendaId,
  agendaEvents,
}) => {
  const [changes, setChanges] = useState("");

  const getAgendaValueFormated = events => {
    let copyAgenda = JSON.parse(JSON.stringify(events));
    copyAgenda.forEach(
      (event, index) => (copyAgenda[index] = event.slice(0, 9))
    );

    return copyAgenda;
  };

  const saveChanges = async () => {
    if (!changes) {
      return notify("Preencha o resumo das alterações", "warning");
    }
    if (!agendaId) {
      return notify(
        "É preciso selecionar um ticker para salvar as alterações",
        "warning"
      );
    }
    try {
      const token = getToken();
      const formatedEvent = getAgendaValueFormated(agendaEvents);
      await axios({
        method: "put",
        url: "https://zf70h40yqf.execute-api.sa-east-1.amazonaws.com/dev/debentures/termssheet",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          agenda_fi_analytics_id: agendaId,
          agenda_change_proposal: {
            value: formatedEvent,
          },
          agenda_change_proposal_summary: changes,
        },
      });
      return notify("Alterações salvas com sucesso", "success");
    } catch (e) {
      console.log(e);
      return notify("Houve algum erro ao salvar", "warning");
    }
  };

  return (
    <>
      <Modal
        isOpen={showModalSaveAgenda}
        onRequestClose={() => setShowModalSaveAgenda(true)}
        style={customStyles}
      >
        <div className="button-close-modal">
          <button onClick={() => setShowModalSaveAgenda(false)}>
            <AiOutlineCloseCircle
              color="#7c00d3"
              size="38px"
              background="transparent"
            />
          </button>
        </div>
        <div>
          <label style={{ display: "block" }}>
            <strong>Resumo das alterações</strong>
          </label>
          <textarea
            style={{ width: "500px", display: "block" }}
            value={changes}
            onChange={e => setChanges(e.target.value)}
          />
          <button onClick={saveChanges} className="btn--save-changes">
            Salvar
          </button>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(SaveAgenda);
