import React, { useState } from "react";

import api from "../../services/api";

import { useSelector } from "react-redux";

import swal from "sweetalert";

const EditPassword = () => {
  const [passwordInputs, setPasswordInputs] = useState({
    password: "",
    confirmPassword: "",
  });

  const { user_id } = useSelector((state) => state.userInfo);

  const changePassword = async () => {
    try {
      await api.patch(`/users/${user_id}`, {
        ...passwordInputs,
      });
      if (passwordInputs.password === passwordInputs.confirmPassword) {
        await swal({
          text: "Senha alterada com sucesso",
          icon: "success",
        });
      } else {
        await swal({
          text: "Erro ao alterar a senha. Os campos devem ser iguais",
          icon: "warning",
        });
      }
    } catch (e) {
      await swal({
        text: "Não foi possível alterar a senha.",
        icon: "warning",
      });
      console.log(e);
    }
  };

  const handleChange = (e) => {
    setPasswordInputs({
      ...passwordInputs,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      <div className="login-form">
        <center>
          <h2>Nova senha</h2>
        </center>
        <center>
          <p>Crie uma nova senha.</p>
        </center>
        <div className="form-group">
          <input
            name="password"
            type="password"
            placeholder="Nova senha"
            className="form-control"
            onChange={handleChange}
            value={passwordInputs.password}
          />
        </div>
        <div className="form-group">
          <input
            name="confirmPassword"
            type="password"
            placeholder="Confirmar nova senha"
            className="form-control"
            onChange={handleChange}
            value={passwordInputs.confirmPassword}
          />
        </div>

        <div className="form-group">
          <button
            className="btn btn-primary btn-block"
            onClick={() => changePassword()}
          >
            Alterar senha
          </button>
        </div>
      </div>
    </>
  );
};

export default EditPassword;
