import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";


const Form = ({getProcessMonitorLastExecution}) => {
    const [dateToFilter, setDateToFilter] = useState(new Date());

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    return (
        <div className="card col-12">
            <div className="date-filter-input text-center">
                <label>
                    <span className="fw-bolder">Selecione a data:</span>
                </label>  
                <div className="mb-3 mt-2">
                    <DatePicker
                        className="dfa-form-control"
                        dateFormat="dd/MM/yyyy"
                        locale={ptBR}
                        selected={dateToFilter}
                        onChange={value => setDateToFilter(value)}
                        maxDate={new Date()}
                        filterDate={isWeekday}
                    />
                </div> 
                <div className="text-center">
                    <button 
                        className='btn dfa-button'
                        id='dfa-button'
                        onClick={() => getProcessMonitorLastExecution(dateToFilter)}
                    >
                        Carregar Relatório
                    </button>
                </div>
            </div>
        </div>
        );
    };

export default Form;