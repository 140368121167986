const INITIAL_STATE = {
  url_file: "",
  company_name: "",
  document_date: "",
  affected_tickers: "",
};

function cvmInfos(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SAVE_CVM_INFOS":
      return {
        url_file: action.url_file,
        company_name: action.company_name,
        document_date: action.document_date,
        affected_tickers: action.affected_tickers,
      };
    default:
      return state;
  }
}

export default cvmInfos;
