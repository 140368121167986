import React, { useState } from "react";

import DataTable from 'react-data-table-component';
import CallsTableValidationModal from "../CallsTableValidation";
import DFALineChart from "../DFALineChart"
import Card from 'react-bootstrap/Card'
import moment from 'moment'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { checkIfIsNaNorNone } from "../../common/checkIfIsNaNorNone"

import { customStyles } from "../../common/CallsValidationColsStyles";
import "./style.css";


const CallsValidation = ({
    date, callsToValidate, selectedCalls, callsResult, get_gov_bond_calls_after, 
    DFAResults, isLoadingDFA, isLoadingSave, saveOnDatabase
}) => {
    const [toggleEstimatedResults, setToggleEstimatedResults] = useState(false)
    const [isPreview, setIsPreview] = useState(null)

    const columns = [
        {
            name: 'Vencimento',
            selector: call => moment(call["maturity_date"]).format("DD/MM/YYYY"),
        },
        {
            name: 'Taxa D-1',
            selector: call => checkIfIsNaNorNone(call["previous_mid"]),
        },
        {
            name: 'Meio',
            selector: call => checkIfIsNaNorNone(call["mid"]),
            conditionalCellStyles: [
                {
                    when: call => (Number(call["mid"]) < Number(call["min_limit"]) ||
                                  Number(call["mid"]) > Number(call["max_limit"])) && call["mid"] != null,
                    style: {
                        color: 'white',
                        backgroundColor: "red",
                        fontWeight: 'bold',
                    }
                },
        ],
        },
        {
            name: 'Spread',
            selector: call => checkIfIsNaNorNone(callsResult?.[call["maturity_date"]]?.['callsSpread']),
            conditionalCellStyles: [
                {
                    when: call => Number(callsResult?.[call["maturity_date"]]?.['callsSpread']) < 0,
                    style: {
                        color: 'red',
                        fontWeight: 'bold',
                    }
                },
            ],
        },
        {
            name: 'Limite Inferior',
            selector: call => checkIfIsNaNorNone(call["min_limit"]),
        },
        {
            name: 'Limite Superior',
            selector: call => checkIfIsNaNorNone(call["max_limit"]),
        },
    ]

    const columnsDFAResult = [
        {
            name: 'Vencimento',
            selector: call => moment(call["maturity_date"]).format("DD/MM/YYYY"),
        },
        {
            name: 'Taxa D-1',
            selector: call => checkIfIsNaNorNone(call["previous_mid"]),
        },
        {
            name: 'Meio',
            selector: call => checkIfIsNaNorNone(call["mid"]),
            conditionalCellStyles: [
                {
                    when: call => (Number(call["mid"]) < Number(call["min_limit"]) ||
                                  Number(call["mid"]) > Number(call["max_limit"])) && call["mid"] != null,
                    style: {
                        color: 'white',
                        backgroundColor: "red",
                        fontWeight: 'bold',
                    }
                },
        ],
        },
        {
            name: 'Spread',
            selector: call => checkIfIsNaNorNone(callsResult?.[call["maturity_date"]]?.['callsSpread']),
            conditionalCellStyles: [
                {
                    when: call => Number(callsResult?.[call["maturity_date"]]?.['callsSpread']) < 0,
                    style: {
                        color: 'red',
                        fontWeight: 'bold',
                    }
                },
            ],
        },
        {
            name: 'Limite Inferior',
            selector: call => checkIfIsNaNorNone(call["min_limit"]),
        },
        {
            name: 'Limite Superior',
            selector: call => checkIfIsNaNorNone(call["max_limit"]),
        },
    ]

    const rowsWithOutMidValues = [
        {
            when: call => call["mid"] === null ,
            style: {
                color: 'black',
                backgroundColor: "#ffffcc",
                fontWeight: 'bold',
            }
        },
      ];

      const rowsWithEstimatedMidValues = [
        {
            when: call => call["is_estimated"] === true ,
            style: {
                color: 'white',
                backgroundColor: "#04AA6D",
                fontWeight: 'bold',
            }
        },
      ];

    const SelectedCallsByMaturity = (maturityDateCall) => {
        const {data} = maturityDateCall;

        return(
        <div className="expandedDebentureRow_Container">
            <div className="col-12 pricingDFA_calls_group">
                {selectedCalls.hasOwnProperty(data.maturity_date) ?
                    <CallsTableValidationModal
                        selectedCalls={selectedCalls[data.maturity_date]}
                        callsResult={callsResult}
                    />
                : <span 
                    className="text-center"> 
                    Aparentemente essa data de vencimento não possui calls na primeira etapa...  
                </span> }
            </div>
        </div>
        )
    }

    return (
        <Card>
            <Card.Footer className="col-sm-12 d-flex justify-content-start d-flex">
                    <span className="dfa-values-color text-wrap">
                        {moment(date).format("DD/MM/YYYY")}
                    </span>
            </Card.Footer>
            <Card.Body>
                <DataTable   
                    title={!toggleEstimatedResults ? " Validação de Calls " : " Resultados das Estimativas (DFA)"}
                    customStyles={customStyles}
                    columns={!toggleEstimatedResults ? columns : columnsDFAResult}
                    data={!toggleEstimatedResults ? callsToValidate : DFAResults}
                    highlightOnHover
                    expandableRows
                    expandableRowsComponent={SelectedCallsByMaturity}
                    conditionalRowStyles={!toggleEstimatedResults ? rowsWithOutMidValues : rowsWithEstimatedMidValues}
                />
            </Card.Body>
            <Card.Footer className="text-center">
                { !toggleEstimatedResults ? 
                <>
                    {isLoadingDFA ? 
                    <button className="btn btn-warning mx-3">
                        <FontAwesomeIcon 
                            icon={faSpinner} 
                            size="1x"
                            className="spinner"/>
                        &nbsp; Aguarde...
                    </button> 
                    : 
                    <button 
                        className="btn btn-warning mx-3"
                        onClick={() => get_gov_bond_calls_after()}>
                        DFA
                    </button>
                    }
                    <button 
                        className="btn btn-success mx-3"
                        disabled={!Object.keys(DFAResults).length}
                        onClick={() => setToggleEstimatedResults(!toggleEstimatedResults)}>
                        Mostrar Resultados
                    </button>
                </>
                :
                <>
                    <button 
                        className="btn btn-success mx-3"
                        onClick={() => setToggleEstimatedResults(!toggleEstimatedResults)}>
                        Esconder Resultados
                    </button>
                </>
                }
            </Card.Footer>
            <DFALineChart
                title={!toggleEstimatedResults ? 'Média call - Observações' : 'Média call - Observações + Estimadas'}
                dataToChart={!toggleEstimatedResults ? callsToValidate : DFAResults}
                showResults={toggleEstimatedResults}
            />
            <Card.Footer className="text-center">
                {toggleEstimatedResults ?
                <>
                    {isLoadingSave ? 
                    <button className="btn btn-danger mx-3">
                        <FontAwesomeIcon 
                            icon={faSpinner} 
                            size="1x"
                            className="spinner"/>
                        &nbsp; Salvando...
                    </button> 
                    : 
                    <button 
                        className="btn btn-danger mx-3"
                        onClick={() => saveOnDatabase(isPreview)}
                        disabled={isPreview === null}>
                        Salvar Resultados
                    </button> }

                    <div className="dfa-form-check">
                        <div className="form-check form-check-inline" id="isPreview">
                                <input 
                                    className="dfa-form-check-input me-2" 
                                    name="isPreview" 
                                    type="radio" 
                                    value="True"
                                    onChange={() => setIsPreview(true)}
                                />
                            <label className="form-check-label">Prévia</label>
                        </div>
                        <div className="form-check form-check-inline" id="isPreview">
                                <input 
                                    className="dfa-form-check-input me-2" 
                                    name="isPreview" 
                                    type="radio" 
                                    value="False"
                                    onChange={() => setIsPreview(false)}
                                />
                            <label className="form-check-label">Fechamento</label>
                        </div>
                    </div>
                </> 
                : null}
            </Card.Footer>
        </Card>
    );
};

export default CallsValidation;