import React, { useState, useEffect } from "react";
import { produce } from "immer";
import moment from 'moment'
import Swal from "sweetalert2";
import Loading from "../../components/DFALoading";
import api from "../../services/api"
import { Prompt } from 'react-router-dom';

import Menu from "./components/Menu";
import FullLoading from "../../components/DFAFullLoading";
import TickerListTable from "./components/TickerListTable";
import QaChangesConfirm from "./components/TickerListTable/components/QaChangesConfirm";

const DebenturesTradesPreview = () => {
    const [loader, showLoader, hideLoader] = FullLoading()
    const [isLoading, setIsLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [emergencyButton, setEmergencyButton] = useState(false)
    const [debBondsQaData, setDebBondsQaData] = useState([]);
    const [debBondsCallTradeData, setDebBondsCallTradeData] = useState([]);
    const [isFinalDataAnalysis, setIsFinalDataAnalysis] = useState(false)
    const [finalDataAnalysisDate, setFinalDataAnalysisDate] = useState(
        {
            is_preview: null, 
            date: new Date
        }
    )

    const handleBeforeUnload = (location) => {
        return `Deseja sair desta página? As alterações não salvas serão perdidas.`;
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          event.returnValue = 'Deseja sair desta página? As alterações não salvas serão perdidas.';
          return 'Deseja sair desta página? As alterações não salvas serão perdidas.'; // Para navegadores antigos
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const testIfPricingGovQaWasDone = async (date, triggerDate, is_preview) => {
        try {
            if (moment(date).isSame(triggerDate)){
                const documents = await api.post('testIfPricingGovQaWasDone', {
                    date, is_preview
                });

                return documents.data.result
            } else {
                return 200
            }
        } catch (e) {
            console.log(e)
        }
    }

    const debBondsFinalQaDataApi = async (filters) => {
        var date = moment(filters.date).format("YYYY-MM-DD")
        var is_preview = filters.is_preview
        setFinalDataAnalysisDate(
            {
                is_preview: is_preview, 
                date: date
            }
        )
        setIsFinalDataAnalysis(true)

        try {
            setIsLoading(true);
            setDebBondsQaData([]);
            debBondsGetCallTradeData(date, is_preview)
            const documents = await api.post('debBondsQaData', {
                date, is_preview
            });

            setDebBondsQaData(documents.data.result);
            setIsLoading(false);
            return documents
        } catch (e) {
            console.log(e)
            setIsLoading(false);
            return false
        }
    }

    const checkDateForPreProcess = async (filters) => {
        var triggerDate = moment(new Date()).format("YYYY-MM-DD")
        var date = moment(filters.date).format("YYYY-MM-DD")
        var is_preview = filters.is_preview
        localStorage.setItem("date", date)
        localStorage.setItem("is_preview", is_preview)

        testIfPricingGovQaWasDone(date, triggerDate, is_preview).then(function(fileResponse) {
            if (fileResponse === 200) {
                checkFiaGiFileExistence(date, is_preview).then(function(response) { 
                    if (response.data.result !== 200 && moment(date).isSame(triggerDate)){
                        Swal.fire({
                            title: "Deseja rodar o processo de preparação de dados?",
                            text: "Não é possivel abortar este processo depois de iniciado!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Sim'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                startStepFunction(date, is_preview).then(function(stepFunctionResponse){
                                    if (stepFunctionResponse.data.result === "SUCCEEDED") {
                                        getDebBondsQaData(date, is_preview);
                                        debBondsGetCallTradeData(date, is_preview);
                                    } else {
                                        setEmergencyButton(true)
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: 'Problema na preparação de dados..',
                                        })
                                    }
                                })
                            }
                        })
                    } else {
                        getDebBondsQaData(date, is_preview);
                        debBondsGetCallTradeData(date, is_preview);
                    }
                }) 
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Orelha Seca',
                    text: 'Favor executar o Pricing GOV QA',
                })
            }
        })
    }

    const startStepFunction = async (date, is_preview) => {
        try {
            setIsLoading(true);

            const documents = await api.post('startPreDataProcessStepFunction', {
                date, is_preview
            });

            setIsLoading(false);

            return documents
        } catch (e) {
            console.log(e)
            setIsLoading(false);
        }
    }

    const checkFiaGiFileExistence = async (date, is_preview) => {
        try {
            setIsLoading(true);
            const documents = await api.post('checkFiaGiFileExistence', {
                date, is_preview
            });
            
            setIsLoading(false);
            return documents
        } catch (e) {
            console.log(e)
            setIsLoading(false);
            return false
        }
    } 

    const getDebBondsQaData = async (date, is_preview) => {
        try {
            setIsLoading(true);
            setDebBondsQaData([]);
            const documents = await api.post('debBondsQaData', {
                date, is_preview
            });

            setDebBondsQaData(documents.data.result)
            setIsLoading(false);
            return documents
        } catch (e) {
            console.log(e)
            setIsLoading(false);
            return false
        }
    }

    const debBondsGetCallTradeData = async (date, is_preview) => {
      try {
            setDebBondsCallTradeData([]);
          const documents = await api.post('debBondsGetCallTradeData', {
              date, is_preview
          });
          
          setDebBondsCallTradeData(documents.data.result);
      } catch (e) {
          console.log("Necessário o pre-processamento de dados")
      }
    }

    const saveDebBondsQaDataChanges = async (changes) => {
        try {
            showLoader()
            const documents = await api.post('saveDebBondsQaDataChanges', {
                changes
            });

            hideLoader()
            return documents
        } catch (e) {
            console.log("Erro ao Salvar")
            hideLoader()
        }
    }

    const startDebBondsModelingCalculator = async (date, is_preview, first_request) => {
        try {
            const documents = await api.post('debBondsModelingCalculator', {
                date, is_preview, first_request
            });

            console.log("DebBondsModelingCalculator inicializada")
            console.log(date, is_preview)
        } catch (e) {
            console.log("Erro ao inicializar DebBondsModelingCalculator")
        }
    }

    const startSaveStepFunction = async (date, is_preview) => {
        showLoader()
        const documents = await api.post('startSavingProcessStepFunction', {
            date, is_preview
        });

        hideLoader()
        return documents
    }

    const debBondPricingFinalSteps = async (date, is_preview) => {
        showLoader()
        const documents = await api.post('debBondPricingFinalSteps', {
            date, is_preview
        });

        hideLoader()
        return documents
    }
    
    const changeBondsQaData = (ticker, value, inputName) => {
        setDebBondsQaData(
            produce(debBondsQaData, (qaData) => {
                const qaDataToChange = qaData.findIndex((data) => data.ticker === ticker);
                qaData[qaDataToChange][`new_${inputName}`] = value;
            })
        );
    }

    const confirmSaveBondsQaData = async () => {
        var changedQaData = debBondsQaData.filter(objeto => objeto.hasOwnProperty('new_mid_m2m') || objeto.hasOwnProperty('new_reliability_score') );

        var date = localStorage.getItem("date");
        var is_preview = localStorage.getItem("is_preview");
        
        var qaDataJson = { 
            changes:{
                date: date, 
                is_preview: is_preview,
                changed_data: changedQaData,
            }
        }

        var changes = JSON.stringify(qaDataJson)

        setModalShow(false);

        saveDebBondsQaDataChanges(changes).then(function(response) {
            if (response.status === 200) {
                console.log("Salvo com sucesso!")
                startDebBondsModelingCalculator(date, is_preview, true)
                startDebBondsModelingCalculator(date, is_preview, false)
                startSaveStepFunction(date, is_preview).then(function(stepFunctionResponse){
                    if (stepFunctionResponse.data.result === "SUCCEEDED") {
                        Swal.fire(
                            'Primeira etapa finalizada com sucesso!',
                            'Atualizando com os novos resultados...',
                            'success'
                        )
                    }
                    var filters = { date: date, is_preview: is_preview }
                    debBondsFinalQaDataApi(filters)
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Erro Durante o Calculo...',
                })
            }
        })
        
    }

    const confirmSaveBondsQaDataFinal = async () => {
        var changedQaData = debBondsQaData.filter(objeto => objeto.hasOwnProperty('new_mid_m2m') || objeto.hasOwnProperty('new_reliability_score') );

        var date = finalDataAnalysisDate.date;
        var is_preview = finalDataAnalysisDate.is_preview;
        
        var qaDataJson = { 
            changes:{
                date: date, 
                is_preview: is_preview,
                changed_data: changedQaData,
            }
        }

        var changes = JSON.stringify(qaDataJson)

        setModalShow(false);

        saveDebBondsQaDataChanges(changes).then(function(response) {
            if (response.status === 200) {
                console.log("Salvo com sucesso!")
                startDebBondsModelingCalculator(date, is_preview, true)
                startDebBondsModelingCalculator(date, is_preview, false)
                debBondPricingFinalSteps(date, is_preview).then(function(stepFunctionResponse){
                    if (stepFunctionResponse.data.result === "SUCCEEDED") {
                        Swal.fire(
                            'Processo Finalizado!',
                            'Todas as etapas foram efetuadas com sucesso',
                            'success'
                        )
                    }
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Erro na hora de salvar...',
                })
            }
        })
        
    }

    return ( 
        <>
            <Prompt when={true} message={handleBeforeUnload} />
            <Menu
                getDebBondsQaData={checkDateForPreProcess} 
                debBondsFinalQaDataApi={debBondsFinalQaDataApi}
                emergencyButton={emergencyButton}
                startStepFunction={startStepFunction}
            />
            <div className="col-md-12 justify-content-center" >
                { isLoading ? <Loading/> :
                <TickerListTable 
                    tickerData={debBondsQaData}
                    debBondsCallTradeData={debBondsCallTradeData}
                    changeBondsQaData={changeBondsQaData}
                    openSaveConfirmModal={setModalShow}
                    isFinalDataAnalysis={isFinalDataAnalysis}
                    finalDataAnalysisDate={finalDataAnalysisDate}
                />
                }
            </div>
            { modalShow ?
                <QaChangesConfirm 
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    debBondsQaData={debBondsQaData}
                    saveBondsQaData={confirmSaveBondsQaData}
                    debBondPricingFinalSteps={confirmSaveBondsQaDataFinal}
                    isFinalDataAnalysis={isFinalDataAnalysis}
                />
            : null }  
            {loader} 
        </> 
    );
};

export default DebenturesTradesPreview;
