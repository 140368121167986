import api from '../services/api'
import { history } from '../helpers/history'


export function loginAction(userCredentials) {
    return dispatch => {
        const { email, password } = userCredentials;
        api.post("/authenticate", { email, password })
            .then(resp => { 
                dispatch({type: 'LOGIN', token: resp.data.token});
                history.push('/');
            })
            .catch(e => {
                dispatch({type: 'FAILURE', error: e.response.data.error});
            });
        };
};

export function logoutAction(){
    return dispatch => { 
        dispatch({type: 'LOGOUT'});
        history.push('/signin');
    }
};

    