import React, {useState, useCallback, useMemo} from "react";
import moment from 'moment'
import Modal from 'react-bootstrap/Modal'
import DataTable from 'react-data-table-component';
import AnimatedNumber from 'react-animated-number';
import Checkbox from '@mui/material/Checkbox';

import { formatDecimals } from "../../common/formatDecimals"
import { checkIfIsNaNorNone } from "../../common/checkIfIsNaNorNone"
import { customStyles } from "../../common/CallsTableModalColsStyles";
import "./style.css";


const CallsTableModal = (props) => {
    const [callsResult, setCallsResult] = useState({
        'minBid': 0, 
        'maxAsk': 0, 
        'callsMid': 0,
        'callsSpread': 0
    });
    const [selectedCalls, setSelectedCalls] = useState([]) 

    const {calls, handleIsAnalyzed} = props

    
    const callsResultFields = [
        'minBid', 'maxAsk', 'callsMid', 'callsSpread',
    ]

    const callsTitleFields = [
        'min_limit', 'max_limit', 'min_spread_limit', 'max_spread_limit'
    ]
    
    const selectedRowsCriterea = useCallback(call => { 
        var userUseSelectedCallsConfig = JSON.parse(sessionStorage.getItem('selectedCallsConfig'))
        var userSelectedCalls = sessionStorage.getItem(calls[0]['maturity_date'])

        if (userUseSelectedCallsConfig && !userSelectedCalls){
            var timesSelected = userUseSelectedCallsConfig.times
            var sourcesSelected = userUseSelectedCallsConfig.sources

            if (timesSelected.length > 0 || sourcesSelected.length > 0){
                return timesSelected.includes(call.time) || sourcesSelected.includes(call.source)
            }
        }
        if (userSelectedCalls) {
            return call.selected_by_user === true
        } else {
            return call.candidate === "True"
        }
	}, [calls]);

    const columns = [
        {
            name: 'Fonte',
            selector: call => call["source"],
        },
        {
            name: 'Compra Orig.',
            selector: call => checkIfIsNaNorNone(call["original_bid"]),
        },
        {
            name: 'Venda Orig.',
            selector: call => checkIfIsNaNorNone(call["original_ask"]),
        },
        {
            name: 'Compra',
            selector: call => checkIfIsNaNorNone(call["bid"]),
            conditionalCellStyles: [
                {
                    when: call => Number(call["bid"]) === Number(callsResult["minBid"]),
                    style: {
                        color: 'rgba(224, 13, 94, 0.836)',
                        fontWeight: 'bold',
                    }
            }
        ],
        },
        {
            name: 'Venda',
            selector: call => checkIfIsNaNorNone(call["ask"]),
            conditionalCellStyles: [
                {
                    when: call => Number(call["ask"]) === Number(callsResult["maxAsk"]),
                    style: {
                        color: 'rgb(3, 109, 180)',
                        fontWeight: 'bold',
                    }
            }
        ],
        },
        {
            name: 'Meio',
            selector: call => checkIfIsNaNorNone(call["mid"]),
            conditionalCellStyles: [
                {
                    when: call => Number(call["mid"]) < Number(call["min_limit"]) ||
                                  Number(call["mid"]) > Number(call["max_limit"]),
                    style: {
                        color: 'white',
                        backgroundColor: "red",
                        fontWeight: 'bold',
                    }
            }
        ],
        },
        {
            name: 'Spread',
            selector: call => checkIfIsNaNorNone(call["spread"]),
        },
        {
            name: 'Ultima',
            selector: call => checkIfIsNaNorNone(call["last"]) 
        },
        {
            name: 'Hora',
            selector: call => call["time"],
            width: '10'
        }
    ]

    const handleCallsSelected = useCallback(state => {
        if (state.selectedRows.length > 0){
            var minBid = Math.min.apply(Math, state.selectedRows.filter(function (callAttributeFilter){
                return callAttributeFilter.bid !== 'nan'
            }).map(function(callAttribute) { 
                return callAttribute.bid 
            }))
            var maxAsk = Math.max.apply(Math, state.selectedRows.filter(function (callAttributeFilter){
                return callAttributeFilter.ask !== 'nan'
            }).map(function(callAttribute) { 
                return callAttribute.ask 
            }))
            var callsMid = (parseFloat(minBid) + parseFloat(maxAsk))/2
            var callsSpread = parseFloat(minBid) - parseFloat(maxAsk)

            setCallsResult({
                minBid: parseFloat(minBid), 
                maxAsk: parseFloat(maxAsk), 
                callsMid: callsMid,
                callsSpread: callsSpread 
            })
        } else {
            setCallsResult({
                minBid: ' - ' , 
                maxAsk: ' - ' , 
                callsMid: ' - ' ,
                callsSpread: ' - ' 
            })
        }

        setSelectedCalls(state.selectedRows)
	}, []);

    const translateFields = (field) => {
        switch (field) {
            case 'minBid':
                return 'Compra Mínima'
            case 'maxAsk':
                return 'Venda Máxima'
            case 'callsMid':
                return 'Mid'
            case 'callsSpread':
                return 'Spread'
            case 'spread':
                return 'Spread' 
            case 'min_limit':    
                return 'Limite mínimo' 
            case 'max_limit':    
                return 'Limite Máximo' 
            case 'min_spread_limit':    
                return 'Limite Min. Spread' 
            case 'max_spread_limit':    
                return 'Limite Max. Spread'              
            default:
                return field
        }
    }

    const renderIndicator = (field) => {
        switch (field) {
            case 'minBid':
            return(
                <>
                    <span 
                        className='indicator_box indicator_bid'>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </span> &nbsp;
                </>
            )
            case 'maxAsk':
            return(
                <>
                    <span 
                        className='indicator_box indicator_ask'>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </span> &nbsp;
                </>
            )
            default:
                return <></>
        }
    }

    const renderOutOfLimitMidValues = (field, calculatedMid, minLimit, maxLimit, spread) => {
        if (field === "Spread" && (spread < 0)){
            return "fw-bold text-danger"
        } else if (field === "Mid" &&  (calculatedMid <= minLimit || calculatedMid >= maxLimit)) {
            return "fw-bold text-danger"
        } else {
            return "fw-bold"
        }
    }

    const contextActions = useMemo(() => {
        return (
            <div className="col-md-12">
                {callsResultFields.map((field, index) => {
                    return (
                    <span key={index} className="me-4">
                        {renderIndicator(field)}
                        <span>{translateFields(field)}: &nbsp;</span> 
                        { isFinite(callsResult[field]) ? <AnimatedNumber 
                            className={renderOutOfLimitMidValues(
                                translateFields(field), 
                                callsResult["callsMid"], 
                                calls[0]["min_limit"],
                                calls[0]["max_limit"],
                                callsResult["callsSpread"]
                            )}
                            value={callsResult[field]}
                            formatValue={n => formatDecimals(n)}
                            duration={1000}
                            stepPrecision= {4}
                            style={{
                                transition: '0.8s ease-out',
                                fontSize: 15,
                                transitionProperty:
                                    'background-color, color, opacity'
                            }} 
                        /> : '-' }
                    </span>
                    )
                })}
            </div> 
        )
	}, [calls ,callsResult, callsResultFields]);


    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            centered="true"
            dialogClassName="dfa-modal-xl"
            keyboard={ false }
            aria-labelledby="calls-modal"
            >
            <Modal.Header>
                <span className="dfa-values-color text-wrap position-absolute">
                    {moment(calls[0]['callDate']).format("DD/MM/YYYY")}
                </span>
                <Modal.Title className="col-sm-12 d-flex justify-content-center col-sm-12 d-flex modal-title h5">
                    Data de Vencimento: &nbsp;
                    <span className="dfa-values-color">
                        {moment(calls[0]['maturity_date']).format("DD/MM/YYYY")}
                    </span>
                </Modal.Title>
                <span className="float-end me-2" 
                    aria-hidden="true" 
                    style={{cursor: 'pointer'}}
                    onClick={props.onHide}>
                        X
                </span>
            </Modal.Header>
            <Modal.Header>
                <div id="calls-modal" className="col-md-11 d-flex justify-content-center">
                {callsTitleFields.map((field, index) => {
                    return (
                    <span key={index} className="me-4">
                        <span>{translateFields(field)}: &nbsp;</span> 
                        <AnimatedNumber 
                            className={"fw-bold"}
                            value={parseFloat(calls[0][field])}
                            formatValue={n => formatDecimals(n)}
                            duration={1000}
                            style={{
                                transition: '0.8s ease-out',
                                fontSize: 15,
                                transitionProperty:
                                    'background-color, color, opacity'
                            }}
                        />
                    </span> 
                    )
                })}
                </div>
            </Modal.Header>
            <Modal.Body id="DFA_table">
                <DataTable   
                    title=" "
                    customStyles={customStyles}
                    columns={columns}
                    data={calls}
                    selectableRows
                    selectableRowSelected={selectedRowsCriterea}
                    onSelectedRowsChange={handleCallsSelected}
                    selectableRowsComponent={Checkbox}
                    selectableRowsComponentProps={{sx: { '& .MuiSvgIcon-root': { fontSize: 28 } }, color: "secondary"}}
                    contextActions={contextActions}
                    contextMessage={{ singular: '', plural: '', message: '' }}
                />
            </Modal.Body>
            <Modal.Footer className="text-center">
                <div className="col-md-12 justify-content-center">
                    <button 
                        className="btn dfa-button"
                        onClick={() => handleIsAnalyzed(calls[0]['maturity_date'], selectedCalls, callsResult)}>
                            Finalizar análise
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
        );
};

export default CallsTableModal;
