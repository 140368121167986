import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { customStyles } from '../../../PricingDFA/common/CallsTableModalColsStyles';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons'

// Pintar Bid median e trade yield permitir editar tbm

const BondsCallTradesContainerModal = (props) => {
  const [dataInfo, setDataInfo] = useState([]);
  const { conglomerate, show, onHide, criCraCallTradeData, finalDataAnalysisDate, isFinalDataAnalysis } = props

  useEffect(() => {
    if (conglomerate.length > 0){
      var filteredArray = criCraCallTradeData.filter((element) => conglomerate.includes(element.ticker));
      setDataInfo(filteredArray.sort())
    }
  }, [conglomerate])

  const callsCols = React.useMemo(
		() => [
      {
        name: 'Source',
        selector: row => row.broker,
      },
      {
        name: 'Date',
        selector: row => moment(row.call_dt).format("DD/MM/YYYY"),
      },
      {
        name: 'Bid',
        selector: row => row.bid,
      },
      {
        name: 'Ask',
        selector: row => row.ask,
      },
      {
        name: 'Is Bid Outlier',
        cell: row => row.is_bid_outlier ? <FontAwesomeIcon icon={faCheck} color={"green"} /> 
        : <FontAwesomeIcon icon={faExclamation} color={"red"} />
      },
      {
        name: 'Is Ask Outlier',
        cell: row => row.is_ask_outlier  ? <FontAwesomeIcon icon={faCheck} color={"green"} /> 
        : <FontAwesomeIcon icon={faExclamation} color={"red"} />
      },
    ], [],
	);
  
  const tradesCols = React.useMemo(
    () => 
    [
      {
        name: 'nm_lower_bound',
        selector: row => row.nm_lower_bound,
      },
      {
        name: 'nm_upper_bound',
        selector: row => row.nm_upper_bound,
      },
      {
        name: 'rr_lower_bound',
        selector: row => row.rr_lower_bound,
      },
      {
        name: 'rr_upper_bound',
        selector: row => row.rr_upper_bound,
      },
      {
        name: 'trade_yield_with_boundaries_nm',
        selector: row => row.trade_yield_with_boundaries_nm,
      },
      {
        name: 'trade_yield_with_boundaries_rr',
        selector: row => row.trade_yield_with_boundaries_rr,
      },
    ], []
  );

  const tickerCols = React.useMemo(
		() => [
      {
        name: 'Ticker',
        selector: row => row.ticker,
        sortable: true,
      },
      {
        name: 'Quantidade de Calls',
        selector: row => row.calls.length,
        sortable: true,
      },
      {
        name: 'Quantidade de Trades',
        selector: row => row.trades.length,
        sortable: true,
      },
    ], [dataInfo],
	);

  const ExpandedComponent = ({ data }) => {
    return(
      <div className="justify-content-center text-center">
          <span className="mt-3"> Calls </span>
          <DataTable   
            columns={callsCols}
            data={data["calls"]}
            highlightOnHover
            customStyles={customStyles}
          />
          <span className="mt-3"> Trades </span>
          <DataTable   
            columns={tradesCols}
            data={data["trades"]}
            highlightOnHover
            customStyles={customStyles}
          />
      </div>
    )
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            { isFinalDataAnalysis && finalDataAnalysisDate.is_preview === "True" ? "Prévia": "" }
            &nbsp; { isFinalDataAnalysis && finalDataAnalysisDate.date }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <DataTable   
                columns={tickerCols}
                data={dataInfo}
                highlightOnHover
                customStyles={customStyles}
                expandableRows 
                expandableRowsComponent={ExpandedComponent}
                defaultSortFieldId={1}
              />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BondsCallTradesContainerModal;