import React from "react";
import Modal from 'react-bootstrap/Modal'

import ExpandedComponentList from '../ExpandedComponentList'
import { debenturesInfoTemplate } from "../../../../common/debenturesInfoTemplate";

const DebenturesModalCustomRegister = (props) => {
  return (
    <Modal
          show={props.show}
          onHide={props.onHide}
          size="xl"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Cadastrar Debênture Manualmente
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExpandedComponentList
                DebentureInfoRow={debenturesInfoTemplate}
                $isCustomDebentureFlag={true}
                reloadDataTable={props.reloadDataTable}
            />
          </Modal.Body>
      </Modal>
    );
};

export default DebenturesModalCustomRegister;
