export const transformStringDatePTBRToFormatMMDDYYYY = (date) => {
  const newFormatDate = new Date(date.split("/").reverse().join("/"))

  return newFormatDate
}

export const transformStringISODateToMMDDYYYY = (dateP) => {
  if (!dateP) return null

  if (dateP instanceof Date) return dateP

  const date = new Date(dateP.split("T")[0].split("-").join("/"))

  return new Date(date)
}

export const getDayBefore = (date) => {
  const yesterday = new Date(date.getTime())
  yesterday.setDate(date.getDate() - 1)
  return yesterday
}

export const transformDateMMDDYYYYToStringDatePTBR = (dateParam) => {
  if (!(dateParam instanceof Date)) return ""

  const date = dateParam,
    day = date.getDate().toString(),
    dayF = day.length === 1 ? "0" + day : day,
    month = (date.getMonth() + 1).toString(),
    monthF = month.length === 1 ? "0" + month : month,
    yearF = date.getFullYear()
  return `${dayF}/${monthF}/${yearF}`
}

export const isoDateToDDMMYYYY = (stringISODate) => {
  if (!stringISODate) {
    return
  }
  const dateObject = new Date(stringISODate)
  const localizedStringDate = dateObject.toLocaleString("pt-BR", {
    timeZone: "UTC",
    day: "numeric",
    month: "numeric",
    year: "numeric",
  })
  return localizedStringDate
}

export const transformDateToAnyString = (dateParam, localZone) => {
  // localZone: "pt-BR", "en-US", etc
  if (!dateParam || !localZone) {
    return
  }

  const dateObj = new Date(dateParam)
  const localizedStringDate = dateObj.toLocaleString(localZone, {
    timeZone: "UTC",
    day: "numeric",
    month: "numeric",
    year: "numeric",
  })
  return localizedStringDate
}

export const transformAnyStringDateToDate = (stringDate) => {
  if (!stringDate) {
    return
  }

  const dateObj = new Date(stringDate)

  const localizedStringDate = dateObj.toLocaleString("en-US", {
    timeZone: "UTC",
    day: "numeric",
    month: "numeric",
    year: "numeric",
  })

  return new Date(localizedStringDate)
}
