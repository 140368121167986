export const eventTypes = [
  "MATURITY_REDEMPTION",
  "INTEREST_PAYMENT",
  "AMORTIZATION",
  "INTEREST_INCORPORATION",
  "PREMIUM",
  "CHARGE",
  "EARLY_PARTIAL_REDEMPTION",
  "TOTAL_EARLY_REDEMPTION",
  "EXTRAORDINARY_AMORTIZATION",
  "GENERIC_EVENT",
  "PREMIUM_AS_PERCENTAGE_OF_CURRENT_NOTIONAL",
  "PREMIUM_AS_PERCENTAGE_OF_ACCRUED_PRICE",
  "PREMIUM_AS_LUMPSUM",
  "COUPON_DEFERRED",
  "COUPON_PARTIALLY_INCORPORATED_INTO_PRINCIPAL",
  "PREMIUM_AS_PERCENTAGE_OF_CURRENT_NOTIONAL_DEFERRED",
  "PREMIUM_AS_PERCENTAGE_OF_ACCRUED_PRICE_DEFERRED",
  "REPACTUATION",
  "EXTRAORDINARY_CAPITALIZATION",
  "COUPON_PARTIALLY_DEFERRED_PARTIALLY_INCORPORATED_BR_CORP",
  "COUPON_PARTIALLY_DEFERRED_PARTIALLY_PAID_BR_CORP",
  "COUPON_INCORPORATED_INTO_PRINCIPAL_THEN_AMORTIZATION_BR_CORP",
  "COUPON_THREE_WAY_SPLIT",
  "SPLIT",
  "RESIDUAL_PAYMENT",
].sort()
