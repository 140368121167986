import React from 'react';
import moment from 'moment'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

ChartJS.defaults.datasets.line.showLine = false;
ChartJS.defaults.elements.point.radius = 6

const DFALineChart = ({title, dataToChart, showResults}) => {
    var options = {
        responsive: true,
        pointStyle: 'circle',
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: title,
          },
        },
    }; 

    const renderGreenCircleIfValueIsEstimated = (is_estimated) => {
      if (!is_estimated) {
        return 'rgb(233, 208, 47)'
      } else {
        return 'rgb(4, 170, 109)'
      }
    } 

    const renderRedBorderIfValueEstimatedIsOutOfLimit = (objects) => {
      const {max_limit, mid, min_limit} = objects

      if (mid >= min_limit && mid <= max_limit){
        return 'rgb(233, 208, 47)'
      } else {
        return 'red'
      }
    }

    const labels = dataToChart.map((obj) => moment(obj.maturity_date).format("DD/MM/YYYY"))

    const data = {
        labels,
        datasets: [
            {
                label: 'Taxa D-1',
                data: dataToChart.map((obj) => obj.previous_mid ? obj.previous_mid : '-'),
                borderColor: 'rgb(0, 119, 255)',
                backgroundColor: 'rgb(0, 119, 255)',
                showLine: true,
                pointRadius: 3,
                tension: 0.1,
            },
            {
                label: 'Limite Inferior',
                data: dataToChart.map((obj) => obj.min_limit),
                borderColor: 'rgb(18, 0, 0)',
                backgroundColor: 'rgb(18, 0, 0)',
                showLine: true,
                pointRadius: 3,
                tension: 0.1,
            },
            {
                label: 'Limite Superior',
                data: dataToChart.map((obj) => obj.max_limit),
                borderColor: 'rgb(161, 161, 161)',
                backgroundColor: 'rgb(161, 161, 161)',
                showLine: true,
                pointRadius: 3,
                tension: 0.1,
            }, 
            {
                label: 'Meio',
                data: dataToChart.map((obj) => obj.mid),
                pointStyle: 'circle',
                borderColor: dataToChart.map((obj) => renderRedBorderIfValueEstimatedIsOutOfLimit(obj)),
                backgroundColor: dataToChart.map((obj) => renderGreenCircleIfValueIsEstimated(obj.is_estimated))
            },
            showResults ?
            {
                label: 'Meio Estimado',
                data: '-',
                pointStyle: 'circle',
                borderColor: 'rgb(4, 170, 109)',
                backgroundColor: 'rgb(4, 170, 109)',
            } : {
              label: '',
              data: '',
              pointStyle: 'circle',
              borderColor: 'rgb(255, 255, 255)',
                backgroundColor: 'rgb(255, 255, 255)',
          }
        ]
    }

    return(
        <div className='container mt-3'>
            <Line options={options} data={data} />
       </div>
    )
};

export default DFALineChart;
