import React, {useState, useEffect} from "react";

import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

import api from "../../../../services/api";


const CalendarTable = ({assembleiasInfo, dateFilter}) => {
    const [momentDateFilter, setMomentDateFilter] = useState('')

    useEffect(() => {
        let m = moment(dateFilter, 'DD/MM/YYYY')
        m.set({hour:0,minute:0,second:0,millisecond:0})

        setMomentDateFilter(m)
    }, [dateFilter]);

    const downloadCvmFile = async (fileUrl) => {
        try {
          const key = fileUrl.split("amazonaws.com/")[1];
          await api.post("/downloadCvmFile", {
            key,
          }).then(resp => { 
            const link = document.createElement('a');
            link.href = resp.data.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })

        } catch (e) {
          console.log(e);
        }
    };

    return ( assembleiasInfo && assembleiasInfo.map((obj, index) => {
        return (   
            moment(obj[5], 'DD/MM/YYYY').isSameOrAfter(momentDateFilter) ?
            <tr key={index}>
                <td>{obj[0]}</td>
                <td>{obj[1]}</td>
                <td>{obj[2]}</td>
                <td>{obj[4]}</td> 
                <td>{obj[5]}</td> 
                <td>{obj[7]}</td> 
                <td>
                    <button className="btn__download" onClick={() => downloadCvmFile(obj[10])}>
                        Download
                    </button>
                </td>           
            </tr> : null      
        )
        })
    );
  };
  
export default CalendarTable;