import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

function ModalWithList({ hideColumns, hiddenColumns }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const colList = [
    "D-", "ask_m2m", "ask_median_D0", "best_ask", "best_bid", "index", "best_spread", 
    "bid_m2m", "bid_median_D0", "mid_median_D0", "conglomerate_cnpj", "conglomerate_name", 
    "is_callable", "is_ipca", "is_tax_free", "mid_D-1", "mid_D-5", "mid_maculayDuration",
    "rating", "trade_yield", "trade_yield_with_boundaries_nm", "trade_yield_with_boundaries_rr",
    "last_trade_date", "RMAE", "last_trade_yield", "last_trade_yield_with_boundaries_nm", 
    "last_trade_yield_with_boundaries_rr", "estimated_yield", "original_mid_m2m", "using_model",
    "unfiltered_trades_mean_price", "unfiltered_trades_mean_m2m_yield", "unfiltered_trades_sum_financial_volume", "unfiltered_trades_count", 
    "unfiltered_trades_percentage_issue_nominal_value", "unfiltered_trades_percentage_par_value",
    "m2m_D-1_percentage_issue_nominal_value", "m2m_D-1_percentage_par_value" 
  ];

  // Divide colList em 3 colunas
  const colChunks = [[], [], []];
  colList.forEach((item, index) => {
    colChunks[index % 2].push(item);
  });

  return (
    <>
      <FontAwesomeIcon 
        icon={faCog} 
        size="2x" 
        color="purple" 
        style={{ cursor: "pointer" }} 
        onClick={handleShow}
      />

      <Modal show={show} onHide={handleClose} size="xl" dialogClassName="custom-modal-width">
        <Modal.Header closeButton>
          <Modal.Title>Lista de Colunas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
            {colChunks.map((chunk, chunkIndex) => (
              <div key={chunkIndex} style={{ flex: 1, margin: '0 30px' }}>
                <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                  {chunk.map((item, index) => (
                    <li 
                      key={index} 
                      style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
                    >
                      <span 
                        style={{ 
                          flex: 1, 
                          fontSize: "1.5rem", 
                          color: hiddenColumns[item] ? "black" : "rgb(0,0,255)" 
                        }}
                        onClick={() => hideColumns(item)}
                      >
                        {item}
                      </span>
                      <div className="form-check form-switch" style={{ transform: "scale(1.5)" }}>
                        <input 
                          className="form-check-input" 
                          type="checkbox" 
                          onChange={() => hideColumns(item)}
                          checked={!hiddenColumns[item]}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <style jsx>{`
        .custom-modal-width .modal-dialog {
          max-width: 90%;
        }
        .modal-xl {
          max-width: 1240px;
        }
      `}</style>
    </>
  );
}

export default ModalWithList;