export const customStyles = {
    contextMenu: {
        style: {
            fontSize: '16px',
        }
    },
    rows: {
        style: {
            minHeight: '50px', // override the row height
        },
    },
    headCells: {
        style: {
            fontSize: '15px',
            fontFamily: 'Verdana, sans-serif',
            fontWeight: 'bold',
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            fontSize: '14px',
            fontFamily: 'Verdana, sans-serif',
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};