import userKey from "./userKey";
import jwtSecret from "./jwtSecret";
import jwt from "jsonwebtoken";

export function setToken(token) {
  localStorage.setItem(userKey, token);
}

export function getToken() {
  return localStorage.getItem(userKey);
}

export function removeToken() {
  localStorage.removeItem(userKey);
  window.location.reload(true);
}

export function getCredentials() {
  const token = localStorage.getItem(userKey);
  return jwt.verify(token, jwtSecret, (err, decoded) => {
    return err ? null : decoded;
  });
}
