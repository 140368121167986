import React, { useState, useEffect } from "react";
import { produce } from "immer";
import moment from 'moment'
import Swal from "sweetalert2";
import Loading from "../../components/FeedbackLoading";
import api from "../../services/api"
import { Prompt } from 'react-router-dom';

import Menu from "./components/Menu";
import FullLoading from "../../components/FullScreenLoading";
import TickerListTable from "./components/TickerListTable";
import QaChangesConfirm from "./components/TickerListTable/components/QaChangesConfirm";

const CriCraDFA = () => {
    const [loader, showLoader, hideLoader] = FullLoading()
    const [isLoading, setIsLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [emergencyButton, setEmergencyButton] = useState(false)
    const [criCraQaData, setCriCraQaData] = useState([]);
    const [criCraCallTradeData, setCriCraCallTradeData] = useState([]);
    const [isFinalDataAnalysis, setIsFinalDataAnalysis] = useState(false)
    const [finalDataAnalysisDate, setFinalDataAnalysisDate] = useState(
        {
            is_preview: null, 
            date: new Date
        }
    )

    const handleBeforeUnload = (location) => {
        // Mensagem de confirmação personalizada
        return `Deseja sair desta página? As alterações não salvas serão perdidas.`;
      };

    const checkDateForPreProcess = async (filters) => {
        var triggerDate = moment(new Date()).format("YYYY-MM-DD")
        var date = moment(filters.date).format("YYYY-MM-DD")
        var is_preview = filters.is_preview
        localStorage.setItem("date", date)
        localStorage.setItem("is_preview", is_preview)

        getCriCraQaData(date, is_preview).then(function(response) { 
          if (response.status !== 200 && moment(date).isSame(triggerDate)){
            Swal.fire({
              title: "Deseja rodar o processo de preparação de dados?",
              text: "Não é possivel abortar este processo depois de iniciado!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sim'
            }).then((result) => {
              if (result.isConfirmed) {
                startPreDataProcess(date, is_preview).then(function(stepFunctionResponse){
                  if (stepFunctionResponse.data.result === "SUCCEEDED") {
                    getCriCraQaData(date, is_preview);
                    criCraGetCallTradeData(date, is_preview);
                  } else {
                    setEmergencyButton(true)
                      Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Problema na preparação de dados..',
                      })
                  }
                })
              }
            })
          } else {
            getCriCraQaData(date, is_preview);
            criCraGetCallTradeData(date, is_preview);
          }
        }) 
    }

    const getCriCraQaData = async (date, is_preview) => {
      try {
          setIsLoading(true);
          setCriCraQaData([]);
          const documents = await api.post('criCraQaData', {
              date, is_preview
          });
          
          setCriCraQaData(documents.data.result);
          console.log(documents.data.result)
          setIsLoading(false);
          return documents
      } catch (e) {
          console.log(e)
          setIsLoading(false);
          return false
      }
    }

    const criCraGetCallTradeData = async (date, is_preview) => {
        try {
            const documents = await api.post('criCraGetCallTradeData', {
                date, is_preview
            });
            
            setCriCraCallTradeData(documents.data.result);
            console.log(documents.data.result)
        } catch (e) {
            console.log("Necessário o pre-processamento de dados")
        }
      }

    const startPreDataProcess = async (date, is_preview) => {
        try {
            setIsLoading(true);

            const documents = await api.post('startCriCraPreDataProcessStepFunction', {
                date, is_preview
            });

            setIsLoading(false);

            console.log(documents)

            return documents
        } catch (e) {
            console.log(e)
            setIsLoading(false);
        }
    }

    const criCraPersistAnalysisResults = async (changes) => {
      try {
          showLoader()
          const documents = await api.post('criCraPersistAnalysisResults', {
              changes
          });

          hideLoader()
          return documents
      } catch (e) {
          console.log("Erro ao Salvar")
          hideLoader()
      }
  }

  const criCraPricingFinalSteps = async (date, is_preview) => {
    showLoader()
    const documents = await api.post('criCraPricingFinalSteps', {
        date, is_preview
    });

    hideLoader()
    return documents
}

  const changeCriCraQaData = (ticker, value, inputName) => {
    setCriCraQaData(
        produce(criCraQaData, (qaData) => {
            const qaDataToChange = qaData.findIndex((data) => data.ticker === ticker);
            qaData[qaDataToChange][`new_${inputName}`] = value;
        })
    );
  }

  const startSaveStepFunction = async (date, is_preview) => {
    showLoader()
    const documents = await api.post('startCriCraSavingProcessStepFunction', {
        date, is_preview
    });

    hideLoader()
    return documents
  }

  const confirmCriCraQaData = async () => {
    var changedQaData = criCraQaData.filter(objeto => objeto.hasOwnProperty('new_mid_m2m') || objeto.hasOwnProperty('new_reliability_score') );

    var date = localStorage.getItem("date");
    var is_preview = localStorage.getItem("is_preview");
      
    var qaDataJson = { 
        changes:{
            date: date, 
            is_preview: is_preview,
            changed_data: changedQaData,
        }
    }

    var changes = JSON.stringify(qaDataJson)

    setModalShow(false);

    criCraPersistAnalysisResults(changes).then(function(response) {
        if (response.status === 200) {
            Swal.fire(
                'Salvo com sucesso!',
                'Finalizando o processo...',
                'success'
            )
            startSaveStepFunction(date, is_preview).then(function(stepFunctionResponse){
                if (stepFunctionResponse.data.result === "SUCCEEDED") {
                    Swal.fire(
                        'Step Function executada com sucesso!',
                        'Atualizando Resultados ...',
                        'success'
                    )
                    var filters = { date: date, is_preview: is_preview }
                    criCraFinalQaDataApi(filters)
                }
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro na hora de salvar...',
            })
        }
    })   
  }

  const criCraFinalQaDataApi = async (filters) => {
    var date = moment(filters.date).format("YYYY-MM-DD")
    var is_preview = filters.is_preview
    setFinalDataAnalysisDate(
        {
            is_preview: is_preview, 
            date: date
        }
    )
    setIsFinalDataAnalysis(true)

    try {
        setIsLoading(true);
        setCriCraQaData([]);
        criCraGetCallTradeData(date, is_preview);
        
        const documents = await api.post('criCraFinalQaDataApi', {
            date, is_preview
        });
        
        setCriCraQaData(documents.data.result);
        setIsLoading(false);
        return documents
    } catch (e) {
        console.log(e)
        setIsLoading(false);
        return false
    }
  }

  const confirmCriCraSaveBondsQaDataFinal = async () => {
    var changedQaData = criCraQaData.filter(objeto => objeto.hasOwnProperty('new_mid_m2m') || objeto.hasOwnProperty('new_reliability_score') );

    var date = finalDataAnalysisDate.date;
    var is_preview = finalDataAnalysisDate.is_preview;
      
    var qaDataJson = { 
        changes:{
            date: date, 
            is_preview: is_preview,
            changed_data: changedQaData,
        }
    }

    var changes = JSON.stringify(qaDataJson)

    setModalShow(false);

    criCraPersistAnalysisResults(changes).then(function(response) {
        if (response.status === 200) {
            criCraPricingFinalSteps(date, is_preview).then(function(stepFunctionResponse){
                if (stepFunctionResponse.data.result === "SUCCEEDED") {
                    Swal.fire(
                        'Processo Finalizado!',
                        'Todas as etapas foram efetuadas com sucesso',
                        'success'
                    )
                }
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro na hora de salvar...',
            })
        }
    })   
  }
  
  return ( 
        <>
            <Prompt when={true} message={handleBeforeUnload} />
            <Menu
                getCriCraQaData={checkDateForPreProcess} 
                emergencyButton={emergencyButton}
                startPreDataProcess={startPreDataProcess}
                criCraFinalQaDataApi={criCraFinalQaDataApi}
            />
            <div className="col-md-12 justify-content-center" >
                { isLoading ? <Loading/> :
                  <TickerListTable 
                      tickerData={criCraQaData}
                      criCraCallTradeData={criCraCallTradeData}
                      openSaveConfirmModal={setModalShow}
                      changeCriCraQaData={changeCriCraQaData}
                      isFinalDataAnalysis={isFinalDataAnalysis}
                      finalDataAnalysisDate={finalDataAnalysisDate}
                  />
                }
            </div>
            { modalShow ?
                <QaChangesConfirm 
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    criCraQaData={criCraQaData}
                    confirmCriCraQaData={confirmCriCraQaData}
                    criCraPricingFinalSteps={confirmCriCraSaveBondsQaDataFinal}
                    isFinalDataAnalysis={isFinalDataAnalysis}
                />
            : null } 
            {loader} 
        </> 
    );
};

export default CriCraDFA;
