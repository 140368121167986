import React, { useState } from "react";
import Spinner from "./Loading.gif";

import "./style.css";

const LoadingSpinner = () => {
  const [loading, setLoading] = useState(false);

  return [
    loading ? (
      <div className="lp-container">
        <div>
          <img src={Spinner} className="lp-loader" alt="loading"></img>
        </div>
        <div className="lp-loader-text text-center">
          <p className="font-weight-bold colored">Cadastrando Documento... </p>
        </div>
      </div>
    ) : null,

    () => setLoading(true),
    () => setLoading(false),
  ];
};

export default LoadingSpinner;
