import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'

export  const formatNumbers = (number, decimalPlaces) => {
    return !isNaN(number) ? number.toFixed(decimalPlaces).replace('.', ',') : "NaN"
}

export const formatFinancialNumbers = (number, decimalPlaces) => { 
    if (!isNaN(number)){
        var numero = number.toFixed(decimalPlaces).split('.');
        numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
        
        return numero.join(',');
    } else {
        return null
    }
}

export const formatPercentNumbers = (number, decimalPlaces) => {
    return !isNaN(number) ? (number * 100).toFixed(decimalPlaces).replace('.', ',') : null
}

export const formatDifference = (number, decimalPlaces) => {
    return !isNaN(number) ? Math.abs(number).toFixed(decimalPlaces).replace('.', ',') : "NaN"
}


export const columns = [
    {
        name: 'ticker',
        selector: row => row.ticker,
        sortable: true
    },
    {
        name: 'isin',
        selector: row => row.isin,
        sortable: true
    },
    {
        name: 'referenceDate',
        selector: row => moment(row.referenceDate).format("DD/MM/YYYY"),
        sortable: true
    },
    {
        name: 'anbimaYield',
        selector: row => formatPercentNumbers(row.anbimaYield, 4),
        sortable: true
    },
    {
        name: 'anbimaPrice',
        selector: row => formatFinancialNumbers(row.anbimaPrice, 6),
        sortable: true
    },
    {
        name: 'fiaYield',
        selector: row => formatPercentNumbers(row.fiaYield, 4),
        sortable: true
    },
    {
        name: 'fiaPrice',
        selector: row => formatFinancialNumbers(row.fiaPrice, 6),
        sortable: true
    },
    {
        name: 'difference',
        selector: row => formatNumbers(row.difference, 10),
        sortable: true
    },
    {
        name: 'differenceAbs',
        selector: row => formatDifference(row.difference, 6),
        sortable: true
    },
    {
        name: 'duration',
        selector: row => formatNumbers(row.duration, 6),
        sortable: true
    },
    {
        name: 'convexity',
        selector: row => formatNumbers(row.convexity, 2),
        sortable: true
    },
    {
        name: 'vnaD0',
        selector: row => formatFinancialNumbers(row.vnaD0, 6),
        sortable: true
    },
    {
        name: 'puParD0',
        selector: row => formatFinancialNumbers(row.puParD0, 6),
        sortable: true
    },
    {
        name: 'eventType',
        selector: row => row.eventType,
        sortable: true
    },
    {
        name: 'amount',
        selector: row => row.amount,
        sortable: true
    },
    {
        name: 'validated',
        selector: row => String(row.validated),
        sortable: true
    },

];

