import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"

import isAuthenticated from "./auth/isAuthenticated"
import SignIn from "./pages/SignIn"
import ResetPassword from "./pages/ResetPassword"
import ChangePassword from "./pages/ChangePassword"
import ManageUsers from "./pages/ManageUsers"
import CreateUser from "./pages/CreateUser"
import EditUser from "./pages/EditUser"
import EditPassword from "./pages/EditPassword"
import UserNetQuant from "./pages/CreateUserNetQuant"
import CreateUserMaster from "./pages/CreateUserMaster"
import ChangeCRICRAAttributes from "./pages/ChangeCRICRAAttributes"
import ChangeDebentureAttributes from "./pages/ChangeDebentureAttributes"
import DebentureAttributes from "./pages/DebentureAttributes"
import Cvm from "./pages/Cvm"
import Agenda from "./pages/Agenda"
import ManualApprovals from "./pages/ManualApprovals"
import EditAgendaFiAnalytics from "./pages/EditAgendaFiAnalytics"
import Calendar from "./pages/Calendar"
import PricingQA from "./pages/PricingQA"
import PricingDFA from "./pages/PricingDFA"
import DebenturesRegister from "./pages/DebenturesRegister"
import CallQA from "./pages/CallQA"
import QueryDFA from "./pages/ConsultaDFA"
import PricingGovQA from "./pages/PricingGovQA"
import ProcessMonitor from "./pages/ProcessMonitor"
import ProcessMonitorRegister from "./pages/ProcessMonitorRegister"
import DebenturesTradesPreview from "./pages/DebenturesTradesPreview"
import EditCriCraAgenda from "./pages/EditCriCraAgenda"
import DailyProcessMonitor from "./pages/DailyProcessMonitor"
import CriCraDFA from "./pages/CriCraDFA"
import CriCraQA from "./pages/CriCraQa"
import NewsletterDashboard from "./pages/NewsletterDashboard"
import ComparativeRates from "./pages/ComparativeRates"
import DebenturesBondsQA from "./pages/DebenturesBondsQA"
import FiaReports from "./pages/FiaReports"

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/signin", state: { from: props.location } }} />
      )
    }
  />
)

const EspecialRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
)

const Routes = () => (
  <Switch>
    <PrivateRoute
      exact
      path="/"
      component={DailyProcessMonitor}
    />
    <EspecialRoute
      path="/signin"
      component={SignIn}
    />
    <Route
      path="/forgot_password"
      component={ResetPassword}
    />
    <PrivateRoute
      path="/password"
      component={ChangePassword}
    />
    <PrivateRoute
      path="/manage_users"
      component={ManageUsers}
    />
    <PrivateRoute
      exact
      path="/create_user"
      component={CreateUser}
    />
    <PrivateRoute
      exact
      path="/edit_user/:id"
      component={EditUser}
    />
    <PrivateRoute
      exact
      path="/edit_password/:id"
      component={EditPassword}
    />
    <PrivateRoute
      exact
      path="/netquant_user"
      component={UserNetQuant}
    />
    <PrivateRoute
      exact
      path="/user_master"
      component={CreateUserMaster}
    />
    <PrivateRoute
      exact
      path="/debenture_attributes"
      component={ChangeDebentureAttributes}
    />
    <PrivateRoute
      exact
      path="/cri_cra_attributes"
      component={ChangeCRICRAAttributes}
    />
    <PrivateRoute
      exact
      path="/show_attributes"
      component={DebentureAttributes}
    />
    <PrivateRoute
      exact
      path="/cvm"
      component={Cvm}
    />
    <PrivateRoute
      exact
      path="/agenda"
      component={Agenda}
    />
    <PrivateRoute
      exact
      path="/manualApprovals"
      component={ManualApprovals}
    />
    <PrivateRoute
      exact
      path="/edit_agenda_fi_analytics"
      component={EditAgendaFiAnalytics}
    />
    <PrivateRoute
      exact
      path="/edit_cri_cra_agenda"
      component={EditCriCraAgenda}
    />
    <PrivateRoute
      exact
      path="/Calendar"
      component={Calendar}
    />
    <PrivateRoute
      exact
      path="/pricing_QA"
      component={PricingQA}
    />
    <PrivateRoute
      exact
      path="/cadastro_debentures"
      component={DebenturesRegister}
    />
    <PrivateRoute
      exact
      path="/pricing_DFA"
      component={PricingDFA}
    />
    <PrivateRoute
      exact
      path="/pricing_gov_QA"
      component={PricingGovQA}
    />
    <PrivateRoute
      exact
      path="/query_DFA"
      component={QueryDFA}
    />
    <PrivateRoute
      exact
      path="/call_qa"
      component={CallQA}
    />
    <PrivateRoute
      exact
      path="/process_monitor"
      component={ProcessMonitor}
    />
    <PrivateRoute
      exact
      path="/daily_process_monitor"
      component={DailyProcessMonitor}
    />
    <PrivateRoute
      exact
      path="/comparative_yields"
      component={ComparativeRates}
    />
    <PrivateRoute
      exact
      path="/fia_reports"
      component={FiaReports}
    />
    <PrivateRoute
      exact
      path="/process_monitor_register"
      component={ProcessMonitorRegister}
    />
    <PrivateRoute
      exact
      path="/debentures_and_trades_preview"
      component={DebenturesTradesPreview}
    />
    <PrivateRoute
      exact
      path="/deb_bonds_qa"
      component={DebenturesBondsQA}
    />
    <PrivateRoute
      exact
      path="/cri_cra_dfa"
      component={CriCraDFA}
    />
    <PrivateRoute
      exact
      path="/cri_cra_qa"
      component={CriCraQA}
    />
    <PrivateRoute
      exact
      path="/newsletter-dashboard"
      component={NewsletterDashboard}
    />

    <Route
      path="*"
      component={() => <h1>Page not found</h1>}
    />
  </Switch>
)

export default Routes
