import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import api from "../../services/api";

import { useSelector, useDispatch } from "react-redux";

import swal from "sweetalert";

import Loading from "./loading";

import { saveUserInfos } from "../../actions/userInfosAction";

const ManageUsers = () => {
  const dispatch = useDispatch();

  const [usersList, setUsersList] = useState([]);

  const { user_entity } = useSelector(state => state.auth.credentials);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const users = await api.post("/getUsers", {
          entity_id: user_entity,
        });
        setUsersList(users.data.users);
      } catch (e) {
        console.log(e);
      }
    };
    getUsers();
  }, [user_entity]);

  const deleteUser = async id => {
    try {
      await api.delete(`/users/${id}`);
      await swal({
        text: "Usuário deletado com sucesso",
        icon: "success",
      });
      const users = usersList.filter(user => user.id !== id);
      setUsersList(users);
    } catch (e) {
      await swal({
        text: "Não foi possível deletar o usuário",
        icon: "warning",
      });
      console.log(e);
    }
  };
  return (
    <>
      <div className="container">
        <Link to="/create_user">
          <button className="btn btn-primary mt-3">Criar usuário</button>
        </Link>

        <div className="table-responsive mt-3">
          {usersList.length ? (
            <table className="table">
              <thead>
                <tr className="text-center">
                  <th>Nome</th>
                  <th>Login</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {usersList.map(user => (
                  <tr className="text-center" key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>
                      <Link to={`/edit_user/${user.id}`}>
                        <button
                          onClick={() =>
                            dispatch(
                              saveUserInfos(user.id, user.name, user.email)
                            )
                          }
                          className="btn btn-info btn-sm mr-2"
                        >
                          Editar
                        </button>
                      </Link>

                      <Link to={`/edit_password/${user.id}`}>
                        <button
                          onClick={() =>
                            dispatch(
                              saveUserInfos(user.id, user.name, user.email)
                            )
                          }
                          className="btn btn-warning btn-sm mr-2"
                        >
                          Alterar senha
                        </button>
                      </Link>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => deleteUser(user.id)}
                      >
                        Excluir
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </>
  );
};

export default ManageUsers;
