import React, {useState, useCallback, useMemo} from "react";
import moment from 'moment'
import DataTable from 'react-data-table-component';

import "./style.css";


const InstrumentGovBondsTable = (props) => {
    const { instrumentType, tableData } = props

    const formatDecimalPlaces = (value) => {
        if (value !== "nan") {
            value = parseFloat(value).toFixed(4)
        } else {
            value = "-"
        }
        
        return value;
    }

    const ltnNtfColumns = [
        {
            name: 'Maturity Date',
            selector: row => moment(row['maturity_date']).format("MM/YYYY"),
        },
        {
            name: 'DI d-1',
            selector: row => formatDecimalPlaces(row['yield d-1_D0']),
        },
        {
            name: 'DI d0',
            selector: row => formatDecimalPlaces(row['yield d0_D0']),
            wrap: true,
        },
        {
            name: 'mid_YTM_d-1',
            selector: row => formatDecimalPlaces(row['mid_rate_D-1']),
            grow: 8,
            compact: true,
        },
        {
            name: 'mid_YTM',
            selector: row => formatDecimalPlaces(row['mid_rate_D0']),
        },
        {
            name: 'prêmio D-1',
            selector: row => formatDecimalPlaces(row['premium D0']),
        },
        {
            name: 'prêmio D0',
            selector: row => formatDecimalPlaces(row['premium D-1']),
        },
        {
            name: 'mid_m2m d-1',
            selector: row => formatDecimalPlaces(row['mid_m2m_D-1']),
            grow: 8,
        },
        {
            name: 'mid_m2m d0',
            selector: row => formatDecimalPlaces(row['mid_m2m_D0']),
            grow: 8,
        },
        {
            name: 'retorno',
            selector: row => formatDecimalPlaces(row['return']),
            grow: 5,
        },
        {
            name: 'delta yield',
            selector: row => formatDecimalPlaces(row['delta yield']),
        },
        {
            name: 'retorno di1',
            selector: row => formatDecimalPlaces(row['DI1 return']),
            grow: 5,
        },
    ];

    const ntbColumns = [
        {
            name: 'Maturity Date',
            selector: row => moment(row['maturity_date']).format("MM/YYYY"),
        },
        {
            name: 'mid_breakevenInflation_d-1',
            selector: row => formatDecimalPlaces(row['mid_breakevenInflation_D-1']),
        },
        {
            name: 'mid_breakevenInflation',
            selector: row => formatDecimalPlaces(row['mid_breakevenInflation_D0']),
            wrap: true,
        },
        {
            name: 'mid_YTM_d-1',
            selector: row => formatDecimalPlaces(row['mid_rate_D-1']),
            compact: true,
        },
        {
            name: 'mid_YTM',
            selector: row => formatDecimalPlaces(row['mid_rate_D0']),
        },
        {
            name: 'mid_m2m d-1',
            selector: row => formatDecimalPlaces(row['mid_m2m_D-1']),
        },
        {
            name: 'mid_m2m d0',
            selector: row => formatDecimalPlaces(row['mid_m2m_D0']),
        },
        {
            name: 'retorno',
            selector: row => formatDecimalPlaces(row['return']),
        },
        {
            name: 'delta yield',
            selector: row => formatDecimalPlaces(row['delta yield']),
        },
    ];

    return (
            <DataTable   
                columns={instrumentType === "NTB" ? ntbColumns : ltnNtfColumns}
                data={tableData}
            />
        );
};

export default InstrumentGovBondsTable;
