import React, { useState } from "react";

import api from "../../services/api";

import { useSelector } from "react-redux";

import { FaUserPlus } from "react-icons/fa";

import swal from "sweetalert";

import CreateUserButton from "../../components/Button/CreateUserButton";
import { generateRandonAndUniquePassoword } from "../../common/index";

const UserNetQUant = () => {
  const { user_entity } = useSelector((state) => state.auth.credentials);
  const password = generateRandonAndUniquePassoword();

  const INITIAL_STATE = {
    name: "",
    email: "",
    password,
    initial_password: password,
    isAdmin: true,
    entity_id: user_entity,
    role: "master",
  };
  const [formInput, setFormInput] = useState(INITIAL_STATE);
  const [disableButton, setDisableButton] = useState(false);

  const handleChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const createUser = async () => {
    try {
      setDisableButton(true);
      await api.post("/users", {
        ...formInput,
      });
      await swal({
        text:
          "Usuário criado com sucesso. O email cadastrado receberá um link de ativação.",
        icon: "success",
      });
      setDisableButton(false);
      setFormInput(INITIAL_STATE);
    } catch (e) {
      setDisableButton(true);
      await swal({
        text: "Não foi possível criar o usuário",
        icon: "warning",
      });
      setDisableButton(false);
      console.log(e);
    }
  };
  return (
    <>
      <div className="login-form mt-5">
        <center className="mb-2">
          <FaUserPlus size="80" />
        </center>
        <center>
          <h2>Criação de Usuário NetQuant</h2>
        </center>
        <center>
          <p>Criando um novo usuário interno</p>
        </center>
        <div className="form-group">
          <input
            name="name"
            type="text"
            placeholder="Nome"
            className="form-control"
            onChange={handleChange}
            value={formInput.name}
          />
        </div>
        <div className="form-group">
          <input
            name="email"
            type="text"
            placeholder="Email"
            className="form-control"
            onChange={handleChange}
            value={formInput.email}
          />
        </div>
        <div className="form-group">
          <CreateUserButton
            disabled={disableButton}
            onClick={() => createUser()}
            className="btn btn-primary btn-block"
          />
        </div>
      </div>
    </>
  );
};

export default UserNetQUant;
