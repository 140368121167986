import React, {useState} from "react";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import InputMask from 'react-input-mask';

import "react-datepicker/dist/react-datepicker.css";
import { eventTypes } from "../../../../common/eventTypes";

const ScheduleEditModalInput = ({id, defaultValue, name, editScheduleData, rowIndex, keyMap}) => {
  if (id === 'Event Date' || id === 'Actual Event Date'){
    let defaultValueDateParts = defaultValue.split("/");
    defaultValue = new Date(defaultValueDateParts[2], defaultValueDateParts[1] - 1, defaultValueDateParts[0]);
  }

  const [date, setDate] = useState(defaultValue)
  
  const renderInputs = () => {
    switch (id) {
      case 'Event Date':
        return( 
          <DatePicker
            className={"form-control"}
            dateFormat="dd/MM/yyyy"
            locale={ptBR}
            selected={date}
            onSelect={(date) => setDate(date)}
            onChange={(date) => editScheduleData(rowIndex, keyMap, date)}
          />
        )
      case 'Actual Event Date':
        return( 
          <DatePicker
            className={"form-control"}
            dateFormat="dd/MM/yyyy"
            locale={ptBR}
            selected={date}
            onSelect={(date) => setDate(date)}
            onChange={(date) => editScheduleData(rowIndex, keyMap, date)}
          />
        )
      case 'Event':
        return(
          <select 
            className="form-select"
            defaultValue={defaultValue}
            onChange={(e) => editScheduleData(rowIndex, keyMap, e.target.value)}
            >
            {eventTypes.map(event => (
                  <option key={event}>{event}</option>
            ))}
          </select>    
          )
      case '% Rate':
          return (
            <InputMask 
            className="form-control"
            id={id}
            name={name} 
            defaultValue={defaultValue} 
            onChange={(e) => editScheduleData(rowIndex, keyMap, e.target.value)}/>
          )
      case 'Amount':
          return (
            <InputMask 
            className="form-control"
            id={id}
            name={name} 
            defaultValue={defaultValue} 
            onChange={(e) => editScheduleData(rowIndex, keyMap, e.target.value)}/>
          )
      case 'Internal Use':
          return (
            <select 
              className="form-select"
              defaultValue={defaultValue}
              onChange={(e) => editScheduleData(rowIndex, keyMap, e.target.value)}
              >
              <option key="false">false</option>
              <option key="true">true</option>
            </select>
        )
      default:
        return (
        <input 
          className="form-control"
          id={id}
          defaultValue={defaultValue}
          name={name}
          onChange={(e) => editScheduleData(rowIndex, keyMap, e.target.value)}/>
        );
    }
  }

  return (
    renderInputs()
  )
};

export default ScheduleEditModalInput;
