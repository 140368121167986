import React from "react"

export default function ColorBadge({ variant = "primary" }) {
  const handleTooltipText = (variant) => {
    if (variant === "success") {
      return "Publicado"
    } else if (variant === "secondary") {
      return "Esperando"
    } else {
      return "Em atraso"
    }
  }

  return (
    <span
      className={`badge bg-${variant}`}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title={handleTooltipText(variant)}
      style={{
        width: "30px",
        height: "15px",
        marginBottom: "-0.2rem",
        marginRight: ".2rem",
      }}
    >
      {" "}
    </span>
  )
}
