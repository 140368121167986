import React, {useState, useEffect} from "react";

import CalendarTable from "./components/CalendarTable";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import moment from 'moment'

import "./style.css";
import api from "../../services/api";

//{"Data.ASSEMBLEIA_AGDEB":{ $elemMatch: { $elemMatch: { $in: ['Edital de Convocação', 'Proposta da Administração']}}}}

const Calendar = () => {
    const [assembleiasInfo, setAssembleiasInfo] = useState([]);
    const [dateToFilter, setDateToFilter] = useState(new Date());

    useEffect(() => {
        const fetchData = async () => {
          try {
            const documents = await api.post("/getAssembleiasInfo");
     
            normalizeAssembleiasInfo(documents.data)
          } catch (e) {
            console.log(e);
          }
        };
        fetchData()
    }, []);


    const normalizeAssembleiasInfo = (assembleiaData) => {
        const arr = []

        assembleiaData && assembleiaData.map((obj) => {
            for (let i = 0; i < obj.Data.ASSEMBLEIA_AGDEB.length; i++) {
                arr.push(obj.Data.ASSEMBLEIA_AGDEB[i])
            }
        })

        for (let i = 0; i < arr.length; i++) {
            arr[i][5] = moment(arr[i][5], "DD/MM/YYYY HH:mm").format('DD/MM/YYYY HH:mm')       
        }

        setAssembleiasInfo(arr)
    }

    return (
        <div>
            <div className="date-filter-input text-center">
                <label>
                    Filtrar a partir de :
                </label>
                <div>
                    <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale={ptBR}
                    selected={dateToFilter}
                    onChange={value => setDateToFilter(value)}
                    />
                </div>
            </div>
            <table className="table table-striped table-bordered mydatatable">
                    <thead className='text-center'>
                        <tr>
                        <th>Código CVM</th>
                        <th>Empresa</th>
                        <th>Categoria</th>
                        <th>Espécie</th>
                        <th>Data Referência</th>
                        <th>Status</th>
                        <th>Download do Documento</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        <CalendarTable
                            assembleiasInfo={assembleiasInfo}
                            dateFilter={dateToFilter}
                         />
                    </tbody>
            </table>
       </div>
    );
  };
  
export default Calendar;