import React from "react";
import Modal from 'react-bootstrap/Modal'
import Loading from "../../../../components/FullScreenLoading"

import api from "../../../../services/api"

import { faSave, faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Swal from "sweetalert2";

const DebenturesModalSaveConfirm = (props) => {
  const [loader, showLoader, hideLoader] = Loading()
  
  const confirmDebentureRegistration = async () => {
    showLoader();
    props.onHide()
    
    if (props.isCustomDebentureFlag == 0){ 
      let mongoId = props.debentureToRegister._id['$oid']
      let debentureCode = props.debentureToRegister["B3 Code"][0].value
      
      let editedDebenture = {
          id: mongoId,
          editedDebenture: props.debentureToRegister, 
      }

      editedDebenture = JSON.stringify(editedDebenture)

      try {
        var response = await api.post('registerEditedDebenture', editedDebenture);

        console.log(response)

        if (response.data.hasAgenda) {
          Swal.fire({
            icon: 'success',
            title: 'Debênture cadastrada com sucesso!',
            text: `Verifique no filtro da aba de Cadastro de Agendas o código ${debentureCode}, para realizar o cadastro de agenda.`,
            showConfirmButton: true,
          })
        } else {
          Swal.fire({
            icon: 'info',
            title: 'Debênture cadastrada com sucesso, porém...',
            text: `A debênture ${debentureCode} foi cadastrada com sucesso, porém sua agenda não foi encontrada pelo micro serviço`,
            showConfirmButton: true,
          })
        }  
      } catch (e) {
        if (e.response.status === 410) {
          Swal.fire({
            icon: 'error',
            title: 'Falha ao cadastrar debênture!',
            text: `Aparentemente a debênture (${debentureCode}) já foi cadastrada no banco de dados por outro usuário. Caso esta debênture
            continue nessa lista contate um administrador.`,
            showConfirmButton: true,
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Sistema fora do ar',
            text: `Espero um dia essa mensagem nunca aparecer, se isso acontecer, é por que provavelmente algo está errado no back-end`,
            showConfirmButton: true,
          })
        }
      }   
    } else {
      let customDebenture = {
        customDebenture: props.debentureToRegister, 
      }

      customDebenture = JSON.stringify(customDebenture)

      try {
        await api.post('registerCustomDebenture', customDebenture);
        
      } catch (e) {
        console.log(e);
      }

      Swal.fire({
        icon: 'info',
        text: 'Debênture cadastrada na lista de debêntures pendentes para cadastro...',
        showConfirmButton: true,
      })
    }
    
    props.reloadDataTable(props.debentureToRegister)
    hideLoader()
    
  }

  return (
    <>
      <Modal
          show={props.show}
          onHide={props.onHide}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Confirmação do cadastro da Debênture
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className="text-center">Atributos Alterados :</h4>
              <div className="row">
              <div className="col-md-4">
                {Object.keys(props.changedAttributes).map(function(key, index) {
                  return(
                    props.changedAttributes[key].old != props.changedAttributes[key].new ?
                      <span key={index}>
                        <p>
                          {key}: <span className="text-danger fw-bold">{props.changedAttributes[key].old}</span>
                        </p>
                      </span> 
                    : null
                  )
                })}
                </div>
                <div className="col-md-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon 
                    icon={faLongArrowAltRight} 
                    size="6x" />
                      
                </div>
                <div className="col-md-4">
                {Object.keys(props.changedAttributes).map(function(key, index) { 
                  return(
                    props.changedAttributes[key].new != props.changedAttributes[key].old ?
                      <span key={index}>
                        <p>
                          {key}: <span className="text-success fw-bold">{props.changedAttributes[key].new}</span>
                        </p>
                      </span>
                    : null
                  )
                })}
                </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <div className="d-grid gap-2 text-center d-md-block mx-auto">
              <button 
                  className="btn btn-success me-2"
                  onClick={() => confirmDebentureRegistration()}
                >    
                  <FontAwesomeIcon 
                  icon={faSave} 
                  size="1x" />
                    Confirmar Alterações
              </button>
                
              <button 
                className="btn btn-danger ms-2 "
                onClick={props.onHide}>Close
              </button>                
            </div>
            </Modal.Footer>
        </Modal>
        {loader}
      </>
      );
};

export default DebenturesModalSaveConfirm;
