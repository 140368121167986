import { combineReducers } from "redux";

import AuthReducer from "./authReducer";
import UserInfo from "./userInfos";
import CvmInfos from "./cvmReducer";

const Reducers = combineReducers({
  auth: AuthReducer,
  userInfo: UserInfo,
  cvmInfos: CvmInfos,
});

export default Reducers;
