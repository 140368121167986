import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Swal from "sweetalert2"
import api from "../../services/api"
import Modal from "react-bootstrap/Modal"
import notify from "../../common/toast"
import { limpaCache } from "../../common/cleanCache"

export default function ChangeCriCraAttributes() {
  const DEFAULT_STATE = {
    version: null,
    value: "",
  }

  const [tickerInput, setTickerInput] = useState("")
  const [hasFocusInputTicker, setHasFocusInputTicker] = useState(false)

  const [attributeInput, setAttributeInput] = useState("")
  const [hasFocusInputAttribute, setHasFocusInputAttribute] = useState(false)
  const [documentInfoKeys, setDocumentInfoKeys] = useState([])
  const [attributeType, setAttributeType] = useState("")

  const [readOnlyValue, setReadOnlyValue] = useState("")
  const [valueInput, setValueInput] = useState(DEFAULT_STATE)
  const [documentTickers, setDocumentsTickers] = useState([])
  const [documentInfo, setDocumentInfo] = useState({})

  //modal states
  const [showModal, setShowModal] = useState(false)
  const [newAttributeName, setNewAttributeName] = useState("")
  const [newAttributeValue, setNewAttributeValue] = useState("")
  const [isNewAttrVersioned, setIsNewAttrVersioned] = useState(true)
  const [isNewAttrBoolean, setIsNewAttrBoolean] = useState(false)
  const [newAttributeType, setNewAttributeType] = useState("")

  const [loading, setLoading] = useState(false)
  const [attributesLoading, setAttributesLoading] = useState(false)
  const [editAttributeLoading, setEditAttributeLoading] = useState(false)
  const [createAttributeLoading, setCreateAttributeLoading] = useState(false)

  const { user_name } = useSelector((state) => state.auth.credentials)

  const isPriceIndexReferenceDayTable =
    attributeInput === "Price Index Reference Day Table"

  useEffect(() => {
    const getDocumentTickers = async () => {
      try {
        setLoading(true)

        const { data } = await api.get("/get_cri_cra_tickers")

        setDocumentsTickers(data)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    getDocumentTickers()
  }, [])

  function filterAttributes(documentInfo) {
    return Object.keys(documentInfo).filter((item) => {
      const skipableProperties = ["_id", "changes"]
      if (!skipableProperties.includes(item)) {
        return true
      }
      return false
    })
  }

  const getAttributesInfo = async (ticker) => {
    try {
      setAttributesLoading(true)

      const { data } = await api.get(`/get_cri_cra_info/?b3_code=${ticker.trim()}`)

      console.log(data)

      setDocumentInfo(data[0])

      setDocumentInfoKeys(filterAttributes(data[0]))
    } catch (error) {
      console.log(error)
    } finally {
      setAttributesLoading(false)
    }
  }

  const handleSetTickerValues = (code) => {
    setTickerInput(code)

    getAttributesInfo(code)
    setReadOnlyValue("")
    setAttributeInput("")
  }

  const handleSetAttributeValue = (label) => {
    setAttributeInput(label)

    const documentValue = documentInfo[label]

    if (Array.isArray(documentValue)) {
      setValueInput(documentValue.at(-1))
      setReadOnlyValue(documentValue.at(-1).value)
      return
    }

    setValueInput({
      version: null,
      value: documentValue,
    })
    setReadOnlyValue(documentValue)
  }

  const handleSetValueInput = (value) => {
    console.log(value)

    if (value.version === null) {
      setValueInput({
        version: null,
        value: value,
      })
      return
    }

    setValueInput({
      ...valueInput,
      value: value,
    })
  }

  const handleOpenModal = () => {
    setShowModal(!showModal)
  }

  const handleNewAttributeName = (value) => {
    setNewAttributeName(value)
  }

  const handleBooleanCheckbox = (value) => {
    setIsNewAttrBoolean(value)
    setNewAttributeValue("")
  }

  const createNewAttribute = async () => {
    if (newAttributeName.length < 1 || String(newAttributeValue).length < 1) {
      return notify("Todos os campos obrigatórios devem ser preenchidos", "error")
    }

    if (!isNewAttrBoolean && !newAttributeType) {
      return notify("Selecione o tipo do dado", "error")
    }

    if (!documentTickers.includes(tickerInput?.trim())) {
      return notify("Selecione um ticker válido", "error")
    }

    try {
      setCreateAttributeLoading(true)

      let shouldCancel

      await Swal.fire({
        title: "Criar atributo",
        text: "Você tem certeza que deseja salvar essa alteração?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        shouldCancel = result.isDismissed
      })

      if (shouldCancel) return

      const response = await api.post("createCriCraAttribute", {
        b3_code: tickerInput,
        attribute_name: newAttributeName,
        attribute_value: newAttributeValue,
        attribute_type: isNewAttrBoolean ? "" : newAttributeType,
        attribute_versioned: isNewAttrVersioned,
        attribute_boolean: isNewAttrBoolean,
      })

      console.log(response)
      setShowModal(!showModal)
      setNewAttributeType("")
      setNewAttributeValue("")
      setNewAttributeName("")
      setIsNewAttrBoolean(false)
      setIsNewAttrVersioned(true)

      notify("Atributo criado com sucesso", "success")
      getAttributesInfo(tickerInput)
      await limpaCache()
    } catch (error) {
      console.log(error)
      notify("Ocorreu um erro ao salvar as informações", "error")
    } finally {
      setCreateAttributeLoading(false)
    }
  }

  const updateAttribute = async () => {
    if (tickerInput.trim().length < 1 || String(valueInput.value).trim().length < 1) {
      return notify("Nenhum campo pode estar vazio", "error")
    }

    if (!documentInfoKeys.includes(attributeInput.trim())) {
      return notify(
        "O campo está vazio ou não existe no papel atual, se deseja adicionar um novo atributo, clique em Criar Atributo",
        "error"
      )
    }

    if (!documentTickers.includes(tickerInput?.trim())) {
      return notify("Selecione um ticker válido", "error")
    }

    const isVersionNull = valueInput.version === null
    const isBoolean = typeof valueInput.value === "boolean"
    const fieldTypes = ["integer", "float", "string"]

    if (!isBoolean && !fieldTypes.includes(attributeType)) {
      return notify("Selecione um tipo!", "error")
    }

    const changeId = documentInfo["changes"]?.at(-1)?.["id"] ?? 0

    const currentAttributeVersion = valueInput?.version

    let patchObject = {
      b3_code: tickerInput,
      attribute_name: attributeInput,
      attribute_type: isBoolean ? "boolean" : attributeType,
      attribute_value: {
        version: currentAttributeVersion + 1,
        value: valueInput.value,
      },
      change: {
        id: changeId + 1,
        who: user_name,
        what: attributeInput,
      },
    }

    if (isVersionNull) {
      patchObject = {
        ...patchObject,
        attribute_value: valueInput,
      }
    }

    console.log(patchObject)

    try {
      setEditAttributeLoading(true)

      let shouldCancel

      await Swal.fire({
        title: "Alterar atributo",
        text: "Você tem certeza que deseja salvar essa alteração?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        shouldCancel = result.isDismissed
      })

      if (shouldCancel) return

      const response = await api.patch("/update_info", patchObject)
      console.log(response)

      setReadOnlyValue("")
      setAttributeInput("")
      setAttributeType("")
      setValueInput(DEFAULT_STATE)
      notify("Atributo editado com sucesso", "success")
      getAttributesInfo(tickerInput)
      await limpaCache()
    } catch (error) {
      console.log(error)
      notify("Não foi possível modificar o atributo", "error")
    } finally {
      setEditAttributeLoading(false)
    }
  }

  return (
    <main className="d-flex justify-content-center align-items-center flex-column">
      {showModal && (
        <Modal
          show={showModal}
          onHide={handleOpenModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Criar Atributo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label htmlFor="attribute-name">Nome do atributo</label>
              <input
                type="text"
                className="form-control"
                value={newAttributeName}
                id="attribute-name"
                onChange={(e) => handleNewAttributeName(e.target.value)}
              />
            </div>

            <div className="mb-3 form-check">
              <label
                htmlFor="boolean"
                className="form-check-label"
              >
                Booleano?
              </label>
              <input
                type="checkbox"
                className="form-check-input"
                id="boolean"
                checked={isNewAttrBoolean}
                onChange={(e) => handleBooleanCheckbox(e.target.checked)}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="attribute-value">Valor do atributo</label>
              {isNewAttrBoolean ? (
                <div>
                  <label htmlFor="true">Sim</label>
                  <input
                    id="true"
                    type="radio"
                    value={true}
                    onChange={() => setNewAttributeValue(true)}
                    checked={newAttributeValue === true}
                    className="margin-right-sm margin-left-sm"
                  />
                  <label htmlFor="false">Não</label>
                  <input
                    id="false"
                    type="radio"
                    value={false}
                    onChange={() => setNewAttributeValue(false)}
                    checked={newAttributeValue === false}
                    className="margin-left-sm"
                  />
                </div>
              ) : (
                <input
                  type="text"
                  id="attribute-value"
                  className="form-control"
                  value={newAttributeValue}
                  onChange={(e) => setNewAttributeValue(e.target.value)}
                />
              )}
            </div>

            <div className="mb-3 form-check">
              <label
                htmlFor="versioned"
                className="form-check-label"
              >
                Versionado?
              </label>
              <input
                type="checkbox"
                className="form-check-input"
                id="versioned"
                checked={isNewAttrVersioned}
                onChange={(e) => setIsNewAttrVersioned(e.target.checked)}
              />
            </div>
            {!isNewAttrBoolean && (
              <div>
                <strong className="mb-3">Tipo do atributo</strong>
                <div className="mb-3 form-check">
                  <label htmlFor="integer">Inteiro</label>
                  <input
                    id="integer"
                    type="radio"
                    value={"integer"}
                    onChange={() => setNewAttributeType("integer")}
                    checked={newAttributeType === "integer"}
                    className="form-check-input"
                  />
                </div>
                <div className="mb-3 form-check">
                  <label htmlFor="float">Float</label>
                  <input
                    id="float"
                    type="radio"
                    value={"float"}
                    onChange={() => setNewAttributeType("float")}
                    checked={newAttributeType === "float"}
                    className="form-check-input"
                  />
                </div>
                <div className="mb-3 form-check">
                  <label htmlFor="string">String</label>
                  <input
                    id="string"
                    type="radio"
                    value={"string"}
                    onChange={() => setNewAttributeType("string")}
                    checked={newAttributeType === "string"}
                    className="form-check-input"
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger"
              onClick={handleOpenModal}
              disabled={editAttributeLoading || createAttributeLoading}
            >
              Fechar
            </button>
            <button
              className="btn btn-success"
              onClick={createNewAttribute}
              disabled={editAttributeLoading || createAttributeLoading}
            >
              Criar
            </button>
          </Modal.Footer>
        </Modal>
      )}
      <h3 className="mt-5">Altere os valores dos atributos - CRI/CRA</h3>
      <div
        style={{
          width: "275px",
          marginBottom: "50px",
        }}
      >
        <div className="">
          <label className="mt-3 fw-bold">Ticker</label>
          <div
            className="d-flex gap-2 position-relative "
            style={{
              width: "100%",
            }}
          >
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Digite o código do documento"
              value={tickerInput}
              onChange={(e) => setTickerInput(e.target.value)}
              onFocus={() => setHasFocusInputTicker(true)}
              onBlur={() => setTimeout(() => setHasFocusInputTicker(false), 200)}
            />
            {loading && (
              <div
                className="spinner-border position-absolute"
                style={{
                  right: "-50px",
                }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </div>

          {hasFocusInputTicker && (
            <div className="scroll mb-2 w-100">
              {documentTickers
                .filter((code) => code.toLowerCase().includes(tickerInput.toLowerCase()))
                .map((code) => (
                  <div
                    className="search-scroll w-100 d-flex justify-content-center"
                    key={code}
                    onClick={() => handleSetTickerValues(code)}
                  >
                    <span>{code}</span>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div>
          <label className="mt-3 fw-bold">Atributo</label>
          <div className="d-flex gap-2 position-relative ">
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Digite o nome do atributo"
              value={attributeInput}
              onChange={(e) => setAttributeInput(e.target.value)}
              onFocus={() => setHasFocusInputAttribute(true)}
              onBlur={() => setTimeout(() => setHasFocusInputAttribute(false), 200)}
            />
            {attributesLoading && (
              <div
                className="spinner-border position-absolute"
                style={{
                  right: "-50px",
                }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </div>

          {hasFocusInputAttribute && (
            <div className="scroll mb-2 w-100">
              {documentInfoKeys
                .filter((label) =>
                  label.toLowerCase().includes(attributeInput.toLowerCase())
                )
                .map((label) => (
                  <div
                    className="search-scroll w-100 d-flex justify-content-center"
                    key={label}
                    onClick={() => handleSetAttributeValue(label)}
                  >
                    <span>{label}</span>
                  </div>
                ))}
            </div>
          )}
        </div>

        <div>
          <label className="mt-3 fw-bold">Valor atual</label>
          {isPriceIndexReferenceDayTable ? (
            <select
              className="form-select mb-3"
              style={{
                width: "275px",
              }}
            >
              {readOnlyValue.map((value) => (
                <option
                  value={value}
                  key={value}
                >
                  {value}
                </option>
              ))}
            </select>
          ) : (
            <span
              className="form-control mb-3"
              style={{
                width: "275px",
              }}
            >
              {String(readOnlyValue)}
            </span>
          )}
        </div>

        {!isPriceIndexReferenceDayTable && (
          <div>
            <label className="mt-3 fw-bold">Novo valor</label>
            {typeof valueInput.value === "boolean" ? (
              <>
                <div className="mb-3 form-check">
                  <label
                    htmlFor="true"
                    className="form-check-label"
                  >
                    Sim
                  </label>
                  <input
                    id="true"
                    type="radio"
                    value={true}
                    onChange={() => handleSetValueInput(true)}
                    checked={valueInput.value === true}
                    className="margin-right-sm margin-left-sm form-check-input"
                  />
                </div>
                <div className="mb-3 form-check">
                  <label
                    htmlFor="false"
                    className="form-check-label"
                  >
                    Não
                  </label>
                  <input
                    id="false"
                    type="radio"
                    value={false}
                    onChange={() => handleSetValueInput(false)}
                    checked={valueInput.value === false}
                    className="margin-left-sm form-check-input"
                  />
                </div>
              </>
            ) : (
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Digite o valor do atributo"
                disabled={isPriceIndexReferenceDayTable}
                value={valueInput.value ?? ""}
                onChange={(e) => handleSetValueInput(e.target.value)}
              />
            )}
          </div>
        )}

        {typeof valueInput.value !== "boolean" && !isPriceIndexReferenceDayTable && (
          <div>
            <strong className="mb-3">Tipo do atributo</strong>
            <div className="mb-3 form-check">
              <label htmlFor="integer-attr">Inteiro</label>
              <input
                id="integer-attr"
                type="radio"
                value={"integer"}
                onChange={() => setAttributeType("integer")}
                checked={attributeType === "integer"}
                className="form-check-input"
              />
            </div>
            <div className="mb-3 form-check">
              <label htmlFor="float-attr">Float</label>
              <input
                id="float-attr"
                type="radio"
                value={"float"}
                onChange={() => setAttributeType("float")}
                checked={attributeType === "float"}
                className="form-check-input"
              />
            </div>
            <div className="mb-3 form-check">
              <label htmlFor="string-attr">String</label>
              <input
                id="string-attr"
                type="radio"
                value={"string"}
                onChange={() => setAttributeType("string")}
                checked={attributeType === "string"}
                className="form-check-input"
              />
            </div>
          </div>
        )}

        <button
          type="button"
          className="btn btn-primary p-2 mb-3"
          style={{
            width: "275px",
          }}
          onClick={handleOpenModal}
          disabled={editAttributeLoading}
        >
          Criar Atributo
        </button>

        <button
          type="button"
          className="btn btn-success p-2"
          style={{
            width: "275px",
          }}
          onClick={updateAttribute}
          disabled={editAttributeLoading || isPriceIndexReferenceDayTable}
        >
          Salvar
        </button>
      </div>
    </main>
  )
}
