import React from "react";
import Modal from 'react-bootstrap/Modal'

import ScheduleEditModalInput from "../SchedulesEditModalInput";
import { eventsStatusDatesTranslate } from "../../../../common/debenturesRegisterEventAndStatusTypes";


const ScheduleEditModal = ({show, onHide, rowSchedule, rowIndex, schedule, saveAgenda}) => {

  const headerTranslate = (header) => {
    switch (header) {
      case '0':
        return "Event Date"
      case '1':
        return "Actual Event Date"
      case '2':
        return "Event"
      case '3':
        return "Percentual Rate"
      case '4':
        return "Status"
      case '5':
        return "Amount"
      case '6':
        return "Internal Use"
      case '7':
        return "Calculus Reference"
      case '8':
        return "Percentage To Principal INCORPORATION"
      default:
        console.log(`Erro durante tradução`);
    }
  }

  const editScheduleData = (index, name, value) => {   
    let cellIndex = parseInt(name) + 2
    let rowToChangeColor = document.getElementById(`row-${index}`);
    let cellToChangeColor = rowToChangeColor.childNodes;

    cellToChangeColor[cellIndex].style.fontWeight = "bold"

    rowToChangeColor.style.background = "#5ef600"
    rowToChangeColor.style.color = "black"

    schedule[index][name] = value;

    saveAgenda(schedule)
  }

  return (
    <Modal
          show={show}
          onHide={onHide}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Editor de Agenda
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="col-md-12 justify-content-center">
          {Object.keys(rowSchedule).map(function(key, index) {
              return(
                <div className="mt-3" key={index}>
                  <span>
                    {headerTranslate(key)}:
                      <br/>
                        <ScheduleEditModalInput 
                          id={headerTranslate(key)}
                          defaultValue={eventsStatusDatesTranslate(headerTranslate(key), rowSchedule[key])}
                          name={key}
                          editScheduleData={editScheduleData}
                          rowIndex={rowIndex}
                          keyMap={key}
                        />
                      
                  </span>
                </div>
              )
          })}
          </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-md-8 justify-content-center"> 
              <button 
                className="btn btn-success"
                onClick={() => onHide()}>
                  Salvar Alterações
              </button>
            </div>
          </Modal.Footer>
      </Modal>
    );
};

export default ScheduleEditModal;
