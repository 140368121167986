import React, { useEffect, useState } from "react"
import notify from "../../common/toast"
import api from "../../services/api"

export default function FiaReports() {
  const [emailInput, setEmailInput] = useState("")
  const [companyInput, setCompanyInput] = useState("")
  const [fileExtensions, setFileExtensions] = useState([])
  const [loading, setLoading] = useState(false)
  const [subscribedUsers, setSubscribedUsers] = useState([])
  const [subscribedLoading, setSubscribedLoading] = useState(false)

  function handleCheckboxChange(event) {
    const { value, checked } = event.target

    if (checked) {
      setFileExtensions((prevFilesInput) => [...prevFilesInput, value])
    } else {
      setFileExtensions((prevFilesInput) =>
        prevFilesInput.filter((file) => file !== value)
      )
    }
  }

  async function subscribeUser(e) {
    e.preventDefault()

    if (companyInput.length < 2 || fileExtensions.length < 1) {
      return notify("Formulário inválido", "error")
    }

    if (emailInput.length < 7) {
      return notify("Digite um e-mail válido", "error")
    }

    try {
      setLoading(true)

      const response = await api.post("reports/users/", {
        email: emailInput,
        company_name: companyInput,
        file_extensions: fileExtensions,
      })

      console.log(response)

      notify("Usuário cadastrado com sucesso", "success")
      setEmailInput("")
      setCompanyInput("")

      handleFetchActiveUsers()
    } catch (err) {
      console.log(err)
      notify("Houve um erro ao cadastrar o usuário", "error")
    } finally {
      setLoading(false)
    }
  }

  async function handleFetchActiveUsers() {
    try {
      setSubscribedLoading(true)

      const response = await api.get("reports/users/")

      setSubscribedUsers(response.data)
    } catch (err) {
      console.log(err)
      notify("Houve um erro ao buscar os usuários ativos", "error")
    } finally {
      setSubscribedLoading(false)
    }
  }

  useEffect(() => {
    handleFetchActiveUsers()
  }, [])

  return (
    <main
      className="container-fluid p-4 d-flex gap-4 justify-content-center align-items-center"
      style={{
        height: "fit-content",
      }}
    >
      <div
        className="shadow d-flex flex-column"
        style={{
          minWidth: "fit-content",
          minHeight: "fit-content",
        }}
      >
        <div className="d-flex justify-content-center">
          <h2>FIA Reports</h2>
        </div>
        <section>
          <form
            action="post"
            onSubmit={subscribeUser}
            className="d-flex flex-column align-items-center gap-4 p-4"
          >
            <div>
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="company" className="form-label">
                Empresa:
              </label>
              <input
                type="text"
                id="company"
                className="form-control"
                value={companyInput}
                onChange={(e) => setCompanyInput(e.target.value)}
              />
            </div>

            <div
              className="d-flex wi gap-2 align-items-center justify-content-start w-100"
              style={{
                marginLeft: "1rem",
              }}
            >
              <div className="d-flex flex-column">
                <div className="d-flex gap-2">
                  <label htmlFor="csv" className="form-check-label">
                    CSV
                  </label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    value="csv"
                    name="csv"
                    id="csv"
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div className="d-flex gap-2">
                  <label htmlFor="xlsx" className="form-check-label">
                    XLSX
                  </label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    value="xlsx"
                    name="xlsx"
                    id="xlsx"
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={loading}
            >
              {loading ? (
                <div
                  className="spinner-border text-light"
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Cadastrar usuário"
              )}
            </button>
          </form>
        </section>
      </div>
      <section className="p-4 shadow">
        <h3 className="text-center fs-4">
          Usuários ativos: {subscribedUsers.length}
        </h3>
        <div className="d-flex justify-content-center h-75">
          {subscribedLoading ? (
            <div
              className="spinner-border text-primary align-self-center"
              style={{
                width: "30px",
                height: "30px",
              }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <ul className="d-flex flex-column list-group">
              {subscribedUsers.map((user) => (
                <li
                  key={user.email}
                  className="list-group-item d-flex flex-column gap-2"
                >
                  <span>{user.email}</span>
                  <div className="d-flex justify-content-between">
                    <small>{user.company_name}</small>

                    <div className="d-flex gap-2">
                      {user.file_extensions.map((file) => (
                        <small key={file}>{file}</small>
                      ))}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>
    </main>
  )
}
