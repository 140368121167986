import React, { useState, useEffect } from "react";
import api from "../../services/api";

import MenuQueryDFA from "./Menu";
import Dropdown from 'react-bootstrap/Dropdown'
import moment from 'moment'
import Swal from "sweetalert2";


import "./style.css";
import CallsVisualization from "./CallsVisualization";


const QueryDFA = () => {
    const [callsResultLog, setCallsResultLog] = useState('')
    
    const [calls, setCalls] = useState('')
    const [callsResult, setCallsResult] = useState([])
    const [isLoadingCalls, setIsLoadingCalls] = useState(false)
    

    const get_gov_best_calls_audit_trial = async (instrumentType, dateToFilter, startTime, endTime) => {
        setIsLoadingCalls(true)
        setCallsResultLog('')
        setCallsResult('')
        setCalls('')

        var date = moment(dateToFilter).format('YYYY-MM-DD')
        var reference_start_date = new Date(moment(date + ' ' + startTime).format('YYYY-MM-DDTHH:mm:ss[.000Z]'));
        var reference_end_date = new Date(moment(date + ' ' + endTime).format('YYYY-MM-DDTHH:mm:ss[.000Z]'));


        try {
            const documents = await api.post('get_gov_best_calls_audit_trial', {instrumentType, reference_start_date, reference_end_date});

            if (documents.data.length > 0) {
                setCallsResultLog(documents.data)
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Sem dados!',
                    text: `Nenhum dado salvo para esta data de referência.`,
                    showConfirmButton: true,
                })
            }

            setIsLoadingCalls(false)     
        } catch (e) {
            setIsLoadingCalls(false)
            Swal.fire({
                icon: 'error',
                title: 'Erro!',
                text: `Erro com a comunicação com o banco de dados!`,
                showConfirmButton: true,
            })

            console.log(e);
        }
    }


    const getFromResultsLog = (index) => {
        setCalls(callsResultLog[index])

        var selectedCalls = callsResultLog[index].selected_calls
        var results = []

        Object.keys(selectedCalls).forEach(maturityDate => {
            var minBid = Math.min.apply(Math, selectedCalls[maturityDate].filter(function (callAttributeFilter){
                return callAttributeFilter.bid !== 'nan'
            }).map(function(callAttribute) { 
                return callAttribute.bid 
            }))
            var maxAsk = Math.max.apply(Math, selectedCalls[maturityDate].filter(function (callAttributeFilter){
                return callAttributeFilter.ask !== 'nan'
            }).map(function(callAttribute) { 
                return callAttribute.ask 
            }))
            var callsMid = (parseFloat(minBid) + parseFloat(maxAsk))/2
            var callsSpread = parseFloat(minBid) - parseFloat(maxAsk)

            var calcResult = {
                minBid: parseFloat(minBid), 
                maxAsk: parseFloat(maxAsk), 
                callsMid: callsMid,
                callsSpread: callsSpread 
            } 
                        
            results = {...results, [maturityDate]: calcResult}
        })

        setCallsResult(results) 
    }

    const renderResultLogBox = () => {
        return(
            <Dropdown className="text-center">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Escolha um dos resultados salvos:
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {callsResultLog.map((result, index) => {
                        return (
                            <Dropdown.Item 
                                key={index}
                                onClick={() => getFromResultsLog(index)}>
                                    Dia <span className="dfa-values-color">
                                        {moment(result.saved_at).format('DD/MM/YYYY')}
                                    </span> 
                                    &nbsp;às <span className="dfa-values-color">
                                        {moment(result.saved_at).utc(false).format('HH:mm:ss')}
                                    </span> &nbsp;por 
                                    <span className="dfa-values-color">
                                    &nbsp;{result.user_info.name}
                                    </span>
                            </Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    return (
        <>
            <h1 className="text-center mt-3"> Consultar Resultados </h1>
            <MenuQueryDFA 
                isLoadingCalls={isLoadingCalls}
                get_calls={get_gov_best_calls_audit_trial}
            />
            <div className="container pricingDFA_container justify-content-center">
                {callsResultLog && renderResultLogBox()}
                { calls ?
                <div className="pricingDFA_calls_group">
                    <CallsVisualization 
                        userInfo={calls}
                        selectedCalls={calls.selected_calls}
                        finalResults={calls.final_results}
                        callsResult={callsResult}
                        isPreview={calls.is_preview}
                    />
                </div>: null }
            </div>
        </>
    );
};

export default QueryDFA;