import React, { useState }  from 'react';
import { Link } from 'react-router-dom';

import api from '../../services/api';
import { resetForm } from '../../common'
import  "./styles.css";

function ResetPassword(){
    const [resetPwInput, setResetPwInput] = useState([{email: '',password: '', 
    password_confirmation: '', token: '', tokenSent: false}])
    const [msg, setMsg] = useState('')

    const handleResetPassword = e => {
        e.preventDefault();
        const {email} = resetPwInput;
        api.post('/password_reset',{ email })
        .then(resp => { 
            setResetPwInput({...resetPwInput, tokenSent: true});
            setMsg(resp.data.success);
        })
        .catch(e => {
            setMsg(e.response.data.error);
        });

    }

    const handleChangePassword = e => {
        e.preventDefault();
        if(resetPwInput.password !== resetPwInput.password_confirmation){
            setMsg('Nova senha e confirmação não batem!');
        }else if (resetPwInput.password.length < 6){
            setMsg('Sua senha precisar ter, no mínimo, 6 digitos!');
        }else{
            const {email, password, password_confirmation, token} = resetPwInput;
            api.put('/password_reset',{ email, password, password_confirmation, token })
            .then(resp => { 
                setMsg(resp.data.success);
                resetForm("resetPwForm")
            })
            .catch(e => {
                setMsg(e.response.data.error);
            });
        }

    }

    return (
        <div className="login-form">
            {!resetPwInput.tokenSent ? 
                <form  onSubmit={handleResetPassword}>
                    <h2 className="text-center">Reset Password</h2>  
                    <div className="form-group">  
                        <input
                        type="email"
                        placeholder="Endereço de e-mail"
                        className="form-control"
                        required
                        onChange={e => setResetPwInput({...resetPwInput, email: e.target.value})}
                        />
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary btn-block" type="submit">Resetar</button>
                    </div>
                    <hr />
                    <center>{msg}</center>
                    <center><Link to="/"> Cancelar </Link></center>
                </form>
            :
                <form id="resetPwForm" onSubmit={handleChangePassword}>
                        <h2 className="text-center">Reset Password</h2> 
                        <div className="form-group">  
                            <input
                            type="password"
                            placeholder="Nova Senha"
                            className="form-control"
                            required
                            autoComplete="off"
                            onChange={e => setResetPwInput({...resetPwInput, password: e.target.value })}
                            />
                        </div>
                        <div className="form-group">   
                            <input
                            type="password"
                            placeholder="Confirmação da nova senha"
                            className="form-control"
                            required
                            autoComplete="off"
                            onChange={e => setResetPwInput({ ...resetPwInput, password_confirmation: e.target.value })}
                            />
                        </div> 
                        <div className="form-group">  
                            <input
                            type="text"
                            placeholder="Token"
                            className="form-control"
                            required
                            autoComplete="off"
                            onChange={e => setResetPwInput({...resetPwInput, token: e.target.value})}
                            />
                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary btn-block" type="submit">Enviar</button>
                        </div>
                        <hr />
                        <center>{msg}</center>
                        <center><Link to="/"> Cancelar </Link></center>
                    </form>
            }
        </div>
    )
}

export default ResetPassword;