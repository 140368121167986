import React, { useState} from "react";
import { useAlert } from 'react-alert'
import CallsTableModal from "../CallsTableModal";

import Card from 'react-bootstrap/Card'
import moment from 'moment'

import "react-datepicker/dist/react-datepicker.css";

import "./style.css";


const CallsGroupContainer = ({calls, maturityDate, selectedCalls, maturityDatesToAnalyze, screenSize}) => {
    const alert = useAlert()
    const {totalMaturityDates, analyzedCalls} = maturityDatesToAnalyze  
    const [isAnalyzed, setIsAnalyzed] = useState(false)
    const [callsModal, setCallsModal] = useState(false)

    function getDifferenceBetweenJson(allCalls, selectedCalls) {
        return allCalls.filter(object1 => {
            return !selectedCalls.some(object2 => {
                return object1.time === object2.time;
            });
        });
    }

    const handleIsAnalyzed = (maturityDate, selectedBestCalls, callsResult) => {
        selectedCalls(maturityDate, selectedBestCalls, callsResult)
        setCallsModal(false)
        if (analyzedCalls < (totalMaturityDates - 1)){
            alert.show(<div className="alert alert-warning text-center fw-bold" role="alert">
                Faltam {(totalMaturityDates - 1) - analyzedCalls} vencimentos para serem analisados.
            </div>)
        } else {
            alert.show(
            (<div className="alert alert-success text-center fw-bold" role="alert">
                Todos os vencimentos foram analisados.
            </div>), {
                type: 'success',
            })        
        }

        for ( var selectedCallsAttr in selectedBestCalls ) {
            selectedBestCalls[selectedCallsAttr].selected_by_user = true
        }

        var notSelectedCalls = getDifferenceBetweenJson(calls, selectedBestCalls)

        if (notSelectedCalls) {
            for ( var notSelectedCallsAttr in notSelectedCalls ) {
                notSelectedCalls[notSelectedCallsAttr].selected_by_user = false
            }
        }

        sessionStorage.setItem(maturityDate, "User has selected calls");

        setIsAnalyzed(true)
    }

    return (
    <>
        <Card className={isAnalyzed ? 'mt-4 mb-4 border-success' : 'mt-4 mb-4 border-warning'}>
            <Card.Header as="h5" className="text-center">
                <span className="fw-bold">
                    Vencimento: 
                    <span className="dfa-values-color">
                        &nbsp; {moment.utc(maturityDate).format("DD/MM/YYYY")}
                    </span>
                </span>
            </Card.Header>
            <Card.Body>
                <div className="row">
                    <div className="col-md-12">
                        <span className="fw-bold float-start">
                            Tipo de Instrumento: 
                            <span className="dfa-values-color">
                                &nbsp; {calls[0]['instrumentType']}
                            </span>
                        </span> 
                        <span className={parseFloat(screenSize) < 0.38 ? "float-small-dvc fw-bold float-end"
                                        : "fw-bold float-end"}>
                            Quantidade de Calls:
                            <span className="dfa-values-color">
                                &nbsp; {calls.length}
                            </span>
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">  
                        <span className={parseFloat(screenSize) < 0.38 ? "fw-bold float-small-dvc"
                                        : "fw-bold"}>
                            Status de Análise: 
                            <span className={isAnalyzed ? 'text-success' : 'text-warning'}>
                                &nbsp; {isAnalyzed ? 'Analisado!' : 'Análise Pendente'}
                            </span>
                        </span>
                    </div>
                </div> 
                <div className="row">
                    <div className="col-md-12">
                        <span className="fw-bold float-start">
                            <span className="dfa-values-color">
                                &nbsp;
                            </span>
                        </span>
                    </div>
                </div>
            </Card.Body>
            <Card.Footer className="text-center">
                <button 
                    className="btn dfa-button"
                    onClick={() => setCallsModal(true)}>
                        Analisar
                </button>
            </Card.Footer>
        </Card>        
        <CallsTableModal
            calls={calls}
            handleIsAnalyzed={handleIsAnalyzed}
            show={callsModal}
            onHide={() => setCallsModal(false)}
        />
    </>
    );
};

export default CallsGroupContainer;