import React, { useState, useEffect } from "react"

import DatePicker from "react-datepicker"
import TimePicker from "react-time-picker"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from "date-fns/locale/pt-BR"

import "./style.css"

const MenuQueryDFA = ({ isLoadingCalls, get_calls }) => {
  const [dateToFilter, setDateToFilter] = useState(new Date())
  const [instrumentType, setInstrumentType] = useState("LTN")
  const [startTimeToFilter, setStartTimeToFilter] = useState("09:00")
  const [endTimeToFilter, setEndTimeToFilter] = useState("17:00")

  const instrumentTypeList = ["LTN", "NTF", "NTB", "LFT"]

  const isWeekday = (date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  //{instrument_type: "NTB", reference_start_date: {$gte: ISODate("2022-05-20T09:00:00.000+00:00")},  reference_end_date: {$lte: ISODate('2022-05-20T17:00:00.000+00:00')}}

  return (
    <div className="col-md-12 d-flex justify-content-center">
      <div className="row pricingDFA_menu">
        <div className="card col-12">
          <div className="date-filter-input text-center">
            <label>
              <span className="fw-bolder">Selecione a data:</span>
            </label>
            <div className="mb-3 mt-2">
              <DatePicker
                className="dfa-form-control"
                dateFormat="dd/MM/yyyy"
                locale={ptBR}
                selected={dateToFilter}
                onChange={(value) => setDateToFilter(value)}
                maxDate={new Date()}
                filterDate={isWeekday}
              />
            </div>
            <label>
              <span className="fw-bolder">
                Selecione o tipo de instrumento:{" "}
              </span>
            </label>
            <div className="mb-3 mt-2">
              <select
                className="dfa-form-control mx-auto"
                id="instrument-select"
                onChange={(e) => setInstrumentType(e.target.value)}
                value={instrumentType}
              >
                {instrumentTypeList.map(function (type) {
                  return <option key={type}>{type}</option>
                })}
              </select>
            </div>
            <label>
              <span className="fw-bolder">
                Selecione o horário inicial e o horário final:
              </span>
            </label>
            <div className="mb-3 mt-2">
              <span className="fw-bolder me-2 ms-2">Inicial:</span>
              <TimePicker
                onChange={(value) => setStartTimeToFilter(value)}
                value={startTimeToFilter}
                hourPlaceholder="HH"
                minutePlaceholder="mm"
              />
              <span className="fw-bolder me-2 ms-2">Final:</span>
              <TimePicker
                onChange={(value) => setEndTimeToFilter(value)}
                value={endTimeToFilter}
                hourPlaceholder="HH"
                minutePlaceholder="mm"
              />
            </div>
            <div className="text-center">
              {isLoadingCalls ? (
                <button className="btn btn-danger mx-3">
                  <FontAwesomeIcon
                    icon={faSpinner}
                    size="1x"
                    className="spinner"
                  />
                  &nbsp; Consultando banco de dados...
                </button>
              ) : (
                <button
                  className="btn dfa-button"
                  onClick={() =>
                    get_calls(
                      instrumentType,
                      dateToFilter,
                      startTimeToFilter,
                      endTimeToFilter
                    )
                  }
                >
                  Carregar Calls (Resultados)
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuQueryDFA
