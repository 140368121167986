export function saveUserInfos(id, name, email) {
  return (dispatch) => {
    dispatch({
      type: "USERINFO",
      id,
      name,
      email,
    });
  };
}
