import React, { createContext,useState} from 'react';

const TableDataContext = createContext();

export function TableDataProvider({children}) {
  const [tableRender,setTableRender] = useState(false)
  return (
    <TableDataContext.Provider value={{tableRender,setTableRender}}>
      {children}
    </TableDataContext.Provider>
  )
}

export default TableDataContext;