import React from "react";
import Modal from "react-bootstrap/Modal";

import CriCraExpandedComponentList from "../CriCraExpandedComponentList";
import { criCraInfoTemplate } from "../../../../common/debenturesInfoTemplate";

const CriCraModalCustomRegister = ({ show, onHide, reloadDataTable }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Cadastrar CRI/CRA Manualmente
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CriCraExpandedComponentList
          criCraInfoRow={criCraInfoTemplate}
          $isCustomCriCraFlag={true}
          reloadDataTable={reloadDataTable}
        />
      </Modal.Body>
    </Modal>
  );
};

export default CriCraModalCustomRegister;
