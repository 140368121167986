import moment from "moment";

export const eventsStatusDatesTranslate = (inputName, eventName) => { 
    if (inputName === "Event Date" || inputName === "Actual Event Date" || inputName === 0 || inputName === 1) {
        let formatedDate = moment(eventName, 'DD/MM/YYYY').format("DD/MM/YYYY")

        return formatedDate
    } else if (inputName === "% RATE" || inputName === "AMOUNT" || inputName === "Percentual Rate" || inputName === "Amount" ||
                inputName === 3 || inputName === 5){
        return eventName.replace(".", ",")
    } else if (inputName === "Event" || inputName === "EVENT" || inputName === 2){
        switch (eventName) {
            case 'AMORTIZACAO':
                return "AMORTIZATION";
            case 'AMORTIZACAO EXTRAORDINARIA':
                return "EXTRAORDINARY_AMORTIZATION"
            case 'CORRECAO DE VALOR NOMINAL':
                return "CORRECAO DE VALOR NOMINAL";
            case 'EVENTO GENERICO':
                return "GENERIC_EVENT";
            case 'INCORPORACAO DE JUROS':
                return "INTEREST_INCORPORATION";
            case 'LIMITE PARA NAO REPACTUACAO':
                return "LIMITE PARA NAO REPACTUACAO";
            case 'NAO REPACTUACAO':
                return "NAO REPACTUACAO";
            case 'OPCAO DE VENDA':
                return "OPCAO DE VENDA";
            case 'PAGAMENTO DE JUROS':
                return "INTEREST_PAYMENT";
            case 'PARTICIPACAO':
                return "DIVIDEND_BASED";
            case 'PREMIO':
                return "PREMIUM";
            case 'PREMIO DE PERMANENCIA':
                return "PREMIO DE PERMANENCIA";
            case 'RECOMPRA PELO EMISSOR':
                return "RECOMPRA PELO EMISSOR";
            case 'REPACTUACAO':
                return "RENEGOTIATION";
            case 'RESGATE PARCIAL ANTECIPADO':
                return "EARLY_PARTIAL_REDEMPTION";
            case 'RESGATE TOTAL ANTECIPADO':
                return "TOTAL_EARLY_REDEMPTION";
            case 'VENCIMENTO (RESGATE)':
                return "MATURITY_REDEMPTION";
            case 'VENCIMENTO':
                return "MATURITY";
            case 'MULTIPLICADOR':
                return "MULTIPLIER";
            default:
                return eventName
        }
    } else {
        return eventName
    }
}

export const normalizeEditedSchedule = (agenda) => { 
    agenda.forEach(function (agendaArray, agendaArrayIndex) {
        if (agendaArrayIndex > 0) {
            agendaArray.forEach(function (agendaValue, agendaValueIndex) { 
                agendaArray[agendaValueIndex] = eventsStatusDatesTranslate(agendaValueIndex, agendaValue)           
            })
        }
    })

    return agenda 
}