import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const ModalAgenda = ({ showModalAgenda, setShowModalAgenda, agenda }) => {
  return (
    <>
      <Modal
        isOpen={showModalAgenda}
        onRequestClose={() => setShowModalAgenda(true)}
      >
        <div className="button-close-modal">
          <button onClick={() => setShowModalAgenda(false)}>Fechar</button>
        </div>
        {agenda ? (
          <table className="table table-striped table-bordered mydatatable">
            <thead>
              <tr>
                {agenda["tableHeader"].map(header => (
                  <th key={header}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {agenda["data"].map(data => (
                <tr key={data}>
                  {data.map((item, index) => (
                    <td key={index}>{item}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="nothing">
            <h3>Nada a ser exibido.</h3>
          </div>
        )}
      </Modal>
    </>
  );
};

export default React.memo(ModalAgenda);
