import React, { useState, useEffect } from "react";
import { produce } from "immer";
import moment from 'moment'
import Swal from "sweetalert2";
import Loading from "../../components/DFALoading";
import api from "../../services/api"

import { generateXlsx } from './action/generateXlsx'
import DataTable from 'react-data-table-component';
import Menu from "./components/Menu";

const DebenturesBondsQA = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [debBondsQaData, setDebBondsQaData] = useState("");
    const [filtersDate, setFiltersDate] = useState({"date": new Date, "is_preview": null})

    const debBondsLastQaDataApi = async (filters) => {
      setIsLoading(true)
      var date = moment(filters.date).format("YYYY-MM-DD")
      var is_preview = filters.is_preview

      setFiltersDate(
        {
          "date": date, 
          "is_preview": is_preview
        }
      )

      try {
          const documents = await api.post('debBondsLastQaDataApi', {
              date, is_preview
          });
          
          console.log(documents.data.result.data)
          setDebBondsQaData(documents.data.result.data)
          setIsLoading(false)
      } catch (e) {
          console.log("Erro na API")
          setIsLoading(false)
      }
  }

    const formatDecimals = (value) => {
      if (value && value !== "nan"){
        var newValue = parseFloat(value).toFixed(3)

        return newValue
      } else {
        return value
      }
    }

    const qaColumns = React.useMemo(
      () => [
        {
          name: 'Ticker',
          selector: row => row.ticker,
        },
        {
          name: 'Mid D-1',
          selector: row => formatDecimals(row["mid D-1"]),
        },
        {
          name: 'Mid D-0',
          selector: row => formatDecimals(row["mid D-0"]),
        },
        {
          name: 'Delta Taxa',
          selector: row => formatDecimals(row["Delta Taxa"]),
        },
        {
          name: 'PU MTM D-1',
          selector: row => formatDecimals(row["PU MTM D-1"]),
        },
        {
          name: '% PU PAR D-1',
          selector: row => formatDecimals(row["PERCENTUAL_PU_PAR_D-1"]),
        },
        {
          name: 'Retorno (%) D-1',
          selector: row => formatDecimals(row["Retorno (%) D-1"]),
        },
        {
          name: 'PU Estim. D-1',
          selector: row => formatDecimals(row["PU estimado D-1"]),
        },
        {
          name: 'PU Estim. Taylor',
          selector: row => formatDecimals(row["PU estimado Taylor"]),
        },
        {
          name: "PU MTM D-0",
          selector: row => formatDecimals(row["PU_MTM_D-0 "]),
        },
        {
          name: '% PU PAR',
          selector: row => formatDecimals(row["% PU PAR "]),
        },
        {
          name: 'Retorno (%) D-0',
          selector: row => formatDecimals(row["Retorno (%) D-0"]),
          omit: true,
        },
        {
          name: 'Dif. Estim. D-1 (%)',
          selector: row => formatDecimals(row["Diferença estimado D-1 (%)"]),
        },
        {
          name: 'Dif. Estim. Taylor (%)',
          selector: row => formatDecimals(row["Diferença estimado Taylor (%)"]),
        },
      ], [],
	  );

  return ( 
    <>
      <Menu
        debBondsLastQaDataApi={debBondsLastQaDataApi}
      />

      <div className="col-md-12 justify-content-center" >
      { isLoading ? <Loading/> :
        <DataTable   
          columns={qaColumns}
          data={debBondsQaData}
          highlightOnHover
          subHeader
          subHeaderComponent={ debBondsQaData ?
            <button
              type="button"
              className="btn btn-info mb-2"
              onClick={() => generateXlsx(filtersDate.date, filtersDate.is_preview, debBondsQaData)}>
                Gerar Arquivo Excel
            </button> : null
          }
        />
      }
      </div>
    </> 
  );
};

export default DebenturesBondsQA;
