import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { customStyles } from '../../../PricingDFA/common/CallsTableModalColsStyles';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import "./style.css";

// Pintar Bid median e trade yield permitir editar tbm

const BondsCallTradesContainerModal = (props) => {
  const [dataInfo, setDataInfo] = useState([]);
  const { conglomerate, show, onHide, debBondsCallTradeData } = props

  useEffect(() => {
    if (conglomerate.length > 0){
      var filteredArray = debBondsCallTradeData.filter((element) => conglomerate.includes(element.ticker));
      setDataInfo(filteredArray.sort())
    }
  }, [conglomerate])

  const formatDecimals = (value) => {
    if (value && value !== "nan"){
      var newValue = parseFloat(value).toFixed(4)

      return newValue
    } else {
      return value
    }
  }

  const callsCols = React.useMemo(
		() => [
      {
        name: 'Source',
        selector: row => row.broker,
      },
      {
        name: 'Date',
        selector: row => moment(row.call_dt).format("DD/MM/YYYY"),
      },
      {
        name: 'Bid',
        selector: row => formatDecimals(row.bid),
      },
      {
        name: 'Ask',
        selector: row => formatDecimals(row.ask),
      },
      {
        name: 'Original Bid',
        selector: row => formatDecimals(row.original_bid),
      },
      {
        name: 'Original Ask',
        selector: row => formatDecimals(row.original_ask),
      },
    ], [],
	);
  
  const tradesCols = React.useMemo(
    () => 
    [
      {
        name: 'nm_lower_bound',
        selector: row => formatDecimals(row.nm_lower_bound),
      },
      {
        name: 'nm_upper_bound',
        selector: row => formatDecimals(row.nm_upper_bound),
      },
      {
        name: 'rr_lower_bound',
        selector: row => formatDecimals(row.rr_lower_bound),
      },
      {
        name: 'rr_upper_bound',
        selector: row => formatDecimals(row.rr_upper_bound),
      },
      {
        name: 'trade_yield_with_boundaries_nm',
        selector: row => formatDecimals(row.trade_yield_with_boundaries_nm),
      },
      {
        name: 'trade_yield_with_boundaries_rr',
        selector: row => formatDecimals(row.trade_yield_with_boundaries_rr),
      },
    ], []
  );

  const tickerCols = React.useMemo(
		() => [
      {
        name: 'Ticker',
        selector: row => row.ticker,
        sortable: true,
      },
      {
        name: 'Quantidade de Calls',
        selector: row => row.calls.length,
        sortable: true,
      },
      {
        name: 'Quantidade de Trades',
        selector: row => row.trades.length,
        sortable: true,
      },
    ], [dataInfo],
	);

  const ExpandedComponent = ({ data }) => {
    return(
      <div className="justify-content-center text-center mt-4 mb-4">
        <div className="data-table-box">
          <span className="mt-4 mb-3"> Calls </span>
          <DataTable   
            columns={callsCols}
            data={data["calls"]}
            highlightOnHover
            customStyles={customStyles}
          />
        </div>

        <div className="data-table-box">
          <span className="mt-4 mb-3"> Trades </span>
          <DataTable   
            columns={tradesCols}
            data={data["trades"]}
            highlightOnHover
            customStyles={customStyles}
          />
        </div>
      </div>
    )
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            { localStorage.getItem("is_preview") === "True" ? "Prévia": "" }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <DataTable   
                columns={tickerCols}
                data={dataInfo}
                highlightOnHover
                customStyles={customStyles}
                expandableRows 
                expandableRowsComponent={ExpandedComponent}
                defaultSortFieldId={1}
              />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BondsCallTradesContainerModal;