const XLSX = require('xlsx');

export function generateXlsx(date, is_preview, jsonData) {

  // Criar um workbook e adicionar uma worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(jsonData);

  // Adicionar a worksheet ao workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados');

  if (is_preview){
    var fileName = `debentures_bonds_qa_preview_${date}.xlsx`;
  } else {
    var fileName = `debentures_bonds_qa_closure_${date}.xlsx`
  }

  XLSX.writeFile(workbook, fileName);

  console.log('Arquivo Excel gerado com sucesso!');
}