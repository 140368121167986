import React, { useState, useMemo, useEffect } from "react"
import api from "../../../../services/api"

import CriCraExpandedComponentList from "../CriCraExpandedComponentList"
import moment from "moment"
import { criCraInfoTemplate } from "../../../../common/debenturesInfoTemplate"
import CriCraModalCustomRegister from "../CriCraModalCustomRegister"
import Loading from "../../../../components/Loading"
import DataTable from "react-data-table-component"

const CriCraToRegisterList = () => {
  const [customCriCraModal, setCustomCriCraModal] = useState(false)
  const [filterTicker, setFilterTicker] = useState("")
  const [criCraToRegisterListData, setCriCraToRegisterListData] = useState([])
  const [pending, setPending] = useState(false)
  const [reloadDataTable, setReloadDataTable] = useState(false)

  const centerOnRow = (debentureRow, event) => {
    let docToScroll = event.target.parentNode.id
    if (docToScroll !== null || docToScroll !== undefined) {
      let row = document.querySelector(`#${docToScroll}`)
      row.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  useEffect(() => {
    let componentMounted = true
    const fetchData = async () => {
      setPending(true)

      const documents = await api.get("/getCriCraToRegister")

      if (componentMounted) {
        setCriCraToRegisterListData(documents.data)
      }
      setPending(false)
    }

    fetchData()
    return () => {
      componentMounted = false
    }
  }, [reloadDataTable])

  const updateCriCraToRegister = () => {
    setCustomCriCraModal(false)
    setReloadDataTable(!reloadDataTable)
  }

  const filteredDebenturesToRegisterListData = criCraToRegisterListData.filter(
    (item) =>
      item["B3 Code"][0]?.value &&
      item["B3 Code"][0]?.value
        .toLowerCase()
        .includes(filterTicker.toLowerCase())
  )

  const conditionalRowStyles = [
    {
      when: (row) => row["custom_debenture"] == true,
      style: {
        backgroundColor: "rgba(248, 148, 6, 0.9)",
        color: "white",
      },
    },
  ]
  const customStyles = {
    rows: {
      style: {
        minHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: "15px",
        fontFamily: "Verdana, sans-serif",
        fontWeight: "bold",
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        fontFamily: "Verdana, sans-serif",
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  }

  const ListColumns = [
    {
      name: "B3 Code",
      selector: (row) => row["B3 Code"][0]?.value,
    },
    {
      name: "ISIN",
      selector: (row) => row["ISIN"][0]?.value,
    },
    {
      name: "Company",
      selector: (row) => row["Company"][0]?.value,
    },
    {
      name: "Yield Start Date",
      selector: (row) =>
        moment(row["Yield Start Date"][0]?.value)
          .utc(false)
          .format("DD/MM/YYYY"),
    },
    {
      name: "Issue Date",
      selector: (row) =>
        moment(row["Issue Date"][0]?.value).utc(false).format("DD/MM/YYYY"),
    },
    {
      name: "Maturity Date",
      selector: (row) =>
        moment(row["Maturity Date"][0]?.value).utc(false).format("DD/MM/YYYY"),
    },
    {
      name: "Adicionado Manualmente ?",
      selector: (row) => (row["custom_debenture"] ? "Sim" : "Não"),
    },
  ]

  const FilterComponent = useMemo(() => {
    return (
      <div className="col-md-6 d-flex justify-content-center">
        <div className="input-group mb-3">
          <span className="input-group-text">Procurar por ticker</span>
          <input
            className="form-control"
            id="search"
            type="text"
            value={filterTicker}
            autoComplete="off"
            placeholder="Filtrar por Ticker"
            onChange={(e) => setFilterTicker(e.target.value)}
          />
          <span className="input-group-text"> Ou </span>
          <button
            className="btn btn-warning"
            type="button"
            onClick={() => setCustomCriCraModal(true)}
          >
            Cadastrar CRI/CRA
          </button>
        </div>
      </div>
    )
  }, [filterTicker])

  const ExpandCriCraRow = ({ data }) => {
    return data && !pending ? (
      <CriCraExpandedComponentList
        criCraInfoRow={data}
        $isCustomCriCraFlag={false}
        reloadDataTable={updateCriCraToRegister}
      />
    ) : null
  }

  return (
    <>
      <CriCraModalCustomRegister
        show={customCriCraModal}
        onHide={() => setCustomCriCraModal(false)}
        template={criCraInfoTemplate}
        reloadDataTable={updateCriCraToRegister}
      />

      <DataTable
        customStyles={customStyles}
        columns={ListColumns}
        data={filteredDebenturesToRegisterListData}
        noDataComponent={"Nenhum CRI/CRA pendente encontrado"}
        expandableRows
        expandOnRowDoubleClicked
        onRowDoubleClicked={(row, event) => centerOnRow(row, event)}
        expandableRowsComponent={ExpandCriCraRow}
        subHeader
        subHeaderComponent={FilterComponent}
        conditionalRowStyles={conditionalRowStyles}
        highlightOnHover
        progressPending={pending}
        progressComponent={<Loading />}
        pagination
        paginationPerPage={50}
      />
    </>
  )
}

export default CriCraToRegisterList
