import React, { useState, useEffect, useMemo, useCallback } from "react"

import Tabs from "../../components/Tabs"
import TableAgendaFiAnalytics from "../../components/Table/TableAgendaFiAnalytics"
import ModalEditAgendaFiAnalytics from "./components/Modal/EditAgendaFIAnalytics"
import TickerBox from "../../components/TickerBox"
import Loading from "../../components/Loading"
import DatePicker from "react-datepicker"
import ptBR from "date-fns/locale/pt-BR"
import Swal from "sweetalert2"
import {
  transformStringDatePTBRToFormatMMDDYYYY,
  transformStringISODateToMMDDYYYY,
  getDayBefore,
} from "../../utils/date"
import { sortAgendaEvents } from "../../utils/orderEvents"
import { isAfter } from "date-fns"
import { limpaCache } from "../../common/cleanCache"

import notify from "../../common/toast"

import api from "../../services/api"

import { useFetch as fetchHook } from "../../hooks/useFetch"

import "./style.css"

const EditCriCraAgenda = () => {
  const [criCras, setCriCras] = useState([])
  const [agenda, setAgenda] = useState({})
  const [infoAgenda, setInfoAgenda] = useState({
    initial_date: "",
    final_date: "",
    version: "",
    versions: [],
  })
  const [agendaValue, setAgendaValue] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState([])
  const [ticker, setTicker] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [formFilterEvents, setFormFilterEvents] = useState({
    initialDate: "",
    finalDate: "",
    typeEvent: "",
  })
  const [selectedTicker, setSelectedTicker] = useState("")

  useEffect(() => {
    const ac = new AbortController()

    try {
      const url = "https://internal21.fi-analytics.com.br/api/v1/get_partial_cri_cra_info"
      const options = { METHOD: "GET", signal: ac.signal }

      fetchHook(url, options).then((value) => setCriCras(value))
    } catch (e) {
      console.log(e)
    }
    return () => {
      ac.abort()
    }
  }, [])

  const criCraTickers = useMemo(
    () => criCras.map((criCra) => criCra["B3 Code"].slice(-1)[0].value),
    [criCras]
  )

  const handleChangeSelect = (version) => {
    const filteredAgenda = agenda["agenda"].filter(
      (item) => Number(item.version) === Number(version)
    )

    const formatedInitialDate = transformStringISODateToMMDDYYYY(
      filteredAgenda[0]["initial_date"]
    )
    const formatedFinalDate = transformStringISODateToMMDDYYYY(
      filteredAgenda[0]["final_date"]
    )

    const orderedEvents = sortAgendaEvents(filteredAgenda[0]["value"])
    setAgendaValue(orderedEvents)

    setInfoAgenda({
      ...infoAgenda,
      initial_date: formatedInitialDate,
      final_date: formatedFinalDate,
      version: version,
    })
  }

  const getAgendaId = useCallback(() => {
    if (!ticker) {
      setIsLoading(false)
      return notify("Selecione um ticker", "warning")
    }
    const filteredCriCras = criCras.filter(
      (criCra) => criCra["B3 Code"][0]["value"] === ticker
    )
    const id =
      filteredCriCras[0]?.["Events Agenda FI Analytics"]?.["id"]?.["$oid"]

    if (!id) return notify("Não existe agenda para essa debênture", "warning")

    return id
  }, [criCras, ticker])

  const getAgenda = useCallback(async () => {
    try {
      setIsLoading(true)
      const agendaId = getAgendaId()
      const { data } = await api.get(`/cri_cra_agenda_by_id/${agendaId}`)
      const agendaLength = data[0]["agenda"].length
      const agendaValue = data[0]["agenda"][agendaLength - 1]["value"]
      const versions = data[0]["agenda"].map((item) => item["version"])
      const formatedInitialDate = transformStringISODateToMMDDYYYY(
        data[0]["agenda"][agendaLength - 1]["initial_date"]
      )
      const formatedFinalDate = transformStringISODateToMMDDYYYY(
        data[0]["agenda"][agendaLength - 1]["final_date"]
      )
      const version = data[0]["agenda"][agendaLength - 1]["version"]

      setInfoAgenda({
        ...infoAgenda,
        initial_date: formatedInitialDate,
        final_date: formatedFinalDate,
        version,
        versions,
      })
      setAgenda(data[0])
      setAgendaValue(agendaValue)
      setIsLoading(false)
      setSelectedTicker(ticker)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [
    infoAgenda,
    setInfoAgenda,
    ticker,
    setAgenda,
    setAgendaValue,
    setIsLoading,
    getAgendaId,
  ])

  const showEditModal = useCallback(
    (event) => {
      setSelectedEvent(event)
      setShowModal(true)
    },
    [setSelectedEvent, setShowModal]
  )

  const handleChangeFinalDate = (date) => {
    setInfoAgenda({
      ...infoAgenda,
      final_date: date,
    })
    let copyAgenda = JSON.parse(JSON.stringify(agenda))
    const { version, versions } = infoAgenda
    const index = versions.indexOf(Number(version))

    copyAgenda["agenda"][index]["final_date"] = date
    setAgenda(copyAgenda)
  }

  const deleteSingleEvent = useCallback(
    async (eventP) => {
      let shouldCancel

      await Swal.fire({
        title: "Deletar evento",
        text: "Você tem certeza que deseja deletar esse evento?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        shouldCancel = result.isDismissed
      })

      if (shouldCancel) return

      const events = agendaValue.filter(
        (event) => JSON.stringify(event) !== JSON.stringify(eventP)
      )
      if (agendaValue.length === events.length) {
        notify("warning", "Não foi possível deletar o evento")
        return
      }
      setAgendaValue(events)

      let copyAgenda = JSON.parse(JSON.stringify(agenda))
      const { version } = infoAgenda
      const agendaVersions = agenda["agenda"].map((item) => item["version"])
      const indexSelectedAgenda = agendaVersions.indexOf(Number(version))
      copyAgenda["agenda"][indexSelectedAgenda]["value"] = events

      setAgenda(copyAgenda)

      notify("Evento deletado", "success")
    },
    [infoAgenda, agenda, agendaValue]
  )

  const chechIfHasBug = (agenda) => {
    agenda.forEach((item) => {
      item.value.forEach((eventos) => {
        if (eventos.length !== 9 || typeof eventos[7] === Boolean) {
          throw new Error()
        }
      })
    })
  }
  const updateAgenda = async () => {
    try {
      let shouldCancel
      await Swal.fire({
        title: "Salvar alterações",
        text: "Você tem certeza que deseja salvar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        shouldCancel = result.isDismissed
      })

      if (shouldCancel) return

      const agendaFormated = getAgendaValueFormated(agenda)
      chechIfHasBug(agendaFormated)

      await api.patch("/update_cri_cra_schedule", {
        agenda: agendaFormated,
        agenda_id: agenda["_id"]["$oid"],
      })
      notify("Agenda atualizado com sucesso.", "success")
      limpaCache()
    } catch (e) {
      notify("Houve algum erro ao atualizar a agenda.", "error")
      console.log(e)
    }
  }

  const isLastAgendaVersion = () => {
    const { versions, version: currentVersion } = infoAgenda
    const lastVersion = versions.slice(-1)[0]

    const isLast = Number(currentVersion) === lastVersion

    return isLast
  }

  const handleChangeInitialDate = (date) => {
    setInfoAgenda({
      ...infoAgenda,
      initial_date: date,
    })
    let copyAgenda = JSON.parse(JSON.stringify(agenda))
    const { version, versions } = infoAgenda
    const index = versions.indexOf(Number(version))

    copyAgenda["agenda"][index]["initial_date"] = date

    if (index === 0) {
      setAgenda(copyAgenda)
      return
    }

    copyAgenda["agenda"][index - 1]["final_date"] = getDayBefore(date)
    setAgenda(copyAgenda)
  }

  const cloneAgenda = async () => {
    let shouldCancel
    await Swal.fire({
      title: "Clone da agenda",
      text: "Você tem certeza que deseja clonar a última versão da agenda?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((result) => {
      shouldCancel = result.isDismissed
    })

    if (shouldCancel) return

    const lastAgenda = agenda.agenda.slice(-1)[0]
    const lastAgendaVersion = +lastAgenda.version
    const agendaValues = agenda.agenda
    const newAgendaVersion = {
      ...lastAgenda,
      version: lastAgendaVersion + 1,
    }
    const newAgenda = {
      ...agenda,
      agenda: [...agendaValues, newAgendaVersion],
    }
    setAgenda(newAgenda)
    setInfoAgenda({
      ...infoAgenda,
      version: lastAgendaVersion + 1,
      versions: [...infoAgenda.versions, lastAgendaVersion + 1],
    })

    notify(
      `${lastAgendaVersion + 1}º versão da agenda criada com sucesso.`,
      "success"
    )
  }

  // remove the element of array that indicates the event was edited
  const getAgendaValueFormated = (agenda) => {
    let copyAgenda = JSON.parse(JSON.stringify(agenda))
    copyAgenda["agenda"].forEach((a, index) =>
      a["value"].forEach((item, index2) => {
        if (item.length === 10 && item[9] === "wasEdited") {
          copyAgenda["agenda"][index]["value"][index2] = item.slice(0, 9)
        }
      })
    )

    setAgenda(copyAgenda)
    return copyAgenda["agenda"]
  }

  const setValuesToAgenda = (newEvents) => {
    const versions = agenda["agenda"].map((item) => item["version"])
    const indexAgendaVersion = versions.indexOf(Number(infoAgenda["version"]))
    let agendaCopy = JSON.parse(JSON.stringify(agenda))
    agendaCopy["agenda"][indexAgendaVersion]["value"] = newEvents
    setAgenda(agendaCopy)
  }

  const filterAgenda = () => {
    const filteredAgendaValues = agendaValue.slice(1).filter((event) => {
      const { initialDate, finalDate, typeEvent } = formFilterEvents
      if (!initialDate && !finalDate && !typeEvent) {
        return true
      }
      if ((!initialDate || !finalDate) && typeEvent) {
        return event[2] === typeEvent
      }
      if (initialDate && finalDate && !typeEvent) {
        return (
          isAfter(
            transformStringDatePTBRToFormatMMDDYYYY(event[0]),
            initialDate
          ) &&
          isAfter(finalDate, transformStringDatePTBRToFormatMMDDYYYY(event[0]))
        )
      }
      if (initialDate && finalDate && typeEvent) {
        return (
          isAfter(
            transformStringDatePTBRToFormatMMDDYYYY(event[0]),
            initialDate
          ) &&
          isAfter(
            finalDate,
            transformStringDatePTBRToFormatMMDDYYYY(event[0])
          ) &&
          typeEvent === event[2]
        )
      }
      return true
    })
    return filteredAgendaValues
  }

  return (
    <>
      <div className="container__agenda margin-top-xl">
        <TickerBox
          debenturesCodes={criCraTickers}
          searchB3Code={ticker}
          isLoading={isLoading}
          ticker={ticker}
          setTicker={setTicker}
          getAgenda={getAgenda}
        />

        {isLoading && <Loading />}

        {!isLoading && agendaValue.length !== 0 && (
          <>
            <Tabs
              setAgendaValue={setAgendaValue}
              agendaValue={agendaValue}
              formFilterEvents={formFilterEvents}
              setFormFilterEvents={setFormFilterEvents}
              setValuesToAgenda={setValuesToAgenda}
            />

            <div className="box__t">
              {selectedTicker && (
                <>
                  <label>Ticker: </label>
                  <span className="margin-left-sm margin-right-md">
                    <strong>{selectedTicker}</strong>
                  </span>
                </>
              )}
              <label className="margin-right-sm">
                <strong>Data inicial</strong>
              </label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                locale={ptBR}
                selected={infoAgenda["initial_date"]}
                onChange={(value) => handleChangeInitialDate(value)}
              />
              {!isLastAgendaVersion() && (
                <>
                  <label className="margin-left-sm margin-right-sm">
                    <strong>Data final</strong>
                  </label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale={ptBR}
                    selected={infoAgenda["final_date"]}
                    onChange={(value) => handleChangeFinalDate(value)}
                  />
                </>
              )}

              <label className="margin-left-sm margin-right-sm">
                <strong>Versão</strong>
              </label>
              <select
                value={infoAgenda["version"]}
                onChange={(e) => handleChangeSelect(e.target.value)}
              >
                {infoAgenda["versions"].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <button
                onClick={updateAgenda}
                className="btn--update--agenda btn--hover"
              >
                Salvar
              </button>
              <button
                className="btn--update--agenda btn--hover"
                onClick={cloneAgenda}
              >
                Clonar agenda
              </button>
            </div>
            <TableAgendaFiAnalytics
              agendaValue={agendaValue}
              showEditModal={showEditModal}
              deleteSingleEvent={deleteSingleEvent}
              setAgendaValue={setAgendaValue}
              filterAgenda={filterAgenda}
            />
          </>
        )}
      </div>

      <ModalEditAgendaFiAnalytics
        showModal={showModal}
        setShowModal={setShowModal}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        agendaValue={agendaValue}
        setAgendaValue={setAgendaValue}
        setValuesToAgenda={setValuesToAgenda}
      />
    </>
  )
}

export default EditCriCraAgenda
