import {getCredentials, setToken, removeToken} from '../auth/credentials' 
import isAuthenticated from '../auth/isAuthenticated' 
 
const INITIAL_STATE = { 
    credentials: getCredentials(), 
    validToken: isAuthenticated(), 
    error: '' 
}; 
 
function authReducer(state = INITIAL_STATE, action){ 
    switch(action.type){ 
        case 'LOGIN': 
            setToken(action.token); 
            return { ...state, validToken: true, credentials: getCredentials()} 
        case 'LOGOUT': 
            removeToken(); 
            return { ...state, validToken: false, credentials: {}}     
        case 'FAILURE': 
            return { ...state, error: action.error}     
        default: 
            return state; 
    } 
} 
 
export default authReducer; 