import React, { useState, useEffect } from "react"
import InputMask from "react-input-mask"
import moment from "moment"

const CriCraInfoEditInput = ({ id, rowToEdit, editMode, handleEditInput }) => {
  const [formControl, setFormControl] = useState("")
  useEffect(() => {
    setFormControl("")
  }, [editMode])

  const onInputChange = (id, editedValue) => {
    if (editedValue === rowToEdit || !editedValue) {
      setFormControl("")
    } else {
      setFormControl("is-valid")
    }

    handleEditInput(id, editedValue, rowToEdit)
  }

  const renderInputs = () => {
    switch (id) {
      case "is_callable":
      case "has_price":
      case "were_redeemed":
      case "is_multiplicative_amortization":
        return (
          <p style={{ fontWeight: "bold" }}>
            <select
              className={`form-control ${formControl}`}
              name={id}
              defaultValue={rowToEdit}
              onChange={(e) => onInputChange(id, e.target.value)}
            >
              <option key="false">false</option>
              <option key="true">true</option>
            </select>
          </p>
        )
      case "Curve Correction Final Period Indicator":
      case "Early Redemption (call option)":
      case "Tax Free (Law 12_431)":
        return (
          <p style={{ fontWeight: "bold" }}>
            <select
              className={`form-control ${formControl}`}
              name={id}
              defaultValue={rowToEdit}
              onChange={(e) => onInputChange(id, e.target.value)}
            >
              <option
                key="S"
                value="S"
              >
                S
              </option>
              <option
                key="N"
                value="N"
              >
                N
              </option>
            </select>
          </p>
        )
      case "Issue Date":
      case "Maturity Date":
      case "Exit Date":
      case "Yield Start Date":
        return (
          <p style={{ fontWeight: "bold" }}>
            <InputMask
              className={`form-control ${formControl}`}
              name={id}
              defaultValue={moment(rowToEdit).utc(false).format("DD/MM/YYYY")}
              mask="99/99/9999"
              autoComplete="off"
              onChange={(e) => onInputChange(id, e.target.value)}
            />
          </p>
        )
      case "Index Multiplier":
      case "Interest New Criteria- Rate":
      case "Interest New Criteria- Term":
      case "Nominal Value":
      case "Quantity Issued":
        return (
          <p style={{ fontWeight: "bold" }}>
            <input
              className={`form-control ${formControl}`}
              type="number"
              step="0.01"
              name={id}
              placeholder={rowToEdit}
              defaultValue={rowToEdit}
              autoComplete="off"
              onChange={(e) => onInputChange(id, e.target.value)}
            />
          </p>
        )
      case "Price Index Reference Day":
        return (
          <p style={{ fontWeight: "bold" }}>
            <input
              className={`form-control ${formControl}`}
              type="number"
              step="1"
              name={id}
              placeholder={rowToEdit}
              defaultValue={rowToEdit}
              autoComplete="off"
              onChange={(e) => onInputChange(id, e.target.value)}
            />
          </p>
        )
      case "instrumentType":
        return (
          <p style={{ fontWeight: "bold" }}>
            <input
              className={`form-control ${formControl}`}
              name={id}
              placeholder={rowToEdit}
              defaultValue={rowToEdit}
              autoComplete="off"
              onChange={(e) => onInputChange(id, e.target.value)}
            />
          </p>
        )
      default:
        return (
          <p style={{ fontWeight: "bold" }}>
            <input
              className={`form-control ${formControl}`}
              name={id}
              // placeholder={String(rowToEdit)}
              defaultValue={rowToEdit}
              autoComplete="off"
              onChange={(e) => onInputChange(id, e.target.value)}
            />
          </p>
        )
    }
  }

  return (
    <div className="col-md-4">
      {editMode ? renderInputs() : <p style={{ fontWeight: "bold" }}>{rowToEdit}</p>}
    </div>
  )
}

export default CriCraInfoEditInput
