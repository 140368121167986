import React from "react"
import Modal from "react-bootstrap/Modal"
import Loading from "../../../../components/FullScreenLoading"

import api from "../../../../services/api"

import { faSave, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Swal from "sweetalert2"

const CriCraModalSaveConfirm = ({
  onHide,
  isCustomCriCraFlag,
  criCraToRegister,
  reloadDataTable,
  show,
  changedAttributes,
}) => {
  const [loader, showLoader, hideLoader] = Loading()
  // TODO -> CHANGE ENDPOINTS TO CRI/CRA
  const confirmCriCraRegistration = async () => {
    showLoader()
    onHide()

    if (isCustomCriCraFlag == 0) {
      let mongoId = criCraToRegister._id["$oid"]
      let criCraCode = criCraToRegister["B3 Code"][0].value

      let editedCriCra = {
        id: mongoId,
        editedCriCra: criCraToRegister,
      }

      try {
        const response = await api.post("registerEditedCriCra", editedCriCra)
        console.log(response)
        if (response.data.hasAgenda) {
          Swal.fire({
            icon: "success",
            title: "CRI/CRA cadastrado com sucesso!",
            text: `Verifique no filtro da aba de Cadastro de Agendas o código ${criCraCode}, para realizar o cadastro de agenda.`,
            showConfirmButton: true,
          })
        } else {
          Swal.fire({
            icon: "info",
            title: "CRI/CRA cadastrado com sucesso, porém...",
            text: `O CRI/CRA ${criCraCode} foi cadastrada com sucesso, porém sua agenda não foi encontrada pelo micro serviço`,
            showConfirmButton: true,
          })
        }
      } catch (e) {
        if (e.response.status === 410) {
          Swal.fire({
            icon: "error",
            title: "Falha ao cadastrar CRI/CRA!",
            text: `Aparentemente o CRI/CRA (${criCraCode}) já foi cadastrada no banco de dados por outro usuário. Caso esta debênture
            continue nessa lista contate um administrador.`,
            showConfirmButton: true,
          })
        } else {
          Swal.fire({
            icon: "error",
            title: "Sistema fora do ar",
            text: `Espero um dia essa mensagem nunca aparecer, se isso acontecer, é por que provavelmente algo está errado no back-end`,
            showConfirmButton: true,
          })
        }
      }
    } else {
      let customCriCra = {
        customCriCra: criCraToRegister,
      }

      customCriCra = JSON.stringify(customCriCra)

      try {
        await api.post("registerCustomCriCra", customCriCra)
      } catch (e) {
        console.log(e)
      }

      Swal.fire({
        icon: "info",
        text: "CRI/CRA cadastrada na lista de CRI/CRA pendentes para cadastro...",
        showConfirmButton: true,
      })
    }

    reloadDataTable(criCraToRegister)
    hideLoader()
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirmação do cadastro da CRI/CRA
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-center">Atributos Alterados :</h4>
          <div className="row">
            <div className="col-md-4">
              {Object.keys(changedAttributes).map(function (key, index) {
                return changedAttributes[key].old !=
                  changedAttributes[key].new ? (
                  <span key={index}>
                    <p>
                      {key}:{" "}
                      <span className="text-danger fw-bold">
                        {changedAttributes[key].old}
                      </span>
                    </p>
                  </span>
                ) : null
              })}
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faLongArrowAltRight} size="6x" />
            </div>
            <div className="col-md-4">
              {Object.keys(changedAttributes).map(function (key, index) {
                return changedAttributes[key].new !=
                  changedAttributes[key].old ? (
                  <span key={index}>
                    <p>
                      {key}:{" "}
                      <span className="text-success fw-bold">
                        {changedAttributes[key].new}
                      </span>
                    </p>
                  </span>
                ) : null
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-grid gap-2 text-center d-md-block mx-auto">
            <button
              className="btn btn-success me-2"
              onClick={() => confirmCriCraRegistration()}
            >
              <FontAwesomeIcon icon={faSave} size="1x" />
              Confirmar Alterações
            </button>

            <button className="btn btn-danger ms-2 " onClick={onHide}>
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {loader}
    </>
  )
}

export default CriCraModalSaveConfirm
