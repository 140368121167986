import DataTable from 'react-data-table-component';
import ToggleButton from 'react-bootstrap/ToggleButton';
import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { customStyles } from '../../../PricingDFA/common/CallsTableModalColsStyles';

import React, { useState } from "react";
import BondsCallTradesContainerModal from '../BondsCallTradesContainerModal';
import CellInputEditor from './components/CellInputEditor';
import { generateXlsx } from '../../../../actions/generateExcel';
import ModalWithList from './components/ModalWithColunmsList';

const conditionalRowStyles = [
	  {
  		when: row => parseInt(row.reliability_score) <= 59 && row.trade_yield,
  		style: {
        backgroundColor: 'rgba(231, 186, 35, 0.9)',
        color: 'black',
 		  },
  	},
    {
  		when: row => row.new_mid_m2m > 0 || row.new_reliability_score >= 0,
  		style: {
        backgroundColor: 'rgba(0, 255, 0, 0.9)',
 		  },
  	},
    {
  		when: row => row.fails_qa_test == true,
  		style: {
        backgroundColor: 'rgba(255, 255, 0, 0.8)',
 		  },
  	},
  ];

const TickerListTable = ({ tickerData, debBondsCallTradeData, changeBondsQaData, openSaveConfirmModal, isFinalDataAnalysis, finalDataAnalysisDate }) => {
  // const [filterText, setFilterText] = useState('');
  // const filteredItems = tickerData.filter(
  //   item => item.ticker && item.conglomerate_name.toLowerCase().includes(filterText.toLowerCase()),
  // );
  const [radioValue, setRadioValue] = useState('STRESSED');
  const filteredItems = tickerData.filter(item => item.calculation_method 
    && item.calculation_method.toLowerCase().includes(radioValue.toLowerCase()))
  const [bondsCallTradesModal, setBondsCallTradesModal] = useState(false);
  const [conglomerateWork, setConglomerateWork] = useState("");

  const [hideColumns, setHideColumns] = useState({
    "D-": false,
    "ask_m2m": true,
    "ask_median_D0": false,
    "best_ask": false,
    "index": false,
    "best_bid": false,
    "best_spread": true,
    "bid_m2m": true,
    "bid_median_D0": false,
    "mid_median_D0": false,
    "conglomerate_cnpj": true,
    "conglomerate_name": true,
    "is_callable": true,
    "is_ipca": true,
    "is_tax_free": false,
    "mid_D-1": false,
    "mid_D-5": true,
    "mid_m2m": false,
    "mid_maculayDuration": false,
    "rating": true,
    "ticker": false,
    "trade_yield": false,
    "trade_yield_with_boundaries_nm": false,
    "trade_yield_with_boundaries_rr": false,
    "unfiltered_trades_mean_price": false,
    "unfiltered_trades_mean_m2m_yield": false,
    "unfiltered_trades_sum_financial_volume": false,
    "unfiltered_trades_count": false,
    "unfiltered_trades_percentage_issue_nominal_value": false,
    "unfiltered_trades_percentage_par_value": false,
    "last_trade_date": false,
    "RMAE": false,
    "last_trade_yield": false,
    "last_trade_yield_with_boundaries_nm": true,
    "last_trade_yield_with_boundaries_rr": true,
    "estimated_yield": true,
    "reliability_score": false,
    "original_mid_m2m": true,
    "using_model": true,
  })

  const handleHideColumns = (name) => {
    setHideColumns(prevState => ({ 
      ...prevState,
      [name]: !prevState[name]
    }))
  }

  const formatDecimals = (value) => {
    if (value && value !== "nan"){
      var newValue = parseFloat(value).toFixed(3)

      return newValue
    } else {
      return value
    }
  }

  const checkDateForFinalProcess =() => {
    var triggerDate = moment(new Date()).format("YYYY-MM-DD")
    var date = moment(finalDataAnalysisDate.date).format("YYYY-MM-DD")

    if (moment(date).isSame(triggerDate)){
      return true
    } else {
      return false
    }
  }

  const isRegular = radioValue.toLowerCase() === 'regular';

  const tickerInfoCols = React.useMemo(() => 
    [
      {
        name: 'm2m_mid_with_d0_bid_ask_test',
        selector: row => row.m2m_mid_with_d0_bid_ask_test === false ? "A" : "B",
        sortable: true,
        omit: true,
      },
      {
        name: 'Ticker',
        selector: row => row.ticker,
        omit: hideColumns.ticker,
        grow: 0.4,
      },
      {
        name: 'Rating',
        selector: row => row.rating,
        omit: hideColumns.rating,
      },
      {
        name: 'Conglo. Name',
        selector: row => row.conglomerate_name,
        omit: hideColumns.conglomerate_name,
      },
      {
        name: 'Conglo. CNPJ',
        selector: row => row.conglomerate_cnpj,
        omit: hideColumns.conglomerate_cnpj,
      },
      {
        name: 'Duration',
        selector: row => formatDecimals(row.mid_maculayDuration),
        omit: hideColumns.mid_maculayDuration,
        grow: 0.5,
      },
      {
        name: 'Callable?',
        cell: row => row.is_callable ? <FontAwesomeIcon icon={faCheck} color={"green"} /> 
        : <FontAwesomeIcon icon={faExclamation} color={"red"} />,
        omit: hideColumns.is_callable,
        grow: 0.5,
      },
      {
        name: 'IPCA?',
        cell: row => row.is_ipca ? <FontAwesomeIcon icon={faCheck} color={"green"} /> 
        : <FontAwesomeIcon icon={faExclamation} color={"red"} />,
        omit: hideColumns.is_ipca,
        grow: 0.5,
      },
      {
        name: 'Index',
        selector: row => row.nature,
        omit: hideColumns.nature,
      },
      {
        name: 'Tax Free?',
        cell: row => row.is_tax_free ? <FontAwesomeIcon icon={faCheck} color={"green"} /> 
        : <FontAwesomeIcon icon={faExclamation} color={"red"} />,
        omit: hideColumns.is_tax_free,
        grow: 0.5,
      },
      {
        name: 'Best Bid',
        selector: row => formatDecimals(row.best_bid),
        omit: hideColumns.best_bid,
        grow: 0.4,
        conditionalCellStyles: [{
          when: row => row.best_ask !== "nan" && row.best_bid !== "nan" && parseFloat(row.best_bid) < parseFloat(row.best_ask),
            style: {                                                                                  
              backgroundColor: "rgba(255, 0, 0, 1)",
              color: "black",
              fontWeight: "bold",
            },
          }
        ],
      },
      {
        name: 'Best Ask',
        selector: row => formatDecimals(row.best_ask),
        omit: hideColumns.best_ask,
        grow: 0.4,
        conditionalCellStyles: [{
          when: row => row.best_bid !== "nan" && row.best_ask !== "nan" && parseFloat(row.best_ask) > parseFloat(row.best_bid),
            style: {                                                                                  
              backgroundColor: "rgba(255, 0, 0, 1)",
              color: "black",
              fontWeight: "bold",
            },
          }
        ],
      },
      {
        name: 'Best Spread',
        selector: row => formatDecimals(row.best_spread),
        omit: hideColumns.best_spread,
      },
      {
        name: 'Mid D-5',
        selector: row => formatDecimals(row["mid_D-5"]),
        omit: hideColumns["mid_D-5"],
      },
      {
        name: <label title='M2M D-1 Percentage Issue Nominal Value'>m2m D-1 % do VNE</label>,
        selector: row => formatDecimals(row["m2m_D-1_percentage_issue_nominal_value"]),
        omit: hideColumns["m2m_D-1_percentage_issue_nominal_value"] || isRegular,
      },
      {
        name: <label title='M2M D-1 Percentage Par Value'>m2m D-1 % PAR</label>,
        selector: row => formatDecimals(row["m2m_D-1_percentage_par_value"]),
        omit: hideColumns["m2m_D-1_percentage_par_value"] || isRegular,
      },
      {
        name: 'Mid D-1',
        selector: row => formatDecimals(row["mid_D-1"]),
        omit: hideColumns["mid_D-1"],
      },
      {
        name: 'Bid M2M',
        selector: row => formatDecimals(row["bid_m2m"]),
        omit: hideColumns["bid_m2m"],
      },
      {
        name: 'Ask M2M',
        selector: row => formatDecimals(row["ask_m2m"]),
        omit: hideColumns["ask_m2m"],
      },
      {
        name: 'Mid M2M',
        cell: row => <CellInputEditor 
          ticker={row.ticker}
          cellDefaultValue={ row.new_mid_m2m ? row.new_mid_m2m : formatDecimals(row.mid_m2m) } 
          changeBondsQaData={changeBondsQaData} 
          inputName={"mid_m2m"}
        />,
        omit: hideColumns["mid_m2m"],
        grow: 0.4,
        style: {
          backgroundColor: "rgba(0, 255, 255, 1)",
          	color: "white",
          },
      },
      {
        name: 'Score',
        cell: row => <CellInputEditor
          ticker={row.ticker}
          cellDefaultValue={ row["new_reliability_score"] ? row["new_reliability_score"] : row["reliability_score"] }
          changeBondsQaData={changeBondsQaData} 
          inputName={"reliability_score"}
        />,
        selector: row => row["reliability_score"],
        omit: hideColumns["reliability_score"],
        grow: 0.3,
      },
      {
        name: 'D-0',
        selector: row => row["D-"],
        omit: hideColumns["D-"],
        minWidth: "15px",
        grow: 0.7,
      },
      {
        name: <label title='Bid Median D0'>BMD0</label>,
        selector: row => formatDecimals(row["bid_median_D0"]),
        omit: hideColumns["bid_median_D0"],
      },
      {
        name: <label title='Ask Median D0'>AMD0</label>,
        selector: row => formatDecimals(row["ask_median_D0"]),
        omit: hideColumns["ask_median_D0"],
      },
      {
        name: <label title='Mid Median D0'>MMD0</label>,
        selector: row => formatDecimals(row["mid_median_D0"]),
        omit: hideColumns["mid_median_D0"],
        conditionalCellStyles: [{
          when: row => row["mid_median_D0"] > 0,
            style: {                                                                                  
              backgroundColor: "rgba(204, 255, 204, 1)",
              color: "black",
              fontWeight: "bold",
            },
          }
        ],
      },
      {
        name: <label title='Unfiltered Trades Mean Price'>All trades Mean Price</label>,
        selector: row => formatDecimals(row["unfiltered_trades_mean_price"]),
        omit: hideColumns["unfiltered_trades_mean_price"] || isRegular,
      },
      {
        name: <label title='Unfiltered Trades Mean m2m Yield'>All trades Mean Yield</label>,
        selector: row => formatDecimals(row["unfiltered_trades_mean_m2m_yield"]),
        omit: hideColumns["unfiltered_trades_mean_m2m_yield"] || isRegular,
      },
      {
        name: <label title='Unfiltered Trades Sum Financial Volume'>All trades Volume $</label>,
        selector: row => formatDecimals(row["unfiltered_trades_sum_financial_volume"]),
        omit: hideColumns["unfiltered_trades_sum_financial_volume"] || isRegular,
      },
      {
        name: <label title='Unfiltered Trades Count'>All trades counter</label>,
        selector: row => formatDecimals(row["unfiltered_trades_count"]),
        omit: hideColumns["unfiltered_trades_count"] || isRegular,
      },
      {
        name: <label title='Unfiltered Trades Percentage Issue Nominal Value'>All trades % VNE</label>,
        selector: row => formatDecimals(row["unfiltered_trades_percentage_issue_nominal_value"]),
        omit: hideColumns["unfiltered_trades_percentage_issue_nominal_value"] || isRegular,
      },
      {
        name: <label title='Unfiltered Trades Percentage Par Value'>All trades % PAR</label>,
        selector: row => formatDecimals(row["unfiltered_trades_percentage_par_value"]),
        omit: hideColumns["unfiltered_trades_percentage_par_value"] || isRegular,
      },
      {
        name: 'Trade Yield',
        selector: row => formatDecimals(row["trade_yield"]),
        omit: hideColumns["trade_yield"],
        conditionalCellStyles: [{
          when: row => row["trade_yield"] > 0 || row["trade_yield"] < 0,
            style: {                                                                                  
              backgroundColor: "rgba(203, 254, 90, 1)",
              color: "black",
              fontWeight: "bold",
            },
          }
        ],
      },
      {
        name: <label title='Mist. de Normais'>MN</label>,
        selector: row => formatDecimals(row["trade_yield_with_boundaries_nm"]),
        omit: hideColumns["trade_yield_with_boundaries_nm"],
      },
      {
        name: <label title='Reg. Robusta'>RR</label>,
        selector: row => formatDecimals(row["trade_yield_with_boundaries_rr"]),
        omit: hideColumns["trade_yield_with_boundaries_rr"],
      },
      {
        name: 'LTD',
        selector: row => row["last_trade_date"] ? moment(row["last_trade_date"]).format("DD/MM/YYYY") : null,
        omit: hideColumns["last_trade_date"],
      },
      {
        name: 'LTY',
        selector: row => formatDecimals(row["last_trade_yield"]),
        omit: hideColumns["last_trade_yield"],
      },
      {
        name: 'RMAE',
        selector: row => row["RMAE"],
        omit: hideColumns["RMAE"],
      },
      {
        name: <label title='Std. M2M Mid Rate'>SM2MMR</label>,
        selector: row => formatDecimals(row["std_m2m_mid_rate"]),
        omit: hideColumns["std_m2m_mid_rate"],
      },
      {
        name: <label title='Prev. M2M Mid Rate'>PM2MMR</label>,
        selector: row => formatDecimals(row["previous_m2m_mid_rate"]),
        omit: hideColumns["previous_m2m_mid_rate"],
      },
      {
        name: <label title='Delta Rate'>Delta Rate</label>,
        selector: row => formatDecimals(row["delta_rate"]),
        omit: hideColumns["delta_rate"],
      },
      {
        name: 'Ult. Mist. Normais',
        selector: row => formatDecimals(row["last_trade_yield_with_boundaries_nm"]),
        omit: hideColumns["last_trade_yield_with_boundaries_nm"],
      },
      {
        name: 'Ult. Reg. Robusta',
        selector: row => formatDecimals(row["last_trade_yield_with_boundaries_rr"]),
        omit: hideColumns["last_trade_yield_with_boundaries_rr"],
      },
      {
        name: 'Estimated Yield',
        selector: row => formatDecimals(row["estimated_yield"]),
        omit: hideColumns["estimated_yield"],
      },
      {
        name: 'Orig. Mid m2m',
        selector: row => formatDecimals(row["original_mid_m2m"]),
        omit: hideColumns["original_mid_m2m"],
      },
      {
        name: 'Using Model',
        selector: row => row["using_model"],
        omit: hideColumns["using_model"],
      },
      {
        name: 'Reg. Model',
        selector: row => row.regression_model,
        omit: !isFinalDataAnalysis,
        grow: 3,
      },
    ], [hideColumns, tickerData, radioValue]);

  const openModal = (conglomerate) => {
    var tickerList = []
    var tickerByConglomerateList = tickerData.filter(
      item => item.conglomerate_cnpj.includes(conglomerate),
    );

    tickerByConglomerateList.forEach((ticker) => tickerList.push(ticker.ticker))

    setBondsCallTradesModal(true);
    setConglomerateWork(tickerList);
  }

  const radios = [
    { name: 'Estressadas', value: 'STRESSED' },
    { name: 'Regulares', value: 'REGULAR' },
  ];

  return (
    tickerData.length  > 0 ?
    <>
      <div className="col-md-12 text-center">
        <ModalWithList 
          hideColumns={handleHideColumns}
          hiddenColumns={hideColumns}
        />
      </div>

      <div className="col-md-12 text-center">
        {radios.map((radio, idx) => (
          <ToggleButton
            className='mt-3'
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant={idx % 2 ? 'outline-success' : 'outline-danger'}
            name="radio"
            value={radio.value}
            checked={radioValue === radio.value}
            onChange={(e) => setRadioValue(e.currentTarget.value)}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </div>

      <DataTable   
        columns={tickerInfoCols}
        data={filteredItems}
        defaultSortFieldId={1}
        highlightOnHover
        customStyles={customStyles}
        subHeader
        subHeaderComponent={
          isFinalDataAnalysis ? 
            <button
            type="button"
            className="btn btn-info mb-2"
            onClick={() => generateXlsx(finalDataAnalysisDate.date, finalDataAnalysisDate.is_preview, tickerData)}>
              Gerar Arquivo Excel
          </button> 
          :
          <button
            type="button"
            className="btn btn-info mb-2"
            onClick={() => generateXlsx(localStorage.getItem("date"), localStorage.getItem("is_preview"), tickerData)}>
              Gerar Arquivo Excel
          </button> 
        }
        fixedHeader
        conditionalRowStyles={conditionalRowStyles}
        onRowDoubleClicked={(row) => openModal(row.conglomerate_cnpj)}
        pagination={true}
        paginationPerPage={150}
        paginationRowsPerPageOptions={[150]}
      />
      <div className="d-grid gap-2">
        {isFinalDataAnalysis ? 
        <button
          type="button"
          className="btn btn-success mb-5 mt-3"
          disabled={!checkDateForFinalProcess()}
          onClick={() => openSaveConfirmModal(true)}>
            Salvar Alterações
        </button>
        :
        <button
          type="button"
          className="btn btn-success mb-5 mt-3"
          onClick={() => openSaveConfirmModal(true)}>
            Calcular Alterações (Prosseguir para Próxima Etapa)
        </button>
        }
      </div>
      <BondsCallTradesContainerModal 
        conglomerate={conglomerateWork}
        show={bondsCallTradesModal}
        onHide={() => setBondsCallTradesModal(false)}
        debBondsCallTradeData={debBondsCallTradeData}
      />
    </>
    : null
  );
};

export default TickerListTable;