import React, { useEffect, useState } from "react";

import api from "../../../../../services/api";

import Modal from "react-modal";

import { AiOutlineCloseCircle } from "react-icons/ai";

import "./style.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const ModalDownloadCvmFile = ({
  showModalDownload,
  setShowModalDownload,
  fileUrl,
}) => {
  const [fileUrlDownload, setFileUrlDownload] = useState("");

  useEffect(() => {
    const downloadCvmFile = async () => {
      try {
        const key = fileUrl.split("amazonaws.com/")[1];
        const url = await api.post("/downloadCvmFile", {
          key,
        });
        setFileUrlDownload(url.data.url);
      } catch (e) {
        console.log(e);
      }
    };
    downloadCvmFile();
  }, [fileUrl]);

  return (
    <>
      <Modal
        isOpen={showModalDownload}
        onRequestClose={() => setShowModalDownload(true)}
        style={customStyles}
      >
        <div>
          <div>
            <div className="button-close-modal">
              <button onClick={() => setShowModalDownload(false)}>
                <AiOutlineCloseCircle
                  color="#7c00d3"
                  size="38px"
                  background="transparent"
                />
              </button>
            </div>
            <div>
              <button className="btn__download">
                <a href={fileUrlDownload}>Download</a>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(ModalDownloadCvmFile);
