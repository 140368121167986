import React, {useState, useEffect} from "react";

import Modal from 'react-bootstrap/Modal'
import CheckboxGroup from 'react-checkbox-group'

import "./style.css";


const SelectedCallsConfiguration = (props) => {
    const { calls } = props

    const [callsTimeList, setCallsTimeList] = useState([])
    const [callsSourceList, setCallsSourceList] = useState([])
    
    const [selectedTimes, setSelectedTimes] = useState([])
    const [selectedSources, setSelectedSources] = useState([])

    useEffect(() => {
        setSelectedTimes([])
        setSelectedSources([])
        var timeList = []
        var sourceList = []

        Object.keys(calls).forEach(function(key) {
            calls[key].forEach(function(callTime) {
                if (!timeList.includes(callTime.time)){
                    timeList.push(callTime.time)
                } 
            })
        });

        Object.keys(calls).forEach(function(key) {
            calls[key].forEach(function(callSource) {
                if (!sourceList.includes(callSource.source)){
                    sourceList.push(callSource.source)
                } 
            })
        });

        setCallsTimeList(timeList)
        setCallsSourceList(sourceList)
    }, [calls]);

    const savePreSelectCallsConfig = () => {
        var config = {
            'times': selectedTimes,
            'sources': selectedSources,
        }

        sessionStorage.setItem('selectedCallsConfig', JSON.stringify(config));
        props.onHide()
    }


    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            centered="true"
            keyboard={ false }
            dialogClassName="dfa-modal-xl"
            >
            <Modal.Header>
                <Modal.Title className="col-sm-12 d-flex justify-content-center col-sm-12 d-flex modal-title h5">
                    Configurações
                </Modal.Title>
            </Modal.Header>
            <Modal.Header>
                <div className="text-danger"> 
                    <p>
                    Essas configurações só vão se aplicar em vencimentos não analisados (Status de análise: Pendente), 
                    caso nenhuma caixa tenha sido marcada o algoritmo irá selecionar todas as calls que estão dentro do limite (inferior e superior).
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>    
                <CheckboxGroup name="times" value={selectedTimes} onChange={setSelectedTimes}>
                    {(Checkbox) => (
                        <>
                        <span className="selected_calls_group_title"> Pré selecionar calls nos horários: </span>
                        <div className="selected_calls_groupbox">
                        {callsTimeList.map(function(time, index) {
                            return (
                            <span className="selected_calls_groupbox_values" key={index}>
                                <Checkbox value={time} /> {time}
                            </span>      
                            )
                        })}
                        </div>
                        </>
                    )}
                </CheckboxGroup>
                <CheckboxGroup name="sources" value={selectedSources} onChange={setSelectedSources}>
                    {(Checkbox) => (
                        <>
                        <span className="selected_calls_group_title"> Pré selecionar calls das seguintes corretoras: </span>
                        <div className="selected_calls_groupbox">
                        {callsSourceList.map(function(source, index) {
                            return (
                            <span className="selected_calls_groupbox_values" key={index}>
                                <Checkbox value={source} /> {source}
                            </span>      
                            )
                        })}
                        </div>
                        </>
                    )}
                </CheckboxGroup>
            </Modal.Body>
            <Modal.Footer className="text-center">
                <div className="col-md-12 justify-content-center">
                    <button 
                        className="btn btn-warning"
                        onClick={() => savePreSelectCallsConfig()}>
                            Salvar Configuração
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default SelectedCallsConfiguration;
