import React from 'react';
import moment from 'moment'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line, Bar} from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

ChartJS.defaults.datasets.line.showLine = false;
ChartJS.defaults.elements.point.radius = 6

const QaLineChart = ({title, chartType, dataToChart, instrumentType}) => {
    var singleAxis = {
        responsive: true,
        pointStyle: 'circle',
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: title,
          },
        },
    }

    var multiAxis = {
        responsive: true,
        pointStyle: 'circle',
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: title,
          },
        },
        scales: {
            y: {
              type: 'linear',
              display: true,
              position: 'left',
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
      
              // grid line settings
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
            },
        },
    }; 

    var barChart = {
        type: 'bar',
        responsive: true,
        plugins: {
            legend: {
            position: 'bottom',
        },
        title: {
            display: true,
            text: title,
        }
        }
      };

    const labels = dataToChart.map((obj) => moment(obj.maturity_date).format("DD/MM/YYYY"))


    const DI1xDI1minus1 = {
        labels,
        datasets: [
            {
                label: 'DI D0',
                data: dataToChart.map((obj) => obj["yield d0_D0"]),
                borderColor: 'rgb(30,144,255)',
                backgroundColor: 'rgb(30,144,255)',
                showLine: true,
                pointRadius: 3,
                tension: 0.1,
            },
            {
                label: 'DI D-1',
                data: dataToChart.map((obj) => obj["yield d-1_D0"]),
                pointStyle: 'circle',
                borderColor: 'rgb(255,127,80)',
                backgroundColor: 'rgb(255,127,80)',
            },
        ]
    }

    const YTMandPREMIUM = {
        labels,
        datasets: [
            {
                label: 'Mid YTM',
                data: dataToChart.map((obj) => obj["mid_rate_D0"]),
                borderColor: 'rgb(30,144,255)',
                backgroundColor: 'rgb(30,144,255)',
                showLine: true,
                pointRadius: 3,
                tension: 0.1,
                yAxisID: 'y',
            },
            {
                label: 'Mid YTM D-1',
                data: dataToChart.map((obj) => obj["mid_rate_D-1"]),
                pointRadius: 4,
                borderColor: 'rgb(255,127,80)',
                backgroundColor: 'rgb(255,127,80)',
                yAxisID: 'y',
            },
            {
                label: 'Prêmio D-1',
                data: dataToChart.map((obj) => obj["premium D-1"]),
                borderColor: 'rgb(119,136,153)',
                backgroundColor: 'rgb(119,136,153)',
                pointRadius: 4,
                tension: 0.1,
                yAxisID: 'y1',
            },
            {
                label: 'Prêmio D0',
                data: dataToChart.map((obj) => obj["premium D0"]),
                pointStyle: 'circle',
                borderColor: 'rgb(0, 255,255)',
                backgroundColor: 'rgb(0, 255,255)',
                showLine: true,
                yAxisID: 'y1',
            }
        ]
    }

    const YTM = {
        labels,
        datasets: [
            {
                label: 'Mid YTM',
                data: dataToChart.map((obj) => obj["mid_rate_D0"]),
                showLine: true,
                pointRadius: 3,
                tension: 0.1,
                borderColor: 'rgb(30,144,255)',
                backgroundColor: 'rgb(30,144,255)',
            },
            {
                label: 'Mid YTM D-1',
                data: dataToChart.map((obj) => obj["mid_rate_D-1"]),
                pointRadius: 4,
                borderColor: 'rgb(255,127,80)',
                backgroundColor: 'rgb(255,127,80)',    
            },
        ]
    }

    const BreakEvenInflation = {
        labels,
        datasets: [
            {
                label: 'Mid Break-Even Inflation D0',
                data: dataToChart.map((obj) => obj["mid_breakevenInflation_D0"]),
                borderColor: 'rgb(30,144,255)',
                backgroundColor: 'rgb(30,144,255)',
                showLine: true,
                pointRadius: 3,
                tension: 0.1,
            },
            {
                label: 'Mid Break-Even Inflation D-1',
                data: dataToChart.map((obj) => obj["mid_breakevenInflation_D-1"]),
                pointRadius: 4,
                borderColor: 'rgb(255,127,80)',
                backgroundColor: 'rgb(255,127,80)',  
            },
        ]
    }

    const AnaliseRetorno = {
        labels,
        datasets: [
            {
                label: 'Retorno',
                data: dataToChart.map((obj) => obj["return"]),
                borderColor: 'rgb(30,144,255)',
                backgroundColor: 'rgb(30,144,255)',
            },
            {
                label: 'Delta Yield',
                data: dataToChart.map((obj) => obj["delta yield"]),
                borderColor: 'rgb(255,127,80)',
                backgroundColor: 'rgb(255,127,80)',
            },
            instrumentType !== "NTB" ?
            {
                label: 'Retorno DI1',
                data: dataToChart.map((obj) => obj["DI1 return"]),
                borderColor: 'rgb(119,136,153)',
                backgroundColor: 'rgb(119,136,153)',
            }
            : 
            {
                label: '',
                data: null,
                backgroundColor: 'rgb(245,245,245)'
            }
        ]
    }
    
    const renderChartType = () => { 
        if (chartType === "DI1xDI1minus1") {    
            return ( 
                <Line options={singleAxis} data={DI1xDI1minus1} />
            )
        }
        
        if (chartType === "YTMandPREMIUM") {    
            return ( 
                <Line options={multiAxis} data={YTMandPREMIUM} />
            )
        }

        if (chartType === "YTM") {    
            return ( 
                <Line options={singleAxis} data={YTM} />
            )
        }

        if (chartType === "BreakEvenInflation") {    
            return ( 
                <Line options={singleAxis} data={BreakEvenInflation} />
            )
        }

        if (chartType === "AnaliseRetorno") {    
            return ( 
                <Bar options={barChart} data={AnaliseRetorno} />
            )
        }
  
    }

    return(
        <div className='container mt-3'>
            {renderChartType()}
       </div>
    )
};

export default QaLineChart;